import styled from "styled-components";
import React from "react";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import GroupsIcon from "@mui/icons-material/Groups";
import BussinessIcon from "../../../../icons/building.png";
import InfoIcon from "@mui/icons-material/Info";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import WorkIcon from "@mui/icons-material/Work";

export const Rectangle = styled.div`
  background-color: #ffffff;
  border: 4px solid #f8fafc;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 140px;
  margin: 16px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
`;

export const MyInRow = styled.div`
  flex-direction: row;
  display: flex;
  gap: 16px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 22px;
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  overflow: hidden;
  background-color: #f4f4f4;
  border-radius: 50%;
`;

export const Title = styled.h4`
  color: #333;
  margin: 0;
  font-size: 16px;
  margin-right: 8px;
`;

export const Website = styled.a`
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
  margin-top: 4px;
  display: inline-block;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
`;

export const Mt10 = styled.div`
  margin-top: ${(props) => props.marginTop || "10px"};
  margin-right: ${(props) => props.marginRight || "10px"};
`;

export const TextDefaultR = styled.text`
  color: ${(props) => (props.color ? props.color : "#000")};
  font-size: ${(props) => (props.size ? props.size : "15px")};
  font-weight: ${(props) => (props.bold ? props.bold : "bold")};
  margin: 8px 0 0;
  display: flex;
  align-items: center;
`;

export const ImgOpt = styled.img`
  width: ${(props) => props.width || "60px"};
  height: ${(props) => props.height || "60px"};
  object-fit: cover;
`;

const ProfileInfo = ({
  companyData,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Rectangle>
          <Row>
            <Icon>
              <ImgOpt src={BussinessIcon} />
            </Icon>
            <div>
              <Title> {companyData?.name}</Title>
              <Website
                href={`${companyData?.domain}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {companyData?.domain}
              </Website>
              <Row>
                {companyData?.linkedin !== "Not informed" && (
                  <a
                    href={companyData?.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon
                      sx={{
                        color: "#8A97AA",
                        fontSize: "1.4rem",
                        verticalAlign: "middle",
                      }}
                    />
                  </a>
                )}
              
              </Row>
            </div>
          </Row>
          <Row>
          <Mt10>
            <TextDefaultR color="#8A97AA">
              {" "}
              <FmdGoodIcon
                sx={{ fontSize: "1.2rem", verticalAlign: "middle" }}
              />{" "}
              Address{" "}
            </TextDefaultR>
            <TextDefaultR color="#4B4B4B" bold="600">
              {" "}
              {companyData?.location != null ? (
                `${companyData?.location}`
              ) : (
                <i>Not Found</i>
              )}
            </TextDefaultR>
            </Mt10>
             <Mt10>
              <TextDefaultR color="#8A97AA">
                {" "}
                <GroupsIcon
                  sx={{ fontSize: "1.2rem", verticalAlign: "middle" }}
                />{" "}
                Headcount{" "}
              </TextDefaultR>
              <TextDefaultR color="#4B4B4B" bold="600">
                {companyData?.size != null ? (
                  companyData?.size
                ) : (
                  <i>Not Found</i>
                )}
              </TextDefaultR>
            </Mt10>
            <Mt10>
              <TextDefaultR color="#8A97AA">
                {" "}
                <InfoIcon
                  sx={{ fontSize: "1.2rem", verticalAlign: "middle" }}
                />{" "}
                Industry{" "}
              </TextDefaultR>
              <TextDefaultR color="#4B4B4B" bold="600">
                {companyData?.industry != null ? (
                  companyData?.industry
                ) : (
                  <i>Not Found</i>
                )}
              </TextDefaultR>
            </Mt10>
          </Row>
          <Row>
          <Mt10>
            <TextDefaultR color="#8A97AA">
              {" "}
              <WorkIcon
                sx={{ fontSize: "1.2rem", verticalAlign: "middle" }}
              />{" "}
              Type{" "}
            </TextDefaultR>
            <TextDefaultR color="#4B4B4B" bold="600">
              {companyData?.type != null ? companyData?.type : <i>Not Found</i>}
            </TextDefaultR>
          </Mt10>
          <Mt10>
            <TextDefaultR color="#8A97AA">
              {" "}
              <WorkHistoryIcon
                sx={{ fontSize: "1.2rem", verticalAlign: "middle" }}
              />{" "}
              Founded at{" "}
            </TextDefaultR>
            <TextDefaultR color="#4B4B4B" bold="600">
              {companyData?.founded != null ? (
                companyData?.founded
              ) : (
                <i>Not Found</i>
              )}
            </TextDefaultR>
          </Mt10> 
          </Row>      
        </Rectangle>

      </div>
    </>
  );
};

export default ProfileInfo;
