import { Grid } from "@mui/material";
import { InputNumber } from "antd";
import styled from "styled-components";

export const Empty = styled.div`
  height: 80dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    width: 40%;
    color: #1b2e8d;
  }

  img {
    width: 80px;
  }

  button {
    background-color: #246dec;
    border: none;
    outline: none;
    font-size: 16px;
    color: #fafafa;
    padding: 12px 24px;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: #3a8cf7;
    }

    &:active {
      background-color: #026AA2;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin-bottom: 4px;

  button {
    font-size: 16;
    font-weight: bold;
    color: #fafafa;
    background: #026AA2;
    padding: 12px 24px;
    border: none;
    outline: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;

    :hover {
      background: #246dec;
    }

    :active {
      background: #1d47b0;
    }
  }
`;

export const Container = styled.main`
  width: 100%;
  height: 80dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TabsContainer = styled.section`
  width: 97%;
  height: fit-content;
  border-radius: 8px;
  transition: all 0.2s;
`;

export const FilterByName = styled.div`
  flex-direction: row;
  display: flex;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  gap: 16px;

  input {
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #aaa;
    outline: none;
  }
`;

export const Heading = styled.h2`
  margin-right: 8px;
`;

export const Ml10 = styled.div`
  margin-left: 10px;
`;

export const HiddenInScreen = styled.p`
  display: none;

  @media print {
    display: block;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
`;

export const MyCard = styled.div`
  border-radius: 18px;
  background-color: #e9edf8;
  padding: 20px;
  flex: 1 0 calc(33.33% - 10px);
  max-width: 300px;
  box-sizing: border-box;
  min-height: 100px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;
export const MyCardContent = styled.div`
  width: 100%;
`;

export const Card = styled.div`
  width: 750px;
  height: auto;
  padding: 20px;
  border: 3px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out;

  ${({ active }) =>
    active &&
    `
    border-color: #4b4b4b;
  `}
`;

export const LeftInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const RightInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const InfoTitle = styled.span`
  font-weight: bold;
  color: #4b4b4b;
  white-space: nowrap;
`;

export const InfoTitleR = styled.span`
  font-weight: normal;
`;
export const InfoValue = styled.span`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 80%;
  margin-left: 5px;
`;

export const InfoRight = styled.span`
  margin-bottom: 15px;
`;

export const InfoPill = styled.span`
  margin-bottom: 15px;
  color: white;
  padding: 5px;
  border-radius: 3px;
  display: inline-block;
  font-size: 11px;
  width: 100px;

  ${(props) => {
    const matchScore = parseFloat(props.children);

    if (matchScore > 50) {
      return `
        background-color: #2C915C;
      `;
    } else if (matchScore >= 40 && matchScore <= 50) {
      return `
        background-color: #D9831D;
      `;
    } else {
      return `
        background-color: #BA2E2E;
      `;
    }
  }};
`;

export const CustomGrid = styled(Grid)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
export const DetailsWrapper = styled.div`
  p {
    padding-left: 24px;
  }
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const ExportButton = styled.button`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.main[500]};
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  &:hover {
    background-color: #365486;
    border: none;
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 30%;
  height: 47px;
  line-height: 30px;
  padding-left: 12px;
  border: 2px solid transparent;
  border-radius: 10px;
  outline: none;
  background-color: #f8fafc;
  color: #4b4b4b;
  transition: 0.5s ease;
  border-width: 2px;
  border-color: #e9edf8;
  font-weight: 530;
  font-size: 13px;
`;
export const MainFilter = styled.div`
  background-color: #fafafa;
  max-width: 100%;
  display: grid;
  flex-direction: column;
  align-items: center;
  align-items: center;
  margin-top: 20px;

  gap: 16px;
  box-shadow: 0 4px 4px #00000040;
  border-radius: 8px;
  padding: 24px 40px;

  h2 {
    color: #17283e;
    grid-column: 1/-1;
  }

  input {
    width: 70%;
    height: 47px;
    line-height: 30px;
    padding-left: 12px;
    border: 2px solid transparent;
    border-radius: 10px;
    outline: none;
    background-color: #f8fafc;
    color: #4b4b4b;
    transition: 0.5s ease;
    border-width: 2px;
    border-color: #e9edf8;
    font-weight: 530;
    font-size: 13px;
    margin-right: 5px;
  }

  .basic-multi-select {
    width: 100%;
  }

  .searchBy {
    grid-column: 1/-1;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

export const InRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 100%;
  > * {
    flex: 1;
  }
`;

export const SearchButton = styled.button`
  background-color: ${({ theme }) => theme.colors.main[500]};
  border: none;
  border-radius: 4px;
  color: #fff;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 12px;
  margin-left: 5px;

  &:hover {
    background-color: #214eb9;
  }
`;

export const ClearButton = styled.button`
  background-color: #fafafa;
  border: 1px solid #fafafa;
  border-radius: 4px;
  color: #000;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: #214eb9;
  }
`;

export const Underline = styled.div`
  width: 5%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.main[500]};
`;

export const CardDetails = styled.div`
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 8px 12px;
  margin-left: 20px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > div {
    display: flex;
  }
`;

export const SelectDefaultC = styled.select`
  border-width: 2px;
  border-color: #e9edf8;
  width: 50%;
  height: 50px;
  padding-right: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #8a97aa;
  margin-right: 8px;
`;
