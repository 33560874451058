import styled from "styled-components";
import mapaMundi from "../../icons/mapaAzulSee.png";
import mapaR from "../../icons/mapaR.png";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.main[500]};
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const ContainerWithMap = styled(Container)`
  flex: 1;
`;

export const MapaMundiR = styled.div`
  width: 580px;
  height: 590px;
  background-image: url(${mapaR});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 20px 20px 0;
`;
export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormRegister = styled.div`
  width: 600px;
  height: 590px;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px 0 0 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PaymentInput = styled.input`
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #aaa;
  outline: none;
`;

export const ContainerPay = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.fullWidth ? "100%" : "calc(50% - 8px)")};
`;

export const StyledInput = styled.input`
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #aaa;
  outline: none;
  width: 100%;
`;

export const Label = styled.small`
  margin-bottom: 4px;
  font-size: 12px;
  color: #666;
`;

export const BtnNextSolutions = styled.div`
  width: 90%;
  padding: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  color: #fff;
  justify-self: right;
  transition: all 0.2s;
  margin-top: 16px;
`;

export const Rectangle = styled.div`
  margin-top: 16px;
  width: 100%;
  border-radius: 4px;
  padding: 16px;
  border: 1px solid #356efe;
`;

export const BoxCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableItem = styled.div`
  padding: 3px 0;
  font-size: 16px;
  color: #333;
`;