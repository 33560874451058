import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import { BoldText, DetailsWrapper } from "../search/style";

const MyCollapse = ({ data }) => {
  const PrimaryDetails = (
    <DetailsWrapper>
      <p>
        <BoldText>Notes:</BoldText>{" "}
        {data?.details?.notes ? data?.details?.notes : "-"}
      </p>
    </DetailsWrapper>
  );
  const AddressDetails = (
    <DetailsWrapper>
      <p>
        <BoldText>City:</BoldText>{" "}
        {data?.details?.addressDetails.city
          ? data?.details?.addressDetails.city
          : "-"}
      </p>
      <p>
        <BoldText>State:</BoldText>{" "}
        {data?.details?.addressDetails.state
          ? data?.details?.addressDetails.state
          : "-"}
      </p>
      <p>
        <BoldText>Street:</BoldText>{" "}
        {data?.details?.addressDetails.country
          ? data?.details?.addressDetails.country
          : "-"}
      </p>
      <p>
        <BoldText>Country:</BoldText>{" "}
        {data?.details?.addressDetails.street
          ? data?.details?.addressDetails.street
          : "-"}
      </p>
      <p>
        <BoldText>Zip-Code:</BoldText>{" "}
        {data?.details?.addressDetails.zipCode
          ? data?.details?.addressDetails.zipCode
          : "-"}
      </p>
    </DetailsWrapper>
  );

  const [isReady, setIsReady] = useState(false);
  const [idNumber, setIdNumber] = useState([]);
  const [idIssueDate, setIdIssueDate] = useState([]);
  const [idIssuingCountry, setIdIssuingCountry] = useState([]);
  const [idNotes, setIdNotes] = useState([]);
  const [idType, setIdType] = useState([]);

  useEffect(() => {
    if(data !== null) {
      const { idDetails } = data?.details;
      const idNumber = idDetails.map((detail) => detail.idNumber)
      const idIssueDate = idDetails.map((detail) => detail.idIssueDate)
      const idIssuingCountry = idDetails.map((detail) => detail.idIssuingCountry)
      const idNotes = idDetails.map((detail) => detail.idNotes)
      const idType = idDetails.map((detail) => detail.idType)
      setIdNumber(idNumber)
      setIdIssueDate(idIssueDate)
      setIdIssuingCountry(idIssuingCountry)
      setIdNotes(idNotes)
      setIdType(idType)
      setIsReady(true)
    }
  }, [data])
  
  const IdDetails = (
    <DetailsWrapper>
      {isReady ? (
        <div>
          <p>
              <BoldText>Id Issue Date:</BoldText>  {idIssueDate?.map(
              (id, index) => idIssueDate?.length - 1 === index ? `${idIssueDate}`  : `${idIssueDate} | `
            )}
            </p>
            <p>
              <BoldText>Id Issuing Country:</BoldText>{" "}
              {idIssuingCountry?.map(
              (id, index) => idIssuingCountry?.length - 1 === index ? `${idIssuingCountry}`  : `${idIssuingCountry} | `
            )}
            </p>
            <p>
              <BoldText>Id Notes:</BoldText> 
              {idNotes?.map(
              (id, index) => idNotes?.length - 1 === index ? `${idNotes}`  : `${idNotes} | `
            )}
            </p>
          <p>
            <BoldText>Id Number:</BoldText>{" "}
            {idNumber?.map(
              (id, index) => idNumber?.length - 1 === index ? `${idNumber}`  : `${idNumber} | `
            )}
          </p>
          <p>
              <BoldText>Id Type:</BoldText> 
              {idType?.map(
              (id, index) => idType?.length - 1 === index ? `${idType}`  : `${idType} | `
            )}
            </p>
        </div>
      ) : (
        <p>-</p>
      )}
    </DetailsWrapper>
  );
  const items = [
    {
      key: "1",
      label: "Primary Details",
      children: PrimaryDetails,
    },
    {
      key: "2",
      label: "Address Details",
      children: AddressDetails,
    },
    {
      key: "3",
      label: "ID Details",
      children: IdDetails,
    },
  ];
  return <Collapse items={items} bordered={false} defaultActiveKey={["1"]} />;
};

export default MyCollapse;
