import React from 'react';
import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined, CheckCircleOutlined  } from '@ant-design/icons';
import { Steps } from 'antd';
const ProgressPayment = ({ finish }) => {
  const doneStatus = finish ? 'finish' : 'wait';
  const payStatus = finish ? 'finish' : 'process';
  const payIcon = finish ? <CheckCircleOutlined /> : <LoadingOutlined />;

  return (
    <Steps
      items={[
        {
          title: 'Verification',
          status: 'finish',
          icon: <SolutionOutlined />,
        },
        {
          title: 'Pay',
          status: payStatus,
          icon: payIcon,
        },
        {
          title: 'Done',
          status: doneStatus,
          icon: <SmileOutlined />,
        },
      ]}
    />
  );
};

export default ProgressPayment;