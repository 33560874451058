import React, { useState } from 'react';
import { Table, Modal, Button, Tooltip } from 'antd';
import ModalMoreInfo from '../ModalMoreInfo';
import moment from 'moment';
import formatToCurrency from '../../../../../../utils/formatToCurrency';
import formatNumber from '../../../../../../utils/numberFormat';
import Capitalize from '../../../../../../utils/capitalize';

const DynamicTable = ({ data, role }) => {

    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const showModal = (record) => {
      setModalContent(record);
      setModalVisible(true);
    };

    const closeModal = () => {
      setModalVisible(false);
    };

    if (!data || data.length === 0) {
      return <p>No data available</p>;
    }

    const infoColumn = {
        title: "+ infos",
        key: "infos",
        render: (text, record) => (
            <Button type="link" onClick={() => showModal(record)}>
          Open
          </Button>
      ),
    };

    const uniqueProductDescriptions = Array.from(
      new Set(data?.map(item => item?.productDescription).filter(Boolean))
    ).map(description => ({
      text: description.length > 30 ? `${description.slice(0, 30)}...` : description,
      value: description,
    }));


    const desiredColumns = [
      {
        dataIndex: "shipmentDate",
        title: "Shipment Date",
        flex: 1,
        // render: (_, record) =>
    
      },        
      {
        dataIndex: "country",
        title: "Country",
        flex: 1,
        render: (_, record) => (
          <>
            {record?.consigneeCountry === "" ? (
              <em>Not Informed</em>
            ) : (
              Capitalize(record?.consigneeCountry)
            )}
          </>
        ),
      },  
      {
        dataIndex: "consigneeName",
        title: "Company",
        flex: 1,
        render: (_, record) => (
          <>
            {record?.consigneeName === "" ? (
              <em>Not Informed</em>
            ) : (
              Capitalize(record?.consigneeName)
            )}
          </>
        ),
      },   
      {
        dataIndex: "productDescription",
        title: "Key Product",
        flex: 1,
        filterSearch: true,
        filters: uniqueProductDescriptions,
        onFilter: (value, record) => record.productDescription === value,
        render: (_, record) => (
          <>
            {record?.productDescription === "" ? (
              <em>Not Informed</em>
            ) : (
              <Tooltip title={record.productDescription.replace(/undefined/gi, "")}>
                {record.productDescription.length > 30 // Ajuste o limite conforme necessário
                  ? `${record.productDescription.replace(/undefined/gi, "").slice(0, 30)}...`
                  : record.productDescription.replace(/undefined/gi, "")}
              </Tooltip>
            )}
          </>
        ),
      },
      {
        dataIndex: "shipperName",
        title: "Shipper Company",
        flex: 1,
        render: (_, record) => (
          <>
            {record?.shipperName === "" ? (
              <em>Not Informed</em>
            ) : (
              Capitalize(record?.shipperName)
            )}
          </>
        ),
      },  
      {
        dataIndex: "shipmentValue",
        title: "Shipment Value (USD)",
        flex: 1,
        sorter: (a, b) => a.shipmentValue - b.shipmentValue,
        render: (_, record) => (
          <>
            {record.shipmentValue <= 0 ? (
              <div style={{ textAlign: "right" }}>
                <em>Not Informed</em>
              </div>
            ) : (
              <div style={{ textAlign: "right" }}>
                {formatToCurrency(record?.shipmentValue)}
              </div>
            )}
          </>
        ),
      },
      {
        dataIndex: "shipmentWeight",
        title: "Shipment Weight (Ton)",
        flex: 1,
        sorter: (a, b) => a.shipmentWeight - b.shipmentWeight,
        render: (_, record) => (
          <>
            {record.shipmentWeight <= 0 ? (
              <div style={{ textAlign: "right" }}>
                <em>Not Informed</em>
              </div>
            ) : (
              <div style={{ textAlign: "right" }}>
                {formatNumber(record?.shipmentWeight)}
              </div>
            )}
          </>
        ),
      },
      // {
      //   dataIndex: "shipperCountry",
      //   title: "Operation Type",
      //   flex: 1,
      //   render: (_, record) =>
      //     `${
      //       role === "Supplier"
      //         ? "Exportation"
      //         : "Importation"
      //     }`,
      // },
      {
        dataIndex: "modeOfTransportation",
        title: "Via",
        flex: 1,
        render: (_, record) => (
          <>
            {record?.modeOfTransportation === "" ? (
              <em>Not Informed</em>
            ) : (
              Capitalize(record?.modeOfTransportation)
            )}
          </>
        ),
      },
      {
        dataIndex: "portOfLading",
        title: "Port Of Lading",
        flex: 1,
        render: (_, record) => (
          <>
            {record.portOfLading === "" ? (
              <em>Not Informed</em>
            ) : (
              Capitalize(record?.portOfLading)
            )}
          </>
        ),
      },
      {
        dataIndex: "portOfUnlading",
        title: "Port Of Unlading",
        flex: 1,
        render: (_, record) => (
          <>
            {record.portOfUnlading === "" ? (
              <em>Not Informed</em>
            ) : (
              Capitalize(record?.portOfUnlading)
            )}
          </>
        ),
      }    
    ];  

    return (
      <>
        <Table columns={desiredColumns} dataSource={data} rowKey="Operation No" />
        <ModalMoreInfo
          visible={modalVisible}
          onCancel={closeModal}
          info={modalContent}
        />
      </>
    );
};

export default DynamicTable;
