import React, { useEffect, useState } from "react";
import { BtnRmList, CardListAddCentral, ColumnContainer, ImgDefault, RowContainer, TextDefault } from "../../../../assets/styles";
import { Checkbox } from "antd";
import country from "../../../../components/Flag";
import { getCompaniesToList, removeList } from "../../../../hooks/ListCompanies";
import formatToCurrency from "../../../../utils/formatToCurrency";

export default function MyListCompanies({...props}) {

    const [selectedLists, setSelectedLists] = useState([])

    const onChange = (e, nameList) => {
        console.log(`checked = ${e.target.checked}`);

        if(e.target.checked) {
            setSelectedLists([...selectedLists, nameList])
            console.log([...selectedLists, nameList])
        } else {
            const newArray = selectedLists.filter(item => item !== nameList);
            setSelectedLists(newArray)
        }
    };

    function formatDate(inputDate) {
        const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
        const date = new Date(inputDate);
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        
        return `${month} ${day}, ${year}`;
    }
    
    const getFlagCountry = (cty) => {        
        if(cty) {            
            const flag = country.filter(obj => obj.country.toLocaleLowerCase() === cty.toLocaleLowerCase());
            return(flag[0].src)
        }
    
        return(require("../../../../flag/world.png"))
    }

    const deleteList = () => {
        if(selectedLists.length > 0) {
            removeList(selectedLists)
            props.setStateAtt(props.stateAtt +1)
            setSelectedLists([])
        }
    }

    const openList = (list) => {
        if(list) {
            props.setTabs('1')
            props.setLoading(true)
            console.log(list)
            
            getCompaniesToList(list.nameList).then(resp => {
                console.log("Lista de Empresas")
                console.log(resp)                
                const companies = {companies: resp, totalCompanies: resp.length}
                props.setDataCompany(companies)
                props.setInfoList(list)
                props.setLoading(false)
                props.setDataList(true)
            })
        }

    }

    useEffect(() => {
        console.log("props.getLists")
        console.log(props.getLists)
    }, [props.getLists])

    function stringArray(array) {
        let string = "";

        array.forEach(element => {
            string += string.length == 0 ? element : " - "+element
        });

        return string

    }

    return(
        <ColumnContainer style={{width: "100%", height: "100%"}}>

            <RowContainer style={{ width: "100%", height: 40, paddingBottom: 10, justifyContent: "space-between", alignItems: "center", paddingLeft: 1}}>
                <BtnRmList onClick={deleteList} style={{marginLeft: 35}} active={selectedLists.length > 0} colorHover="#FFE4E4">
                  <ImgDefault src={require("../../../../icons/deleteSmall.png")} width="15px" height="15px" style={{marginRight: 5}}/>                 
                  <TextDefault size="14px" color="#4b4b4b" bold="600">Delete</TextDefault>
                </BtnRmList>

                {selectedLists.length > 0 ? (
                    <TextDefault color="#8a97aa" size="14px" bold="500">{selectedLists.length} List{selectedLists.length > 1 ? "s": ""} selected</TextDefault>
                ) : (
                    <TextDefault color="#8a97aa" size="14px" bold="500">{props.getLists.length} List{props.getLists.length > 1 ? "s": ""}</TextDefault>
                )}

            </RowContainer>

            {props.getLists.length > 0 ? props.getLists.map((list) => (
                <RowContainer style={{justifyContent: 'center'}} key={list.nameList}>

                    <Checkbox onChange={(e) => onChange(e, list.nameList)} style={{marginRight: 20}}/>

                    <CardListAddCentral onClick={() => openList(list)}>
                        <RowContainer style={{justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                            <RowContainer>
                                <RowContainer style={{width: 60, height: 60, borderRadius: 30, backgroundColor: "#026AA2", justifyContent: "center", alignItems: "center", marginRight: 10}}>
                                    <ImgDefault src={require("../../../../icons/bookmark 4.png")} width="25px" height="25px"/>
                                </RowContainer>
                                <ColumnContainer>
                                    <TextDefault color="#4b4b4b" size="20px">{list.nameList}</TextDefault>
                                    <RowContainer>
                                        <TextDefault color="#8a97aa" size="12px">{formatDate(list.lastUpdate)}</TextDefault>
                                        <TextDefault color="#8a97aa" size="12px" style={{marginLeft: 20}}>List Type: Company</TextDefault>
                                        <TextDefault color="#8a97aa" size="12px" style={{marginLeft: 20}}>Role: {list.role}</TextDefault>
                                        <TextDefault color="#8a97aa" size="12px" style={{marginLeft: 20}}>HsCodes: {stringArray(list.hsCode)}</TextDefault>
                                        <TextDefault color="#8a97aa" size="12px" style={{marginLeft: 20}}>Shipment Value: {formatToCurrency(list.somaShipmentValue)}</TextDefault>
                                    </RowContainer>
                                </ColumnContainer>
                            </RowContainer>                            

                            <ColumnContainer style={{alignItems: "flex-end", paddingRight: 20}}>
                                    <RowContainer>
                                        {list.country ? list.country.slice(0, 3).map((flag) =>  (
                                            <ImgDefault style={{marginLeft: 5}} src={getFlagCountry(flag)} width="40px" height="40px" />
                                        )) : ""}
                                        {list.country.length > 3 ? (
                                            <ImgDefault style={{marginLeft: 5, marginTop: 3}} src={require("../../../../icons/flagMore.png")} width="40px" height="30px" />
                                        ) : ""}
                                    </RowContainer>
                                    <TextDefault color="#8a97aa" size="14px">{list.count} Compan{list.count > 1 ? "ies" : "y"}</TextDefault>
                            </ColumnContainer>
                        </RowContainer>
                    </CardListAddCentral>
                </RowContainer>
            )) : ""}


        </ColumnContainer>
    )
}