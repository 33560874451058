import { Result, Button } from "antd";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Page not found"
            extra={
                <Link to="/">
                    <Button style={{backgroundColor: "#026aa2", color: "#fff"}}>
                        Home
                    </Button>
                </Link>
            }
        />
    );
};

export default NotFound;