const PlansPrice = [
  {
    planName: "Trade Data",
    type: "annual",
    pay: 3600,
    credits: "350",
  },
  {
    planName: "Trade Data",
    type: "monthly",
    pay: 350,
    credits: "350",
  },
  {
    planName: "Trade Data",
    type: "annual",
    pay: 5000,
    credits: "600",
  },
  {
    planName: "Trade Data",
    type: "monthly",
    pay: 500,
    credits: "600",
  }
];

export default PlansPrice;
