/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { message } from "antd";
import { Container, HeaderFilter } from "./styles";
import { TextDefault } from "../../../../assets/styles";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import axios from "axios";

function CreditCardForm({ onCancel, info, address }) {
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardCVC, setCardCVC] = useState("");
  const [isCardFlipped, setIsCardFlipped] = useState(false);
  const [maskedCardNumber] = useState("1234 #### #### 1234");
  const [currentCardBackground] = useState(Math.floor(Math.random() * 25 + 1));
  const [cardBrand, setCardBrand] = useState("visa");

  // address.country

  const handleSubmit = async (event) => {
    event.preventDefault();

    const expMonth = cardExpiry.substring(0, 2);
    const expYear = cardExpiry.substring(2, 6);
    const cartaoNumero = cardNumber?.replace(/[\s.-]/g, ""); 

    const cardData = {
      customerId: info.customerId,
      card: {
        number: cartaoNumero,
        expMonth,
        expYear,
        name: cardName,
        cvc: cardCVC,
        address: {
          postalCode: address?.postalCode,
          country: "US",
          region: address?.state,
          streetAddress: address?.line1,
          city: address?.city,
        },
      },
    };

    try {
      const response = await axios.post(
        "https://api.tsalemetals.com:4578/save-card",
        cardData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        let successMessage = "Card saved successfully!";
        message.success(successMessage);
        window.location.reload();
        onCancel();
      }
    } catch (error) {
      console.error("Erro ao enviar token para o servidor:", error);
      let errorMessage = "Error card number";
      message.error(errorMessage);
    }
  };
  const handleCardExpiryChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-digits
    if (value.length > 6) value = value.slice(0, 6);
    setCardExpiry(value);
  };

  const formatCardExpiry = (value) => {
    if (value.length > 2) {
      return `${value.slice(0, 2)} / ${value.slice(2)}`;
    }
    return value;
  };

  const flipCard = (status) => {
    setIsCardFlipped(status);
  };

  return (
    <Container className="wrapper" id="app">
      <div className="card-form">
        <div className="card-list">
          <div className={`card-item ${isCardFlipped ? "-active" : ""}`}>
            <div className="card-item__side -front">
              <div className="card-item__focus"></div>
              <div className="card-item__cover">
                <img
                  src={`https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/${currentCardBackground}.jpeg`}
                  className="card-item__bg"
                  alt="Card Background"
                />
              </div>
              <div className="card-item__wrapper">
                <div className="card-item__top">
                  <img
                    src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png"
                    className="card-item__chip"
                    alt="Card Chip"
                  />
                  <div className="card-item__type">
                    <img
                      src={`https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/${cardBrand}.png`}
                      className="card-item__typeImg"
                      alt="Card Flag"
                    />
                  </div>
                </div>
                <label htmlFor="cardNumber" className="card-item__number">
                  {maskedCardNumber}
                </label>

                <div className="card-item__content">
                  <label htmlFor="cardName" className="card-item__info">
                    <div className="card-item__holder">Card Holder</div>
                    <div className="card-item__name">
                      {cardName.length ? (
                        <CSSTransition classNames="slide-fade-up" key="1">
                          <div className="card-item__name" key="1">
                            <TransitionGroup name="slide-fade-right">
                              {cardName
                                .replace(/\s\s+/g, " ")
                                .split("")
                                .map((n, index) => (
                                  <span
                                    className="card-item__nameItem"
                                    key={index + 1}
                                  >
                                    {n}
                                  </span>
                                ))}
                            </TransitionGroup>
                          </div>
                        </CSSTransition>
                      ) : (
                        <CSSTransition classNames="slide-fade-up" key="2">
                          <div className="card-item__name" key="2">
                            Full Name
                          </div>
                        </CSSTransition>
                      )}
                    </div>
                  </label>

                  <div className="card-item__date">
                    <label htmlFor="cardMonth" className="card-item__dateTitle">
                      Expires
                    </label>
                    <label htmlFor="cardMonth" className="card-item__dateItem">
                      {cardExpiry ? (
                        <span key={cardExpiry}>
                          {String(cardExpiry).substring(0, 2)}
                        </span>
                      ) : (
                        <span key="2">12</span>
                      )}
                    </label>
                    /
                    <label htmlFor="cardYear" className="card-item__dateItem">
                      {cardExpiry ? (
                        <span key={cardExpiry}>
                          {String(cardExpiry).substring(2, 6)}
                        </span>
                      ) : (
                        <span key="2">28</span>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-item__side -back">
              <div className="card-item__cover">
                <img
                  src={`https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/${currentCardBackground}.jpeg`}
                  className="card-item__bg"
                  alt="Card background"
                />
              </div>
              <div className="card-item__band"></div>
              <div className="card-item__cvv">
                <div className="card-item__cvvTitle">CVV</div>
                <div className="card-item__cvvBand">
                  {cardCVC.split("").map((_, index) => (
                    <span key={index}>*</span>
                  ))}
                </div>
                <div className="card-item__type">
                  <img
                    src={`https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/${cardBrand}.png`}
                    className="card-item__typeImg"
                    alt="Card Flag"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-form__inner">
          <label htmlFor="cardName" className="card-input__label">
            Card Number
          </label>
          <input
            placeholder="Card Number"
            autoComplete="cc-number"
            name="cardNumber"
            className="card-input__input"
            required
            autoFocus
            onChange={(e) => setCardNumber(e.target.value)}
          />

          <div className="card-input">
            <label htmlFor="cardName" className="card-input__label">
              Card Holders
            </label>
            <input
              placeholder="Card Holders"
              type="text"
              id="cardName"
              className="card-input__input"
              value={cardName}
              onChange={(e) => setCardName(e.target.value)}
            />
          </div>

          <div className="card-form__row">
            <div className="card-form__col">
              <label htmlFor="cardName" className="card-input__label">
                Expiry
              </label>
              <input
                type="tel"
                placeholder="MM / YYYY"
                autoComplete="cc-exp"
                name="cardExpiry"
                required
                value={formatCardExpiry(cardExpiry)}
                onChange={handleCardExpiryChange}
                className="card-input__input"
              />
            </div>
            <div className="card-form__col -cvv">
              <div className="card-input">
                <label htmlFor="cardName" className="card-input__label">
                  CVV
                </label>
                <input
                  type="tel"
                  placeholder="CVV"
                  autoComplete="cc-csc"
                  name="cardCVC"
                  required
                  className="card-input__input"
                  autoFocus
                  onChange={(e) => setCardCVC(e.target.value)}
                  onFocus={() => flipCard(true)}
                  onBlur={() => flipCard(false)}
                />
              </div>
            </div>
          </div>

          <button className="card-form__button" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </Container>
  );
}

export default CreditCardForm;
