import React from "react";
import { Modal } from "antd";
import { TextDefault } from "../../../../../../assets/styles";
import { Container, InRow, InfoItem, Section, Title } from "./style";
import CloseIcon from "@mui/icons-material/Close";

const ModalMoreInfo = ({ visible, onCancel, info }) => {
  const getFieldSection = (key) => {
    const consigneeKeywords = ["Consignee", "Unlading", "UnLading"];
    const originKeywords = ["Shipper", "Origin", "Lading"];

    if (consigneeKeywords.some((keyword) => key.includes(keyword))) {
      return "Consignee";
    } else if (originKeywords.some((keyword) => key.includes(keyword))) {
      return "Origin";
    }
    return "General Information";
  };

  const groupedInfo = Object.entries(info).reduce((acc, [key, value]) => {
    const section = getFieldSection(key);
    if (!acc[section]) acc[section] = [];
    acc[section].push({ key, value });
    return acc;
  }, {});

  const orderedSections = ["General Information", "Origin", "Consignee"];

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable={false}
      style={{ minHeight: "300px" }}
      bodyStyle={{ maxHeight: "500px", overflowY: "auto" }}
      width={1000}
    >
      <InRow>
        <TextDefault>More Info</TextDefault>
        <CloseIcon sx={{ cursor: "pointer" }} onClick={onCancel} />
      </InRow>
      <br />
      <Container>
        {orderedSections.map(
          (section) =>
            groupedInfo[section] && (
              <Section key={section}>
                <Title>{section}</Title>
                {groupedInfo[section].map(({ key, value }) => (
                  <InfoItem key={key}>
                    <strong>{key}</strong>: {value}
                  </InfoItem>
                ))}
              </Section>
            )
        )}
      </Container>
    </Modal>
  );
};

export default ModalMoreInfo;
