import { Table, Button, Input, Space } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BackgFlag, BtnAddList, BtnDeleteSmall, BtnLoadMore, BtnRmList, CardListAdd, ColumnContainer, ContainerModalHs, GroupInput, ImgClose, ImgDefault, InputDefault, ModalPainelAddList, RowContainer, TextDefault } from "../../../../assets/styles";
import Capitalize from "../../../../utils/capitalize";
import country from "../../../../components/Flag";
import { TradeDataContext } from "../../useFNP";
import formatToCurrency from "../../../../utils/formatToCurrency";
import { AddCompaniesToList } from "../../../../hooks/ListCompanies";
import { Modal } from "@mui/material";
import { SearchOutlined } from '@ant-design/icons';

export default function GridDataRecord({...props}) {

  const { deleteFavoritedCompany } = useContext(TradeDataContext);

  const [isMapCompanyId, setIsMapComapnyId] = useState([])
  const [isDataTableShort, setIsDataTableShort] = useState([])
  const [loadingMore, setLoadingMore] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModal, setIsModal] = useState(false)
  const [newList, setNewList] = useState(false);
  const [nameNewList, setNameNewList] = useState();
  const [selectedRow, setSelectedRow] = useState([]);
  const [filterCountry, setFilterCountry] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };


  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    
  });

  useEffect(() => {

    console.log("DADOS DATA RECORD")
    console.log(props.getData)

    let arrayFilterCountry = []


    //Cálculo de relevância de empresas
    //(ShipmentValue / Number Shipment) * Trading Partner² = score

     if(props.getData.length > 0) {      
       const shortArray = props.getData.sort((a, b) => {
         
        let dateA = new Date(a.savedAt)
        let dateB = new Date(b.savedAt)
        return dateB - dateA
       })
       setIsDataTableShort(shortArray)


      const uniqueArrayCountry = props.getData.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.country === obj.country
        ))
      );
  
      uniqueArrayCountry.forEach(element => {
        let data1 = {
          text: Capitalize(element.country),
          value: element.country
        }
  
        arrayFilterCountry.push(data1)
      });

      setFilterCountry(arrayFilterCountry)
  

     }

  }, [props.getData])

  const calcScore = (ship, nShip, partner) => {
    let result = (ship/nShip) * partner**2
    return result
  }

  const closeModal = () => {
    setIsModal(false)
    setNewList(false)
  }

  const sendCompaniesList = (step, listName) => {
    console.log("Add List")
    console.log(selectedRow)
    if(selectedRow.length > 0) {
      if(step == 1) {
        setIsModal(true)
      } else {
        props.setStateAtt(props.stateAtt + 1)
        console.log("Teste undefined")
        console.log(listName)
        console.log(selectedRow)
        AddCompaniesToList(listName, selectedRowKeys, "key")
        .catch(e => {
          console.log(e)
        })
        closeModal()
      }
    }
  }


  const createNewList = () => {
    if(newList) {
      if(nameNewList) {
        sendCompaniesList(2, nameNewList)
        setNewList(false)
        setIsModal(false)
        setNameNewList("")
        props.setStateAtt(props.stateAtt + 1)
      }

    } else {
      setNewList(true)
    }
  }

  const url = (companyName, countryC) => {
    return window.open(      
      `/leadsenrichment-search?nameCompany=${encodeURIComponent(companyName)}&countryC=${encodeURIComponent(countryC)}&otherPage=true`,
      "_blank"
    );
  }

  function formatDate(inputDate) {

    if(inputDate) {
      const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
      const date = new Date(inputDate);
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();
  
      return `${month} ${day}, ${year}`;
    }

    return "-"
  }

  const deleteCompany = () => {
    if(selectedRowKeys.length > 0) {
      deleteFavoritedCompany(selectedRowKeys)
      props.setData([])
    }
  }


  const DropMenu = ({param}) => { 
      return(
          <div class="paste-button">
              <button class="buttonDrop">Option &nbsp; ▼</button>
              <div class="dropdown-content">
                  <a id="top" onClick={() => handleRedirect(param)}>Company Profile</a>
                  <a id="middle" onClick={() => url(param.companyName, Capitalize(param.country))}>Leads</a>                                                     
              </div>
          </div>
      )
  }

  const getFlagCountry = (cty) => {        
      if(cty) {            
          const flag = country.filter(obj => obj.country.toLocaleLowerCase() === cty.toLocaleLowerCase());
          return(flag[0].src)
      }
  
      return(require("../../../../flag/world.png"))
  }
  const handleRedirect = (params) => {
  
    return window.open(
      `/trade-data?companyName=${params.companyName}&companyId=${params.id}&country=${params.country}&role=${params.role}&isFavorited="Favorited"`,
      "_blank"
    );
    
  };
    
    const columns = [      
      {
        title: <TextDefault color="#4b4b4b">Country</TextDefault>,
        dataIndex: 'country',
        key: 'country',
        filters: filterCountry, 
        onFilter: (value, record) => record.country.indexOf(value) === 0,
        render: (_, param) => (
          <RowContainer style={{justifyContent: "flex-start", alignItems: "center", cursor: "pointer"}}>
            <ImgDefault src={getFlagCountry(param.country)} width="40px" height="40px"/>
            <TextDefault color="#8a97aa" size="14px" bold="500" style={{marginLeft: 10}}>{Capitalize(param.country)}</TextDefault>                     
          </RowContainer>
        ),
      },
      {
        title: <TextDefault color="#4b4b4b">Company Name</TextDefault>,
        dataIndex: 'companyName',
        key: 'companyName',
        render: (_, param) => <TextDefault style={{cursor: "pointer"}} color="#8a97aa" size="14px" bold="500">{Capitalize(param.companyName)}</TextDefault>,
        ...getColumnSearchProps('companyName'),
      },
      {
        title: <TextDefault color="#4b4b4b">Saved At</TextDefault>,
        dataIndex: 'shipmentValue',
        key: 'shipmentValue',
        render: (_, param) => <TextDefault style={{cursor: "pointer"}} color="#8a97aa" size="14px" bold="500">{formatDate(param.savedAt)}</TextDefault>
      },
      {
        title: <TextDefault color="#026AA2">Action</TextDefault>,
        dataIndex: 'action',
        key: 'action',
        render: (_, param) => <DropMenu param={param}/>
      },
    ];

    const rowSelection = {
      // função que é chamada quando uma linha é selecionada/desselecionada
      onChange: (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys:', selectedRowKeys);
        console.log('selectedRows:', selectedRows);
        setSelectedRowKeys(selectedRowKeys)
        setSelectedRow(selectedRows)

      },
      // determina qual propriedade usar como chave para a seleção
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        Table.SELECTION_NONE,
        {
          key: 'odd',
          text: 'Select Odd Row',
          onSelect: (changeableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            setSelectedRowKeys(newSelectedRowKeys);
          },
        },
        {
          key: 'even',
          text: 'Select Even Row',
          onSelect: (changeableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            });
            setSelectedRowKeys(newSelectedRowKeys);
          },
        },
      ],
    };
  
    const handleRowClick = (record) => {
      console.log('Row clicked:', record);
      handleRedirect(record) 
    };
    
      
    return(
      <>
        <Modal
            open={isModal}
            onClose={() => closeModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <ContainerModalHs>
            <ModalPainelAddList>

            <RowContainer style={{width: "100%", justifyContent: "flex-end", paddingRight: 20}}>
                <ImgClose onClick={closeModal} src={require("../../../../icons/CloseG1.png")} width="14px" height="14px"/>
            </RowContainer>

            <ColumnContainer style={{width: "100%", paddingRight: 20, marginTop: 20, height: "100%"}}>
              <CardListAdd onClick={createNewList} next={newList}>
                  <RowContainer style={{width: 40, height: 40, borderRadius: 25, backgroundColor: "#026AA2", justifyContent: "center", alignItems: "center", marginRight: 10}}>
                    <ImgDefault src={require("../../../../icons/addW.png")} width="17px" height="17px"/>
                  </RowContainer>
                  <ColumnContainer>
                    <TextDefault color={newList ? "#fff" : "#4b4b4b"} size="17px">{newList ? "Save" : "Add new list"}</TextDefault>
                  </ColumnContainer>
              </CardListAdd>

              <RowContainer style={{width: "100%", height: 1, backgroundColor: "#e9edf8", marginBottom: 10}} />

              {newList ? (
                <>
                  <TextDefault size="12px" color="#8a97aa" bold="700" style={{marginTop: 25}}>Name List*</TextDefault>
                  <GroupInput className="groupInputSale" style={{marginTop: 5}}>                    
                      <InputDefault                
                      className={"inputSale selectSale"}
                      placeholder="Company Name"             
                      type="text"
                      style={{ color: "#94a3b8"}}
                      value={nameNewList}
                      onChange={(item) => setNameNewList(item.target.value)}
                      />

                  </GroupInput> 
                </>
              ) : (              
                <ColumnContainer style={{width: "100%", overflowY: "auto", overflowX: "hidden", height: "75%"}}>
                  {props.getLists.length > 0 ? props.getLists.map((list) => (
                    <CardListAdd style={{padding: "20px 20px"}} key={list.nameList} onClick={() => sendCompaniesList(2, list.nameList)}>
                        <RowContainer style={{width: 40, height: 40, borderRadius: 25, backgroundColor: "#026AA2", justifyContent: "center", alignItems: "center", marginRight: 10}}>
                          <ImgDefault src={require("../../../../icons/bookmark 4.png")} width="17px" height="17px"/>
                        </RowContainer>
                        <ColumnContainer>
                          <TextDefault color="#4b4b4b" size="17px">{list.nameList}</TextDefault>
                          <TextDefault color="#8a97aa" size="12px">{list.count} Compan{list.count > 1 ? "ies" : "y"}</TextDefault>
                        </ColumnContainer>
                    </CardListAdd>
                  )) : (
                    <ColumnContainer style={{ width: "100%", height: "90%", justifyContent: "center", alignItems: "center"}}>
                      <ImgDefault src={require("../../../../icons/empty-box.png")} width="90px" height="90px"/>
                      <TextDefault color="#366dfb" size="12px" style={{marginTop: 20}}>You don't have any list, add a new one</TextDefault>                      
                    </ColumnContainer>
                  )}
                </ColumnContainer>
              )}

            </ColumnContainer>
                
            </ModalPainelAddList>
          </ContainerModalHs>

        </Modal>

          <RowContainer style={{ width: "100%", height: 40, paddingLeft: 5, alignItems: "center", justifyContent: "space-between", paddingBottom: 10}}>
        
            <RowContainer>
              {/* <BtnRmList onClick={() => sendCompaniesList(1)} style={{marginRight: 15}} active={selectedRow.length > 0} colorHover="#DAF2FF">
                  <ImgDefault src={require("../../../../icons/add4b.png")} width="15px" height="15px" style={{marginRight: 5}}/>                 
                  <TextDefault size="14px" color="#4b4b4b" bold="600">Add my list</TextDefault>
              </BtnRmList> */}

              <BtnRmList onClick={deleteCompany} style={{marginRight: 15}} active={selectedRow.length > 0} colorHover="#FFE4E4">
                  <ImgDefault src={require("../../../../icons/deleteSmall.png")} width="15px" height="15px" style={{marginRight: 5}}/>                 
                  <TextDefault size="14px" color="#4b4b4b" bold="600">Delete</TextDefault>
              </BtnRmList>
            </RowContainer>

            {selectedRow.length > 0 ? (
              <TextDefault color="#8a97aa" size="14px" bold="500" style={{marginTop: 5}}>{selectedRow.length} compan{selectedRow.length > 1 ? "ies" : 'y'} selected</TextDefault>

            ) : (

              <TextDefault color="#8a97aa" size="14px" bold="500" style={{marginTop: 5}}>{isDataTableShort.length} compan{isDataTableShort.length > 1 ? "ies" : 'y'}</TextDefault>
            )}

          </RowContainer>

        <Table
        rowKey={(record) => record.id}
        rowSelection={rowSelection}
        pagination={false}
        dataSource={isDataTableShort}
        columns={columns}
        onRow={(record) => ({
          onClick: () => {
            handleRowClick(record);
          },
        })}  
        />        
      </>
    )
}