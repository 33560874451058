/* eslint-disable no-undef */
const countriesData = [
    {
      src: require("../flag/afghanistan.png"),
      label: "Afghanistan",
      value: "AFG",
    },
    {
      src: require("../flag/albania.png"),
      label: "Albania",
      value: "ALB",
    },
    {
      src: require("../flag/algeria.png"),
      label: "Algeria",
      value: "DZA",
    },
    {
      src: require("../flag/american-samoa.png"),
      label: "American Samoa",
      value: "ASM",
    },
    {
      src: require("../flag/andorra.png"),
      label: "Andorra",
      value: "AND",
    },
    {
      src: require("../flag/angola.png"),
      label: "Angola",
      value: "AGO",
    },
    { src: require("../flag/anguilla.png"), label: "Anguilla",  value: "AIA" },
    {
      src: require("../flag/antigua-and-barbuda.png"),
      label: "Antigua and Barbuda",
      value: "ATG"
    },
    {
      src: require("../flag/argentina.png"),
      label: "Argentina",
      value: "ARG",
    },
    {
      src: require("../flag/armenia.png"),
      label: "Armenia",
      value: "ARM",
    },
    { src: require("../flag/aruba.png"), label: "Aruba", value: "ABW" },
    {
      src: require("../flag/australia.png"),
      label: "Australia",
      value: "AUS",
    },
    {
      src: require("../flag/austria.png"),
      label: "Austria",
      value: "AUT",
    },
    {
      src: require("../flag/azerbaijan.png"),
      label: "Azerbaijan",
      value: "AZE",
    },
    {
      src: require("../flag/bahamas-3.png"),
      label: "Bahamas",
      value: "BHS"
    },
    {
      src: require("../flag/bahrain.png"),
      label: "Bahrain",
      value: "BHR"
    },
    {
      src: require("../flag/barbados.png"),
      label: "Barbados",
      value: "BRB"
    },
    {
      src: require("../flag/belarus.png"),
      label: "Belarus",
      value: "BLR"
    },
    {
      src: require("../flag/belgium-3.png"),
      label: "Belgium",
      value: "BEL"
    },
    {
      src: require("../flag/belize.png"),
      label: "Belize",
      value: "BLZ"
    },
    {
      src: require("../flag/benin.png"),
      label: "Benin",
      value: "BEN"
    },
    {
      src: require("../flag/bermuda.png"),
      label: "Bermuda",
      value: "BMU"
    },
    {
      src: require("../flag/bhutan.png"),
      label: "Bhutan",
      value: "BTN"
    },
    {
      src: require("../flag/bngladesh.png"),
      label: "Bangladesh",
      value: "BGD"
    },
    {
      src: require("../flag/bolivia.png"),
      label: "Bolivia",
      value: "BOL"
    },
    {
      src: require("../flag/bosnia.png"),
      label: "Bosnia",
      value: "BIH"
    },
    {
      src: require("../flag/botswana.png"),
      label: "Botswana",
      value: "BWA"
    },
    {
      src: require("../flag/brazil.png"),
      label: "Brazil",
      value: "BRA"
    },
    {
      src: require("../flag/british-virgin-islands.png"),
      label: "British Virgin Islands",
      value: "VGB"
    },
    { 
      src: require("../flag/brunei.png"),
      label: "Brunei",
      value: "BRN"
    },
    { 
      src: require("../flag/bulgaria.png"),
      label: "Bulgaria",
      value: "BGR"
    },
    {
      src: require("../flag/burkina-faso.png"),
      label: "Burkina Faso",
      value: "BFA"
    },
    { 
      src: require("../flag/burundi.png"),
      label: "Burundi",
      value: "BDI"
    },
    { 
      src: require("../flag/cambodia.png"),
      label: "Cambodia",
      value: "KHM"
    },
    { 
      src: require("../flag/cameroon.png"),
      label: "Cameroon",
      value: "CMR"
    },
    { 
      src: require("../flag/canada.png"),
      label: "Canada",
      value: "CAN"
    },
    { 
      src: require("../flag/cape-verde.png"),
      label: "Cape Verde",
      value: "CPV"
    },
    {
      src: require("../flag/cayman-islands.png"),
      label: "Cayman Islands",
      value: "CYM"
    },
    {
      src: require("../flag/central-african-republic.png"),
      label: "Central African Republic",
      value: "CAF"
    },
    {
      src: require("../flag/chad.png"),
      label: "Chad",
      value: "TCD"
    },
    {
      src: require("../flag/chech-republic.png"),
      label: "Czech Republic",
      value: "CZE"
    },
    {
      src: require("../flag/chile.png"),
      label: "Chile",
      value: "CHL"
    },
    {
      src: require("../flag/china.png"),
      label: "China",
      value: "CHN"
    },
    {
      src: require("../flag/colombia.png"),
      label: "Colombia",
      value: "COL"
    },
    {
      src: require("../flag/comoros.png"),
      label: "Comoros",
      value: "COM"
    },
    {
      src: require("../flag/congo-democratic.png"),
      label: "Congo Democratic",
      value: "COD"
    },
    {
      src: require("../flag/congo-republic.png"),
      label: "Congo Republic",
      value: "COG"
    },
    {
      src: require("../flag/cook-islands.png"),
      label: "Cook Islands",
      value: "COK"
    },
    {
      src: require("../flag/costa-rica.png"),
      label: "Costa Rica",
      value: "CRI"
    },
    {
      src: require("../flag/croatia.png"),
      label: "Croatia",
      value: "HRV"
    },
    {
      src: require("../flag/cuba.png"),
      label: "Cuba",
      value: "CUB"
    },
    {
      src: require("../flag/curacao.png"),
      label: "Curacao",
      value: "CUW"
    },
    {
      src: require("../flag/cyprus.png"),
      label: "Cyprus",
      value: "CYP"
    },
    {
      src: require("../flag/denmark.png"),
      label: "Denmark",
      value: "DNK"
    },
    {
      src: require("../flag/djibouti.png"),
      label: "Djibouti",
      value: "DJI"
    },
    {
      src: require("../flag/dominica.png"),
      label: "Dominica",
      value: "DMA"
    },
    {
      src: require("../flag/dominican-republic.png"),
      label: "Dominican Republic",
      value: "DOM"
    },
    {
      src: require("../flag/ecuador.png"),
      label: "Ecuador",
      value: "ECU"
    },
    {
      src: require("../flag/egypt.png"),
      label: "Egypt",
      value: "EGY"
    },
    {
      src: require("../flag/el-salvador.png"),
      label: "El Salvador",
      value: "SLV"
    },
    {
      src: require("../flag/england.png"),
      label: "England",
      value: "GB-ENG"
    },
    {
      src: require("../flag/equatorial-guinea.png"),
      label: "Equatorial Guinea",
      value: "GNQ"
    },
    {
      src: require("../flag/eritrea.png"),
      label: "Eritrea",
      value: "ERI"
    },
    {
      src: require("../flag/estonia-2.png"),
      label: "Estonia",
      value: "EST"
    },
    {
      src: require("../flag/ethiopia.png"),
      label: "Ethiopia",
      value: "ETH"
    },
    {
      src: require("../flag/faroe-islands.png"),
      label: "Faroe Islands",
      value: "FRO"
    },
    {
      src: require("../flag/fiji.png"),
      label: "Fiji",
      value: "FJI"
    },
    {
      src: require("../flag/finland.png"),
      label: "Finland",
      value: "FIN"
    },
    {
      src: require("../flag/france-3.png"),
      label: "France",
      value: "FRA"
    },
    {
      src: require("../flag/gabon.png"),
      label: "Gabon",
      value: "GAB"
    },
    {
      src: require("../flag/gambia.png"),
      label: "Gambia",
      value: "GMB"
    },
    {
      src: require("../flag/georgia.png"),
      label: "Georgia",
      value: "GEO"
    },
    {
      src: require("../flag/germany.png"),
      label: "Germany",
      value: "DEU"
    },
    {
      src: require("../flag/ghana.png"),
      label: "Ghana",
      value: "GHA"
    },
    {
      src: require("../flag/gibraltar.png"),
      label: "Gibraltar",
      value: "GIB"
    },
    {
      src: require("../flag/greece.png"),
      label: "Greece",
      value: "GRC"
    },
    {
      src: require("../flag/grenada.png"),
      label: "Grenada",
      value: "GRD"
    },
    {
      src: require("../flag/guam.png"),
      label: "Guam",
      value: "GUM"
    },
    {
      src: require("../flag/guatemala.png"),
      label: "Guatemala",
      value: "GTM"
    },
    {
      src: require("../flag/guinea.png"),
      label: "Guinea",
      value: "GIN"
    },
    {
      src: require("../flag/guyana.png"),
      label: "Guyana",
      value: "GUY"
    },
    {
      src: require("../flag/haiti.png"),
      label: "Haiti",
      value: "HTI"
    },
    {
      src: require("../flag/honduras.png"),
      label: "Honduras",
      value: "HND"
    },
    {
      src: require("../flag/hong-kong.png"),
      label: "Hong Kong",
      value: "HKG"
    },
    {
      src: require("../flag/hungary-3.png"),
      label: "Hungary",
      value: "HUN"
    },
    {
      src: require("../flag/iceland.png"),
      label: "Iceland",
      value: "ISL"
    },
    {
      src: require("../flag/india.png"),
      label: "India",
      value: "IND"
    },
    {
      src: require("../flag/indonesia.png"),
      label: "Indonesia",
      value: "IDN"
    },
    {
      src: require("../flag/iran.png"),
      label: "Iran",
      value: "IRN"
    },
    {
      src: require("../flag/iraq.png"),
      label: "Iraq",
      value: "IRQ"
    },
    {
      src: require("../flag/ireland.png"),
      label: "Ireland",
      value: "IRL"
    },
    {
      src: require("../flag/israel.png"),
      label: "Israel",
      value: "ISR"
    },
    {
      src: require("../flag/italy.png"),
      label: "Italy",
      value: "ITA"
    },
    {
      src: require("../flag/jamaica.png"),
      label: "Jamaica",
      value: "JAM"
    },
    {
      src: require("../flag/japan.png"),
      label: "Japan",
      value: "JPN"
    },
    {
      src: require("../flag/jordan-2.png"),
      label: "Jordan",
      value: "JOR"
    },
    {
      src: require("../flag/kazakhstan.png"),
      label: "Kazakhstan",
      value: "KAZ"
    },
    {
      src: require("../flag/kenya.png"),
      label: "Kenya",
      value: "KEN"
    },
    {
      src: require("../flag/kiribati.png"),
      label: "Kiribati",
      value: "KIR"
    },
    {
      src: require("../flag/kosovo.png"),
      label: "Kosovo",
      value: "XKX"
    },
    {
      src: require("../flag/kuwait.png"),
      label: "Kuwait",
      value: "KWT"
    },
    {
      src: require("../flag/kyrgystan.png"),
      label: "Kyrgyzstan",
      value: "KGZ"
    },
    {
      src: require("../flag/laos.png"),
      label: "Laos",
      value: "LAO"
    },
    {
      src: require("../flag/latvia.png"),
      label: "Latvia",
      value: "LVA"
    },
    {
      src: require("../flag/lebanon.png"),
      label: "Lebanon",
      value: "LBN"
    },
    {
      src: require("../flag/lesotho.png"),
      label: "Lesotho",
      value: "LSO"
    },
    {
      src: require("../flag/liberia.png"),
      label: "Liberia",
      value: "LBR"
    },
    {
      src: require("../flag/libya.png"),
      label: "Libya",
      value: "LBY"
    },
    {
      src: require("../flag/liechtenstein.png"),
      label: "Liechtenstein",
      value: "LIE"
    },
    {
      src: require("../flag/lithuania.png"),
      label: "Lithuania",
      value: "LTU"
    },
    {
      src: require("../flag/luxembourg.png"),
      label: "Luxembourg",
      value: "LUX"
    },
    {
      src: require("../flag/macau.png"),
      label: "Macau",
      value: "MAC"
    },
    {
      src: require("../flag/macedonia.png"),
      label: "Macedonia",
      value: "MKD"
    },
    {
      src: require("../flag/madagascar.png"),
      label: "Madagascar",
      value: "MDG"
    },
    {
      src: require("../flag/malaysia.png"),
      label: "Malaysia",
      value: "MYS"
    },
    {
      src: require("../flag/maldives.png"),
      label: "Maldives",
      value: "MDV"
    },
    {
      src: require("../flag/mali-3.png"),
      label: "Mali",
      value: "MLI"
    },
    {
      src: require("../flag/malta.png"),
      label: "Malta",
      value: "MLT"
    },
    {
      src: require("../flag/marshall-islands.png"),
      label: "Marshall Islands",
      value: "MHL"
    },
    {
      src: require("../flag/mauritania.png"),
      label: "Mauritania",
      value: "MRT"
    },
    {
      src: require("../flag/mauritius.png"),
      label: "Mauritius",
      value: "MUS"
    },
    {
      src: require("../flag/mexico.png"),
      label: "Mexico",
      value: "MEX"
    },
    {
      src: require("../flag/moldova.png"),
      label: "Moldova",
      value: "MDA"
    },
    {
      src: require("../flag/monaco.png"),
      label: "Monaco",
      value: "MCO"
    },
    {
      src: require("../flag/mongolia.png"),
      label: "Mongolia",
      value: "MNG"
    },
    {
      src: require("../flag/montenegro.png"),
      label: "Montenegro",
      value: "MNE"
    },
    {
      src: require("../flag/morocco.png"),
      label: "Morocco",
      value: "MAR"
    },
    {
      src: require("../flag/mozambique-3.png"),
      label: "Mozambique",
      value: "MOZ"
    },
    {
      src: require("../flag/myanmar.png"),
      label: "Myanmar",
      value: "MMR"
    },
    {
      src: require("../flag/namibia.png"),
      label: "Namibia",
      value: "NAM"
    },
    {
      src: require("../flag/nauru.png"),
      label: "Nauru",
      value: "NRU"
    },
    {
      src: require("../flag/nepal.png"),
      label: "Nepal",
      value: "NPL"
    },
    {
      src: require("../flag/netherlands-3.png"),
      label: "Netherlands",
      value: "NLD"
    },
    {
      src: require("../flag/new-caledonia.png"),
      label: "New Caledonia",
      value: "NCL"
    },
    {
      src: require("../flag/new-zealand.png"),
      label: "New Zealand",
      value: "NZL"
    },
    {
      src: require("../flag/nicaragua-2.png"),
      label: "Nicaragua",
      value: "NIC"
    },
    {
      src: require("../flag/niger.png"),
      label: "Niger",
      value: "NER"
    },
    {
      src: require("../flag/nigeria.png"),
      label: "Nigeria",
      value: "NGA"
    },
    {
      src: require("../flag/north-korea.png"),
      label: "North Korea",
      value: "PRK"
    },
    {
      src: require("../flag/northern-ireland.png"),
      label: "Northern Ireland",
      value: "NIR"
    },
    {
      src: require("../flag/norway.png"),
      label: "Norway",
      value: "NOR"
    },
    {
      src: require("../flag/oman.png"),
      label: "Oman",
      value: "OMN"
    },
    {
      src: require("../flag/pakistan.png"),
      label: "Pakistan",
      value: "PAK"
    },
    {
      src: require("../flag/palau.png"),
      label: "Palau",
      value: "PLW"
    },
    {
      src: require("../flag/palestine.png"),
      label: "Palestine",
      value: "PSE"
    },
    {
      src: require("../flag/panama.png"),
      label: "Panama",
      value: "PAN"
    },
    {
      src: require("../flag/papua-new-guinea.png"),
      label: "Papua New Guinea",
      value: "PNG"
    },
    {
      src: require("../flag/paraguay.png"),
      label: "Paraguay",
      value: "PRY"
    },
    {
      src: require("../flag/peru.png"),
      label: "Peru",
      value: "PER"
    },
    {
      src: require("../flag/philippines.png"),
      label: "Philippines",
      value: "PHL"
    },
    {
      src: require("../flag/poland.png"),
      label: "Poland",
      value: "POL"
    },
    {
      src: require("../flag/portugal.png"),
      label: "Portugal",
      value: "PRT"
    },
    {
      src: require("../flag/puerto-rico.png"),
      label: "Puerto Rico",
      value: "PRI"
    },
    {
      src: require("../flag/qatar.png"),
      label: "Qatar",
      value: "QAT"
    },
    {
      src: require("../flag/romania.png"),
      label: "Romania",
      value: "ROU"
    },
    {
      src: require("../flag/russia.png"),
      label: "Russia",
      value: "RUS"
    },
    {
      src: require("../flag/rwanda.png"),
      label: "Rwanda",
      value: "RWA"
    },
    {
      src: require("../flag/saint-lucia.png"),
      label: "Saint Lucia",
      value: "LCA"
    },
    {
      src: require("../flag/saint-vincent-and-the-grenadines.png"),
      label: "Saint Vincent and the Grenadines",
      value: "VCT"
    },
    {
      src: require("../flag/samoa.png"),
      label: "Samoa",
      value: "WSM"
    },
    {
      src: require("../flag/san-marino.png"),
      label: "San Marino",
      value: "SMR"
    },
    {
      src: require("../flag/sao-tome-and-principe.png"),
      label: "Sao Tome and Principe",
      value: "STP"
    },
    {
      src: require("../flag/saudi-arabia.png"),
      label: "Saudi Arabia",
      value: "SAU"
    },
    {
      src: require("../flag/scotland.png"),
      label: "Scotland",
      value: "SCT"
    },
    {
      src: require("../flag/senegal.png"),
      label: "Senegal",
      value: "SEN"
    },
    {
      src: require("../flag/serbia.png"),
      label: "Serbia",
      value: "SRB"
    },
    {
      src: require("../flag/seychelles.png"),
      label: "Seychelles",
      value: "SYC"
    },
    {
      src: require("../flag/sierra-leone-2.png"),
      label: "Sierra Leone",
      value: "SLE"
    },
    {
      src: require("../flag/singapore.png"),
      label: "Singapore",
      value: "SGP"
    },
    {
      src: require("../flag/slovakia-2.png"),
      label: "Slovakia",
      value: "SVK"
    },
    {
      src: require("../flag/slovenia.png"),
      label: "Slovenia",
      value: "SVN"
    },
    {
      src: require("../flag/solomon-islands.png"),
      label: "Solomon Islands",
      value: "SLB"
    },
    {
      src: require("../flag/south-africa.png"),
      label: "South Africa",
      value: "ZAF"
    },
    {
      src: require("../flag/south-korea.png"),
      label: "South Korea",
      value: "KOR"
    },
    {
      src: require("../flag/south-sudan.png"),
      label: "South Sudan",
      value: "SSD"
    },
    {
      src: require("../flag/spain.png"),
      label: "Spain",
      value: "ESP"
    },
    {
      src: require("../flag/sri-lanka.png"),
      label: "Sri Lanka",
      value: "LKA"
    },
    {
      src: require("../flag/sudan.png"),
      label: "Sudan",
      value: "SDN"
    },
    {
      src: require("../flag/suriname.png"),
      label: "Suriname",
      value: "SUR"
    },
    {
      src: require("../flag/sweden.png"),
      label: "Sweden",
      value: "SWE"
    },
    {
      src: require("../flag/switzerland.png"),
      label: "Switzerland",
      value: "CHE"
    },
    {
      src: require("../flag/syria.png"),
      label: "Syria",
      value: "SYR"
    },
    {
      src: require("../flag/taiwan.png"),
      label: "Taiwan",
      value: "TWN"
    },
    {
      src: require("../flag/tajikistan.png"),
      label: "Tajikistan",
      value: "TJK"
    },
    {
      src: require("../flag/tanzania.png"),
      label: "Tanzania",
      value: "TZA"
    },
    {
      src: require("../flag/thailand.png"),
      label: "Thailand",
      value: "THA"
    },
    {
      src: require("../flag/togo.png"),
      label: "Togo",
      value: "TGO"
    },
    {
      src: require("../flag/tonga.png"),
      label: "Tonga",
      value: "TON"
    },
    {
      src: require("../flag/tunisia.png"),
      label: "Tunisia",
      value: "TUN"
    },
    {
      src: require("../flag/turkey.png"),
      label: "Turkey",
      value: "TUR"
    },
    {
      src: require("../flag/turkmenistan.png"),
      label: "Turkmenistan",
      value: "TKM"
    },
    {
      src: require("../flag/turks-and-caicos-islands.png"),
      label: "Turks and Caicos Islands",
      value: "TCA"
    },
    {
      src: require("../flag/tuvalu.png"),
      label: "Tuvalu",
      value: "TUV"
    },
    {
      src: require("../flag/uganda.png"),
      label: "Uganda",
      value: "UGA"
    },
    {
      src: require("../flag/uk.png"),
      label: "UK",
      value: "GBR"
    },
    {
      src: require("../flag/ukraine.png"),
      label: "Ukraine",
      value: "UKR"
    },
    {
      src: require("../flag/united-arab-emirates.png"),
      label: "United Arab Emirates",
      value: "ARE"
    },
    {
      src: require("../flag/uruguay.png"),
      label: "Uruguay",
      value: "URY"
    },
    {
      src: require("../flag/usa.png"),
      label: "United States",
      value: "USA"
    },
    {
      src: require("../flag/uzbekistan.png"),
      label: "Uzbekistan",
      value: "UZB"
    },
    {
      src: require("../flag/vanuatu.png"),
      label: "Vanuatu",
      value: "VUT"
    },
    {
      src: require("../flag/vatican.png"),
      label: "Vatican",
      value: "VAT"
    },
    {
      src: require("../flag/venezuela.png"),
      label: "Venezuela",
      value: "VEN"
    },
    {
      src: require("../flag/vietnam.png"),
      label: "Vietnam",
      value: "VNM"
    },
    {
      src: require("../flag/wales.png"),
      label: "Wales",
      value: "WLS"
    },
    {
      src: require("../flag/yemen.png"),
      label: "Yemen",
      value: "YEM"
    },
    {
      src: require("../flag/zimbabwe.png"),
      label: "Zimbabwe",
      value: "ZWE"
    }
  ];
  
  const countries = countriesData.map((country) => {
    return {
      ...country,
      value: country.value,
    };
  });
  
  export default countries;
  