import { Col, Row, Table, Spin } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { ExcelExportButton } from "./download";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { fetchCompaniesByName } from "../../../../service/shipmentsByHs";

export default function GeolocationTable({ data }) {
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const onSelectChange = (selectedKeys, selectedRecords) => {
    setSelectedRowKeys(selectedKeys);
    setSelectedRows(selectedRecords);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: false,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const [statusMap, setStatusMap] = useState({});
  const [loadingMap, setLoadingMap] = useState({});
  const processedCompaniesRef = useRef(new Set());

  useEffect(() => {
    const fetchStatusesSequentially = async () => {
      const newStatusMap = {};
      const newLoadingMap = {};
  
      for (let record of data) {
        const { name, country } = record;
        const key = `${name}_${country}`;
  
        if (!processedCompaniesRef.current.has(key)) {
          processedCompaniesRef.current.add(key);
  
          newLoadingMap[key] = true;
          setLoadingMap((prev) => ({ ...prev, ...newLoadingMap }));
  
          try {
            const result = await fetchCompaniesByName(name, country);
  
            if (result && result.length > 0) {
              newStatusMap[key] = { status: "green", companyId: result[0].companyId, companyName: result[0].companyName };
            } else {
              newStatusMap[key] = { status: "red" };
            }
          } catch (error) {
            newStatusMap[key] = { status: "red" };
          } finally {
            newLoadingMap[key] = false;
            setLoadingMap((prev) => ({ ...prev, ...newLoadingMap }));
          }
  
          setStatusMap((prevMap) => ({ ...prevMap, ...newStatusMap }));
        }
      }
    };
  
    fetchStatusesSequentially();
  }, [data]);

  const columns = [
    {
      title: "Company",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      filters: [...new Set(data.map((item) => item.name))].map((name) => ({
        text: name,
        value: name,
      })),
      onFilter: (value, record) => record.name.includes(value),
      render: (text, record) => (
        <Col style={{ textAlign: "center" }}>
          {record?.logo_url?.length > 0 ? (
            <img
              src={record.logo_url}
              alt={record.name}
              style={{
                width: "30px",
                margin: "0% 2%",
                justifyContent: "center",
              }}
            />
          ) : null}
          <Row style={{ textAlign: "center", justifyContent: "center" }}>
            {text}
          </Row>
        </Col>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      sorter: (a, b) => {
        const locationA = a.location || "";
        const locationB = b.location || "";
        if (!locationA) return 1;
        if (!locationB) return -1;
        return locationA.localeCompare(locationB);
      },
      render: (text, record) => `${text || "-"} - ${record.country || "-"}`,
      filters: [
        ...new Set(data.map((item) => `${item.location} - ${item.country}`)),
      ].map((locationCountry) => {
        const [location, country] = locationCountry.split(" - ");
        return {
          text: locationCountry,
          value: location.trim(),
          country: country?.replace(" - ", "").trim(),
        };
      }),
      onFilter: (value, record) => (record.location || "").includes(value),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      sorter: (a, b) => {
        const numA = parseInt(a.size.replace(/[^0-9]/g, ""), 10);
        const numB = parseInt(b.size.replace(/[^0-9]/g, ""), 10);
        return numA - numB;
      },
      filters: [...new Set(data.map((item) => item.size))].map((size) => ({
        text: size,
        value: size,
      })),
      onFilter: (value, record) => (record.size || "").includes(value),
    },
    {
      title: "Primary Industry",
      dataIndex: "primary_industry",
      key: "primary_industry",
      sorter: (a, b) => a.primary_industry.localeCompare(b.primary_industry),
      filters: [...new Set(data.map((item) => item.primary_industry))].map(
        (industry) => ({ text: industry, value: industry })
      ),
      onFilter: (value, record) =>
        (record.primary_industry || "").includes(value),
    },
    {
      title: "Contact",
      dataIndex: "linkedin_url",
      key: "linkedin_url",
      render: (text, record) => (
        <Row gutter={8} align="middle">
          <Link to={text} target="_blank">
            <LinkedInIcon style={{ color: `${theme.colors.darker[800]}` }} />,
          </Link>
          {record?.website?.length > 0 ? (
            <Link
              to={record?.website}
              target="_blank"
              style={{ marginLeft: 8 }}
            >
              <LanguageIcon style={{ color: `${theme.colors.darker[800]}` }} />
            </Link>
          ) : null}
        </Row>
      ),
    },
    {
      title: "More",
      key: "more",
      render: (text, record) => {
        const { name, country } = record;
        const key = `${name}_${country}`;
        const statusEntry = statusMap[key];
    
        if (loadingMap[key]) {
          return <Spin />;
        } else if (statusEntry?.status === "green") {
          return (
            <CheckCircleOutlined
              style={{ color: "green", fontSize: "20px", cursor: "pointer" }}
              onClick={() => {
                const url = `/trade-data?companyName=${encodeURIComponent(statusEntry.companyName)}&companyId=${statusEntry.companyId}&country=${country}&role=Supplier&isFavorited=false`;
                window.open(url, "_blank");
              }}
            />
          );
        } else {
          return (
            <CloseCircleOutlined style={{ color: "red", fontSize: "20px" }} />
          );
        }
      },
    }
  ];

  return (
    <>
      {hasSelected ? `Selected ${selectedRowKeys.length} items` : null}
      {hasSelected ? (
        <ExcelExportButton data={selectedRows} />
      ) : (
        <ExcelExportButton data={data} />
      )}

      <Table
        rowKey={(record) => record.id || record.key || record.name}
        dataSource={data}
        rowSelection={rowSelection}
        columns={columns}
        style={{ margin: "1%" }}
        bordered
      />
    </>
  );
}
