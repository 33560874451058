export const isoCodeCountryIm = [
  {
    countryOfImport: "Afghanistan",
    isoCode: "AF",
  },
  {
    countryOfImport: "Austria",
    isoCode: "AT",
  },
  {
    countryOfImport: "Belgium",
    isoCode: "BE",
  },
  {
    countryOfImport: "Bulgaria",
    isoCode: "BG",
  },
  {
    countryOfImport: "Cyprus",
    isoCode: "CY",
  },
  {
    countryOfImport: "Czech Republic ",
    isoCode: "CZ",
  },
  {
    countryOfImport: "Germany",
    isoCode: "DE",
  },
  {
    countryOfImport: "Denmark",
    isoCode: "DK",
  },
  {
    countryOfImport: "Estonia",
    isoCode: "EE",
  },
  {
    countryOfImport: "Finland",
    isoCode: "FI",
  },
  {
    countryOfImport: "Span",
    isoCode: "ES",
  },
  {
    countryOfImport: "France",
    isoCode: "FR",
  },
  {
    countryOfImport: "Greece",
    isoCode: "GR",
  },
  {
    countryOfImport: "Croatia",
    isoCode: "HR",
  },
  {
    countryOfImport: "Hungary",
    isoCode: "HU",
  },
  {
    countryOfImport: "Ireland",
    isoCode: "IE",
  },
  {
    countryOfImport: "Italy",
    isoCode: "IT",
  },
  {
    countryOfImport: "Lithuania",
    isoCode: "LT",
  },
  {
    countryOfImport: "Luxembourg",
    isoCode: "LU",
  },
  {
    countryOfImport: "Latvia",
    isoCode: "LV",
  },
  {
    countryOfImport: "Malta",
    isoCode: "MT",
  },
  {
    countryOfImport: "Netherlands",
    isoCode: "NL",
  },
  {
    countryOfImport: "Poland",
    isoCode: "PL",
  },
  {
    countryOfImport: "Portugal",
    isoCode: "PT",
  },
  {
    countryOfImport: "Romania",
    isoCode: "RO",
  },
  {
    countryOfImport: "Sweden",
    isoCode: "SE",
  },
  {
    countryOfImport: "Slovenia",
    isoCode: "SI",
  },
  {
    countryOfImport: "Slovakia",
    isoCode: "SK",
  },
  {
    countryOfImport: "United Kingdom",
    isoCode: "GB",
  },
  {
    countryOfImport: "Canada",
    isoCode: "CA",
  },
  {
    countryOfImport: "United States of America",
    isoCode: "US",
  },
  {
    countryOfImport: "Japan",
    isoCode: "JP",
  },
  {
    countryOfImport: "Norway",
    isoCode: "NO",
  },
  {
    countryOfImport: "Chile",
    isoCode: "CL",
  },
  {
    countryOfImport: "India",
    isoCode: "IN",
  },
  {
    countryOfImport: "Mexico",
    isoCode: "MX",
  },
  {
    countryOfImport: "South Korea",
    isoCode: "KR",
  },
  {
    countryOfImport: "Singapore",
    isoCode: "SG",
  },
  {
    countryOfImport: "Malaysia",
    isoCode: "MY",
  },
  {
    countryOfImport: "Botswana",
    isoCode: "BW",
  },
  {
    countryOfImport: "Lesotho",
    isoCode: "LS",
  },
  {
    countryOfImport: "Namibia",
    isoCode: "NA",
  },
  {
    countryOfImport: "Eswatini",
    isoCode: "NA",
  },
  {
    countryOfImport: "South Africa",
    isoCode: "ZA",
  },
  {
    countryOfImport: "Indonesia",
    isoCode: "ID",
  },
  {
    countryOfImport: "Vietnam",
    isoCode: "VN",
  },
  {
    countryOfImport: "Nepal",
    isoCode: "NP",
  },
  {
    countryOfImport: "Saudi Arabia",
    isoCode: "SA",
  },
  {
    countryOfImport: "China",
    isoCode: "CN",
  },
  {
    countryOfImport: "Hong Kong",
    isoCode: "HK",
  },
  {
    countryOfImport: "Taiwan",
    isoCode: "TW",
  },
  {
    countryOfImport: "Philippines",
    isoCode: "PH",
  },
  {
    countryOfImport: "Switzerland",
    isoCode: "CH",
  },
  {
    countryOfImport: "Guernsey",
    isoCode: "GG",
  },
  {
    countryOfImport: "Jersey",
    isoCode: "JE",
  },
  {
    countryOfImport: "Isle of Man",
    isoCode: "IM",
  },
  {
    countryOfImport: "Australia",
    isoCode: "AU",
  },
  {
    countryOfImport: "New Zealand",
    isoCode: "NZ",
  },
];

export const isoCodeCountryEx = [
  {
    countryOfExport: "Afghanistan",
    isoCode: "AF",
  },
  {
    countryOfExport: "Albania",
    isoCode: "AL",
  },
  {
    countryOfExport: "Algeria",
    isoCode: "DZ",
  },
  {
    countryOfExport: "American Samoa",
    isoCode: "AS",
  },
  {
    countryOfExport: "Andorra",
    isoCode: "AD",
  },
  {
    countryOfExport: "Angola",
    isoCode: "AO",
  },
  {
    countryOfExport: "Anguilla",
    isoCode: "AI",
  },
  {
    countryOfExport: "Antarctica",
    isoCode: "AQ",
  },
  {
    countryOfExport: "Antigua and Barbuda",
    isoCode: "AG",
  },
  {
    countryOfExport: "Argentina",
    isoCode: "AR",
  },
  {
    countryOfExport: "Armenia",
    isoCode: "AM",
  },
  {
    countryOfExport: "Aruba",
    isoCode: "AW",
  },
  {
    countryOfExport: "Austria",
    isoCode: "AT",
  },
  {
    countryOfExport: "Australia",
    isoCode: "AU",
  },
  {
    countryOfExport: "Azerbaijan",
    isoCode: "IE",
  },
  {
    countryOfExport: "Bahamas",
    isoCode: "BS",
  },
  {
    countryOfExport: "Bahrain",
    isoCode: "BH",
  },
  {
    countryOfExport: "Bangladesh",
    isoCode: "BD",
  },
  {
    countryOfExport: "Barbados",
    isoCode: "BB",
  },
  {
    countryOfExport: "Belarus",
    isoCode: "BY",
  },
  {
    countryOfExport: "Belgium",
    isoCode: "BE",
  },
  {
    countryOfExport: "Belize",
    isoCode: "BZ",
  },
  {
    countryOfExport: "Benin",
    isoCode: "BJ",
  },
  {
    countryOfExport: "Bermuda",
    isoCode: "BM",
  },
  {
    countryOfExport: "Åland Islands",
    isoCode: "AX",
  },
  {
    countryOfExport: "Bhutan",
    isoCode: "BT",
  },
  {
    countryOfExport: "Bolivia",
    isoCode: "BO",
  },
  {
    countryOfExport: "Bonaire Sint Eustatius and Saba",
    isoCode: "BQ",
  },
  {
    countryOfExport: "Bosnia and Herzegovina",
    isoCode: "BA",
  },
  {
    countryOfExport: "Botswana",
    isoCode: "BW",
  },
  {
    countryOfExport: "Bouvet Island",
    isoCode: "BV",
  },
  {
    countryOfExport: "Brazil",
    isoCode: "BR",
  },
  {
    countryOfExport: "British Indian Ocean Territory",
    isoCode: "IO",
  },
  {
    countryOfExport: "Brunei Darussalam",
    isoCode: "BN",
  },
  {
    countryOfExport: "Bulgaria",
    isoCode: "BG",
  },
  {
    countryOfExport: "Burkina Faso",
    isoCode: "BF",
  },
  {
    countryOfExport: "Burundi",
    isoCode: "BI",
  },
  {
    countryOfExport: "Cabo Verde",
    isoCode: "CV",
  },
  {
    countryOfExport: "Cambodia",
    isoCode: "KH",
  },
  {
    countryOfExport: "Cameroon",
    isoCode: "CM",
  },
  {
    countryOfExport: "Canada",
    isoCode: "CA",
  },
  {
    countryOfExport: "Cayman Islands",
    isoCode: "KY",
  },
  {
    countryOfExport: "Central African Republic",
    isoCode: "CF",
  },
  {
    countryOfExport: "Chad",
    isoCode: "TD",
  },
  {
    countryOfExport: "Chile",
    isoCode: "CL",
  },
  {
    countryOfExport: "China",
    isoCode: "CN",
  },
  {
    countryOfExport: "Christmas Island",
    isoCode: "CX",
  },
  {
    countryOfExport: "Cocos (Keeling) Islands",
    isoCode: "CC",
  },
  {
    countryOfExport: "Colombia",
    isoCode: "CO",
  },
  {
    countryOfExport: "Comoros",
    isoCode: "KM",
  },
  {
    countryOfExport: "Congo (the Democratic Republic of the)",
    isoCode: "CD",
  },
  {
    countryOfExport: "Congo",
    isoCode: "CG",
  },
  {
    countryOfExport: "Cook Islands",
    isoCode: "CK",
  },
  {
    countryOfExport: "Costa Rica",
    isoCode: "CR",
  },
  {
    countryOfExport: "Croatia",
    isoCode: "HR",
  },
  {
    countryOfExport: "Cuba",
    isoCode: "CU",
  },
  {
    countryOfExport: "Curaçao",
    isoCode: "CW",
  },
  {
    countryOfExport: "Cyprus",
    isoCode: "CY",
  },
  {
    countryOfExport: "Czechia",
    isoCode: "CZ",
  },
  {
    countryOfExport: "Côte dIvoire",
    isoCode: "CI",
  },
  {
    countryOfExport: "Côte dIvoire",
    isoCode: "CI",
  },
  {
    countryOfExport: "Denmark",
    isoCode: "DK",
  },
  {
    countryOfExport: "Djibouti",
    isoCode: "DJ",
  },
  {
    countryOfExport: "Dominica",
    isoCode: "DM",
  },
  {
    countryOfExport: "Dominican Republic",
    isoCode: "DO",
  },
  {
    countryOfExport: "Ecuador",
    isoCode: "EC",
  },
  {
    countryOfExport: "Egypt",
    isoCode: "EG",
  },
  {
    countryOfExport: "El Salvador",
    isoCode: "SV",
  },
  {
    countryOfExport: "Equatorial Guinea",
    isoCode: "GQ",
  },
  {
    countryOfExport: "Eritrea",
    isoCode: "ER",
  },
  {
    countryOfExport: "Estonia",
    isoCode: "EE",
  },
  {
    countryOfExport: "Eswatini",
    isoCode: "SZ",
  },
  {
    countryOfExport: "Ethiopia",
    isoCode: "ET",
  },
  {
    countryOfExport: "Falkland Islands",
    isoCode: "FK",
  },
  {
    countryOfExport: "Faroe Islands",
    isoCode: "FO",
  },
  {
    countryOfExport: "Fiji",
    isoCode: "FJ",
  },
  {
    countryOfExport: "Finland",
    isoCode: "FI",
  },
  {
    countryOfExport: "France",
    isoCode: "FR",
  },
  {
    countryOfExport: "French Guiana",
    isoCode: "GF",
  },
  {
    countryOfExport: "French Polynesia",
    isoCode: "PF",
  },
  {
    countryOfExport: "French Southern Territories",
    isoCode: "TF",
  },
  {
    countryOfExport: "Gabon",
    isoCode: "GA",
  },
  {
    countryOfExport: "Gambia",
    isoCode: "GM",
  },
  {
    countryOfExport: "Georgia",
    isoCode: "GE",
  },
  {
    countryOfExport: "Germany",
    isoCode: "DE",
  },
  {
    countryOfExport: "Ghana",
    isoCode: "GH",
  },
  {
    countryOfExport: "Gibraltar",
    isoCode: "GI",
  },
  {
    countryOfExport: "Greece",
    isoCode: "GR",
  },
  {
    countryOfExport: "Greenland",
    isoCode: "GL",
  },
  {
    countryOfExport: "Grenada",
    isoCode: "GD",
  },
  {
    countryOfExport: "Guadeloupe",
    isoCode: "GP",
  },
  {
    countryOfExport: "Guam",
    isoCode: "GU",
  },
  {
    countryOfExport: "Guatemala",
    isoCode: "GT",
  },
  {
    countryOfExport: "Guernsey",
    isoCode: "GG",
  },
  {
    countryOfExport: "Guinea",
    isoCode: "GN",
  },
  {
    countryOfExport: "Guinea-Bissau",
    isoCode: "GW",
  },
  {
    countryOfExport: "Guyana",
    isoCode: "GY",
  },
  {
    countryOfExport: "Haiti",
    isoCode: "HT",
  },
  {
    countryOfExport: "Heard Island and McDonald Islands",
    isoCode: "HM",
  },
  {
    countryOfExport: "Holy See",
    isoCode: "VA",
  },
  {
    countryOfExport: "Honduras",
    isoCode: "HN",
  },
  {
    countryOfExport: "Hong Kong",
    isoCode: "HK",
  },
  {
    countryOfExport: "Hungary",
    isoCode: "HU",
  },
  {
    countryOfExport: "Iceland",
    isoCode: "IS",
  },
  {
    countryOfExport: "India",
    isoCode: "IN",
  },
  {
    countryOfExport: "Indonesia",
    isoCode: "ID",
  },
  {
    countryOfExport: "Iran (Islamic Republic of)",
    isoCode: "IR",
  },
  {
    countryOfExport: "Iraq",
    isoCode: "IQ",
  },
  {
    countryOfExport: "Ireland",
    isoCode: "IE",
  },
  {
    countryOfExport: "Isle of Man",
    isoCode: "IM",
  },
  {
    countryOfExport: "Israel",
    isoCode: "IL",
  },
  {
    countryOfExport: "Italy",
    isoCode: "IT",
  },
  {
    countryOfExport: "Jamaica",
    isoCode: "JM",
  },
  {
    countryOfExport: "Japan",
    isoCode: "JP",
  },
  {
    countryOfExport: "Jersey",
    isoCode: "JE",
  },
  {
    countryOfExport: "Jordan",
    isoCode: "JO",
  },
  {
    countryOfExport: "Kazakhstan",
    isoCode: "KZ",
  },
  {
    countryOfExport: "Kenya",
    isoCode: "KE",
  },
  {
    countryOfExport: "Kiribati",
    isoCode: "KI",
  },
  {
    countryOfExport: "Korea (the Democratic Peoples Republic of)",
    isoCode: "KP",
  },
  {
    countryOfExport: "Korea (the Republic of)",
    isoCode: "KR",
  },
  {
    countryOfExport: "Kuwait",
    isoCode: "KW",
  },
  {
    countryOfExport: "Kyrgyzstan",
    isoCode: "KG",
  },
  {
    countryOfExport: "Lao Peoples Democratic Republic",
    isoCode: "LA",
  },
  {
    countryOfExport: "Latvia",
    isoCode: "LV",
  },
  {
    countryOfExport: "Lebanon",
    isoCode: "LB",
  },
  {
    countryOfExport: "Lesotho",
    isoCode: "LS",
  },
  {
    countryOfExport: "Liberia",
    isoCode: "LR",
  },
  {
    countryOfExport: "Libya",
    isoCode: "LY",
  },
  {
    countryOfExport: "Liechtenstein",
    isoCode: "LI",
  },
  {
    countryOfExport: "Lithuania",
    isoCode: "LT",
  },
  {
    countryOfExport: "Luxembourg",
    isoCode: "LU",
  },
  {
    countryOfExport: "Macao",
    isoCode: "MO",
  },
  {
    countryOfExport: "Madagascar",
    isoCode: "MG",
  },
  {
    countryOfExport: "Malawi",
    isoCode: "MW",
  },
  {
    countryOfExport: "Malaysia",
    isoCode: "MY",
  },
  {
    countryOfExport: "Maldives",
    isoCode: "MV",
  },
  {
    countryOfExport: "Mali",
    isoCode: "ML",
  },
  {
    countryOfExport: "Malta",
    isoCode: "MT",
  },
  {
    countryOfExport: "Marshall Islands",
    isoCode: "MH",
  },
  {
    countryOfExport: "Martinique",
    isoCode: "MQ",
  },
  {
    countryOfExport: "Mauritania",
    isoCode: "MR",
  },
  {
    countryOfExport: "Mauritius",
    isoCode: "MU",
  },
  {
    countryOfExport: "Mayotte",
    isoCode: "YT",
  },
  {
    countryOfExport: "Mexico",
    isoCode: "MX",
  },
  {
    countryOfExport: "Micronesia (Federated States of)",
    isoCode: "FM",
  },
  {
    countryOfExport: "Moldova (the Republic of)",
    isoCode: "MD",
  },
  {
    countryOfExport: "Monaco",
    isoCode: "MC",
  },
  {
    countryOfExport: "Mongolia",
    isoCode: "MN",
  },
  {
    countryOfExport: "Montenegro",
    isoCode: "ME",
  },
  {
    countryOfExport: "Montserrat",
    isoCode: "MS",
  },
  {
    countryOfExport: "Morocco",
    isoCode: "MA",
  },
  {
    countryOfExport: "Mozambique",
    isoCode: "MZ",
  },
  {
    countryOfExport: "Myanmar",
    isoCode: "MM",
  },
  {
    countryOfExport: "Namibia",
    isoCode: "NA",
  },
  {
    countryOfExport: "Nauru",
    isoCode: "NR",
  },
  {
    countryOfExport: "Nepal",
    isoCode: "NP",
  },
  {
    countryOfExport: "Netherlands",
    isoCode: "NL",
  },
  {
    countryOfExport: "New Caledonia",
    isoCode: "NC",
  },
  {
    countryOfExport: "New Zealand",
    isoCode: "NZ",
  },
  {
    countryOfExport: "Nicaragua",
    isoCode: "NI",
  },
  {
    countryOfExport: "Niger",
    isoCode: "NE",
  },
  {
    countryOfExport: "Nigeria",
    isoCode: "NG",
  },
  {
    countryOfExport: "Niue",
    isoCode: "NU",
  },
  {
    countryOfExport: "Norfolk Island",
    isoCode: "NF",
  },
  {
    countryOfExport: "North Macedonia",
    isoCode: "MK",
  },
  {
    countryOfExport: "Northern Mariana Islands",
    isoCode: "MP",
  },
  {
    countryOfExport: "Norway",
    isoCode: "NO",
  },
  {
    countryOfExport: "Oman",
    isoCode: "OM",
  },
  {
    countryOfExport: "Pakistan",
    isoCode: "PK",
  },
  {
    countryOfExport: "Palau",
    isoCode: "PW",
  },
  {
    countryOfExport: "Palestine State of",
    isoCode: "PS",
  },
  {
    countryOfExport: "Panama",
    isoCode: "PA",
  },
  {
    countryOfExport: "Papua New Guinea",
    isoCode: "PG",
  },
  {
    countryOfExport: "Paraguay",
    isoCode: "PY",
  },
  {
    countryOfExport: "Peru",
    isoCode: "PE",
  },
  {
    countryOfExport: "Philippines",
    isoCode: "PH",
  },
  {
    countryOfExport: "Pitcairn",
    isoCode: "PN",
  },
  {
    countryOfExport: "Poland",
    isoCode: "PL",
  },
  {
    countryOfExport: "Portugal",
    isoCode: "PT",
  },
  {
    countryOfExport: "Puerto Rico",
    isoCode: "PR",
  },
  {
    countryOfExport: "Qatar",
    isoCode: "QA",
  },
  {
    countryOfExport: "Romania",
    isoCode: "RO",
  },
  {
    countryOfExport: "Russian Federation",
    isoCode: "RU",
  },
  {
    countryOfExport: "Rwanda",
    isoCode: "RW",
  },
  {
    countryOfExport: "Réunion",
    isoCode: "RE",
  },
  {
    countryOfExport: "Saint Barthélemy",
    isoCode: "BL",
  },
  {
    countryOfExport: "Saint Helena Ascension and Tristan da Cunha",
    isoCode: "SH",
  },
  {
    countryOfExport: "Saint Kitts and Nevis",
    isoCode: "KN",
  },
  {
    countryOfExport: "Saint Lucia",
    isoCode: "LC",
  },
  {
    countryOfExport: "Saint Martin (French part)",
    isoCode: "MF",
  },
  {
    countryOfExport: "Saint Pierre and Miquelon",
    isoCode: "PM",
  },
  {
    countryOfExport: "Saint Vincent and the Grenadines",
    isoCode: "VC",
  },
  {
    countryOfExport: "Samoa",
    isoCode: "WS",
  },
  {
    countryOfExport: "San Marino",
    isoCode: "SM",
  },
  {
    countryOfExport: "Sao Tome and Principe",
    isoCode: "ST",
  },
  {
    countryOfExport: "Saudi Arabia",
    isoCode: "SA",
  },
  {
    countryOfExport: "Senegal",
    isoCode: "SN",
  },
  {
    countryOfExport: "Serbia",
    isoCode: "RS",
  },
  {
    countryOfExport: "Seychelles",
    isoCode: "SC",
  },
  {
    countryOfExport: "Sierra Leone",
    isoCode: "SL",
  },
  {
    countryOfExport: "Singapore",
    isoCode: "SG",
  },
  {
    countryOfExport: "Sint Maarten (Dutch part)",
    isoCode: "SX",
  },
  {
    countryOfExport: "Slovakia",
    isoCode: "SK",
  },
  {
    countryOfExport: "Slovenia",
    isoCode: "SI",
  },
  {
    countryOfExport: "Solomon Islands",
    isoCode: "SB",
  },
  {
    countryOfExport: "Somalia",
    isoCode: "SO",
  },
  {
    countryOfExport: "South Africa",
    isoCode: "ZA",
  },
  {
    countryOfExport: "South Georgia and the South Sandwich Islands",
    isoCode: "GS",
  },
  {
    countryOfExport: "South Sudan",
    isoCode: "SS",
  },
  {
    countryOfExport: "Spain",
    isoCode: "ES",
  },
  {
    countryOfExport: "Sri Lanka",
    isoCode: "LK",
  },
  {
    countryOfExport: "Sudan",
    isoCode: "SD",
  },
  {
    countryOfExport: "Suriname",
    isoCode: "SR",
  },
  {
    countryOfExport: "Svalbard and Jan Mayen",
    isoCode: "SJ",
  },
  {
    countryOfExport: "Sweden",
    isoCode: "SE",
  },
  {
    countryOfExport: "Switzerland",
    isoCode: "CH",
  },
  {
    countryOfExport: "Syrian Arab Republic",
    isoCode: "SY",
  },
  {
    countryOfExport: "Taiwan (Province of China)",
    isoCode: "TW",
  },
  {
    countryOfExport: "Tajikistan",
    isoCode: "TJ",
  },
  {
    countryOfExport: "Tanzania the United Republic of",
    isoCode: "TZ",
  },
  {
    countryOfExport: "Thailand",
    isoCode: "TH",
  },
  {
    countryOfExport: "Timor-Leste",
    isoCode: "TL",
  },
  {
    countryOfExport: "Togo",
    isoCode: "TG",
  },
  {
    countryOfExport: "Tokelau",
    isoCode: "TK",
  },
  {
    countryOfExport: "Tonga",
    isoCode: "TO",
  },
  {
    countryOfExport: "Trinidad and Tobago",
    isoCode: "TT",
  },
  {
    countryOfExport: "Tunisia",
    isoCode: "TN",
  },
  {
    countryOfExport: "Turkey",
    isoCode: "TR",
  },
  {
    countryOfExport: "Turkmenistan",
    isoCode: "TM",
  },
  {
    countryOfExport: "Turks and Caicos Islands",
    isoCode: "TC",
  },
  {
    countryOfExport: "Tuvalu",
    isoCode: "TV",
  },
  {
    countryOfExport: "Uganda",
    isoCode: "UG",
  },
  {
    countryOfExport: "Ukraine",
    isoCode: "UA",
  },
  {
    countryOfExport: "United Arab Emirates",
    isoCode: "AE",
  },
  {
    countryOfExport: "United Kingdom",
    isoCode: "GB",
  },
  {
    countryOfExport: "United States Minor Outlying Islands",
    isoCode: "UM",
  },
  {
    countryOfExport: "United States of America",
    isoCode: "US",
  },
  {
    countryOfExport: "Uruguay",
    isoCode: "UY",
  },
  {
    countryOfExport: "Uzbekistan",
    isoCode: "UZ",
  },
  {
    countryOfExport: "Vanuatu",
    isoCode: "VU",
  },
  {
    countryOfExport: "Venezuela (Bolivarian Republic of)",
    isoCode: "VE",
  },
  {
    countryOfExport: "Viet Nam",
    isoCode: "VN",
  },
  {
    countryOfExport: "Virgin Islands (British)",
    isoCode: "VG",
  },
  {
    countryOfExport: "Virgin Islands (U.S.)",
    isoCode: "VI",
  },
  {
    countryOfExport: "Wallis and Futuna",
    isoCode: "WF",
  },
  {
    countryOfExport: "Western Sahara",
    isoCode: "EH",
  },
  {
    countryOfExport: "Yemen",
    isoCode: "YE",
  },
  {
    countryOfExport: "Zambia",
    isoCode: "ZM",
  },
  {
    countryOfExport: "Zimbabwe",
    isoCode: "ZW",
  },
  {
    countryOfExport: "Republic of Kosovo",
    isoCode: "XK",
  },
  {
    countryOfExport: "Northern Ireland (United Kingdom)",
    isoCode: "XI",
  },
  {
    countryOfExport: "Ceuta",
    isoCode: "XC",
  },
  {
    countryOfExport: "Melilla",
    isoCode: "XL",
  },
];
