const NUMBER_FORMATTER = new Intl.NumberFormat("en-US");

export default function formatNumber(number) {
  let convertTon = NUMBER_FORMATTER.format(parseFloat(number/1000).toFixed(2));
  if (convertTon.includes(".")) {
    return convertTon;
  } else {
    return convertTon+".00";
  }

}

export function formatNumberKg(number) {
  let convertTon = NUMBER_FORMATTER.format(parseFloat(number).toFixed(2));
  if (convertTon.includes(".")) {
    return convertTon;
  } else {
    return convertTon+".00";
  }

}
