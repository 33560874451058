import React from "react";
import ReactApexChart from "react-apexcharts";
import formatToCurrency from "../../../../../utils/formatToCurrency";
import Country from "../../../../../components/Flag";
import { formatNumberKg } from "../../../../../utils/numberFormat";
import { Square, SquaresContainer } from "./styles";

const ChartValueCountry = ({ data, share }) => {
  
  const getCountry = (id) => {
    if (id) {
      if (id == "Global Search") {
        return "World";
      }
      const flag = Country.filter((obj) => obj.id === id);

      if (flag.length > 0) {
        return flag[0].country;
      }
      return id;
    }

    return "World";
  };

  const top10Data = [...data]
    .filter((item) => item.partnerCode !== 0)
    .sort((a, b) => (b.primaryValue || 0) - (a.primaryValue || 0))
    .filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.partnerCode === item.partnerCode)
    )
    .slice(0, 10)
    .map((item) => ({
      ...item,
      primaryValue:
        item.primaryValue !== undefined && item.primaryValue !== null
          ? item.primaryValue
          : 0,
      country: getCountry(item.partnerCode),
    }));

  const chartData = {
    categories: top10Data.map((item) => getCountry(item.partnerCode)),
    series: [
      {
        name: "Primary Value",
        data: top10Data.map((item) => item.primaryValue),
      },
    ],
  };

  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      distributed: true,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    xaxis: {
      categories: chartData.categories,
      title: {
        text: "By Value",
      },
    },
    yaxis: {
      title: {
        text: "Primary Value",
      },
    },
    tooltip: {
      y: {
        formatter: (value) => formatToCurrency(value),
      },
    },
    colors: [
      "#00E396",
      "#FEB019",
      "#FF4560",
      "#775DD0",
      "#3F51B5",
      "#03A9F4",
      "#4CAF50",
      "#F9CE1D",
      "#FF9800",
      "#33B2DF",
    ],
  };

  const top10DataVolume = [...data]
    .filter((item) => item.partnerCode !== 0)
    .sort((a, b) => (b.netWgt || 0) - (a.netWgt || 0))
    .filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.partnerCode === item.partnerCode)
    )
    .slice(0, 10)
    .map((item) => ({
      ...item,
      netWgt:
        item.netWgt !== undefined && item.netWgt !== null ? item.netWgt : 0,
      country: getCountry(item.partnerCode),
    }));

  const chartDataVolume = {
    categories: top10DataVolume.map((item) => getCountry(item.partnerCode)),
    series: [
      {
        name: "Net Weight (Kg)",
        data: top10DataVolume.map((item) => item.netWgt),
      },
    ],
  };

  const chartOptionsVolume = {
    chart: {
      type: "bar",
      height: 350,
      distributed: true,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    xaxis: {
      categories: chartDataVolume.categories,
      title: {
        text: "By Volume",
      },
    },
    yaxis: {
      title: {
        text: "Net Weight (Kg)",
      },
    },
    tooltip: {
      y: {
        formatter: (value) => formatNumberKg(value),
      },
    },
    colors: ["#33B2DF"],
  };

  const getValuesByPartnerCode = (data, partnerCode) => {
    const partners = data.filter((item) => item.partnerCode === partnerCode);
  
    if (partners.length > 0) {
      const partnerWithMaxValue = partners.reduce((max, current) =>
        current.primaryValue > max.primaryValue ? current : max
      );
  
      return {
        primaryValue: partnerWithMaxValue.primaryValue,
        netWgt: partnerWithMaxValue.netWgt,
      };
    } else {
      return null;
    }
  };

  const allTotal = getValuesByPartnerCode(data, 0);
  const worldSharePercentage = (share[0]?.worldShare * 100).toFixed(2);

  return (
    <>
      <SquaresContainer>
        <Square>
          <div className="wordd">Shipments</div>
          <div className="number">{data?.length}</div>
        </Square>
        <Square>
          <div className="wordd">World Share - {share[0]?.reporterDesc} {share[0]?.period}</div>
          <div className="number">{data?.length > 0 ? `${worldSharePercentage}%` : 0}</div>
        </Square>
        <Square>
          <div className="wordd">Total Value</div>
          <div className="number">
            {data?.length > 0 ? formatToCurrency(allTotal?.primaryValue) : "$0"}
          </div>
        </Square>
        <Square>
          <div className="wordd">Total Volume</div>
          <div className="number">{allTotal?.netWgt != null ? formatNumberKg(allTotal?.netWgt) : 0}</div>
        </Square>
      </SquaresContainer>

      <ReactApexChart
        options={chartOptions}
        series={chartData.series}
        type="bar"
        height={350}
      />

      <ReactApexChart
        options={chartOptionsVolume}
        series={chartDataVolume.series}
        type="bar"
        height={350}
      />
    </>
  );
};

export default ChartValueCountry;
