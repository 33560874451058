import React, { useEffect, useState } from "react";
import { ContainerHome } from "../../../assets/styles";
import { Grid } from "@mui/material";
import { authScreen } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import getUserInfo from "../../../hooks/getUsers";
import {
  ContainerInfo,
  LeftText,
  Rectangle,
  RightText,
  InfoValue,
  InfoTitle,
  ContainerPdf,
  LogoPdf,
} from "./stylePdf";
import { Descriptions } from "antd";
import iconLogo from "../../../icons/T-SaleMetals-03.svg";

export const PdfPage = React.forwardRef((props, ref) => {
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 500);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    });
  });

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  const formattedNotes = Object.entries(props.item?.details?.notes).map(
    ([key, value]) => {
      let extractedValue = value;

      if (typeof value === "string") {
        [, extractedValue] = value.split(":");
      }

      if (extractedValue && typeof extractedValue.trim === "function") {
        extractedValue = extractedValue.trim();
      }

      return { label: key, value: extractedValue };
    }
  );

  const items = [
    {
      key: "1",
      label: "City",
      children: `${
        props.item?.details?.addressDetails?.city
          ? props.item?.details?.addressDetails?.city
          : "NA"
      }`,
    },
    {
      key: "2",
      label: "Country",
      children: `${
        props.item?.details?.addressDetails?.country
          ? props.item?.details?.addressDetails?.country
          : ""
      }`,
    },
    {
      key: "3",
      label: "State",
      children: `${
        props.item?.details?.addressDetails?.state
          ? props.item?.details?.addressDetails?.state
          : "NA"
      }`,
    },
    {
      key: "4",
      label: "Street",
      children: `${
        props.item?.details?.addressDetails?.street
          ? props.item?.details?.addressDetails?.street
          : "NA"
      }`,
    },
    {
      key: "5",
      label: "ZipCode",
      children: ` ${
        props.item?.details?.addressDetails?.zipcode
          ? props.item?.details?.addressDetails?.zipcode
          : "NA"
      }`,
    },
  ];

  return (
    <ContainerPdf ref={ref}>
      <ContainerHome>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item container>
            <Rectangle>
              <LogoPdf src={iconLogo} size={"60px"} />

              <LeftText>TradeSee</LeftText>
              <RightText>Entity Details</RightText>
            </Rectangle>
            <ContainerInfo>
              <div className="infoRow">
                <InfoTitle>Listing Country: </InfoTitle>
                <InfoValue>
                  {props.item?.details?.addressDetails?.country}
                </InfoValue>
              </div>
              <div className="infoRow">
                <InfoTitle>Authority: </InfoTitle>
                <InfoValue>xxxx</InfoValue>
              </div>
              <div className="infoRow">
                <InfoTitle>List Name: </InfoTitle>
                <InfoValue>
                  {formattedNotes[2]?.value ? formattedNotes[2]?.value : "NA"}
                </InfoValue>
              </div>
              <div className="infoRow">
                <InfoTitle>Entity Type: </InfoTitle>
                <InfoValue>
                  {formattedNotes[3]?.value ? formattedNotes[3]?.value : "NA"}
                </InfoValue>
              </div>
              <div className="infoRow">
                <InfoTitle>Entity Name: </InfoTitle>
                <InfoValue>xxxx</InfoValue>
              </div>
              <div className="infoRow">
                <InfoTitle>Entity Aliases: </InfoTitle>
                <InfoValue>xxxx</InfoValue>
              </div>
              <div className="infoRow">
                <InfoTitle>Expiry Date: </InfoTitle>
                <InfoValue>{props.item?.details?.expiryDate}</InfoValue>
              </div>
              <div className="infoRow">
                <InfoTitle>Citation Link: </InfoTitle>
                <InfoValue>
                  <a
                    href={props.item?.details?.citationLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                </InfoValue>{" "}
              </div>
              <div className="infoRow">
                <InfoTitle>Excluding Agency: </InfoTitle>
                <InfoValue>
                  {formattedNotes[1]?.value ? formattedNotes[1]?.value : "NA"}
                </InfoValue>
              </div>
              <div className="infoRow">
                <InfoTitle>Exclusion Type: </InfoTitle>
                <InfoValue>
                  {formattedNotes[4]?.value ? formattedNotes[4]?.value : "NA"}
                </InfoValue>
              </div>
              <div className="infoRow">
                <InfoTitle>Exclusion Program: </InfoTitle>
                <InfoValue>
                  {formattedNotes[6]?.value ? formattedNotes[6]?.value : "NA"}
                </InfoValue>
              </div>
              <div className="infoRow">
                <InfoTitle>Notes: </InfoTitle>
                <InfoValue>
                  {formattedNotes[0]?.value ? formattedNotes[0]?.value : "NA"}
                </InfoValue>
              </div>
            </ContainerInfo>
          </Grid>
        </Grid>
        <Descriptions
          title="Address Details"
          size={"small"}
          bordered={true}
          items={items}
          style={{ marginLeft: "8px" }}
        />
      </ContainerHome>
    </ContainerPdf>
  );
});
