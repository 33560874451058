/* eslint-disable no-undef */
const countriesData = [
  {
    src: require("../../../flag/afghanistan.png"),
    label: "Afghanistan",
  },
  { src: require("../../../flag/albania.png"), label: "Albania" },
  { src: require("../../../flag/algeria.png"), label: "Algeria" },
  {
    src: require("../../../flag/american-samoa.png"),
    label: "American Samoa",
  },
  { src: require("../../../flag/andorra.png"), label: "Andorra" },
  { src: require("../../../flag/angola.png"), label: "Angola" },
  { src: require("../../../flag/anguilla.png"), label: "Anguilla" },
  {
    src: require("../../../flag/antigua-and-barbuda.png"),
    label: "Antigua and Barbuda",
  },
  { src: require("../../../flag/argentina.png"), label: "Argentina" },
  { src: require("../../../flag/armenia.png"), label: "Armenia" },
  { src: require("../../../flag/aruba.png"), label: "Aruba" },
  { src: require("../../../flag/australia.png"), label: "Australia" },
  { src: require("../../../flag/austria.png"), label: "Austria" },
  { src: require("../../../flag/azerbaijan.png"), label: "Azerbaijan" },
  { src: require("../../../flag/bahamas-3.png"), label: "Bahamas" },
  { src: require("../../../flag/bahrain.png"), label: "Bahrain" },
  { src: require("../../../flag/barbados.png"), label: "Barbados" },
  { src: require("../../../flag/belarus.png"), label: "Belarus" },
  { src: require("../../../flag/belgium-3.png"), label: "Belgium" },
  { src: require("../../../flag/belize.png"), label: "Belize" },
  { src: require("../../../flag/benin.png"), label: "Benin" },
  { src: require("../../../flag/bermuda.png"), label: "Bermuda" },
  { src: require("../../../flag/bhutan.png"), label: "Bhutan" },
  { src: require("../../../flag/bngladesh.png"), label: "Bangladesh" },
  { src: require("../../../flag/bolivia.png"), label: "Bolivia" },
  { src: require("../../../flag/bosnia.png"), label: "Bosnia" },
  { src: require("../../../flag/botswana.png"), label: "Botswana" },
  { src: require("../../../flag/brazil.png"), label: "Brazil" },
  {
    src: require("../../../flag/british-virgin-islands.png"),
    label: "British Virgin Islands",
  },
  { src: require("../../../flag/brunei.png"), label: "Brunei" },
  { src: require("../../../flag/bulgaria.png"), label: "Bulgaria" },
  {
    src: require("../../../flag/burkina-faso.png"),
    label: "Burkina Faso",
  },
  { src: require("../../../flag/burundi.png"), label: "Burundi" },
  { src: require("../../../flag/cambodia.png"), label: "Cambodia" },
  { src: require("../../../flag/cameroon.png"), label: "Cameroon" },
  { src: require("../../../flag/canada.png"), label: "Canada" },
  { src: require("../../../flag/cape-verde.png"), label: "Cape Verde" },
  {
    src: require("../../../flag/cayman-islands.png"),
    label: "Cayman Islands",
  },
  {
    src: require("../../../flag/central-african-republic.png"),
    label: "Central African Republic",
  },
  { src: require("../../../flag/chad.png"), label: "Chad" },
  {
    src: require("../../../flag/chech-republic.png"),
    label: "Czech Republic",
  },
  { src: require("../../../flag/chile.png"), label: "Chile" },
  { src: require("../../../flag/china.png"), label: "China" },
  { src: require("../../../flag/colombia.png"), label: "Colombia" },
  { src: require("../../../flag/comoros.png"), label: "Comoros" },
  {
    src: require("../../../flag/congo-democratic.png"),
    label: "Congo Democratic",
  },
  {
    src: require("../../../flag/congo-republic.png"),
    label: "Congo Republic",
  },
  {
    src: require("../../../flag/cook-islands.png"),
    label: "Cook Islands",
  },
  { src: require("../../../flag/costa-rica.png"), label: "Costa Rica" },
  { src: require("../../../flag/croatia.png"), label: "Croatia" },
  { src: require("../../../flag/cuba.png"), label: "Cuba" },
  { src: require("../../../flag/curacao.png"), label: "Curacao" },
  { src: require("../../../flag/cyprus.png"), label: "Cyprus" },
  { src: require("../../../flag/denmark.png"), label: "Denmark" },
  { src: require("../../../flag/djibouti.png"), label: "Djibouti" },
  { src: require("../../../flag/dominica.png"), label: "Dominica" },
  {
    src: require("../../../flag/dominican-republic.png"),
    label: "Dominican Republic",
  },
  { src: require("../../../flag/ecuador.png"), label: "Ecuador" },
  { src: require("../../../flag/egypt.png"), label: "Egypt" },
  {
    src: require("../../../flag/el-salvador.png"),
    label: "El Salvador",
  },
  { src: require("../../../flag/england.png"), label: "England" },
  {
    src: require("../../../flag/equatorial-guinea.png"),
    label: "Equatorial Guinea",
  },
  { src: require("../../../flag/eritrea.png"), label: "Eritrea" },
  { src: require("../../../flag/estonia-2.png"), label: "Estonia" },
  { src: require("../../../flag/ethiopia.png"), label: "Ethiopia" },
  {
    src: require("../../../flag/faroe-islands.png"),
    label: "Faroe Islands",
  },
  { src: require("../../../flag/fiji.png"), label: "Fiji" },
  { src: require("../../../flag/finland.png"), label: "Finland" },
  { src: require("../../../flag/france-3.png"), label: "France" },
  { src: require("../../../flag/gabon.png"), label: "Gabon" },
  { src: require("../../../flag/gambia.png"), label: "Gambia" },
  { src: require("../../../flag/georgia.png"), label: "Georgia" },
  { src: require("../../../flag/germany.png"), label: "Germany" },
  { src: require("../../../flag/ghana.png"), label: "Ghana" },
  { src: require("../../../flag/gibraltar.png"), label: "Gibraltar" },
  { src: require("../../../flag/greece.png"), label: "Greece" },
  { src: require("../../../flag/grenada.png"), label: "Grenada" },
  { src: require("../../../flag/guam.png"), label: "Guam" },
  { src: require("../../../flag/guatemala.png"), label: "Guatemala" },
  { src: require("../../../flag/guinea.png"), label: "Guinea" },
  { src: require("../../../flag/guyana.png"), label: "Guyana" },
  { src: require("../../../flag/haiti.png"), label: "Haiti" },
  { src: require("../../../flag/honduras.png"), label: "Honduras" },
  { src: require("../../../flag/hong-kong.png"), label: "Hong Kong" },
  { src: require("../../../flag/hungary-3.png"), label: "Hungary" },
  { src: require("../../../flag/iceland.png"), label: "Iceland" },
  { src: require("../../../flag/india.png"), label: "India" },
  { src: require("../../../flag/indonesia.png"), label: "Indonesia" },
  { src: require("../../../flag/iran.png"), label: "Iran" },
  { src: require("../../../flag/iraq.png"), label: "Iraq" },
  { src: require("../../../flag/ireland.png"), label: "Ireland" },
  { src: require("../../../flag/israel.png"), label: "Israel" },
  { src: require("../../../flag/italy.png"), label: "Italy" },
  { src: require("../../../flag/jamaica.png"), label: "Jamaica" },
  { src: require("../../../flag/japan.png"), label: "Japan" },
  { src: require("../../../flag/jordan-2.png"), label: "Jordan" },
  { src: require("../../../flag/kazakhstan.png"), label: "Kazakhstan" },
  { src: require("../../../flag/kenya.png"), label: "Kenya" },
  { src: require("../../../flag/kiribati.png"), label: "Kiribati" },
  { src: require("../../../flag/kosovo.png"), label: "Kosovo" },
  { src: require("../../../flag/kuwait.png"), label: "Kuwait" },
  { src: require("../../../flag/kyrgystan.png"), label: "Kyrgyzstan" },
  { src: require("../../../flag/laos.png"), label: "Laos" },
  { src: require("../../../flag/latvia.png"), label: "Latvia" },
  { src: require("../../../flag/lebanon.png"), label: "Lebanon" },
  { src: require("../../../flag/lesotho.png"), label: "Lesotho" },
  { src: require("../../../flag/liberia.png"), label: "Liberia" },
  { src: require("../../../flag/libya.png"), label: "Libya" },
  {
    src: require("../../../flag/liechtenstein.png"),
    label: "Liechtenstein",
  },
  { src: require("../../../flag/lithuania.png"), label: "Lithuania" },
  { src: require("../../../flag/luxembourg.png"), label: "Luxembourg" },
  { src: require("../../../flag/macau.png"), label: "Macau" },
  { src: require("../../../flag/macedonia.png"), label: "Macedonia" },
  { src: require("../../../flag/madagascar.png"), label: "Madagascar" },
  { src: require("../../../flag/malaysia.png"), label: "Malaysia" },
  { src: require("../../../flag/maldives.png"), label: "Maldives" },
  { src: require("../../../flag/mali-3.png"), label: "Mali" },
  { src: require("../../../flag/malta.png"), label: "Malta" },
  {
    src: require("../../../flag/marshall-islands.png"),
    label: "Marshall Islands",
  },
  { src: require("../../../flag/mauritania.png"), label: "Mauritania" },
  { src: require("../../../flag/mauritius.png"), label: "Mauritius" },
  { src: require("../../../flag/mexico.png"), label: "Mexico" },
  { src: require("../../../flag/moldova.png"), label: "Moldova" },
  { src: require("../../../flag/monaco.png"), label: "Monaco" },
  { src: require("../../../flag/mongolia.png"), label: "Mongolia" },
  { src: require("../../../flag/montenegro.png"), label: "Montenegro" },
  { src: require("../../../flag/morocco.png"), label: "Morocco" },
  {
    src: require("../../../flag/mozambique-3.png"),
    label: "Mozambique",
  },
  { src: require("../../../flag/myanmar.png"), label: "Myanmar" },
  { src: require("../../../flag/namibia.png"), label: "Namibia" },
  { src: require("../../../flag/nauru.png"), label: "Nauru" },
  { src: require("../../../flag/nepal.png"), label: "Nepal" },
  {
    src: require("../../../flag/netherlands-3.png"),
    label: "Netherlands",
  },
  {
    src: require("../../../flag/new-caledonia.png"),
    label: "New Caledonia",
  },
  {
    src: require("../../../flag/new-zealand.png"),
    label: "New Zealand",
  },
  { src: require("../../../flag/nicaragua-2.png"), label: "Nicaragua" },
  { src: require("../../../flag/niger.png"), label: "Niger" },
  { src: require("../../../flag/nigeria.png"), label: "Nigeria" },
  {
    src: require("../../../flag/north-korea.png"),
    label: "North Korea",
  },
  {
    src: require("../../../flag/northern-ireland.png"),
    label: "Northern Ireland",
  },
  { src: require("../../../flag/norway.png"), label: "Norway" },
  { src: require("../../../flag/oman.png"), label: "Oman" },
  { src: require("../../../flag/pakistan.png"), label: "Pakistan" },
  { src: require("../../../flag/palau.png"), label: "Palau" },
  { src: require("../../../flag/palestine.png"), label: "Palestine" },
  { src: require("../../../flag/panama.png"), label: "Panama" },
  {
    src: require("../../../flag/papua-new-guinea.png"),
    label: "Papua New Guinea",
  },
  { src: require("../../../flag/paraguay.png"), label: "Paraguay" },
  { src: require("../../../flag/peru.png"), label: "Peru" },
  {
    src: require("../../../flag/philippines.png"),
    label: "Philippines",
  },
  { src: require("../../../flag/poland.png"), label: "Poland" },
  { src: require("../../../flag/portugal.png"), label: "Portugal" },
  {
    src: require("../../../flag/puerto-rico.png"),
    label: "Puerto Rico",
  },
  { src: require("../../../flag/qatar.png"), label: "Qatar" },
  { src: require("../../../flag/romania.png"), label: "Romania" },
  { src: require("../../../flag/russia.png"), label: "Russia" },
  { src: require("../../../flag/rwanda.png"), label: "Rwanda" },
  {
    src: require("../../../flag/saint-lucia.png"),
    label: "Saint Lucia",
  },
  {
    src: require("../../../flag/saint-vincent-and-the-grenadines.png"),
    label: "Saint Vincent and the Grenadines",
  },
  { src: require("../../../flag/samoa.png"), label: "Samoa" },
  { src: require("../../../flag/san-marino.png"), label: "San Marino" },
  {
    src: require("../../../flag/sao-tome-and-principe.png"),
    label: "Sao Tome and Principe",
  },
  {
    src: require("../../../flag/saudi-arabia.png"),
    label: "Saudi Arabia",
  },
  { src: require("../../../flag/scotland.png"), label: "Scotland" },
  { src: require("../../../flag/senegal.png"), label: "Senegal" },
  { src: require("../../../flag/serbia.png"), label: "Serbia" },
  { src: require("../../../flag/seychelles.png"), label: "Seychelles" },
  {
    src: require("../../../flag/sierra-leone-2.png"),
    label: "Sierra Leone",
  },
  { src: require("../../../flag/singapore.png"), label: "Singapore" },
  { src: require("../../../flag/slovakia-2.png"), label: "Slovakia" },
  { src: require("../../../flag/slovenia.png"), label: "Slovenia" },
  {
    src: require("../../../flag/solomon-islands.png"),
    label: "Solomon Islands",
  },
  {
    src: require("../../../flag/south-africa.png"),
    label: "South Africa",
  },
  {
    src: require("../../../flag/south-korea.png"),
    label: "South Korea",
  },
  {
    src: require("../../../flag/south-sudan.png"),
    label: "South Sudan",
  },
  { src: require("../../../flag/spain.png"), label: "Spain" },
  { src: require("../../../flag/sri-lanka.png"), label: "Sri Lanka" },
  { src: require("../../../flag/sudan.png"), label: "Sudan" },
  { src: require("../../../flag/suriname.png"), label: "Suriname" },
  { src: require("../../../flag/sweden.png"), label: "Sweden" },
  {
    src: require("../../../flag/switzerland.png"),
    label: "Switzerland",
  },
  { src: require("../../../flag/syria.png"), label: "Syria" },
  { src: require("../../../flag/taiwan.png"), label: "Taiwan" },
  { src: require("../../../flag/tajikistan.png"), label: "Tajikistan" },
  { src: require("../../../flag/tanzania.png"), label: "Tanzania" },
  { src: require("../../../flag/thailand.png"), label: "Thailand" },
  { src: require("../../../flag/togo.png"), label: "Togo" },
  { src: require("../../../flag/tonga.png"), label: "Tonga" },
  { src: require("../../../flag/tunisia.png"), label: "Tunisia" },
  { src: require("../../../flag/turkey.png"), label: "Turkey" },
  {
    src: require("../../../flag/turkmenistan.png"),
    label: "Turkmenistan",
  },
  {
    src: require("../../../flag/turks-and-caicos-islands.png"),
    label: "Turks and Caicos Islands",
  },
  { src: require("../../../flag/tuvalu.png"), label: "Tuvalu" },
  { src: require("../../../flag/uganda.png"), label: "Uganda" },
  { src: require("../../../flag/uk.png"), label: "UK" },
  { src: require("../../../flag/ukraine.png"), label: "Ukraine" },
  {
    src: require("../../../flag/united-arab-emirates.png"),
    label: "United Arab Emirates",
  },
  { src: require("../../../flag/uruguay.png"), label: "Uruguay" },
  { src: require("../../../flag/usa.png"), label: "United States" },
  { src: require("../../../flag/uzbekistan.png"), label: "Uzbekistan" },
  { src: require("../../../flag/vanuatu.png"), label: "Vanuatu" },
  { src: require("../../../flag/vatican.png"), label: "Vatican" },
  { src: require("../../../flag/venezuela.png"), label: "Venezuela" },
  { src: require("../../../flag/vietnam.png"), label: "Vietnam" },
  { src: require("../../../flag/wales.png"), label: "Wales" },
  { src: require("../../../flag/yemen.png"), label: "Yemen" },
  { src: require("../../../flag/zimbabwe.png"), label: "Zimbabwe" },
];

const countries = countriesData.map((country) => {
  return {
    ...country,
    value: country.label,
  };
});

export default countries;
