import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { authScreen } from "../../contexts/auth";

import Country from "../../components/Flag";

import HsCodeList, { AllHsCodeList } from "../../hooks/HsCodeList";
import filterCompaniesDB from "../../hooks/filterComapniesDB";
import {
  deleteCredit,
  handleLimitCredits,
  historyCredits,
  viewCredit,
} from "../../hooks/credits";
import getUserInfo from "../../hooks/getUsers";

import ProfileCompany from "../../service/ProfileCompany";
import filterCompaniesMore from "../../service/moreCompanies";
import GlobalCompany from "../../service/GlobalCompany";
import { TOKENTAPI } from "../../service/key";
import { addFavoriteCompanie } from "../../service/FavoriteCompanies";
import axios from "axios";
import { saveAnalytics } from "../../hooks/analytics";
import { saveControlRequests } from "../../hooks/controlRequets";
import { publicIpv4 } from "public-ip";
import CompanyMapperInfo from "../home/utils/mapper";
import {
  getCompanieFavorite,
  removeCompanieFavorite,
} from "../../hooks/findNewPartner";
import SearchCompany from "../../service/searchCompany";
import { getFunctionStatus } from "../../hooks/controlFuncions";
import ShipmentsCompany from "../../service/Shipments";
import ShipmentsCompanyHS, { GetShipments } from "../../service/shipmentsByHs";
import { message } from "antd";
import Mock from "./shipments.json";
import GetTotalCompanies from "../../service/GetTotalCompanies";
import { sendUrlCompanies, sendUrlScout } from "../../service/apiHunter";
import { checkIfGeoCompaniesExist, checkIfScoutExist, getGeoCompaniesByDomain, getScoutData, saveDomainScout } from "../../hooks/filterLeadsDB";
import { saveRequestApi } from "../../hooks/logFirebase";

export const TradeDataContext = createContext();

export default function TradeDataInfo({ children }) {
  const [searchParams] = useSearchParams();

  const [auth, setAuth] = useState(false);
  const [toggleDrawer, useTroggleDawer] = useState(false);
  const [step, setStep] = useState(0);
  const [animatedStep, setAnimatedStep] = useState("staticStep");
  const [countrySel, setCountrySel] = useState(searchParams.get("country"));
  const [hsCodeSel, setHsCodeSel] = useState([]);
  const [products, setProducts] = useState([]);
  const [supplierOrBuyer, setSupplierOrBuyer] = useState(
    searchParams.get("role")
  );
  const [defaultRange, setDefaultRange] = useState([0, 10000000]);
  const [companies, setCompanies] = useState([]);
  const [maxCompaniesFilter, setMaxCompaniesFilter] = useState("");
  const [hsCodeSeach, setHsCodeSeach] = useState("");
  const [companySelect, setCompanySelect] = useState(false);
  const [selectedTable, setSelectedTable] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedInfo, setSelectedInfo] = useState(0);
  const [isRequestFinished, setIsRequestFinished] = useState(true);
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [companyName, setCompanyName] = useState(
    searchParams.get("companyName") || ""
  );
  const [allCompaniesDb, setAllCompaniesDb] = useState([]);
  const [companyId] = useState(searchParams.get("companyId"));
  const [general, setGeneral] = useState();
  const [generalData, setGeneralData] = useState();
  const [userIP, setUserIP] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date().setFullYear(new Date().getFullYear() - 1)
  );
  const [toDate, setToDate] = useState(Date.now());
  const [switchValue, setSwitchValue] = useState("api");
  const [searchFor, setSearchFor] = useState("company");
  const [shipments, setShipments] = useState([]);
  const [isShipmentsLoading, setIsShipmentsLoading] = useState(false);
  const [countryTrade, setCountryTrade] = useState([]);
  const [allHsCodes, setAllHsCodes] = useState([]);
  const [key, setKey] = useState(0);
  const [companyLink, setCompanyLink] = useState("");
  const [type, setType] = useState("");
  const [industries, setIndustries] = useState([]);
  const [excludeIndustries, setExcludeIndustries] = useState([]);
  const [companyData, setCompanyData] = useState([])
  const [companiesData, setCompaniesData] = useState([])
  const [scoutData, setScoutData] = useState([])
  const [states, setStates] = useState([])
  const [productKeywords, setProductKeywords] = useState([])
  
  //Filtro atual
  const [listType, setListType] = useState("")
  const [role, setRole] = useState("")
  const [country, setCountry] = useState("")
  const [keyWordsIncluded, setKeyWordsIncluded] = useState([])
  const [keyWordsExcluded, setKeyWordsExcluded] = useState([])
  const [period, setPeriod] = useState("")
  const [countryPartner, setCountryPartner] = useState("")
  const [hsCodeSelected, setHsCodeSelected] = useState([])
  const [size, setSize] = useState([])
  const [fromDateShip, setFromDateShip] = useState(null);
  const [toDateShip, setToDateShip] = useState(null);
  const fetchGeoCompanies = async () => {
    try {
      const resultExist = await checkIfGeoCompaniesExist(companyLink, country, industries);
      if (!resultExist) {
        const sendUrl = await sendUrlCompanies(companyLink, size, country, role, states, industries, excludeIndustries, keyWordsIncluded, keyWordsExcluded);
        if (sendUrl === "OK") {
          let attempts = 0;
          let resp = null;
          let delay = 1000;
          while (!resp && attempts < 5) {
            resp = await getGeoCompaniesByDomain(companyLink, country, industries);
            if (!resp) {
              attempts++;
              await (async (currentDelay) => {
                await new Promise(resolve => setTimeout(resolve, currentDelay));
              })(delay);
              delay *= 2;
            }
          }
          if (resp) {
            setCompanyData(resp);
          } else {
            console.log("Erro");
          }
        }
      } else {
        const resp = await getGeoCompaniesByDomain(companyLink, country, industries);
        setCompanyData(resp);
        setLoading(false);
      }
    } catch (err) {
      console.log("Erro");
      setLoading(false); 
    }
  };

  const fetchScout = async () => {
    try { 

      const resultExist = checkIfScoutExist(companyLink);
      if (!resultExist) {
      const sendUrl = await sendUrlScout(companyLink);
      await saveDomainScout(companyLink);
      if (sendUrl === "OK") {
        let attempts = 0;
        let resp = null;
        let delay = 4000; 
      
        while (!resp && attempts < 5) {
          await new Promise(resolve => setTimeout(resolve, delay));
      
          resp = await getScoutData(companyLink);
      
          if (!resp) {
            attempts++;
            delay *= 2; 
          }
        }
      
        
        if (resp) {
          setScoutData(resp);
          setLoading(false);
        } else {
          console.log("Erro ao obter dados do Scout.");
        }
      }   
    } else {
      const resp = await getScoutData(companyLink);
      setScoutData(resp);
      setLoading(false);
    }   

    } catch (err) {
      console.error("Erro");
      setLoading(false); 
    }
  };

  
  useEffect(() => {
    (async () => {
      async function fetchFunctionStatus() {
        try {
          const snapshot = await getFunctionStatus();

          if (window.location.hostname !== "localhost") {
            setSwitchValue(snapshot.val().ativo);
          } else {
            setSwitchValue(snapshot.val().test);
          }
        } catch (error) {
          console.error("Erro ao buscar status da função:", error);
        }
      }
      fetchFunctionStatus();
      setUserIP(await publicIpv4());
    })();
    if(companyData?.companies?.companies){
      setCompaniesData(companyData?.companies?.companies)
    }
  }, [companyData]);

  const navigate = useNavigate();
  let range = [0, null];

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const pdfRef = useRef(null);
  const pdfProfileRef = useRef(null);

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  const [userCredit, setUserCredit] = useState(null);
  const custCredit = 10;

  useEffect(() => {
    if (userInfo) {
      const fetchCredits = async () => {
        try {
          const userCredits = await viewCredit(userInfo?.uid);
          setUserCredit(userCredits);
        } catch (error) {
          console.error("Erro ao buscar os créditos do usuário:", error);
        }
      };

      fetchCredits();
    }
  }, [userInfo]);

  const clearFilters = () => {
    setSearchFor("company");
    setSelectedPeriod("");
    setHsCodeSel([]);
    //  setCountrySel(searchParams.get("country"));
    setKey((prevKey) => prevKey + 1);
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [modalMoreVisible, setModalMoreVisible] = useState(false);

  const fetchFavoritedCompanies = async () => {
    return new Promise(async(resolve, reject) => {
      try {
        const dataUnmapped = await getCompanieFavorite(userInfo.uid);
        const mappedData = CompanyMapperInfo(dataUnmapped);
        setCompanies(mappedData);
        resolve(mappedData)
        setIsRequestFinished(true);
      } catch (error) {
        console.log("Erro ao buscar informações do usuário:", error);
      } finally {
        setLoading(false);
      }
    })
  };

  const deleteFavoritedCompany = async (arr) => {
    try {

      arr.forEach(async(id) => {
        await removeCompanieFavorite(userInfo.uid, id);
      })

    } catch (err) {
      console.error("Error on delete:", err);
    }
  };

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setStep(1);
    setModalVisible(false);
  };

  const handleOpenModalMore = () => {
    setModalMoreVisible(true);
  };

  const handleCloseModalMore = () => {
    setModalMoreVisible(false);
  };

  const handleConfirmAction = () => {
    navigate(`/credits`);
  };

  const searchHsCode = useMemo(() => {
    const typeHsCode = isNaN(hsCodeSeach);

    if (typeHsCode) {
      return products.filter((item) =>
        item.hsName.toLowerCase().includes(hsCodeSeach.toLowerCase())
      );
    }

    return products.filter((item) => item.hsCode.includes(hsCodeSeach));
  }, [products, hsCodeSeach]);

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 500);
        requestAllHsCodes();
        requestHsList();
      } else {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    });
  }, []);

  const requestHsList = () => {
    if (products.length === 0) {
      HsCodeList()
        .then((data) => {
          const sortedProduts = data.map((prod) => ({
            ...prod,
            hsCode: Number(prod.hsCode.replace(/\./g, "")),
          }));

          const fixedProducts = sortedProduts
            .map((prod) => ({
              ...prod,
              hsCode: prod.hsCode.toString(),
            }))
            .sort((a, b) => a.hsCode.slice(0, 2) - b.hsCode.slice(0, 2));

          setProducts(fixedProducts);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const requestAllHsCodes = useCallback(() => {
    if (allHsCodes.length === 0) {
      AllHsCodeList()
        .then((data) => {
          const sortedProduts = data
            .map((prod) => ({
              ...prod,
              hsCode: Number(prod.hsCode.replace(/\./g, "")),
            }))
            .sort((a, b) => a.hsCode - b.hsCode);

          const fixedProducts = sortedProduts.map((prod) => ({
            ...prod,
            hsCode: prod.hsCode.toString(),
          }));

          setAllHsCodes(fixedProducts);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [allHsCodes]);

  function SetToggle(state) {
    useTroggleDawer(state);
  }

  const moveStepAnimation = (animation, step) => {
    //animation next = Direita para Esquerda
    //animation back = Esqueda pra direita
    //step = condicional da tela de exibição
    scrollTop();

    if (animation === "next") {
      setAnimatedStep("nextStepAnimated");
      setStep(step);
      setTimeout(() => {
        setAnimatedStep("staticStep");
      }, 1000);
    } else if (animation === "none") {
      setStep(step);
    } else {
      setAnimatedStep("backStepAnimated");
      setStep(step);
      setCompanies([]);
      setTimeout(() => {
        setAnimatedStep("staticStep");
      }, 1000);
    }
  };

  const formattedDate = new Date().toISOString();

  const infoC = {
    text: `Credits used with research on Trade Data`,
    type: "decrease",
    date: formattedDate,
    credits: 5,
  };

  async function fetchCompaniesByName(companyName, country) {

    return new Promise(async(resolve, reject) => {

      setCompanies([]);
      setIsRequestFinished(false);
  
      const infoRequest = {
        action: "Requested for tradeMo",
        date: formattedDate,
        api: "CompanyName API",
        keywords: `${country} - ${companyName}`,
        user: userInfo?.userData?.name,
      };
  
      try {
        await SearchCompany(companyName)
          .then((res) => {
            resolve(res.data)
            setCompanies(res.data.companies);
          })
          .catch(async (err) => {
            console.log("Error to fetch:", err);
            saveControlRequests(infoRequest);
  
            await axios
              .post(
                "https://api.tsalemetals.com/t-api/v1/company-matcher",
                JSON.stringify({
                  companyName: companyName,
                  country: country,
                  user: userInfo?.userData?.name,
                  userId: userInfo?.uid,
                }),
                {
                  headers: {
                    Authorization: "Bearer " + TOKENTAPI.key,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                  },
                }
              )
              .then((res) => {
                saveControlRequests(infoRequest);
                resolve(res.data)
                setCompanies(res.data.companies);
              })
              .catch((err) => console.log("Error company by name:", err));
          })
          .finally(() => setIsRequestFinished(true));
      } catch (error) {
        console.log(error);
        return null;
      } finally {
        setIsRequestFinished(true);
      }
    })
  }

  async function fetchCompaniesByNameDB() {
    setCompanies([]);
    setIsRequestFinished(false);

    try {
      await SearchCompany(companyId)
        .then((res) => {
          setCompanies(res);
        })
        .catch((e) => {
          console.log("Error to call DB:", e);
        })
        .finally(() => setIsRequestFinished(true));
    } catch (error) {
      console.log(error);
      return null;
    } finally {
      setIsRequestFinished(true);
    }
  }

  //Faz a requisição das empresas com base no hscode e otimiza a busca com o banco de dados e a API
  const requestCompanies = async (role, country, cPartner, hsCodeFull) => {

    


    return new Promise(async(resolve, reject) => {

      setRole(role)
      setCountry(country)
      setCountryPartner(cPartner)
    
      const hsCode = sendHS(hsCodeFull)
      setHsCodeSelected(hsCode)

      const userCredits = await viewCredit(userInfo?.uid);
      setIsRequestFinished(false);
  
      const requestData = {
        api: "/full-request",
        searchQuery: `${hsCode} - ${country} - ${role}`,
        creditsUsed: 4,
        status: null,
      };

      if (userCredits < custCredit) {
        handleOpenModal();

      } else {
        if (await handleLimitCredits(userInfo?.uid)) {
          window.alert("Sorry you reached your limits of credits");
          return "Limit reached";
        }
        setIsRequestFinished(false);
        
        let countryTrader = [];
        if(cPartner) {
          countryTrader = [cPartner]
        }
  
        let companiesDB = [];
        const rangeInit = defaultRange[0];
        const rangeEnd = defaultRange[1];
        const infoA = {
          action: `Search`,
          date: formattedDate,
          page: "Find new partner",
          keywords: `${country} - ${hsCode} - ${role}`,
          name: userInfo?.userData?.name,
        };
  
        if (countryTrader.length === 0) {
          await filterCompaniesDB(
            country.toLocaleLowerCase(),
            hsCode,
            10,
            rangeInit,
            rangeEnd,
            role
          ).then(async (res) => {
            //Verifica se houve resultado no banco de dados e armazena na variavel
            if (res.length > 0) {
              res.forEach((item) => {
                companiesDB.push(item.companyId);
              });
            }
            //Verifica se o número de empresa encontrado no banco de dados é suficiente para solicitação
            if (res.length >= 10) {
              deleteCredit(userInfo?.uid, 5);
              historyCredits(infoC, userInfo?.uid);
              saveAnalytics(userInfo?.uid, infoA);
              setCompanies(res);
              setIsRequestFinished(true);
              
              GetTotalCompanies(
              [country.toLocaleLowerCase()],
              hsCode,
              rangeInit,
              rangeEnd,
              role,
              userInfo?.userData?.name,
              userInfo?.uid,
              userIP,
              fromDate,
              toDate,
              countryTrader)
              .then(nResp => {


                if(nResp) {
                  resolve({companies: res, ...nResp});
                } else {
                  resolve({companies: res, totalCompanies: res.length});
                }
              })
              .catch(e => {          
                resolve({companies: res, totalCompanies: res.length});
              })

            } else {
              const resultCompanies = 10 - res.length;              
  
              GlobalCompany(
                [country.toLocaleLowerCase()],
                hsCode,
                10,
                rangeInit,
                rangeEnd,
                role,
                userInfo?.userData?.name,
                userInfo?.uid,
                userIP,
                fromDate,
                toDate,
                countryTrader
              )
                .then((response) => {

                  if (res.length > 0) {
                    //Verifica se a reposta do banco de dados é maior que zero para solicitação parcial
                    const newResponse = responseMesclar(
                      response.companies,
                      resultCompanies,
                      country.toLocaleLowerCase()
                    );
  
                    setGeneralData(response);
                    const finalValue = (res.length + newResponse.length) * 0.5;
                    deleteCredit(userInfo?.uid, finalValue);
                    saveAnalytics(userInfo?.uid, infoA);
                    historyCredits(
                      {
                        text: `Credits used with research on Trade Data`,
                        type: "decrease",
                        date: formattedDate,
                        credits: finalValue,
                      },
                      userInfo?.uid
                    );
                    requestData.status = 200;
                    saveRequestApi(requestData); 
                    setCompanies([...res, ...newResponse]);
                    setGeneral(response);
                    setIsRequestFinished(true);
                    setMaxCompaniesFilter(response.totalCompanies);                    
                    resolve(response);            
                  } else {
                    //Realiza consulta completa da API
                    setGeneral(response);
                    setGeneralData(response);
                    setCompanies(response.companies);                  
                    resolve(response)
                    setMaxCompaniesFilter(response.totalCompanies);
                    deleteCredit(
                      userInfo?.uid,
                      parseInt(response.companies.length) * 0.5
                    );
                    saveAnalytics(userInfo?.uid, infoA);
                    historyCredits(
                      {
                        text: `Credits used with research on Trade Data`,
                        type: "decrease",
                        date: formattedDate,
                        credits: parseInt(response.companies.length) * 0.5,
                      },
                      userInfo?.uid
                    );
                    requestData.status = "No cust";
                    saveRequestApi(requestData); 
                    setIsRequestFinished(true);
                    setMaxCompaniesFilter(response.totalCompanies);
                    moveStepAnimation("next", 4);


                    return response;
                  }
                })
                .catch((err) => {
                  console.error("Erro na requisição: " + err);
                  requestData.status = err.response ? err.response.status : "failed"; 
                  saveRequestApi(requestData);
                  reject(err)
                  if (err === "empty") {
                    console.log("Resposta Vazia");
                  }
                })
                .finally(() => setIsRequestFinished(true));
            }
          });
        } else {
          GlobalCompany(
            [country.toLocaleLowerCase()],
            hsCode,
            10,
            rangeInit,
            rangeEnd,
            role,
            userInfo?.userData?.name,
            userInfo?.uid,
            userIP,
            fromDate,
            toDate,
            countryTrader
          )
            .then((response) => {
              //Realiza consulta completa da API
              setGeneral(response);
              setGeneralData(response);
              setCompanies(response.companies);
              setMaxCompaniesFilter(response.totalCompanies);
              deleteCredit(
                userInfo?.uid,
                parseInt(response.companies.length) * 0.5
              );
              saveAnalytics(userInfo?.uid, infoA);
              historyCredits(
                {
                  text: `Credits used with research on Trade Data`,
                  type: "decrease",
                  date: formattedDate,
                  credits: parseInt(response.companies.length) * 0.5,
                },
                userInfo?.uid
              );
              requestData.status = "No cust";
              saveRequestApi(requestData); 
              setIsRequestFinished(true);
              setMaxCompaniesFilter(response.totalCompanies);
              moveStepAnimation("next", 4);

              resolve(response);
            })
            .catch((err) => {
              console.error("Erro na requisição: " + err);
              requestData.status = err.response ? err.response.status : "failed"; 
              saveRequestApi(requestData);
              if (err === "empty") {
                console.log("Resposta Vazia");
              }
            })
            .finally(() => setIsRequestFinished(true));
        }
      }
    })
    
  };

  const RequestCompaniesDb = async () => {
    const userCredits = await viewCredit(userInfo?.uid);

    if (userCredits < custCredit) {
      handleOpenModal();
    } else {
      if (await handleLimitCredits(userInfo?.uid)) {
        window.alert("Sorry you reached your limits of credits");
        return "Limit reached";
      }
      setIsRequestFinished(false);
      const hs = arrayHS();
      let companiesDB = [];
      const rangeInit = defaultRange[0];
      const rangeEnd = defaultRange[1];
      const infoA = {
        action: `Search`,
        date: formattedDate,
        page: "Find new partner",
        keywords: `${countrySel} - ${hs} - ${supplierOrBuyer}`,
        name: userInfo?.userData?.name,
      };
      await filterCompaniesDB(
        countrySel.toLocaleLowerCase(),
        hs,
        50,
        rangeInit,
        rangeEnd === "" ? 1000000000000 : rangeEnd,
        supplierOrBuyer
      ).then(async (res) => {
        //Verifica se houve resultado no banco de dados e armazena na variavel

        if (res.length > 0) {
          res.forEach((item) => {
            companiesDB.push(item.companyId);
          });
        }

        const filteredCompanies = Array.isArray(res)
          ? res.filter((_, index) => index <= 9)
          : [];
        deleteCredit(userInfo?.uid, 5);
        historyCredits(infoC, userInfo?.uid);
        saveAnalytics(userInfo?.uid, infoA);
        setAllCompaniesDb(res);
        setCompanies(filteredCompanies);
        setIsRequestFinished(true);
        moveStepAnimation("next", 4);
      });
    }
  };

  const fetchCompanies = async () => {
    const hs = arrayHS();
    const rangeInit = defaultRange[0];
    const rangeEnd = defaultRange[1];
    const countryTrader = countryTradeArray();

    GlobalCompany(
      [countrySel.toLocaleLowerCase()],
      hs,
      5,
      rangeInit,
      rangeEnd,
      supplierOrBuyer,
      userInfo?.userData?.name,
      userInfo?.uid,
      userIP,
      fromDate,
      toDate,
      countryTrader
    ).then((res) => setGeneralData(res));
  };

  const fetchShipments = async (companyName, country, role) => {
    setIsShipmentsLoading(true);

    ShipmentsCompany(companyName, country, role)
      .then((res) => {
        setShipments(res?.shipment);
        deleteCredit(userInfo?.uid, 5);
      })
      .finally(() => setIsShipmentsLoading(false));
  };

  const fetchShipmentsByHs = async (hsCodeFull, country, role, countryTrade, fromDateShip, toDateShip, productKeywords, limit) => {
    const hs = sendHS(hsCodeFull)
    setSupplierOrBuyer(role)
    setIsShipmentsLoading(true);
    // const countryTrader = countryTradeArray();
    setHsCodeSelected(hs)
    ShipmentsCompanyHS(hs, country, role, countryTrade, fromDateShip, toDateShip, productKeywords, limit)
      .then((res) => {
        setShipments(res?.shipment);            
         deleteCredit(userInfo?.uid, 5);
        }
    )
      .finally(() => setIsShipmentsLoading(false));
  };

  const loadMoreShipments = async (hsCodeFull, country, role, countryTrade, fromDateShip, toDateShip, productKeywords, limit) => {
  
    setIsShipmentsLoading(true);
    // const countryTrader = countryTradeArray();

    ShipmentsCompanyHS(hsCodeFull, country, role, countryTrade, fromDateShip, toDateShip, productKeywords, limit)
      .then((res) => setShipments(res?.shipment))
      .finally(() => setIsShipmentsLoading(false));
  };

  // const testeShipments = Mock;

  // const fetchShipmentsByHs = async (country, role, period, hsCodeFull) => {
  //   return new Promise(async(resolve, reject) => {
  //     setIsShipmentsLoading(true);
  //     const hs = sendHS(hsCodeFull)
  //     try {
  //     //  setShipments(Object.values(testeShipments));

  //       await GetShipments(
  //         country,
  //         role,
  //         hs,
  //         period
  //       ).then(res => {
  //         // 
  //         setShipments(res)
  //         resolve("ok")
  //       })
  //     } catch (error) {
  //       let errorMessage = "Error: No data available.";
  //       message.error(errorMessage);
  //       reject(errorMessage)
  //     } finally {
  //       console.log("Finally")
  //       setIsShipmentsLoading(false);
  //     }
  //   })
  // };

  //Procura mais empresas
  async function requestMore(moreCompanie, listComapniesNow) {

    return new Promise(async(resolve, reject) => {

      setIsMoreLoading(true);
      const userCredits = await viewCredit(userInfo?.uid);
      setUserCredit(userCredits);
  
      if (userCredits < 10) {
        handleOpenModalMore();
        setIsMoreLoading(false);
      }
      const rangeInit = defaultRange[0];
      const rangeEnd = defaultRange[1];
  
      let companiesDB = [];
  
      const infoA = {
        action: `More`,
        date: formattedDate,
        page: "Find new partner",
        keywords: `${country} - ${hsCodeSelected} - ${role} - ${moreCompanie}`,
        name: userInfo?.userData?.name,
      };
      deleteCredit(userInfo?.uid, 5);
      historyCredits(
        {
          text: `Credits used with more companies on Trade Data`,
          type: "decrease",
          date: formattedDate,
          credits: 5,
        },
        userInfo?.uid
      );

      await filterCompaniesMore(
        hsCodeSelected,
        country.toLocaleLowerCase(),
        moreCompanie,
        rangeInit,
        rangeEnd,
        listComapniesNow,
        role
      )

      
        .then(async (res) => {

    

          
          if (res.length > 0) {

            res.forEach((item) => {
              companiesDB.push(item.companyId);
            });
          }
          if (res.length >= moreCompanie) {
            setCompanies([...companies, ...res]);
            resolve(res)

            const finalValue = (companies.length + res.length) * 0.5;
            deleteCredit(finalValue);
            saveAnalytics(userInfo?.uid, infoA);
            historyCredits(
              {
                text: `Credits used with research on Trade Data`,
                type: "decrease",
                date: formattedDate,
                credits: finalValue,
              },
              userInfo.uid
            );
          } else {
            const resultCompanies = moreCompanie - res.length;
            let newExclude = [];
  
            companiesDB.length > 0
              ? (newExclude = [...listComapniesNow, ...companiesDB])
              : (newExclude = listComapniesNow);
  
              const requestData = {
                api: "/full-request",
                searchQuery: `${hsCodeSelected} - ${country} - ${role}`,
                creditsUsed: 4,
                status: null,
              };

            GlobalCompany(
              [country.toLocaleLowerCase()],
              hsCodeSelected,
              resultCompanies,
              rangeInit,
              rangeEnd,
              role,
              userInfo?.userData?.name,
              userInfo?.uid,
              userIP,
              fromDate,
              toDate,
              countryPartner,
              newExclude
            )
              .then((response) => {

                
                if (res.length > 0) {
                  setGeneralData(response);
                  const newResponse = responseMesclar(
                    response.companies,
                    resultCompanies,
                    country.toLocaleLowerCase()
                  );
                  setCompanies([...companies, ...res, ...newResponse]);
                  resolve([...res, ...response.companies])                  
                  requestData.status = 200;
                  saveRequestApi(requestData);
                  const finalValue =
                  companies.length + res.length + newResponse.length;
                  deleteCredit(finalValue);
                  historyCredits(
                    {
                      text: `Credits used with research on Trade Data`,
                      type: "decrease",
                      date: formattedDate,
                      credits: finalValue,
                    },
                    userInfo.uid
                  );
                } else {
                  setCompanies([...companies, ...response.companies]);
                  resolve(response.companies)
                  requestData.status = "No cust";
                  saveRequestApi(requestData);
                  deleteCredit(parseInt(response.companies.length) * 0.5);
                  historyCredits(
                    {
                      text: `Credits used with research on Trade Data`,
                      type: "decrease",
                      date: formattedDate,
                      credits: parseInt(response.companies.length) * 0.5,
                    },
                    userInfo.uid
                  );
                }
              })
              .catch((err) => {
                console.log("Erro na requisição: " + err);
                requestData.status = err.response ? err.response.status : "failed"; 
                saveRequestApi(requestData);
                if (err === "empty") {
                  console.log("All companies already showed");
                }
              });
          }
        })
        .catch((err) => {
          console.error("Error na consulta do BD => " + err);
        });
  
      setTimeout(() => {
        setIsMoreLoading(false);
      }, 1000);
    })
  }

  async function RequestMoreDb(qty) {
    const userCredits = await viewCredit(userInfo?.uid);

    if (userCredits < custCredit) {
      handleOpenModal();
    } else {
      if (await handleLimitCredits(userInfo?.uid)) {
        window.alert("Sorry you reached your limits of credits");
        return "Limit reached";
      }
      setIsRequestFinished(false);
      const hs = arrayHS();
      const infoA = {
        action: `Search`,
        date: formattedDate,
        page: "Find new partner",
        keywords: `${countrySel} - ${hs} - ${supplierOrBuyer}`,
        name: userInfo?.userData?.name,
      };

      deleteCredit(userInfo?.uid, 5);
      historyCredits(infoC, userInfo?.uid);
      saveAnalytics(userInfo?.uid, infoA);
      setCompanies((prevState) =>
        allCompaniesDb.filter((_, index) => index <= prevState.length + qty - 1)
      );
      setIsRequestFinished(true);
      moveStepAnimation("next", 4);
    }
  }

  const configRange = (setRange) => {
    if (setRange === 0) {
      return setRange;
    } else if (setRange === 10000) {
      return null;
    } else {
      return setRange * 1000;
    }
  };

  const arrayHS = () => {
    let array = [];
    hsCodeSel.forEach((item) => {
      array.push(item.hsCode);
    });
    return array;
  };
  const sendHS = (items) => {
    let array = [];
    items.forEach((item) => {
      array.push(item.hsCode);
    });
    return array;
  };

  const countryTradeArray = () => {
    let array = [];
    countryTrade.forEach((item) => {
      array.push(item.value);
    });
    return array;
  };

  function responseMesclar(array, limite, country) {
    let filtrados = array.filter((obj) => obj.country === country);
    // Limitar o número de objetos no array filtrado
    filtrados = filtrados.slice(0, limite);
    return filtrados;
  }

  const validSeletHsCode = (key) => {
    const indexObject = hsCodeSel.findIndex((obj) => obj.key === key);

    if (indexObject !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const cardSupplierOrBuyer = (item) => {
    setSupplierOrBuyer(item.value);
  };

  const listHsCodeCompany = (array) => {
    let string;
    array.forEach((obj) => {
      if (string) {
        string = string + `, ${obj}`;
      } else {
        string = obj;
      }
    });

    return string;
  };

  const handleSearchAgain = () => {
    setHsCodeSel([]);
    setCountrySel("");

    setStep(1);
  };

  function scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const dataCompany = async (id, info) => {
    const userCredits = await viewCredit(userInfo?.uid);

    if (userCredits < 5) {
      handleOpenModalMore();
    } else {
      // let getData = info.filter((obj) => {
      //   if (obj?.companyId) {
      //     return obj.companyId === id;
      //   }
      //   return obj.id === id;
      // });

      if (await handleLimitCredits(userInfo?.uid)) {
        return window.alert("Sorry you reached your limits of credits");
      }
      deleteCredit(userInfo?.uid, 1);
      historyCredits(
        {
          text: `Credits used with profile on Trade Data`,
          type: "decrease",
          date: formattedDate,
          credits: 1,
        },
        userInfo?.uid
      );
      setStep(5);
      if (temShipments(companySelect)) {
        //setCompanySelect(getData);

        setStep(6);
      } else {
        //setCompanySelect(getData);
        await SearchCompany(companyId)
          .then((resp) => {
            setStep(6);
            let array = Object.assign({}, companySelect[0], resp.profile, {
              shipments: resp.shipment,
            });

            setCompanySelect([array]);
          })
          .catch(async () => {
            ProfileCompany(
              companySelect[0]?.companyId ? companySelect[0]?.companyId : companySelect[0]?.id,
              companySelect[0]?.companyName,
              companySelect[0]?.country,
              `${userInfo.userData?.name} ${userInfo.userData?.lastName}`,
              userInfo.uid,
              userIP
            )
              .then((resp) => {
                setStep(6);
                let array = Object.assign({}, companySelect[0], resp.profile, {
                  shipments: resp.shipment,
                });
                setCompanySelect([array]);
              })
              .catch((err) => {
                console.log(
                  "Erro na requisição profile id: " + companySelect[0].companyId
                );
                console.log(err);
              });
          });
      }
    }
  };

  const dataCompanyById = async (id, companyName, country,isFavorited) => {
    const userCredits = await viewCredit(userInfo?.uid);
    
    const requiredCredits = isFavorited ? 0 : 5;

    if (userCredits < requiredCredits) {
      handleOpenModalMore();
    } else {
      if (await handleLimitCredits(userInfo?.uid)) {
        return window.alert("Sorry you reached your limits of credits");
      }
      const consumeCredits = isFavorited ? 0 : 1;
      deleteCredit(userInfo?.uid, consumeCredits);
      historyCredits(
        {
          text: "Credits used with profiles on Trade Data",
          type: "decrease",
          date: formattedDate,
          credits: 1,
        },
        userInfo?.uid
      );
      setStep(5);

      setCompanySelect({ companyId: id, companyName, country});

      await SearchCompany(id).then((resp) => {
        console.log('1ª Chamada')
        console.log(resp)
        if (
          resp?.exportTradingPartners?.length > 0 ||
          resp?.importTradingPartners?.length > 0
        ) {

          console.log('Banco de dados Request')
          
          setStep(6);
          let array = {
            companyId: id,
            companyName,
            country,
            ...resp,
            shipments: resp.shipments,
          };
          return setCompanySelect([array]);
        }

        ProfileCompany(
          id,
          companyName,
          country,
          `${userInfo.userData?.name} ${userInfo.userData?.lastName}`,
          userInfo.uid,
          userIP
        )
        .then((response) => {
          console.log('API Request')
          console.log(response)
          setStep(6);
            let array = {
              companyId: id,
              companyName,
              country,
              ...response.profile,
              shipments: response.shipment,
            };
            setCompanySelect([array]);
          })
          .catch((err) => {
            console.log("Erro na requisição profile id: " + id);
            console.log(err);
          });
      });
    }
  };

  const dataCompanyDb = async (id, info, isFavorited) => {
    let getData = info.filter((obj) => {
      if (obj?.companyId) {
        return obj.companyId === id;
      }
      return obj.id === id;
    });

    if (await handleLimitCredits(userInfo?.uid)) {
      return window.alert("Sorry you reached your limits of credits");
    }

    const consumeCredits = isFavorited ? 0 : 5;
    deleteCredit(userInfo?.uid, consumeCredits);
    historyCredits(
      {
        text: `Credits used with profiles on Trade Data`,
        type: "decrease",
        date: formattedDate,
        credits: 5,
      },
      userInfo?.uid
    );
    setStep(5);

    setCompanySelect(getData);
    await SearchCompany(companyId)
      .then((resp) => {
        setStep(6);
        let array = {
          ...getData[0],
          ...resp,
          shipments: resp?.shipments,
        };

        return setCompanySelect([array]);
      })
      .catch((e) => {
        console.log("Error to request commpanies", e);
      });
  };

  const renderFlag = (countryName) => {
    const filterCountry = Country.filter(
      (obj) => obj.country.toLocaleLowerCase() === countryName
    );
    if (filterCountry.length === 0) {
      const flagDefault = Country.filter(
        (obj) => obj.country.toLocaleLowerCase() === "flag"
      );
      return flagDefault[0].src;
    } else {
      return filterCountry[0].src;
    }
  };

  function temShipments(array) {
    return array.some((objeto) => objeto.hasOwnProperty("shipments"));
  }

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });
  const handlePrintProfile = useReactToPrint({
    content: () => pdfProfileRef.current,
  });

  const saveCompany = () => {
    addFavoriteCompanie(
      companySelect[0].companyId,
      companySelect[0].companyName,
      true,
      companySelect[0].country,
      userInfo.uid
    );
  };

  const handleSelectTable = (newValue) => {
    setSelectedTable(newValue.value);
  };

  const handleSelectPeriod = (newValue) => {
    setSelectedPeriod(newValue.value);
  };

  const handleSearchFor = (newValue) => {
    setSearchFor(newValue.value);
  };

  const handleSelectInfo = (event, newValue) => {
    if (newValue) {
      return setSelectedInfo(newValue);
    }

    setSelectedInfo((prevState) => (prevState === 0 ? 1 : 0));
  };

  function handleCountrySelect(selected) {
    setCountrySel(selected.value);
  }

  function handleCompanyName(event) {
    setCompanyName(event.target.value);
  }

  function reduceShipmentsArray(array) {
    const reduced = array.reduce(
      (acc, actual) => acc + actual.shipmentValue,
      0
    );

    return reduced;
  }

  const contextValue = {
    auth,
    isRequestFinished,
    toggleDrawer,
    SetToggle,
    step,
    setStep,
    animatedStep,
    selectedTable,
    handleSelectTable,
    handleSelectPeriod,
    selectedPeriod,
    countrySel,
    moveStepAnimation,
    searchHsCode,
    hsCodeSel,
    setHsCodeSel,
    userInfo,
    validSeletHsCode,
    supplierOrBuyer,
    cardSupplierOrBuyer,
    defaultRange,
    requestCompanies,
    deleteFavoritedCompany,
    fetchFavoritedCompanies,
    pdfProfileRef,
    companySelect,
    renderFlag,
    handlePrintProfile,
    saveCompany,
    modalVisible,
    handleCloseModal,
    handleConfirmAction,
    listHsCodeCompany,
    handleCountrySelect,
    handlePrint,
    companies,
    requestMore,
    pdfRef,
    dataCompany,
    handleSearchAgain,
    products,
    setCountrySel,
    companyName,
    setDefaultRange,
    handleCompanyName,
    fetchCompaniesByName,
    reduceShipmentsArray,
    isMoreLoading,
    general,
    selectedInfo,
    handleSelectInfo,
    maxCompaniesFilter,
    setCompanyName,
    fetchCompanies,
    generalData,
    userIP,
    setFromDate,
    fromDate,
    setToDate,
    toDate,
    modalMoreVisible,
    handleCloseModalMore,
    RequestCompaniesDb,
    dataCompanyDb,
    fetchCompaniesByNameDB,
    switchValue,
    RequestMoreDb,
    dataCompanyById,
    handleSearchFor,
    searchFor,
    fetchShipments,
    shipments,
    fetchShipmentsByHs,
    isShipmentsLoading,
    setShipments,
    setCountryTrade,
    countryTrade,
    allHsCodes,
    clearFilters,
    key,
    companyLink,
    setCompanyLink,
    companyData, 
    setCompanyData,
    fetchGeoCompanies,
    fetchScout,
    companiesData,
    size, 
    setSize,
    country,
    setCountry,
    listType,
    setListType,
    scoutData,
    setScoutData,
    type,
    setType,
    industries,
    setIndustries,
    states,
    setStates,
    toDateShip,
    setToDateShip,
    fromDateShip,
    setFromDateShip,
    role,
    loadMoreShipments,
    hsCodeSelected,
    keyWordsIncluded,
    setKeyWordsIncluded,
    keyWordsExcluded,
    setKeyWordsExcluded,
    productKeywords,
    setProductKeywords
  }

  return (
    <TradeDataContext.Provider value={contextValue}>
      {children}
    </TradeDataContext.Provider>
  );
}
