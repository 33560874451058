import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const ConfirmPayment = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="success"
      title="Successfully Purchased"
      subTitle="Server configuration takes 1-5 minutes, please wait."
      extra={[
        <Button type="primary" key="console" onClick={() => navigate("/home")}>
          Go home
        </Button>,
      ]}
    />
  );
};

export default ConfirmPayment;
