import { useState } from "react";
import { Button, Checkbox, Divider, Modal } from "antd";
import * as XLSX from "xlsx";

export default function GenerateExcelModal({ data, isOpen, onClose }) {
  const [rowsSelecteds, setRowsSelected] = useState([]);

  if (data.length <= 0) {
    return;
  }

  const dataKeys = data.map((row) => Object.keys(row));
  const mostCompleteRow = getMostCompleteRow(dataKeys);
  const rowsToSelect = mostCompleteRow.map((row) => ({
    value: row,
    label: row.replace(/[A-Z0-9]/g, " $&"),
  }));

  function getMostCompleteRow(allRows) {
    let mostCompleteRow = null;
    let biggestLength = 0;

    for (let i = 0; i < allRows.length; i++) {
      let actualRow = allRows[i];
      if (actualRow.length > biggestLength) {
        biggestLength = actualRow.length;
        mostCompleteRow = actualRow;
      }
    }

    return mostCompleteRow;
  }

  function unifyDataByCompanyName(data, keys) {
    const unifiedData = [];

    data.forEach((subArray) => {
      subArray.forEach((object) => {
        const companyName = object.companyName;

        const existingCompanyIndex = unifiedData.findIndex(
          (company) => company.companyName === companyName
        );

        if (existingCompanyIndex !== -1) {
          keys.forEach((key) => {
            if (
              !(key in unifiedData[existingCompanyIndex]) ||
              unifiedData[existingCompanyIndex][key] === undefined
            ) {
              unifiedData[existingCompanyIndex][key] = object[key];
            }
          });
        } else {
          const companyData = { ...object };
          unifiedData.push(companyData);
        }
      });
    });

    return unifiedData;
  }

  function handleSelectRow(checkedRows) {
    const selectedRowsSet = checkedRows.map((row) =>
      data.map((company) => ({
        companyName: company.companyName,
        [row]: company[row],
      }))
    );

    const selectedRows = unifyDataByCompanyName(selectedRowsSet, checkedRows);
    console.log(selectedRows);
    setRowsSelected(selectedRows);
  }

  function handleCreateExcel() {
    const excelBook = XLSX.utils.book_new();

    rowsSelecteds.forEach((row) => {
      const arraysCols = Object.keys(row).filter(
        (key) => Array.isArray(row[key]) || ""
      );
      const stringColsArray = Object.keys(row)
        .filter((key) => !Array.isArray(row[key]) || "")
        .map((mappedKey) => ({
          [mappedKey]: row[mappedKey] || "Not informed",
        }));

      const stringCols = stringColsArray.reduce(
        (accumulator, current) => ({ ...accumulator, ...current }),
        {}
      );

      // const header = [
      //   {
      //     Created: format(new Date(), "MM/dd/yyyy"),
      //     "Company Name": row.companyName,
      //   },
      // ];

      let table = XLSX.utils.json_to_sheet([stringCols]);

      // XLSX.utils.sheet_add_json(table, header, {
      //   origin: 0,
      // });

      XLSX.utils.book_append_sheet(excelBook, table, row.companyName);

      arraysCols.forEach((key, index) => {
        const prevKey = arraysCols[index - 1];

        XLSX.utils.sheet_add_json(table, row[key], {
          origin: index === 0 ? 3 : 11 + row[prevKey].length,
        });
      });
    });

    XLSX.writeFile(excelBook, "AllCompanies.xlsx");
  }

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      onOk={handleCreateExcel}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button type="primary" onClick={handleCreateExcel}>
          Generate XLSX
        </Button>,
      ]}
    >
      <h2>Data for excel</h2>
      <span>Select the rows you want to add on your excel file</span>

      <Divider />

      <Checkbox.Group
        options={rowsToSelect}
        defaultValue={["companyName"]}
        onChange={handleSelectRow}
      />
    </Modal>
  );
}
