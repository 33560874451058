import React from "react";
import { Tag } from "antd";

export const TagForEmail = ({ status }) => {
  switch (status) {
    case "unknown":
      return <Tag color="volcano">Not Valid</Tag>;
    case "valid":
      return <Tag color="green">Valid</Tag>;      
    default:
      return <Tag color="gray">No Status</Tag>;
  }
};

export const TagForType = ({ type }) => { 
  switch (type) {
    case "generic":
      return <Tag color="volcano">Generic</Tag>;
    case "professional":
      return <Tag color="blue">Professional</Tag>;      
    default:
      return <Tag color="gray">No Status</Tag>;
  }
};
