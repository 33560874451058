import React, { useEffect, useRef, useState } from "react";
import { ContainerHome, GroupInput, TextDefault } from "../../../assets/styles";
import Drawer from "../../../components/Drawer";
import { Grid } from "@mui/material";
import {
  Card,
  ClearButton,
  Container,
  CustomGrid,
  InfoPill,
  InfoTitle,
  InfoValue,
  LeftInfo,
  MainFilter,
  RightInfo,
  SearchButton,
  SelectDefaultC,
  StyledInputNumber,
  TabsContainer,
  Underline,
} from "./style";
import country from "../utils/flag";
import { message, Empty } from "antd";
// import data from "../utils/mock";
import { getSessionId } from "../../../hooks/compliance";
import * as XLSX from "xlsx";
import { useReactToPrint } from "react-to-print";
import PdfExport from "../utils/pdfExport";
import { authScreen } from "../../../contexts/auth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import getUserInfo from "../../../hooks/getUsers";
import LoadingPage from "../../../components/LoadingPage";
import SearchIcon from "@mui/icons-material/Search";
import { saveSanction } from "../../../hooks/sanction";
import { PdfPage } from "../utils/pagePdf";
import MyCardForDetails from "../utils/cardForDetails";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { APITSALE_KEY } from "../../../service/key";
import ApiSanction from "../../../service/apiSanctions";

function Option({ country }) {
  return (
    <div
      className="country-option"
      style={{ display: "flex", alignItems: "center" }}
    >
      <img src={country.src} alt={country.label} width={20} height={20} />
      <strong style={{ marginLeft: 12 }}>{country.label}</strong>
    </div>
  );
}

export default function SearchSanction() {
  const [toggleDrawer, useTroggleDawer] = useState(false);
  const [countryState, setCountryState] = useState("");
  const [step, setStep] = useState(0);
  const [key, setKey] = useState(0);
  const [sessionId, setSessionId] = useState("");
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [number, setNumber] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const location = useLocation();
  const item = location?.state;
  const [searchParams] = useSearchParams();
  const [sanctionsResult, setSanctionsResult] = useState(null);
  const [data, setData] = useState();
  const handleCardClick = (item) => {
    setSelectedItem(item);
  };
  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 500);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    });
  });

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  function SetToggle(state) {
    useTroggleDawer(state);
  }

  const onChange = (value) => {
    setNumber(value);
  };

  function handleCountrySelect(selected) {
    setCountryState(selected.value);
  }
  function handleCompanyName(event) {
    setCompanyName(event.target.value);
  }
  const buscar = () => {
    if (!companyName || !countryState) {
      let errorMessage = "Please fill in the following fields:";
      if (!companyName) errorMessage += " Company Name";

      message.error(errorMessage);
      return;
    }
    requestSanctions();
    setStep(1);
  };

  const clearFilters = () => {
    setCountryState("");
    setCompanyName("");
    setKey((prevKey) => prevKey + 1);
    setStep(0);
  };

  useEffect(() => {
    async function fetchSessionId() {
      try {
        const getSession = await getSessionId();
        setSessionId(getSession);
      } catch (error) {
        console.error("Erro ao buscar sessionid:", error);
      }
    }

    fetchSessionId();
  }, []);

  const pdfRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });

  const userId = userInfo?.uid;

  function saveSearch() {
    saveSanction(userId, selectedItem);
  }

  function handleItemClick() {
    const item = {
      companyName: companyName,
      countrySelected: countryState,
      page: "sanction",
    };
    navigate("/trade-data", { state: item });
  }

  const sheetName = "List Sanction";
  const dataSheet = data?.entityMatchResults?.map((item) => ({
    matchedEntityName: item.matchedEntityName,
    listName: item.listName,
    sanctioned: "Active",
    matchScore: `${item.matchScore}`,
  }));

  const excel = () => {
    const formattedData = dataSheet?.map((item) => ({
      "Sanctioning List": item.matchedEntityName,
      "Sanctioning Source": item.listName,
      "Match Score": `${item.matchScore}%`,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, sheetName + ".xlsx");
  };

  useEffect(() => {
    if (item) {
      const stateCountry = item?.countrySel;

      const selectedCountry = country.filter((c) => c.label === stateCountry);
      setCompanyName(item?.companyName);
      setCountryState(selectedCountry);
    }
  }, [item]);

  useEffect(() => {
    if (searchParams != null) {
      const countrySel = searchParams.get("countrySel");
      const nameCompany = searchParams.get("companyName");

      const selectedCountry = country.filter((c) => c.label === countrySel);
      setCompanyName(nameCompany);
      setCountryState(selectedCountry);
    }
  }, [searchParams]);

  const dataSource = ["ALL"];
  const uid = "";
  const address = "";

  const requestSanctions = async () => {
    ApiSanction(number, companyName, dataSource, uid, countryState, address)
      .then((response) => {
        setData(response);
      })
      .catch((err) => {
        console.error("Erro na requisição: " + err);
        if (err === "empty") {
          console.log("Resposta Vazia");
        }
      });
  };

  return (
    <>
      {auth ? (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={toggleDrawer ? 2 : 1}>
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>

            <Grid
              item
              xs={toggleDrawer ? 10 : 11}
              marginLeft={toggleDrawer ? 35 : 10}
              container
            >
              <Container>
                <TabsContainer>
                  <TextDefault
                    color={"#17283E"}
                    size={"32px"}
                    style={{ marginTop: 30, marginBottom: 10 }}
                  >
                    Sanction
                  </TextDefault>
                  <MainFilter>
                    <TextDefault color={"#000000"} size={"20px"}>
                      Filters
                      <Underline />
                    </TextDefault>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <GroupInput className="groupInputSale">
                        <SelectDefaultC
                          key={`countryState-${key}`}
                          className="inputSale selectSale"
                          type="text"
                          style={{ color: "#94a3b8" }}
                          value={countryState}
                          onChange={(item) =>
                            setCountryState(item.target.value)
                          }
                        >
                          <option value="" disabled selected>
                            Select the country
                          </option>
                          {country.map((obj) => {
                            return (
                              <option value={obj.value}>{obj.label}</option>
                            );
                          })}
                        </SelectDefaultC>
                        <input
                          key={`companyName-${key}`}
                          type="text"
                          placeholder="Company Name"
                          value={companyName}
                          onChange={handleCompanyName}
                        />
                        <StyledInputNumber
                          defaultValue={100}
                          min={0}
                          max={100}
                          formatter={(value) => `${value}%`}
                          parser={(value) => value.replace("%", "")}
                          onChange={onChange}
                        />
                        <ClearButton onClick={clearFilters}>
                          {" "}
                          <ClearIcon sx={{ fontSize: "1.2rem" }} />
                          Clear
                        </ClearButton>

                        <SearchButton onClick={buscar}>Search</SearchButton>
                      </GroupInput>
                    </div>
                  </MainFilter>
                  <br />
                  <>
                    {step === 0 ? (
                      <>
                        <Empty
                          description={
                            <span>Your search result will appear here</span>
                          }
                        ></Empty>
                      </>
                    ) : (
                      <div>
                        <br />
                        <PdfExport
                          action={handlePrint}
                          saveOp={saveSearch}
                          fndp={handleItemClick}
                          excel={excel}
                        />
                        <div>
                          {selectedItem && (
                            <PdfPage ref={pdfRef} item={selectedItem} />
                          )}
                          <TextDefault
                            color={"#8a97aa"}
                            size={"18px"}
                            bold={"400"}
                            style={{
                              width: "100%",
                              marginBottom: 15,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <SearchIcon style={{ marginRight: 5 }} />
                            {data?.totalEntityNameMatchResults}{" "}
                            {data?.totalEntityNameMatchResults.length === 1
                              ? "Matches Found"
                              : "Match Found"}
                          </TextDefault>
                          <CustomGrid container spacing={1}>
                            <Grid item xs={6}>
                              {data?.entityMatchResults?.map((item, index) => (
                                <Card
                                  key={index}
                                  active={selectedItem === item}
                                  onClick={() => handleCardClick(item)}
                                >
                                  <LeftInfo>
                                    <div className="infoRow">
                                      <InfoTitle>Entity Name:</InfoTitle>
                                      <InfoValue>
                                        {item.matchedEntityName}
                                      </InfoValue>
                                    </div>
                                    <div
                                      className="infoRow"
                                      style={{ marginTop: "5px" }}
                                    >
                                      <InfoTitle>List Name: </InfoTitle>
                                      <InfoValue>{item.listName}</InfoValue>
                                    </div>
                                    <div
                                      className="infoRow"
                                      style={{ marginTop: "5px" }}
                                    >
                                      <InfoTitle>Sanctioned:</InfoTitle>
                                      <InfoValue>Active</InfoValue>
                                    </div>
                                  </LeftInfo>
                                  <RightInfo>
                                    <div>
                                      <InfoTitle>Match Score:</InfoTitle>
                                      <br />
                                      <div
                                        style={{
                                          marginTop: "7px",
                                        }}
                                      >
                                        <InfoPill>{item.matchScore}%</InfoPill>
                                      </div>
                                    </div>
                                  </RightInfo>
                                </Card>
                              ))}
                            </Grid>
                            <Grid item xs={5.5}>
                              {selectedItem ? (
                                <MyCardForDetails data={selectedItem} />
                              ) : (
                                ""
                              )}
                            </Grid>
                          </CustomGrid>
                        </div>
                      </div>
                    )}
                  </>
                </TabsContainer>
              </Container>
            </Grid>
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
