import { Col, Row, Table } from "antd";
import { useContext, useState } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { ExcelExportButton } from "./download";

export default function ScoutTable({ data }) {
  const theme = useContext(ThemeContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const onSelectChange = (selectedKeys, selectedRecords) => {
    setSelectedRowKeys(selectedKeys);
    setSelectedRows(selectedRecords);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: false,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const tableData = Object.values(data?.dados);

  const columns = [
    {
      title: "Company",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <Col style={{ textAlign: "center" }}>
          {record?.logo_url?.length > 0 ? (
            <img
              src={record.logo_url}
              alt={record.name}
              style={{
                width: "30px",
                margin: "0% 2%",
                justifyContent: "center",
              }}
            />
          ) : null}
          <Row style={{ textAlign: "center", justifyContent: "center" }}>
            {text}
          </Row>
        </Col>
      ),
    },
    {
      title: "Location",
      dataIndex: "locality",
      key: "locality",
      sorter: (a, b) => {
        const localityA = a.locality || "";
        const localityB = b.locality || "";
        if (!localityA) return 1;
        if (!localityB) return -1;
        return localityA.localeCompare(localityB);
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: [...new Set(tableData.map((item) => item.category))].map(
        (category) => ({
          text: category,
          value: category,
        })
      ),
      onFilter: (value, record) => record.category.includes(value),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      filters: [...new Set(tableData.map((item) => item.size))].map((size) => ({
        text: size,
        value: size,
      })),
      onFilter: (value, record) => (record.size || "").includes(value),
    },
    {
      title: "Website",
      dataIndex: "linkedin_url",
      key: "linkedin_url",
      render: (text, record) => (
        <Row gutter={8} align="middle">
          <Link to={text} target="_blank">
            <LinkedInIcon style={{ color: `${theme.colors.darker[800]}` }} />,
          </Link>
          {record?.website?.length > 0 ? (
            <Link
              to={record?.website}
              target="_blank"
              style={{ marginLeft: 8 }}
            >
              <LanguageIcon style={{ color: `${theme.colors.darker[800]}` }} />
            </Link>
          ) : null}
        </Row>
      ),
    },
  ];

  return (
    <>
      {hasSelected ? `Selected ${selectedRowKeys.length} items` : null}
      {hasSelected ? (
        <ExcelExportButton data={selectedRows} />
      ) : (
        <ExcelExportButton data={tableData} />
      )}
      <Table
        rowKey={(record) => record.id || record.key || record.name}
        dataSource={tableData}
        rowSelection={rowSelection}
        columns={columns}
        style={{ margin: "1%" }}
        bordered
      />
    </>
  );
}
