import styled from "styled-components";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CustomerLists from "../../../icons/customerList.png";
import TodayIcon from "@mui/icons-material/Today";
import { Space } from "antd";
import { PopOver } from "../../../components/PopOver";
import { OptionsContainer } from "../search/style";
import { useEffect, useState } from "react";
import ListPage from "../listPage";

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 12px;
  padding: 20px;
  margin: 10px;
  width: calc(50% - 20px);
  cursor: pointer;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #026aa2;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 20px;
`;

const Content = styled.div`
  flex: 1;
`;

const Title = styled.h3`
  color: #4b4b4b;
  margin: 0;
`;

const SubTitle = styled.a`
  color: #8a97aa;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 14px;

  & > svg {
    margin-right: 5px; // espaço entre ícone e texto
  }
`;

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RectanguleListPerson = ({
  data,
  userId,
  handleDeleteClick,
  handleRename,
}) => {
    
  const [selectedList, setSelectedList] = useState(null);

  useEffect(() => {
    setSelectedList(null);
  }, [data]);

  const handleClick = async (name) => {
    try {
      // const url = `/leadsenrichment-listPage?name=${name}`;
      // window.open(url, "_blank");
      setSelectedList(name);
    } catch (error) {
      console.error(error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const formattedDate = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${year}`;
    return formattedDate;
  };

  return (
    <>
      {selectedList ? (
        <ListPage name={selectedList} />
      ) : (
        <>
      <GridContainer>
        {data.map((item, index) => (
          <Container
            key={index}
            onClick={(e) => {
              if (!e.target.closest(".no-container-click")) {
                handleClick(item.name);
              }
            }}
          >
            <Circle>
              <img
                src={CustomerLists}
                alt="Customer List"
                style={{ width: "80%", height: "80%" }}
              />
            </Circle>
            <Content>
              <Title>{item?.name}</Title>
              <SubTitle>
                <TodayIcon />
                {formatDate(item?.created_at)}
              </SubTitle>
            </Content>
            <>
              <Space size="middle" className="no-container-click">
                <PopOver.Root>
                  <PopOver.Trigger>
                    <MoreHorizIcon sx={{ cursor: "pointer" }} />
                  </PopOver.Trigger>
                  <PopOver.Content>
                    <OptionsContainer>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteClick(userId, item?.name)}
                      >
                        Delete
                      </p>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRename(item?.name)}
                      >
                        Rename
                      </p>
                    </OptionsContainer>
                  </PopOver.Content>
                </PopOver.Root>
              </Space>
            </>
          </Container>
        ))}
      </GridContainer>
      </>
      )}
    </>
 
  );
};

export default RectanguleListPerson;
