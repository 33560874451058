import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import KeyInput from "../../../../icons/keyIconFigma.png";
import { FloatInput } from "../FloatInput";
import { BtnDefault, TextDefault } from "../../../../assets/styles";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { deleteAccount } from "../../../../hooks/getUsers";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 400,
  bgcolor: "#F5F5F5",
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

export default function ModalDelete({ visible, onCancel, userId, email }) {
  const themeContext = useContext(ThemeContext);
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
  const navigate = useNavigate();

  function handleChangePass(event) {
    setConfirmNewPassword(event.target.value);
  }

  const handleDeleteAccount = async () => {
    const result = await deleteAccount(userId, email, confirmNewPassword);
    if (result) {
      navigate("/");
    } else {
      let errorMessage = "Error";
      message.error(errorMessage);
    }
  };

  return (
    <div>
      <Modal
        open={visible}
        onClose={onCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DangerousIcon
            style={{ color: themeContext.colors.danger.main, fontSize: 20 }}
          />
          <TextDefault size="18px" color="#4b4b4b" bold="800">
            Deleting an account
          </TextDefault>
          <br />
          <TextDefault size="14px" color="#4b4b4b" bold="400">
            You are about to delete your account. All your saved data will be
            permanently deleted and cannot be recovered. Are you sure you want
            to continue?
          </TextDefault>

          <br />
          <br />
          <TextDefault size="14px" color="#4b4b4b" bold="400">
            Confirm your password to delete
          </TextDefault>

          <FloatInput
            type="password"
            onChange={handleChangePass}
            value={confirmNewPassword}
            label={"Confirm the password*"}
            notRequired
            img={KeyInput}
          />
          <BtnDefault
            borderR="4px"
            color={!confirmNewPassword ? "#4b4b4b" : ""}
            hoverColor={!confirmNewPassword ? "#4b4b4b" : ""}
            onClick={handleDeleteAccount}
            disabled={!confirmNewPassword}
          >
            <TextDefault size="18px" color="#fff" bold="600">
              Confirm
            </TextDefault>
          </BtnDefault>
          <BtnDefault
            borderR="4px"
            color={"#FC5050"}
            marginLeft={"8px"}
            onClick={onCancel}
          >
            <TextDefault size="18px" color="#fff" bold="600">
              Cancel
            </TextDefault>
          </BtnDefault>
        </Box>
      </Modal>
    </div>
  );
}
