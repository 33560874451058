import axios from "axios";
import { message } from "antd";

import { auth, database } from "../database/config";
import { TOKENTAPI } from "../service/key";

export const signIn = async (email, pass) => {
  return new Promise(async (resolve, reject) => {
    await auth
      .signInWithEmailAndPassword(email, pass)
      .then((value) => {
        resolve("Login bem sucedido " + value);
      })
      .catch((err) => {
        reject("Erro ai se conectar: " + err);
      });
  });
};

export const authScreen = () => {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
};

export const logout = async () => {
  await auth.signOut();
};

const injectLeads = async (name, lastName, email) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(
        "http://18.221.41.195:34200/t-api/v1/newLeads",
        JSON.stringify({
          name,
          lastName,
          email,
        }),
        {
          headers: {
            Authorization: "Bearer " + TOKENTAPI.key,
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        resolve(response.data.id);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

function addOneMonth(dateString) {
  const date = new Date(dateString);
  if (isNaN(date)) {
    throw new RangeError('Invalid time value');
  }
  date.setMonth(date.getMonth() + 1);
  return date.toISOString();
}

function addOneWeek(dateString) {
  const date = new Date(dateString);
  if (isNaN(date)) {
    throw new RangeError('Invalid time value');
  }
  date.setDate(date.getDate() + 7);
  return date.toISOString();
}

export async function requestRegister(address, step1Data) {
  const formattedDate = new Date().toISOString();
  const planEndDate = addOneWeek(formattedDate);

  let firstLogin = true;
  let name = step1Data.name;
  let lastName = step1Data.lastName;
  let email = step1Data.email;
  let password = step1Data.password;
  let phone = step1Data.phone;
  let role = step1Data.role;
  let Credits = 100;
  let addMonthly = 0;
  let planMonthly = 100;
  let uid = null;

  try {
    const userCredential = await auth.createUserWithEmailAndPassword(
      email,
      password
    );
    uid = userCredential.user.uid;

    // leads
    //   const id = await injectLeads(name, lastName, email);

    await database.ref("Users").child(uid).set({
      name,
      lastName,
      email,
      phone,
      role,
      // leadId: id,
      firstLogin,
      address,
      Credits,
      planMonthly,
      addMonthly,
      planEndDate
    });

    message.success(
      "Your account has been successfully created. Welcome aboard!"
    );
  } catch (error) {
    console.error("Erro durante o registro: ", error);
    message.error(
      "Unable to create account. Please check your information and try again."
    );
  }

  return uid;
}

export async function registerUserWeb(address, data) {
  let firstLogin = true;
  let name = data.name;
  let lastName = data.lastName;
  let email = data.email;
  let password = data.password;
  let phone = data.phone;
  let role = data.role;
  let Credits = data.credits;
  let addMonthly = 0;
  let planMonthly = data.credits;
  let uid = null;

  try {
    const userCredential = await auth.createUserWithEmailAndPassword(
      email,
      password
    );
    uid = userCredential.user.uid;

    await database.ref("Users").child(uid).set({
      name,
      lastName,
      email,
      phone,
      role,
      firstLogin,
      address,
      Credits,
      planMonthly,
      addMonthly,
    });

    message.success(
      "Your account has been successfully created. Welcome aboard!"
    );
  } catch (error) {
    console.error("Erro durante o registro: ", error);
    message.error(
      "Unable to create account. Please check your information and try again."
    );
  }

  return uid;
}

export async function listEmail(email) {
  return new Promise(async (resolve, reject) => {
    await auth
      .fetchSignInMethodsForEmail(email)
      .then((providers) => {
        if (providers.length === 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
