import React from "react";
import { ColumnContainer, ImgDefault, TextDefault } from "../../../assets/styles";

export default function Empty({...props}) {
    return(
        <ColumnContainer
        style={{
            width: "100%",
            height: "70vh",                
            justifyContent: "center",
            alignItems: "center"
        }}>
            <ImgDefault src={require("../../../icons/empty-box.png")} width="150px" height="150px"/>
            <TextDefault size="16px" color="#366dfb" bold="700" style={{marginTop: 20}}>{props.desc}</TextDefault>
        </ColumnContainer>
    )
}