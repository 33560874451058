import React, { useContext } from "react";
import { Row } from "antd";
import * as XLSX from "xlsx";
import { TradeDataContext } from "../../../useFNP";
import ExcelExport from "./pdfExport";

export const ExcelExportButton = ({ data }) => {
  const { companyLink } = useContext(TradeDataContext);

  const updatedCompanies = data?.map((company) => {
    const { employee_count, logo_url, follower_count, ...rest } = company;
    return rest;
  });


  const transformDataForExport = (data) => {
    return data.map((item) => ({
      company_name: item.name,
      description: item.description,
      category: item.category,      
      headcount: item.size,
      industries: item.industries?.join(", "),
      type: item.type,
      specialties: item.specialties?.join(", "),
      founded: item.founded,
      addresses:
      item.locations?.length > 0
        ? item.locations
            .map((location) => location?.address || "N/A")
            .join(", ")
        : "N/A",

      location: item.locality,
      country: item.country,
      website: item.website,
      linkedin_url: item.linkedin_url,
      
    }));
  };

  const extractDomain = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?([^\.]+)\.com/;
    const match = url.match(regex);
    return match ? match[1] : url;
  };

  const exportToExcel = () => {
    const exportData = transformDataForExport(updatedCompanies);
    const searchedCompany = extractDomain(companyLink);
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, `${searchedCompany} data.xlsx`);
  };

  return (
    <Row style={{ justifyContent: "flex-end", margin: 0, padding: 0 }}>
      <ExcelExport excel={exportToExcel} />
    </Row>
  );
};
