import styled from "styled-components";

export const SquaresContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
`;

export const Square = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(to bottom, #026aa2 30%, transparent 30%);
  text-align: center;
  color: white;
  padding: 10px;
  box-sizing: border-box;

  & > div {
    width: 100%;
    text-align: center;
  }
  & > .wordd {
    font-size: 14px;    
    margin: 0;
    position: relative;
    top: -20px;
  }

  & .number {
    font-size: 16px;
    margin-top: 5px;
    color: black;
    margin: 0;
  }
`;
