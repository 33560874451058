import { auth, database } from "../database/config";


export async function logDownload(obj) {
    var user = auth.currentUser;

    const dateId = formatarDataHoraId(getDataHoraUTC3())

    await database.ref("Log").child("download").child(dateId).set({
        title: obj.title,
        desc: obj.desc,
        dateForm: getDataHoraUTC3(),
        date: formatarDataHora(getDataHoraUTC3()),
        user: user.uid,
        type: "Download Firebase, function once/on",
        transferSize: calcularTamanhoEmMB(obj.resp)
    })

    
}

function getDataHoraUTC3() {
    const agora = new Date();
    const fusoHorario = agora.getTimezoneOffset() / 60; // Em horas
    const horaUTC3 = agora.setHours(agora.getHours() - (3 + fusoHorario));
    
    return new Date(horaUTC3);
}


function formatarDataHora(dataHora) {
    const data = new Date(dataHora);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    const hora = String(data.getHours()).padStart(2, '0');
    const minuto = String(data.getMinutes()).padStart(2, '0');
    const segundo = String(data.getSeconds()).padStart(2, '0');
  
    return `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;
}

function formatarDataHoraId(dataHora) {
    const data = new Date(dataHora);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    const hora = String(data.getHours()).padStart(2, '0');
    const minuto = String(data.getMinutes()).padStart(2, '0');
    const segundo = String(data.getSeconds()).padStart(2, '0');
  
    return `${dia}-${mes}-${ano} ${hora}-${minuto}-${segundo}`;
}

function calcularTamanhoEmMB(objeto) {
    const jsonString = JSON.stringify(objeto);
    const tamanhoEmBytes = new Blob([jsonString]).size;
    const tamanhoEmMB = tamanhoEmBytes / (1024 * 1024);
    return tamanhoEmMB+" MB";
}


export async function saveRequestApi(data) {
    const { api, searchQuery, creditsUsed, status } = data;
  
    try {
      const requestsRef = database.ref("RequestsApi");
  
      const newRequestRef = requestsRef.push();
      await newRequestRef.set({
        api,
        searchQuery,
        searchDate: new Date().toISOString(),
        creditsUsed,
        status,
      });
  
      return { success: true, message: "Request saved successfully" };
    } catch (error) {
      console.error("Error saving request:", error);
      throw new Error("Erro ao salvar a requisição: " + error.message);
    }
  }