import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-collapse: collapse;
  width: 100%;

  table {
    width: 100%;
  }

  th,
  td {
    border-collapse: collapse;
    padding: 8px;
  }

  td {
    text-align: center;
  }

  th {
    border-bottom: 1px solid #aaa;
  }

  h1 {
    font-size: 48px;
    margin: 0;
  }
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;
`;
