import React, { useEffect, useRef, useState } from "react";
import {
  ContainerHome,
  SelectDefault,
  TextDefault,
} from "../../../assets/styles";
import Drawer from "../../../components/Drawer";
import { Grid } from "@mui/material";
import {
  Card,
  ClearButton,
  Container,
  CustomGrid,
  GroupInput,
  HiddenInScreen,
  InfoTitle,
  InfoValue,
  LeftInfo,
  MainFilter,
  MyLine,
  SearchButton,
  TabsContainer,
  Underline,
  UnderlineG,
} from "./style";
import HsCodes from "../utils/hsCodes";
import { Empty, message } from "antd";
import data from "../utils/mock";
import {
  deleteCompliance,
  getCompliance,
  getSessionId,
  saveCompliance,
} from "../../../hooks/compliance";
import axios from "axios";
import {
  isoCodeCountryEx,
  isoCodeCountryIm,
} from "../utils/mappedCountryImport";
import { useReactToPrint } from "react-to-print";
import PdfExport from "../utils/pdfExport";
import { authScreen } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import getUserInfo from "../../../hooks/getUsers";
import LoadingPage from "../../../components/LoadingPage";
import { PdfPage } from "../utils/pagePdf";
import ClearIcon from "@mui/icons-material/Clear";
import MyCardForDetails from "../utils/cardForDetails";
import ApiCompliance from "../../../service/apiCompliance";
import CardCompliance from "../utils/complianceCard";
import CardComplianceFavorites from "../utils/complianceCardFavorites";

const mappedHsCodes = HsCodes.map((hsCode) => ({
  value: hsCode.value,
  label: `${hsCode.value} - ${hsCode.hsName}`,
}));

export default function SearchCompliance() {
  const [toggleDrawer, useTroggleDawer] = useState(false);
  const [countryImport, setCountryImport] = useState("");
  const [countryExport, setCountryExport] = useState("");
  const [countryOrigin, setCountryOrigin] = useState("");
  const [hsCodeSel, setHsCodeSel] = useState("");
  const [trade, setTrade] = useState("");
  const [step, setStep] = useState(0);
  const [key, setKey] = useState(0);
  const [sessionId, setSessionId] = useState("");
  const [favorites, setFavorites] = useState();
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 500);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    });
  });

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  function SetToggle(state) {
    useTroggleDawer(state);
  }

  function handleHsCodeSelect(selected) {
    setHsCodeSel(selected.value);
  }

  const buscar = () => {
    if (!trade || !countryImport || !countryExport || !hsCodeSel) {
      let errorMessage = "Please fill in the following fields:";
      if (!trade) errorMessage += " Trade Direction";
      if (!countryImport) errorMessage += " Country Import";
      if (!countryExport) errorMessage += " Country Export";
      if (!hsCodeSel) errorMessage += " HsCode";

      message.error(errorMessage);
      return;
    }
    // requestCompliance();
    setStep(1);
  };

  const clearFilters = () => {
    setCountryImport("");
    setCountryExport("");
    setHsCodeSel("");
    setTrade("");
    setCountryOrigin("");
    setKey((prevKey) => prevKey + 1);
    setStep(0);
  };

  useEffect(() => {
    async function fetchSessionId() {
      try {
        const getSession = await getSessionId();
        setSessionId(getSession);
      } catch (error) {
        console.error("Erro ao buscar sessionid:", error);
      }
    }

    fetchSessionId();
  }, []);

  const hsCode = "1201";
  const hsCodeType = 1;

  const requestCompliance = async () => {
    ApiCompliance(hsCodeSel, countryImport, countryExport, hsCodeType, trade)
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.error("Erro na requisição: " + err);
        if (err === "empty") {
          console.log("Resposta Vazia");
        }
      });
  };

  const pdfRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });

  const currentDate = new Date();
  const formattedDate = `${
    currentDate.getMonth() + 1
  }/${currentDate.getDate()}/${currentDate.getFullYear()}`;

  const userId = userInfo?.uid;

  function saveSearch() {
    saveCompliance(userId, data[0]);
  }

  function handleItemClick() {
    if (trade === "import") {
      const item = {
        trade: "import",
        hsCode: hsCode,
        countryOfImport: countryImport?.label,
        page: "compliance",
      };
      navigate("/trade-data", { state: item });
    } else {
      const item = {
        trade: "export",
        hsCode: hsCode,
        countryOfExport: countryExport?.label,
      };
      navigate("/trade-data", { state: item });
    }
  }

  const handleCardClick = (item) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    async function fetchFavorites() {
      try {
        const getFavorites = await getCompliance(userId);
        setFavorites(getFavorites);
      } catch (error) {
        console.error("Erro ao buscar sessionid:", error);
      }
    }

    fetchFavorites();
  }, [userInfo]);


  const dataTeste = {
    derivedReference: "GERDA",
    scopeOfControl:
      "Currently Banned Ships by Europe an Maritime Safety Agency",
    countryOfImport: "Brazil",
    countryOfExport: "United States",
  };

  return (
    <>
      {auth ? (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={toggleDrawer ? 2 : 1}>
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>
            <>
              <Grid
                item
                xs={toggleDrawer ? 10 : 11}
                marginLeft={toggleDrawer ? 35 : 10}
                container
              >
                <Container>
                  <TabsContainer>
                    <TextDefault
                      color={"#17283E"}
                      size={"32px"}
                      style={{ marginTop: 20, marginBottom: 10 }}
                    >
                      Compliance Control
                    </TextDefault>

                    <br />
                    <TextDefault
                      color={"#17283E"}
                      size={"18px"}
                      bold={"400"}
                      style={{ width: "100%", marginBottom: 20 }}
                    >
                      Manage your global trade operations and compliance while
                      reducing operational risk by defining and enforcing global
                      trade regulations and corporate policies.
                    </TextDefault>

                    <MainFilter>
                      <TextDefault color={"#000000"} size={"20px"}>
                        Filters
                        <Underline />
                      </TextDefault>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <GroupInput className="groupInputSale">
                          <SelectDefault
                            key={`trade-${key}`}
                            className={"inputSale   selectSale"}
                            type="text"
                            style={{ color: "#94a3b8" }}
                            value={trade}
                            onChange={(item) => setTrade(item.target.value)}
                          >
                            <option value="" disabled selected>
                              Select trade options...
                            </option>
                            <option value={"import"}>Import</option>
                            <option value={"export"}>Export</option>
                          </SelectDefault>
                        </GroupInput>

                        <GroupInput className="groupInputSale">
                          <SelectDefault
                            key={`countryImport-${key}`}
                            className="inputSale selectSale"
                            type="text"
                            style={{ color: "#94a3b8" }}
                            value={countryImport}
                            onChange={(item) =>
                              setCountryImport(item.target.value)
                            }
                          >
                            <option value="" disabled selected>
                              Select the country of import
                            </option>
                            {isoCodeCountryIm.map((obj) => {
                              return (
                                <option value={obj.isoCode}>
                                  {obj.countryOfImport}
                                </option>
                              );
                            })}
                          </SelectDefault>
                        </GroupInput>

                        <GroupInput className="groupInputSale">
                          <SelectDefault
                            key={`countryExport-${key}`}
                            className="inputSale selectSale"
                            type="text"
                            style={{ color: "#94a3b8" }}
                            value={countryExport}
                            onChange={(item) =>
                              setCountryExport(item.target.value)
                            }
                          >
                            <option value="" disabled selected>
                              Select the country of export
                            </option>
                            {isoCodeCountryEx.map((obj) => {
                              return (
                                <option value={obj.isoCode}>
                                  {obj.countryOfExport}
                                </option>
                              );
                            })}
                          </SelectDefault>
                        </GroupInput>

                        <GroupInput className="groupInputSale">
                          <SelectDefault
                            key={`hsCodeSel-${key}`}
                            className="inputSale selectSale"
                            type="text"
                            style={{ color: "#94a3b8" }}
                            value={hsCodeSel}
                            onChange={(item) => setHsCodeSel(item.target.value)}
                          >
                            <option value="" disabled selected>
                              Select HsCode
                            </option>
                            {mappedHsCodes.map((hsCode) => (
                              <option key={hsCode.value} value={hsCode.value}>
                                {hsCode.label}
                              </option>
                            ))}
                          </SelectDefault>
                        </GroupInput>

                        <ClearButton onClick={clearFilters}>
                          {" "}
                          <ClearIcon sx={{ fontSize: "1.2rem" }} />
                          Clear
                        </ClearButton>
                        <SearchButton onClick={buscar}>Search</SearchButton>
                      </div>
                    </MainFilter>
                    <br />
                    <>
                      {favorites === null ? (
                        <>
                          <Empty
                            description={
                              <span>Your search result will appear here</span>
                            }
                          ></Empty>
                        </>
                      ) : (
                        <div>
                          {/* <PdfExport
                            action={handlePrint}
                            saveOp={saveSearch}
                            fndp={handleItemClick}
                          /> */}
                          <HiddenInScreen>
                            Your {trade} compliance survey on the{" "}
                            {formattedDate}
                          </HiddenInScreen>
                          <PdfPage ref={pdfRef} item={data} />
                          <CustomGrid container spacing={1}>
                            <Grid item xs={6}>
                              <TextDefault color={"#4B4B4B"} bold={"600"}>
                                Favorites
                              </TextDefault>
                              <br />
                              {favorites &&
                              Object.keys(favorites).length > 0 ? (
                                Object.keys(favorites).map((key) => (
                                  <CardComplianceFavorites
                                    key={key}
                                    data={favorites[key]}
                                    userId={userId}
                                    itemKey={key}
                                    onRemove={(userId, itemKey) =>
                                      deleteCompliance(userId, itemKey)
                                    }
                                  />
                                ))
                              ) : (
                                <p>No favorites found.</p>
                              )}
                              <UnderlineG />
                              <TextDefault color={"#4B4B4B"} bold={"600"}>
                                Full Data
                              </TextDefault>
                              <br />
                              <CardCompliance
                                data={dataTeste}
                                userId={userId}
                              />
                              {/* {data.map((item, index) => (
                                <Card
                                  key={index}
                                  active={selectedItem === item}
                                  onClick={() => handleCardClick(item)}
                                >
                                  <LeftInfo>
                                    <div className="infoRow">
                                      <InfoTitle>Country of import:</InfoTitle>
                                      <InfoValue>
                                        {item?.countryOfImport}
                                      </InfoValue>
                                    </div>
                                    <div
                                      className="infoRow"
                                      style={{ marginTop: "5px" }}
                                    >
                                      <InfoTitle>Country of export: </InfoTitle>
                                      <InfoValue>
                                        {item?.countryOfExport}
                                      </InfoValue>
                                    </div>
                                    <div
                                      className="infoRow"
                                      style={{ marginTop: "5px" }}
                                    >
                                      <InfoTitle>Reference:</InfoTitle>
                                      <InfoValue>{item?.reference}</InfoValue>
                                    </div>
                                  </LeftInfo>
                                </Card>
                              ))} */}
                            </Grid>
                            {/* <Grid item xs={5} mr={5.5}>
                              {selectedItem ? (
                                <MyCardForDetails data={selectedItem} />
                              ) : (
                                ""
                              )}
                            </Grid> */}
                          </CustomGrid>
                        </div>
                      )}
                    </>
                  </TabsContainer>
                </Container>
              </Grid>
            </>
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
