/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import CSV from "../../../../icons/csv.png";
import * as XLSX from "xlsx";
import { ThemeContext } from "styled-components";
import {
  BtnLoadMore,
  ColumnContainer,
  RowContainer,
  TextDefault,
} from "../../../../assets/styles";
import "./styles.css";
import { TradeDataContext } from "../../useFNP";
import DynamicTable from "./components/DynamicTable";
import { MyCardContent } from "../../../leadsEnrichment/search/style";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import RankingShipTable from "../CompanyProfile/components/rankingShipTable";
import PortsShipTable from "../CompanyProfile/components/portsShipTable";
import RankingShipCountryExport from "../CompanyProfile/components/shipmentsCountryExport";
import BarChart from "./components/graphicBar";
import BuyerChart from "./components/graphicBar/buyerBar";
import PortsShipTableExport from "../CompanyProfile/components/portsShipTable/portTableExport";
import Empty from "../empty";
import DistributionChart from "./components/DynamicTable/distributionChart";
import VolumeDistributionChart from "./components/DynamicTable/weightChart";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <br />
      <Box>{children}</Box>
    </div>
  );
}

function allyProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Shipments({...props}) {
  const {
    shipments,
    toggleDrawer,
    isShipmentsLoading,
    supplierOrBuyer,
    role,
    hsCodeSelected, 
    country, 
    countryTrade, 
    fromDateShip, 
    toDateShip,
    productKeywords,
    loadMoreShipments
  } = useContext(TradeDataContext);
  const theme = useContext(ThemeContext);

  const [selectedInfo, setSelectedInfo] = useState(0);
  const [portsOfLading, setPortsOfLading] = useState([]);
  const [portsOfUnlading, setPortsOfUnlading] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false)
  const [loadedShipmentsCount, setLoadedShipmentsCount] = useState(0);


  const actualDate = new Date().toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  const sheetName = `Shipments Info`;
  const excel = () => {
    const header = [
      {
        A: "TradeSee",
        B: `Date: ${actualDate}`,
        C: `Search Index: ${role}`,
      },
    ];

    const headerSheet = XLSX.utils.json_to_sheet(header, { skipHeader: true });
    const dataSheet = XLSX.utils.json_to_sheet(shipments);

    const dataRange = XLSX.utils.decode_range(dataSheet["!ref"]);
    const finalSheet = { ...headerSheet };
    const startRow = 2;

    for (let R = dataRange.s.r; R <= dataRange.e.r; ++R) {
      for (let C = dataRange.s.c; C <= dataRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R + startRow, c: C });
        finalSheet[cellAddress] =
          dataSheet[XLSX.utils.encode_cell({ r: R, c: C })];
      }
    }

    const finalRange = {
      s: { r: 0, c: dataRange.s.c },
      e: { r: dataRange.e.r + startRow, c: dataRange.e.c },
    };
    finalSheet["!ref"] = XLSX.utils.encode_range(finalRange);

    finalSheet["A1"] = { v: "TradeSee", s: { font: { bold: true } } };
    finalSheet["B1"] = { v: actualDate, s: { font: { bold: true } } };
    finalSheet["C1"] = {
      v: `Search Index: ${role}`,
      s: { font: { bold: true } },
    };

    finalSheet["!merges"] = [
      { s: { r: 0, c: 2 }, e: { r: 0, c: 3 } }, // Mesclar C1 e D1
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, finalSheet, sheetName);

    XLSX.writeFile(wb, `${sheetName}.xlsx`);
  };

  const handleSelectInfo = (event, newValue) => {
    if (newValue) {
      return setSelectedInfo(newValue);
    }

    setSelectedInfo((prevState) => (prevState === 0 ? 1 : 0));
  };
  

    useEffect(() => {
      setLoadedShipmentsCount(shipments?.length)
    }, [shipments]);

  
    const handleLoadMore = () => {
      setLoadingMore(true);
      const limit = loadedShipmentsCount + 10;
      loadMoreShipments(hsCodeSelected, country, supplierOrBuyer, countryTrade, fromDateShip, toDateShip, productKeywords, limit).then(resp => {
        if (resp) {
          props.setShipment(true);
          setTimeout(() => {
            props.setLoading(false);
          }, 1500);
        }
        setLoadingMore(false); 
      }) 
      .catch(e => {
        console.log(e)
        setLoadingMore(false)
        props.setLoading(false)
      })
      setLoadedShipmentsCount(prevCount => prevCount + 10);
    };   
  
    const isLoadMoreDisabled =
      shipments.length < loadedShipmentsCount || shipments.length % 10 !== 0;


  useEffect(() => {
    const filteredShipments = shipments
      .filter(
        (shipment) => shipment["portOfLading"] || shipment["Port of Origin"]
      )
      .map((shipment, index) => ({
        key: index,
        port: shipment["portOfLading"] || shipment["Port of Origin"],
      }));

    setPortsOfLading(filteredShipments);
  }, [shipments]);

  useEffect(() => {
    const filteredShipmentsUnlading = shipments
      .filter(
        (shipment) =>
          shipment["portOfUnlading"] || shipment["Port of Destination"]
      )
      .map((shipment, index) => ({
        key: index,
        port: shipment["portOfUnlading"] || shipment["Port of Destination"],
      }));

    setPortsOfUnlading(filteredShipmentsUnlading);
  }, [shipments]);


  function calculateSupplierRankings(shipments) {
    let supplierData = {};

    // Processando cada shipment
    shipments.forEach((shipment) => {
      const shipperName = shipment["shipperName"];
      const weight = parseFloat(shipment["shipmentWeight"]);
      const value = parseFloat(shipment["shipmentValue"]);
      const country = shipment["shipperCountry"];

      if (!supplierData[shipperName]) {
        supplierData[shipperName] = {
          totalWeight: 0,
          totalValue: 0,
          totalShipments: 0,
          country: country,
        };
      }

      supplierData[shipperName].totalWeight += weight;
      supplierData[shipperName].totalValue += value;
      supplierData[shipperName].totalShipments += 1;
    });

    const rankings = Object.keys(supplierData).map((supplier) => ({
      supplierName: supplier,
      totalWeight: supplierData[supplier].totalWeight,
      totalValue: supplierData[supplier].totalValue,
      totalShipments: supplierData[supplier].totalShipments,
      country: supplierData[supplier].country,
    }));

    rankings.sort((a, b) => {
      if (b.totalValue !== a.totalValue) {
        return b.totalValue - a.totalValue;
      }
      return b.totalWeight - a.totalWeight;
    });

    return rankings;
  }

  const supplierRankings = calculateSupplierRankings(shipments);


  function calculateBuyerRankings(shipments) {
    let buyerData = {};

    // processando cada shippment
    shipments.forEach((shipment) => {
      const consigneeName = shipment["consigneeName"];
      const weight = parseFloat(shipment["shipmentWeight"]);
      const value = parseFloat(shipment["shipmentValue"]);

      const country = shipment["consigneeCountry"];

      if (!buyerData[consigneeName]) {
        buyerData[consigneeName] = {
          totalWeight: 0,
          totalValue: 0,
          totalShipments: 0,
          country: country,
        };
      }

      buyerData[consigneeName].totalWeight += weight;
      buyerData[consigneeName].totalValue += value;
      buyerData[consigneeName].totalShipments += 1;
    });

    // convertendo em lista
    let buyerRankings = Object.keys(buyerData).map((consigneeName) => {
      return {
        consigneeName: consigneeName,
        totalWeight: buyerData[consigneeName].totalWeight,
        totalValue: buyerData[consigneeName].totalValue,
        totalShipments: buyerData[consigneeName].totalShipments,
        country: buyerData[consigneeName].country,
      };
    });

    //ordenando
    buyerRankings.sort((a, b) => b.totalWeight - a.totalWeight);

    return buyerRankings;
  }

  const buyerRankings = calculateBuyerRankings(shipments);

 
  return (
    <>
      <Grid item xs={toggleDrawer ? 10 : 11} container>
        <Grid item xs={12} style={{ marginBottom: 50 }}>
          {shipments?.length > 0 && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <div style={{ gridColumn: "1/-1" }}>
                {shipments?.length > 0 ? (
                  <>
                    <RowContainer
                      style={{
                        marginTop: 20,
                        marginBottom: 24,
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ position: "fixed", right: 50, top: 1 }}>
                        <ul className="wrapper">
                          <li className="icon twitter" onClick={excel}>
                            <span className="tooltip">EXCEL</span>
                            <span>
                              <i className="fab fa-twitter"></i>
                            </span>
                            <img
                              src={CSV}
                              style={{ width: 25, height: 25 }}
                              alt="CSV Icon"
                            />
                          </li>
                        </ul>
                      </div>
                    </RowContainer>
                    <MyCardContent>
                      <Tabs
                        value={selectedInfo}
                        onChange={handleSelectInfo}
                        aria-label="Filter by"
                        style={{
                          width: "98%",
                          borderBottom: "1px solid #d9d9d9",
                          marginTop: "8px",
                        }}
                      >
                        <Tab label="Shipments" {...allyProps(0)} />
                        <Tab label="Suppliers" {...allyProps(1)} />
                        <Tab label="Buyers" {...allyProps(2)} />
                         <Tab label="By Country" {...allyProps(3)} />
                        <Tab label="Port's of Lading" {...allyProps(4)} />
                        <Tab label="Port's of Unlanding" {...allyProps(5)} />
                      </Tabs>

                      <CustomTabPanel value={selectedInfo} index={0}>
                        <Box sx={{ height: "85%", width: "98%" }}>
                          <DynamicTable data={shipments} role={role} />
                          <ColumnContainer style={{marginTop: 20, marginBottom: 30, width: "100%", justifyContent: "center", alignItems: "center"}}>
            <BtnLoadMore onClick={() => handleLoadMore()} disabled={isLoadMoreDisabled} >
              {loadingMore ? (
                <div aria-label="Loading..." role="status" class="loaderGet" style={{width: 75}}>
                  <svg class="iconLoading" viewBox="0 0 256 256">
                      <line x1="128" y1="32" x2="128" y2="64" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                      <line x1="195.9" y1="60.1" x2="173.3" y2="82.7" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                      <line x1="224" y1="128" x2="192" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                      <line x1="195.9" y1="195.9" x2="173.3" y2="173.3" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                      <line x1="128" y1="224" x2="128" y2="192" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                      <line x1="60.1" y1="195.9" x2="82.7" y2="173.3" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                      <line x1="32" y1="128" x2="64" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                      <line x1="60.1" y1="60.1" x2="82.7" y2="82.7" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                  </svg>
                  <TextDefault size="13px" color="#fff" bold="700" >Loading...</TextDefault>
                </div>
              ) : (
                <TextDefault color="#fff" size="16px">Load more</TextDefault>
              )}
            </BtnLoadMore>
          </ColumnContainer>
                        </Box>
                      </CustomTabPanel>

                      <CustomTabPanel value={selectedInfo} index={1}>
                        <BarChart rankings={supplierRankings} />
                        {/* <RankingShipTable
                          data={supplierRankings}
                          renderFlag={renderFlag}
                        /> */}
                      </CustomTabPanel>

                      <CustomTabPanel value={selectedInfo} index={2}>
                        <BuyerChart rankings={buyerRankings} />
                        {/* <RankingShipTable
                          data={buyerRankings}
                          renderFlag={renderFlag}
                        /> */}
                      </CustomTabPanel>

                       <CustomTabPanel value={selectedInfo} index={3}>
                       <DistributionChart shipments={shipments} role={supplierOrBuyer} />
                       <VolumeDistributionChart shipments={shipments} role={supplierOrBuyer} />

                        {/* <RowShipments>
                          <Section>
                            <Title> Shipment by Country Export </Title>
                            <RankingShipCountryExport
                              data={countryExportData}
                              renderFlag={renderFlag}
                            />
                          </Section>
                          <Section>
                            <Title> Shipment by Country Import </Title>
                            <RankingShipCountryExport
                              data={countryImportData}
                              renderFlag={renderFlag}
                            />
                          </Section>
                        </RowShipments> */}
                      </CustomTabPanel> 
                      <CustomTabPanel value={selectedInfo} index={4}>
                         <PortsShipTable data={portsOfLading} />
                      </CustomTabPanel>
                      <CustomTabPanel value={selectedInfo} index={5}>
                        <PortsShipTableExport data={portsOfUnlading} />
                      </CustomTabPanel>
                    </MyCardContent>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}

          {shipments.length <= 0 && !isShipmentsLoading && (
            <div>
              <Empty desc={"No results found. Try adjusting your search terms!"}/>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
}
