import React, { useEffect, useState } from "react";
import {
  ContainerHome,
  TextDefault,
  IconServices,
  ColumnContainer,
} from "../../assets/styles";
import Drawer from "../../components/Drawer";
import { Grid } from "@mui/material";
import ButtonBlue from "../../components/myButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SustainabilityImg from "../../icons/sustainIcon.png";
import { Link, useNavigate } from "react-router-dom";
import { authScreen } from "../../contexts/auth";
import LoadingPage from "../../components/LoadingPage";

export default function Compliance() {
  const [toggleDrawer, useTroggleDawer] = useState(false);
  function SetToggle(state) {
    useTroggleDawer(state);
  }

  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 500);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    });
  });

  return (
    <>
      {auth ? (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            style={{
              height: "100%",
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={toggleDrawer ? 2 : 1}>
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>
            <Grid item xs={toggleDrawer ? 8 : 8} container alignItems="center">
              <Grid container xs={12}>
                <Grid item xs={7}>
                  <ColumnContainer style={{ width: "100%", marginLeft: 100 }}>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <TextDefault color={"#4b4b4b"} size={"32px"}>
                      Compliance
                    </TextDefault>
                    <TextDefault
                      color={"#8a97aa"}
                      size={"18px"}
                      bold={"400"}
                      style={{ marginTop: 20, width: "75%" }}
                    >
                      Manage your global trade operations and compliance while
                      reducing operational risk by defining and enforcing global
                      trade regulations and corporate policies.
                    </TextDefault>
                    <Link to={"/search-compliance"}>
                      <ButtonBlue width="250px" marginTop="50px">
                        Get started{" "}
                        <ArrowForwardIcon
                          sx={{ fontSize: "1.2rem", verticalAlign: "middle" }}
                        />
                      </ButtonBlue>
                    </Link>
                  </ColumnContainer>
                </Grid>
                <Grid item xs={2} style={{ marginLeft: 200 }}>
                  <IconServices iconUrl={SustainabilityImg} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
