export const IndustriesMock = [
    "Abrasives and Nonmetallic Minerals Manufacturing",
    "Accessible Architecture and Design",
    "Accommodation Services",
    "Accounting",
    "Administration of Justice",
    "Administrativ and Support Services",
    "Adverstising Services",
    "Agricultural Chemical Manufacturing",
    "Agriculture, Construction, Mining Machinery Manufacturing",
    "Air, Water, and Waste Program Management",
    "Airlines and Aviation",
    "Alternative Dispute Resolution",
    "Alternative Medicine",
    "Ambulance Services",
    "Amusement Parks and Arcades",
    "Animal Feed Manufacturing",
    "Animation",
    "Animation and Post-production",
    "Apparel Manufacturing",
    "Apparel and Fashion",
    "Appliances, Electrical, and Electronics Manufacturing",
    "Architectural and Structural Metal Manufacturing",
    "Architecture and Planning",
    "Armed Forces",
    "Artists And Writes",
    "Arts and Crafts",
    "Audio and Video Equipment Manufacturing",
    "Automation Machinery Manufacturing",
    "Automative",
    "Aviation & Aerospace Component",
    "Aviation and Aerospace Component Manufacturing",
    "Baked Goods Manufacturing",
    "Banking",
    "Bars, Tavers, And Nightclubs",
    "Bed-and-Breakfasts,Hostels, Homestays",
    "Beverage Manufacturing",
    "Biomass Eletric Power Generation",
    "Biotechnology",
    "Biotechnology research",
    "Blockchain Services",
    "Blogs",
    "Boilers, Tanks, and Shipping Container Manufacturing",
    "Book Publishing",
    "Book and Periodical Publishing",
    "Breweries",
    "Broadcast Media Production and Distribution",
    "Building Construction",
    "Building Equipment Contractors",
    "Building Finishing Contractors",
    "Building Materials",
    "Building Structure and Exterior Contractors",
    "Business Consulting and Services",
    "Business Content",
    "Business Intelligence Platforms",
    "Business Supplies and Equipment",
    "Capital Markets",
    "Caterers",
    "Chemical Manufacturing",
    "Chemical Raw Materials Manufacturing",
    "Child Day Care Services",
    "Chiropractors",
    "Civic and Social Organizations",
    "Civil Engineering",
    "Claims Adjusting, Actuarial Services",
    "Clay and Refractory Products Manufacturing",
    "Climate Data and Analytics",
    "Climate Technology Product Manufacutring",
    "Coal Mining",
    "Collection Agencies",
    "Commercial Real Estate",
    "Commercial and Industrial Equipment Rental",
    "Commercial and Industrial Machinery Maintenance",
    "Commercial and Service Industry Machinery Manufacturing",
    "Communications Equipment Manufacturing",
    "Community Development and Urban Planning",
    "Community Services",
    "Computer Games",
    "Computer Hardware",
    "Computer Hardware Manufacturing",
    "Computer Networking Products",
    "Computer Networking",
    "Computer and Networking Securtiy",
    "Computaers and Electronics Manufacturing",
    "Conservation Programs",
    "Construction",
    "Construction Hardware Manufacturing",
    "Consumer Electronics",
    "Consumer Goods",
    "Consumer Goods Rental",
    "Consumer Services",
    "Cosmetics",
    "Cosmetology and Barber Schools",
    "Courts of Law",
    "Credit Intermediation",
    "Dairy",
    "Dairy Product Manufacturing",
    "Dance Companies",
    "Data Infrastructure and Analytics",
    "Data Security Software Products",
    "Defense & Space",
    "Defense and Space Manufacturing",
    "Dentists",
    "Design",
    "Design Services",
    "Desktop Computing Software Products",
    "Digital Accessibility Services",
    "Distilleries",
    "E-Learning",
    "E-Learning Providers",
    "Economic Programs",
    "Education",
    "Education Administration Programs",
    "Education Management",
    "Eletric Lighting Equipment Manufacturing",
    "Eletric Power Generation",
    "Eletric Power Transmission, Control, and Distribution",
    "Eletrical Equipment Manufacturing",
    "Eletronic and Precision Equipment Maintenance",
    "Embedded Software Products",
    "Emergency and Relief Services",
    "Engineering Services",
    "Engines and Power Transmission Equipment Manufacturing",
    "Entertainment",
    "Entertainment Providers",
    "Environmental Quality Programs",
    "Environmental Services",
    "Equipment Rental Services",
    "Events Services",
    "Executive Offices",
    "Executive Search Services",
    "Fabricated Metal Products",
    "Facilities Services",
    "Farming",
    "Farmig, Ranching, Forestry",
    "Fashion Accessories Manufacturing",
    "Financial Services",
    "Fine Art",
    "Fine Arts Schools",
    "Fire Protection",
    "Fisheries",
    "Flight Training",
    "Food & Beverages",
    "Food Production",
    "Food and Beverage Retail",
    "Food and Beverage Services",
    "Footwear Manufacturing",
    "Forestry and Logging",
    "Freight and Package Transportagion",
    "Fruit and Vegetable Preserves Manufacturing",
    "Fundraising",
    "Funds and Trusts",
    "Furniture",
    "Furniture and Home Furnishings Manufacturing",
    "Gambling Facilities and Casinos",
    "Geothermal Eletric Power Generation",
    "Glass Product Manufacturing",
    "Glass, Ceramics and Concrete Manufacturing",
    "Golf Courses and Country Clubs",
    "Government Administration",
    "Government Relations",
    "Government Relations Services",
    "Graphic Design",
    "Ground Passenger Transportation",
    "HVAC and Refrigeration Equipment Manufacturing",
    "Health and Human Services",
    "Health, Wellness and Fitness",
    "Higher Educaion",
    "Highway, Street, and Bridge Construction",
    "Historical Sites",
    "Holding Companies",
    "Home Health Care Services",
    "Horticulture",
    "Hospitality",
    "Hospitals",
    "Hospitals and Health Care",
    "Hotels and Motels",
    "Household Appliance Manufacturing",
    "Household Services",
    "Household and Institutional Furniture Manufacturing",
    "Housing Programs",
    "Housing and Community Development",
    "Human Resources",
    "Human Resources Services",
    "Hydroelectric Power Generation",
    "IT Services and IT Consulting",
    "IT System Custom Software Development",
    "IT System Data Services",
    "IT System Design Services",
    "IT System Installation and Disposal",
    "IT System Operations and Maintenance",
    "IT System Testing and Evaluation",
    "IT System Training and Support",
    "Import and Export",
    "Individual and familiy Services",
    "Industrial Automation",
    "Industrial Machinery Manufacturing",
    "Industry Associations",
    "Information Services",
    "Information Technology and Services",
    "Insurance",
    "Insurance Agencies and Brokerages",
    "Insurance Carriers",
    "Insurance and Employee Benefit Funds",
    "Interior Design",
    "International Affairs",
    "International Trade and Development",
    "Internet Marktplace Platforms",
    "Internet News",
    "Internet Publishing",
    "Investment Advice",
    "Investment Banking",
    "Investment Management",
    "Janitorial Services",
    "Landscaping Services",
    "Language Schools",
    "Laundry and Drycleaning Services",
    "Law Enforcement",
    "Law Practice",
    "Leasing Non-residential Real Estate",
    "Leasing Residential Real Estate",
    "Leasing Product Manufacturing",
    "Legal Services",
    "Legislative Offices",
    "Leisure, Travel & Tourism",
    "Libraries",
    "Loan Brokers",
    "Luxury Goods and Jewelry",
    "Machinery Manufacturing",
    "Manufacturing",
    "Maritime",
    "Maritime Transportation",
    "Market Research",
    "Marketing Services",
    "Mattress and Blinds Manufacturing",
    "Measuring and Control Instrument Manufacturing",
    "Meat Products Manufacturing",
    "Mechanical or Industrial Engineering",
    "Media & Telecommunications",
    "Media Production",
    "Medical Devices",
    "Medical Equipment Manufacturing",
    "Medical Practices",
    "Medical and Diagnostic Laboratories",
    "Mental Health Care",
    "Metal Ore Mining",
    "Metal Treatments",
    "Metal Valve, Ball, and Roller Manufacturing",
    "Metalworking Machinery Manufacturing",
    "Military and International Affairs",
    "Mining",
    "Mobile Computing Software products",
    "Mobile Food Services",
    "Mobile Gaming Apps",
    "Motor Vehicle Manufacturing",
    "Motor Vehicle Parts Manufacturing",
    "Movies and Sound Recording",
    "Movies, Videos and Sound",
    "Museums",
    "Museums, Historical Sites, and Zoos",
    "Music",
    "Musicians",
    "Nanotechonology Research",
    "Natural Gás Distribuiton",
    "Nwspaper Publishing",
    "Non_profit Organization Management",
    "Non-Profit Organizations",
    "Nonmetallic Mineral Mining",
    "Nonresidential Building Construction",
    "Nuclear Eletric Power Generation",
    "Nursing Homes and Residential Care Facilities",
    "Office Administration",
    "Office Furniture and Fixtures Manufacturing",
    "Oil and Gás",
    "Oil, Gás, and Mining",
    "Online Audio and Video Media",
    "Online Media",
    "Online and Mail Order Retail",
    "Operations Consulting",
    "Optometrists",
    "Outpatient Care Centers",
    "Outsourcing and Offshoring Consulting",
    "Outsourcing/Offshoring",
    "Packing and Containers",
    "Packing and Containers Manufacturing",
    "Paint, Coating, and Adhesive Manufacturing",
    "Paper and Forest Product Manufacturing",
    "Paper and Forest Products",
    "Perfoming Arts",
    "Perfoming Arts and Spectator Sports",
    "Periodical Publishing",
    "Personal Care Product Manufacturing",
    "Personal Care Services",
    "Personal and Laundry Services",
    "Pet Services",
    "Pharmaceutical Manufacturing",
    "Philanthropical Fundraising Services",
    "Philanthropy",
    "Photography",
    "Physical, Occupational and Speech Thereapists",
    "Physicians",
    "Plasics and Rubber Product Manufacturing",
    "Political Organizations",
    "Primary Metal Manufacturing",
    "Primary and Secondary Education",
    "Printing Services",
    "Professional Organizations",
    "Professional Services",
    "Professional Training and Coaching",
    "Program Development",
    "Public Assistance Programs",
    "Public Health",
    "Public Policy",
    "Public Policy Offices",
    "Public Relations and Communciations Services",
    "Public Safety",
    "Radio and Television Broadcasting",
    "Rail Transportation",
    "Railroad Equipment Manufacturing",
    "Ranching",
    "Real Estate",
    "Real Estate Agents and Brokers",
    "Real Estate and Equipment Rental Services",
    "Recreational Facilities",
    "Religious Institutions",
    "Renewable Energy Equipment Manufacturing",
    "Renewable Energy Power Generation",
    "Renewable Energy Semiconductor Manufacturing",
    "Renewable & Environment",
    "Repair and Maintenance",
    "Research",
    "Research Services",
    "Residential Building Construction",
    "Restaurants",
    "Retail",
    "Retail Apparel and Fashion",
    "Retail Appliances, Electrical, and Electronic",
    "Retail Art Dealers",
    "Retail Art Supplies",
    "Retail Books and Printed News",
    "Retail Building Materials and Garden Equipment",
    "Retail Florists",
    "Retail Furniture and Home Furnishings",
    "Retail Gasoline",
    "Retail Groceries",
    "Retail Health and Personal Care Products",
    "Retail Luxury Goods and Jewelry",
    "Retail Motor Vehicles",
    "Retail Musical Instruments",
    "Retail Office Equipment",
    "Retail Office Supplies and Gifts",
    "Retail Pharmacies",
    "Retail Recyclable Materials & Used Merchandise",
    "Reupholstery and Furniture Repair",
    "Robotics Engineering",
    "Rubber Products Manufacturing",
    "Satellite Telecommunications",
    "School and Employee Bus Services",
    "Seafood Product Manufacturing",
    "Securities and Commodity Exchanges",
    "Security Guards and Patrol Services",
    "Security Systems Services",
    "Security and Investigations",
    "Semiconductor Manufacturing",
    "Semiconductors",
    "Services for Renewable Energy",
    "Services for the Elderyl and Disabled",
    "Sheet Music Publishing",
    "Shipbuilding",
    "Shuttles and Special Needs Transportation Services",
    "Sightseeing Transportation",
    "Soap and Cleaning Product Manufacturing",
    "Social Networking Platforms",
    "Software Development",
    "Solar Eletric Power Generation",
    "Sound Recording",
    "Space Research and Technology",
    "Specialty Trade Contractors",
    "Spectator Sports",
    "Sporting Goods",
    "Sporting Goods Manufacturing",
    "Sports Teams and Clubs",
    "Sports and Recreation Instruction",
    "Spring and Wire Product Manufacturing",
    "Staffing and Recruiting",
    "Steam and Air-Conditioning Supply",
    "Strategic Management Services",
    "Subdivision of Land",
    "Sugar and Confectionery Product Manufacturing",
    "Surveying and Mapping Services",
    "Taxi and Limousine Services",
    "Technical and Vocational Training",
    "Technology, Information and Internet",
    "Technology, Information and Media",
    "Telecommunications",
    "Telecommunications Carriers",
    "Telephone Call Centers",
    "Tempory Help Services",
    "Textile Manufacturing",
    "Theater Companies",
    "Think Tanks",
    "Tabacco",
    "Tabacco Manufacturing",
    "Translation and Localization",
    "Transportation Equipment Manufacturing",
    "Transportation programs",
    "Transportation, Logistics, Supply Chain and Storage",
    "Transportation/Trucking/Railroad",
    "Travel Arrangements",
    "Truck Transportation",
    "Trusts and Estates",
    "Turned Products and Fastener Manufacturing",
    "Urban Transit Services",
    "Utilities",
    "Utilities Administration",
    "Utility System Construction",
    "Vehicle Repair and Maintenance",
    "Venture Capital and Private Equity Principals",
    "Veterinary",
    "Veterinary Services",
    "Vocational Rehabilitation Services",
    "Warehousing",
    "Warehousing and Storage",
    "Waste Collection",
    "Waste Treatnebt and Disposal",
    "Water Suplly and Irrigation Systems",
    "Water, Waste, Steam, and Air Conditioning Services",
    "Wellness and Fitness Services",
    "Wholesale",
    "Wholesale Alcoholic Beverages",
    "Wholesale Apparel and Sewing Supplies",
    "Wholesale Appliances, Electrical, and Electronics",
    "Wholesale Building Materials",
    "Wholesale Recyclabe Materials",
    "Wind Electric Power generation",
    "Wine and Spirits",
    "Wineries",
    "Wireless Services",
    "Wood Product Manufacturing",
    "Writing and Editing",
    "Zoos and Botanical Gardens"
  ];
  