import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
`;

export const Title = styled.h3`
  color: #004163;
  margin-bottom: 10px;
`;

export const InfoItem = styled.div`
  margin-bottom: 5px;
`;

export const TitleTow = styled.h3`
  color: #004163;
  margin: 0;
`;

export const InfoItemTwo = styled.h3`
 margin: 0;
`;

export const InRow = styled.div`
flex-direction: row;
display: flex;
align-items: center;
justify-content: space-between;
`;


export const RowShipments = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px; 
`;