import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import axios from "axios";
import { message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { Table } from "antd";

const RemoveButton = styled.button`
  background-color: #fff;
  color: #ff6347;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
`;

const CardDetails = ({ customerId, cards }) => {
  const theme = useContext(ThemeContext);

  if (!cards || !Array.isArray(cards)) {
    return null;
  }

  const handleRemoveCard = async (customerId, cardId) => {
    try {
      const response = await axios.delete(
      `https://api.tsalemetals.com:4578/delete-card/${customerId}/${cardId}`,
      
      );
      
      if (response.ok) {
        let successMessage = "Card successfully deleted";
        message.success(successMessage);
        window.location.reload();
      }
     
    } catch (error) {
      console.error("Erro ao excluir cartão:", error);
      let errorMessage = "Error";
      message.error(errorMessage);
    }
  };

  library.add(
    faCcJcb,
    faCcAmex,
    faCcDinersClub,
    faCcDiscover,
    faCcMastercard,
    faCcVisa
  );

  const formatCardExpiry = (expMonth, expYear) => {
    return `${expMonth}/${expYear}`;
  };

  const columns = [
    {
      title: "Card Brand",
      dataIndex: "cardType",
      key: "cardType",
      render: (text) => (
        <FontAwesomeIcon
          icon={[
            "fab",
            `fa-cc-${
              text === "MC"
                ? "mastercard"
                : text.toLowerCase().replace(" ", "-")
            }`,
          ]}
          size="2xl"
          color={theme.colors.dark[950]}
          style={{
            marginRight: 12,
          }}
        />
      ),
    },
    {
      title: "Last 4",
      key: "number",
      dataIndex: "number",
    //  render: (text) => {text},
    },
    {
      title: "Card Expiry",
      dataIndex: "card_expiry",
      key: "card_expiry",
      render: (text, record) =>
        formatCardExpiry(record.expMonth, record.expYear),
    },
    {
      title: "Settings",
      dataIndex: "settings",
      key: "settings",
      render: (text, record) => (
        <RemoveButton
          onClick={() => handleRemoveCard(customerId, record.id)}
        >
          Remove
        </RemoveButton>
      ),
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={cards} />
    </>
  );
};

export default CardDetails;
