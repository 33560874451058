import { Col, Row, Table } from "antd";
import { useEffect, useState } from "react";
import formatToCurrency from "../../../../utils/formatToCurrency";
import { formatNumberKg } from "../../../../utils/numberFormat";
import * as XLSX from "xlsx";
import { RowContainer } from "../../../../assets/styles";
import Country from "../../../../components/Flag";
import CSV from "../../../../icons/csv.png";

export default function UNComtradeTable({ data }) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [filterPeriod, setFilterPeriod] = useState([]);
    const [filterCountry, setFilterCountry] = useState([]);
    const [filterPartner, setFilterPartner] = useState([]);
    const onSelectChange = (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    };
    
    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
      type: 'checkbox', 
    };
    
    const hasSelected = selectedRowKeys.length > 0;


    const sheetName = `${data.data[0]?.refPeriodId} - ${data.data[0]?.cmdCode}`;
    const excel = () => {  

        const ws = XLSX.utils.json_to_sheet(data.data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, sheetName +".xlsx");
    };

    const getCountry = (id) => {
        if(id) {
            if(id == "Global Search") {
                return("World")
            }
            const flag = Country.filter(obj => obj.id === id);

            if(flag.length > 0) {
                return (flag[0].country)
            }
            return (id)
        }
   
        
        return("World")
    }

    useEffect(() => {

        let arrayFilterPeriod = []
        let arrayFilterCountry = []
        let arrayFilterPartner = []
    
       
    
        if(data.data.length > 0) {

            //Filtro Periodo
            const uniqueArrayPeriod = data.data.filter((obj, index, self) =>
                index === self.findIndex((t) => (
                    t.refYear === obj.refYear
                ))
            );
        
            uniqueArrayPeriod.forEach(element => {
                let data1 = {
                    text: element.refYear,
                    value: element.refYear
                }
            
                arrayFilterPeriod.push(data1)
            });

            setFilterPeriod(arrayFilterPeriod)

            //Filtro Country
            const uniqueArrayCountry = data.data.filter((obj, index, self) =>
                index === self.findIndex((t) => (
                    t.reporterCode === obj.reporterCode
                ))
            );
        
            uniqueArrayCountry.forEach(element => {
                let data2 = {
                    text: getCountry(element.reporterCode),
                    value: element.reporterCode
                }

            
                arrayFilterCountry.push(data2)
            });

            setFilterCountry(arrayFilterCountry)

            //Filtro Partner
            const uniqueArrayPartner = data.data.filter((obj, index, self) =>
                index === self.findIndex((t) => (
                    t.partnerCode === obj.partnerCode
                ))
            );
        
            uniqueArrayPartner.forEach(element => {
                let data3 = {
                    text: getCountry(element.partnerCode),
                    value: element.partnerCode
                }

            
                arrayFilterPartner.push(data3)
            });

            setFilterPartner(arrayFilterPartner)
    
        }

    
      }, [data])
      
    const columns = [
        {
            title: 'Period',
            dataIndex: 'period',
            key: 'period',
            filters: filterPeriod, 
            onFilter: (value, record) => record.refYear.indexOf(value) === 0,
            render: (_, record) => (
                <Col style={{ textAlign: "center" }} >
                    <Row style={{ textAlign: "center", justifyContent: "center" }}>
                        {record.refYear}
                    </Row>
                </Col>
            ),
        },
        {
            title: 'Trade Flow',
            dataIndex: 'tradeFlow',
            key: 'tradeFlow',            
            render: (_, record) => (
                <Col style={{ textAlign: "center" }} >
                    <Row style={{ textAlign: "center", justifyContent: "center" }}>
                        {record.flowCode == "X" ? "Export" : "Import"}
                    </Row>
                </Col>
            ),
        },
        {
            title: 'Reporter',
            dataIndex: 'reporter',
            key: 'reporter',
            filters: filterCountry, 
            onFilter: (value, record) => record.reporterCode == value,
            render: (_, record) => (
                <Col style={{ textAlign: "center" }} >
                    <Row style={{ textAlign: "center", justifyContent: "center" }}>
                        {getCountry(record.reporterCode)}
                    </Row>
                </Col>
            ),
        },
        {
            title: 'Partner',
            dataIndex: 'partner',
            key: 'partner',
            filters: filterPartner,
            onFilter: (value, record) => record.partnerCode == value,
            render: (_, record) => (
                <Col style={{ textAlign: "center" }} >
                    <Row style={{ textAlign: "center", justifyContent: "center" }}>
                        {getCountry(record.partnerCode)}
                    </Row>
                </Col>
            ),
        },
        {
            title: 'HS Code',
            dataIndex: 'hscode',
            key: 'hscode',
            render: (_, record) => (
                <Col style={{ textAlign: "center" }} >
                    <Row style={{ textAlign: "center", justifyContent: "center" }}>
                        {record.cmdCode}
                    </Row>
                </Col>
            ),
        },
        {
            title: 'Trade Value (US$)',
            dataIndex: 'tradeValue',
            key: 'tradeValue',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.primaryValue - b.primaryValue,
            render: (_, record) => (
                <Col style={{ textAlign: "center" }} >
                    <Row style={{ textAlign: "center", justifyContent: "center" }}>
                        {record.primaryValue ? formatToCurrency(record.primaryValue) : "-"}
                    </Row>
                </Col>
            )
        },
        {
            title: 'Net Weight (Kg)',
            dataIndex: 'netWeight',
            key: 'netWeight',
            sorter: (a, b) => a.netWgt - b.netWgt,
            render: (_, record) => (
                <Col style={{ textAlign: "center" }} >
                    <Row style={{ textAlign: "center", justifyContent: "center" }}>
                        {record.netWgt ? formatNumberKg(record.netWgt) : "-"}
                    </Row>
                </Col>
            )
        }       
    ];

    return (
      <>
        {hasSelected ? `Selected ${selectedRowKeys.length} items` : null}
        <RowContainer
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingLeft: 15,
          }}
        >
          <div style={{ position: "fixed", right: 50, top: 1 }}>
            <ul className="wrapper">
              <li className="icon twitter" onClick={excel}>
                <span className="tooltip">EXCEL</span>
                <span>
                  <i className="fab fa-twitter"></i>
                </span>
                <img
                  src={CSV}
                  style={{ width: 25, height: 25 }}
                  alt="CSV Icon"
                />
              </li>
            </ul>
          </div>
        </RowContainer>
        <Table
          dataSource={data.data}
          // rowSelection={rowSelection}
          columns={columns}
          style={{ margin: "1%" }}
          bordered
        />
      </>
    );
}