import styled from "styled-components";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import BusinessIcon from "@mui/icons-material/Business";
import LinkIcon from "@mui/icons-material/Link";
import { saveAnalytics } from "../../../hooks/analytics";
import { deleteCredit, historyCredits } from "../../../hooks/credits";
import { FullPageRectangle } from "../search/style";

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 12px;
  padding: 20px;
  margin: 10px;
  width: calc(50% - 20px);
  cursor: pointer;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #004163;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 20px;
`;

const Content = styled.div`
  flex: 1;
`;

const Title = styled.h3`
  color: #4b4b4b;
  margin: 0;
`;

const Link = styled.a`
  color: #8a97aa;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 14px;

  & > svg {
    margin-right: 5px; // espaço entre ícone e texto
  }
`;

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RectanguleProfileLeads = ({ data, firstName, lastName, userId, userName, companyName }) => {
  const formattedDate = new Date().toISOString();

  const handleClick = async (urlCompany) => {
    try {
      await deleteCredit(userId, 1);
      await historyCredits(
        {
          text: `Credits used with company profile on Leads Enrichment`,
          type: "decrease",
          date: formattedDate,
          credits: 1,
        },
        userId
      );
      const infoA = {
        action: `Search`,
        date: formattedDate,
        page: "Leads Enrichment",
        keywords: `${companyName}`,
        name: userName,
      };
      await saveAnalytics(userId, infoA);
      const url = `/leads-ProfileContact?urlCompany=${encodeURIComponent(
        urlCompany
      )}&firstName=${firstName}&lastName=${lastName}`;
      window.open(url, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <FullPageRectangle>
        Select the main website that matches the company you are looking for
      </FullPageRectangle>
      <GridContainer>
        {data.map((item, index) => (
          <Container
            key={index}
            onClick={(e) => {
              if (!e.target.closest("a")) {
                handleClick(item.link);
              }
            }}
          >
            <Circle>
              <BusinessIcon />
            </Circle>
            <Content>
              <Title>{item.title}</Title>
              <Link href={item.link} target="_blank" rel="noopener noreferrer">
                <LinkIcon /> {item.link}
              </Link>
            </Content>
            <ArrowOutwardIcon style={{ color: "#4B4B4B" }} />
          </Container>
        ))}
      </GridContainer>
    </>
  );
};

export default RectanguleProfileLeads;
