import { Table } from "antd";
import React from "react";  

export default function TableHs() {

    const columns = [
        {
          title: 'Nome',
          dataIndex: 'name',
          showSorterTooltip: {
            target: 'full-header',
          },
          filters: [
            {
              text: 'Emanuel Ferreira',
              value: 'Emanuel Ferreira',
            },            
            
          ],
          // specify the condition of filtering result
          // here is that finding the name started with `value`
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ['descend'],
        },
        {
          title: 'Papel',
          dataIndex: 'role',
          defaultSortOrder: 'descend',

          filters:[
            {
                text: "Administrador Master",
                value: "Administrador Master",
            },
            {
                text: "Administrador",
                value: "Administrador",
            },
            {
                text: "Colaborador",
                value: "Colaborador",
            },
          ],
          onFilter: (value, record) => record.role.indexOf(value) === 0,
          sorter: (a, b) => a.age - b.age,
        },
        {
          title: 'E-mail',
          dataIndex: 'mail',          
          onFilter: (value, record) => record.address.indexOf(value) === 0,
        },
      ];
      const data = [
        {
          key: '1',
          name: 'Emanuel Ferreira',
          role: "Administrador Master",
          mail: 'emanuelorion992@outlook.com',
        },
        {
          key: '2',
          name: 'Isabela Almeida',
          role: "Administrador",
          mail: 'isabela_almeida@email.com',
        },
        {
          key: '3',
          name: 'Gabriel Silva',
          role: "Colaborador",
          mail: 'gabriel_silva@email.com',
        },
        {
          key: '4',
          name: 'Marina Santos',
          role: "Colaborador",
          mail: 'marina_santos@email.com',
        },
        {
          key: '5',
          name: 'Matheus Oliveira',
          role: "Colaborador",
          mail: 'matheus_oliveira@email.com',
        },
        {
          key: '6',
          name: 'Carolina Pereira',
          role: "Colaborador",
          mail: 'carolina_pereira@email.com',
        },
        {
          key: '7',
          name: 'Lucas Mendes',
          role: "Colaborador",
          mail: 'lucas_mendes@email.com',
        },
      ];
      const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
      };

    return(
        
        <Table
        columns={columns}
        dataSource={data}
        onChange={onChange}
        showSorterTooltip={{
        target: 'sorter-icon',
        }}
        style={{width: "100%"}}
        />
        
    )
}