import axios from "axios";
import { APITSALE_KEY } from "./key";

export default function UNcomtradeTradeData(
  typeCode,
  freqCode,
  clCode,
  period,
  reporterCode,
  cmdCode,
  flowCode,
  partnerCode,
  userName,
  userId,
  userIP
) {
  return new Promise((resolve, reject) => {
    const body = JSON.stringify({
      typeCode,
      freqCode,
      clCode,
      period,
      reporterCode,
      cmdCode,
      flowCode,
      partnerCode,
      // user: userName,
      // userId,
      // userIp: userIP,
    });

    const header = {
      headers: {
        // Authorization: "Bearer " + APITSALE_KEY.key,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("https://api.tsalemetals.com/tradedata", body, header)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject("failed");
      });
  });
}

export function WorldShare(typeCode, freqCode, period, reporterCode) {
  return new Promise((resolve, reject) => {
    const body = JSON.stringify({
      typeCode,
      freqCode,
      period,
      reporterCode,
    });

    const header = {
      headers: {
        // Authorization: "Bearer " + APITSALE_KEY.key,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("https://api.tsalemetals.com/worldshare", body, header)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject("failed");
      });
  });
}
