import React, { useContext, useEffect, useState } from "react";
import {
  ColumnContainer,
  ContainerHome,
  TextDefault,
} from "../../assets/styles";
import Drawer from "../../components/Drawer";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../components/LoadingPage";
import { authScreen } from "../../contexts/auth";
import getUserInfo, { getEndPlanUser } from "../../hooks/getUsers";
import { stripeService } from "../../service/apiStripe";
import { Table, Empty, message, Alert } from "antd";
import CardDetails from "./components/cardDetails";
import ModalSaveCard from "./components/modalCard";
import axios from "axios";
import formatsDate from "../../utils/formats";
import { PaidStatusTag } from "../../utils/paidStatusTag";
import { BillingButton, CustomButton } from "./style";
import { ThemeContext } from "styled-components";

export default function Billing() {
  const theme = useContext(ThemeContext);
  const [auth, setAuth] = useState(false);
  const [toggleDrawer, useTroggleDawer] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [endPlan, setEndPlan] = useState(false);
  const currentDate = new Date();
  function SetToggle(state) {
    useTroggleDawer(state);
  }

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 500);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    });
  });

  const goSubs = () => {
    navigate("/credits");
  };

  const NoDataComponent = () => (
    <>
      <Empty description={<span>No data</span>}></Empty>
      <BillingButton type="primary" onClick={goSubs}>
        Get subscription
      </BillingButton>
    </>
  );

  const [userInfo, setUserInfo] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setCustomerId(userData?.userData?.customerId);
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  useEffect(() => {
    const TitlePage = "Billing";

    document.title = TitlePage;
  }, []);

  const [cardDetail, setCardDetail] = useState();

  async function fetchCustomerCards(customerId) {
    try {
      const response = await fetch(
        `https://api.tsalemetals.com:4578/cards/${customerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao obter os cartões do cliente");
      }

      const cards = await response.json();
      return cards;
    } catch (error) {
      console.error("Erro ao fazer a requisição:", error);
      throw error;
    }
  }

  useEffect(() => {
    const fetchCard = async () => {
      if (userInfo) {
        fetchCustomerCards(userInfo?.userData?.customerId)
          .then((cards) => {
            setCardDetail(cards);
          })
          .catch((error) => {
            console.error("Erro ao obter os cartões do cliente:", error);
          });
      }
    };
    fetchCard();
  }, [userInfo]);

  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(
          `https://api.tsalemetals.com:4578/invoices/${userInfo?.userData?.customerId}`
        );
        if (
          response.data.QueryResponse &&
          response.data.QueryResponse.Invoice
        ) {
          const invoicesWithStatus = response.data.QueryResponse.Invoice.map(
            (invoice) => {
              let status = "Unpaid";

              // verifica se existem transações vinculadas
              if (invoice.LinkedTxn && invoice.LinkedTxn.length > 0) {
                status = invoice.LinkedTxn[0].TxnType;
              }

              const createTime = invoice.MetaData?.CreateTime;

              return { ...invoice, status: status, CreateTime: createTime };
            }
          );
          setInvoices(invoicesWithStatus);
        } else {
          console.warn("No invoices found in response");
        }
      } catch (error) {
        console.error("Erro ao buscar faturas:", error);
      }
    };

    if (userInfo) {
      fetchInvoices();
    }
  }, [userInfo]);

  async function sendInvoice(invoiceId) {
    const invoiceData = {
      invoiceId: invoiceId,
      sendTo: userInfo?.userData?.email,
    };

    try {
      const response = await axios.post(
        "https://api.tsalemetals.com:4578/send-invoice",
        invoiceData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        let successMessage = "Invoice sent successfully!";
        message.success(successMessage);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error sending invoice:", error);
      let errorMessage = "Error sending invoice";
      message.error(errorMessage);
      return false;
    }
  }

  async function downloadInvoice(invoiceId) {
    const invoiceData = {
      invoiceId: invoiceId,
    };

    try {
      const response = await axios.post(
        "https://api.tsalemetals.com:4578/download-invoice",
        invoiceData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "arraybuffer",
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "invoice.pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);

        message.success("Invoice downloaded successfully!");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error downloading invoice:", error);
      message.error("Error downloading invoice");
    }
  }

  const columns = [
    {
      title: "Invoice Amout",
      dataIndex: "TotalAmt",
      key: "TotalAmt",
      render: (text) => "US$" + text,
    },
    {
      title: "Payment Status",
      key: "status",
      dataIndex: "status",
      render: (text) => <PaidStatusTag status={text} />,
    },
    {
      title: "Create Date",
      dataIndex: "CreateTime",
      key: "CreateTime",
      render: (text) => formatsDate(text),
    },
    {
      title: "Invoice Number",
      dataIndex: "Id",
      key: "Id",
    },
    {
      title: "Invoice",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <>
            <a onClick={() => sendInvoice(record.Id)}>Send | </a>
            <a onClick={() => downloadInvoice(record.Id)}>Download</a>
          </>
        );
      },
    },
  ];

  const handleAddCard = async () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const info = {
    name: userInfo?.userData?.name,
    customerId: userInfo?.userData?.customerId,
    email: userInfo?.email,
    userId: userInfo?.uid,
  };

  const address = {
    city: userInfo?.userData?.address?.city,
    country: userInfo?.userData?.address?.country,
    line1: userInfo?.userData?.address?.line1,
    postalCode: userInfo?.userData?.address?.postalCode,
    state: userInfo?.userData?.address?.state,
  };

  useEffect(() => {
    if (userInfo) {
      getEndPlanUser(userInfo?.uid)
        .then((dados) => {
          setEndPlan(dados);
        })
        .catch((error) => {
          console.error("Erro ao verificar os dados:", error);
        });
    }
  }, [userInfo]);

  const shouldShowAlert = currentDate >= new Date(endPlan);

  return (
    <>
      {auth ? (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={toggleDrawer ? 2 : 1}>
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>
            <Grid
              item
              xs={toggleDrawer ? 9 : 10}
              position={"relative"}
              marginLeft={toggleDrawer ? 4 : 2}
            >
              <Grid item xs={7}>
                <ColumnContainer style={{ marginTop: 32 }}>
                  <TextDefault color={theme.colors.gray[800]} size={"32px"}>
                    Billing
                  </TextDefault>
                </ColumnContainer>
              </Grid>
              {shouldShowAlert && (
                <Alert
                  message="Your plan is about to end, renew to continue enjoying it!"
                  type="error"
                  showIcon
                />
              )}
              <br />
              <br />
              <TextDefault color={"#17283E"} size={"24px"} bold={"600"}>
                Wallet
              </TextDefault>
              <br />
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextDefault color={"#17283E"} size={"18px"} bold={"500"}>
                  Saved Payment Methods
                </TextDefault>
                <div style={{ marginLeft: "auto", marginBottom: "8px" }}>
                  <CustomButton onClick={handleAddCard}>
                    Add new card
                  </CustomButton>
                </div>
              </div>
              <ModalSaveCard
                visible={modalVisible}
                onCancel={closeModal}
                info={info}
                address={address}
              />

              <CardDetails
                customerId={userInfo?.userData?.customerId}
                cards={cardDetail}
              />
              <br />
              <TextDefault color={"#17283E"} size={"24px"} bold={"600"}>
                Current Balance
              </TextDefault>
              <Table
                columns={columns}
                dataSource={invoices}
                locale={{ emptyText: <NoDataComponent /> }}
              />
            </Grid>
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
