const NUMBER_FORMATTER = new Intl.NumberFormat("en-US");

export default function FormatNumber(number) {
    let convertTon;
    if(parseFloat(number/1000) > 1) {
      convertTon = NUMBER_FORMATTER.format(parseFloat(number/1000).toFixed(0));
    } else {
      convertTon = NUMBER_FORMATTER.format(parseFloat(number/1000));
    }
    return convertTon;
}