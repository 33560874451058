import { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { message, Table } from "antd";
import { Container, InRow, TableContainer } from "./styles";
import { ContainerHome, TextDefault } from "../../../assets/styles";
import { authScreen } from "../../../contexts/auth";
import Drawer from "../../../components/Drawer";
import ModalList from "../components/modalList";
import getUserInfo from "../../../hooks/getUsers";
import {
  createCompanyList,
  saveDataToList,
  saveOneDataToList,
} from "../../../hooks/leads";
import {
  finderSocial,
  sendLinkedinUrls,  
} from "../../../service/apiHunter";
import filterLeadsDB, { 
  getAllLeadsByDomain,
  getEnrichmentLeadsByDomain,
} from "../../../hooks/filterLeadsDB";
import LoadingBar from "../../../components/PercentLoading";
import ExcelExport from "../utils/pdfExport";
import * as XLSX from "xlsx";
import GenerateExcelModal from "../../../components/generateExcelModal";
import CompanyCard from "./components/companyCard";
import Man from "../../../icons/man.png";
import Man1 from "../../../icons/man1.png";
import Man2 from "../../../icons/man2.png";
import Woman from "../../../icons/woman.png";
import Woman1 from "../../../icons/woman1.png";
import Woman2 from "../../../icons/woman2.png";
import { TagForType } from "../../../utils/tagForEmail";

export default function ProfileCompany({ url, isChildren }) {
  const [toggleDrawer, useTroggleDawer] = useState(false);
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlCompany = searchParams.get("urlCompany");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rowSelected, setRowSelect] = useState([]);
  const [isLeadsLoading, setIsLeadsLoading] = useState(true);
  const [isCeciexModalOpen, setIsCeciexModalOpen] = useState(false);
  const [rowsToExcel, setRowsToExcel] = useState([]);
  const [loadingLinks, setLoadingLinks] = useState(false);
  const [social, setSocial] = useState([]);
  const [data, setData] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [leads, setLeads] = useState(null);

  const isAdmin = useMemo(
    () => userInfo?.userData?.role === "ceciex",
    [userInfo]
  );

  const OpenModal = () => {
    setIsModalOpen(true);
  };

  const CloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseCeciexModal = () => {
    setIsCeciexModalOpen(false);
  };

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 500);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    });
  });

  function SetToggle(state) {
    useTroggleDawer(state);
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLeadsLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 8000));

        const result = await getEnrichmentLeadsByDomain(url);

        if (result) {
         
          setCompanyData(result.company);

         
          const urls = result?.leads.map((person) => person.url);

         
          const send = await sendLinkedinUrls(urls, url);
          console.log(">>>>>>>", send);

          
          await new Promise((resolve) => setTimeout(resolve, 8000));

         
          if (result.company) {
            const resultLeads = await getAllLeadsByDomain(url);
            setLeads(Object.values(resultLeads));
          }

         
          setData(Object.values(result.leads));
        } else {
          console.error("Nenhum dado de empresa encontrado");
        }
      } catch (error) {
        console.error("Erro ao buscar leads:", error);
      } finally {       
        setIsLeadsLoading(false);
      }
    };

    fetchData();

    return () => clearTimeout();
  }, [url]);

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const user = await getUserInfo();
          setUserInfo(user);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  useEffect(() => {
    if (userInfo !== null) {
      finderSocialLink();
    }
  }, [userInfo, companyData]);

  async function finderSocialLink() {
    try {
      setLoadingLinks(true);
      const response = await finderSocial(
        companyData?.name,
        companyData?.country
      );
      setSocial(response);
    } catch (error) {
      console.error("Erro:", error);
    } finally {
      setTimeout(() => setLoadingLinks(false), 2000);
    }
  }

  const imagesChar = [Man, Man1, Man2, Woman, Woman1, Woman2];

  const getRandomImage = () => {
    return imagesChar[Math.floor(Math.random() * imagesChar.length)];
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      render: (text, record) => {
        const fullName = `${record.first_name} ${record.last_name}`;

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={getRandomImage()}
              alt="Profile"
              style={{
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                marginRight: "8px",
              }}
            />
            {fullName}
          </div>
        );
      },
      filters: data?.map((record) => ({
        text: `${record.first_name} ${record.last_name}`,
        value: `${record.first_name} ${record.last_name}`,
      })),
      onFilter: (value, record) =>
        `${record.first_name} ${record.last_name}`.includes(value),

      width: "30%",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (_, record) => record.email?.email || "Not available",
    },
    {
      title: "Type",
      dataIndex: "email",
      render: (email) => <TagForType type={email?.email_type} />,
      filters: [
        { text: "Professional", value: "professional" },
        { text: "Generic", value: "generic" },
      ],
      onFilter: (value, record) => record.email?.email_type === value,
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      render: (job_title) => job_title || "Not found",
    },
    {
      title: "Linkedin",
      dataIndex: "linkedin",
      render: (_, record) => {
        return record?.linkedin ? (
          <a href={record.linkedin} target="_blank" rel="noreferrer">
            <LinkedInIcon />
          </a>
        ) : (
          <em>Not found</em>
        );
      },
    },
  ];

  const onSelectChange = (selectedKeys, selectedRows) => {
    setSelectedRowKeys(selectedKeys);
    setRowSelect(selectedRows);
    setRowsToExcel(selectedRows);
    // setSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  //falta resolver isso
  const companyDatasSave = {
    name: companyData?.name,
    description: companyData?.description,
    size: companyData?.size,
    industry: companyData?.industry,
    type: companyData?.type,
    founded: companyData?.founded,
    domain: companyData?.website,
    location: companyData?.locality,
    linkedin: companyData?.url,
  };

  const handleClickCreate = async () => {
    try {
      const newListName = await createCompanyList(
        userInfo?.uid,
        companyData?.name,
        companyDatasSave
      );

      await saveOneDataToList(userInfo?.uid, newListName, leads);
    } catch (error) {
      console.error("Erro ao criar lista e salvar dados:", error);
    }
  };

  const sheetName = "Leads Enrichment";

  const handleExcel = () => {
    if (!isAdmin) {
      const ws = {};

      // referencio oq vai ter em cada linha/coluna
      ws["A1"] = {
        v: companyData?.name || "Not Informed",
      };
      ws["B1"] = { v: companyData?.website || "Not Informed" };
      ws["C1"] = { v: companyData?.locality || "Not Informed" };
      ws["D1"] = { v: companyData.url || "Not Informed" };
      ws["E1"] = { v: companyData.description || "Not Informed" };

      // fazendo o header
      ws["A3"] = { v: "Name" };
      ws["B3"] = { v: "Email" };
      ws["C3"] = { v: "Type" };
      ws["D3"] = { v: "Linkedin" };

      // add filteredData a partir da quarta linha
      const filteredData = leads.map((item) => ({
        Name: `${item.first_name || "Not Informed"} ${item.last_name || ""}`,
        email: item.email?.email || "Not Informed",
        Type: item.email?.email_type || "Not Informed",
        Linkedin: item?.linkedin || "Not Informed",
      }));

      filteredData.forEach((item, idx) => {
        const rowIndex = idx + 4;

        ws[`A${rowIndex}`] = { v: item.Name };
        ws[`B${rowIndex}`] = { v: item.email };
        ws[`C${rowIndex}`] = { v: item.Type };
        ws[`C${rowIndex}`] = { v: item.Linkedin };
      });

      const finalRange = {
        s: { r: 0, c: 0 },
        e: { r: filteredData.length + 2, c: 10 },
      };
      ws["!ref"] = XLSX.utils.encode_range(finalRange);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, sheetName + ".xlsx");
      return;
    }

    if (rowsToExcel.length === 0) {
      message.error("You must select at least one company");
      return;
    }

    setIsCeciexModalOpen(true);
  };

  return (
    <>
      {isLeadsLoading ? (
        <div
          style={{
            width: "100%",
            marginTop: "20px",
          }}
        >
             <TextDefault color={"#17283E"} bold={"600"} size={"20px"}>
                        Loading...
                      </TextDefault>
        </div>
      ) : (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {!isChildren && (
              <Grid item xs={toggleDrawer ? 2 : 1}>
                <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
              </Grid>
            )}
            <>
              <Grid
                item
                // xs={isChildren ? (toggleDrawer ? 8 : 4) : (toggleDrawer ? 10 : 11)}
                marginLeft={
                  isChildren ? (toggleDrawer ? 2 : 1) : toggleDrawer ? 35 : 10
                }
                marginTop={3}
                container
              >
            
                  <>
                    <Container>
                      <TextDefault color={"#17283E"} bold={"600"} size={"20px"}>
                        Profile Company
                      </TextDefault>
                      <TextDefault color={"#8A97AA"} bold={"600"} size={"15px"}>
                        Find your customers' contact details
                      </TextDefault>
                    </Container>

                    <TableContainer>
                      <CompanyCard
                        companyData={companyData}
                        handleClickCreate={handleClickCreate}
                        handleExcel={handleExcel}
                        selectedRowKeys={selectedRowKeys}
                        openModal={OpenModal}
                        socialMedia={social}
                      />

                      <Table
                        rowKey={(record) => record.entity_urn || record.Number}
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={leads}
                      />
                    </TableContainer>
                    <ExcelExport excel={handleExcel} />

                    <Container>
                      <hr />
                      <InRow>
                        <GenerateExcelModal
                          isOpen={isCeciexModalOpen}
                          data={rowsToExcel}
                          onClose={handleCloseCeciexModal}
                        />
                      </InRow>
                      <br />
                      <ModalList
                        visible={isModalOpen}
                        onCancel={CloseModal}
                        userId={userInfo?.uid}
                        infos={rowSelected}
                      />
                    </Container>
                  </>
              
              </Grid>
            </>
          </Grid>
        </ContainerHome>
      )}
    </>
  );
}
