import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import formatToCurrency from '../../../../../../utils/formatToCurrency';


const BuyerChart = ({ rankings  }) => {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "buyer-value-chart",
      },
      xaxis: {
        categories: [],
        title: {
          text: "Buyer Name",
        },
      },
      yaxis: {
        title: {
          text: "Total Value (USD)",
        },
        labels: {
          formatter: (val) => formatToCurrency(val), 
        },
      },
      title: {
        text: "Buyer Rankings by Value",
        align: "center",
      },
      tooltip: {
        y: {
          formatter: (val) => formatToCurrency(val), 
        },
      },
    },
    series: [
      {
        name: "Total Value",
        data: [], 
      },
    ],
  });

  useEffect(() => {
    
    const buyerNames = rankings.map((item) => {
      const name = item.consigneeName
      ? item.consigneeName.charAt(0).toUpperCase() + item.consigneeName.slice(1)
      : "Not Informed";
      return name;
    })
   

    const totalValues = rankings.map((item) => item.totalValue);

    setChartData((prevData) => ({
      ...prevData,
      options: {
        ...prevData.options,
        xaxis: {
          ...prevData.options.xaxis,
          categories: buyerNames, 
        },
      },
      series: [
        {
          name: "Total Value",
          data: totalValues, 
        },
      ],
    }));
  }, [rankings]);

  return (
    <div className="chart">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        width="100%"
        height="400"
      />
    </div>
  );
};

export default BuyerChart;
