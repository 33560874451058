import React, { useEffect, useRef, useState } from "react";
import { ContainerHome, TextDefault } from "../../../assets/styles";
import Drawer from "../../../components/Drawer";
import { Grid } from "@mui/material";
import {
  Container,
  Heading,
  HiddenInScreen,
  InRow,
  Ml10,
  TabsContainer,
} from "../search/style";
import data from "../utils/mock";
import { getSessionId, saveCompliance } from "../../../hooks/compliance";
import { useReactToPrint } from "react-to-print";
import PdfExport from "../utils/pdfExport";
import { authScreen } from "../../../contexts/auth";
import { useLocation, useNavigate } from "react-router-dom";
import getUserInfo from "../../../hooks/getUsers";
import LoadingPage from "../../../components/LoadingPage";

export default function SavedCompliance() {
  const [toggleDrawer, useTroggleDawer] = useState(false);
  const [trade, setTrade] = useState("");
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 500);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    });
  });

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  function SetToggle(state) {
    useTroggleDawer(state);
  }

  const pdfRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });

  const currentDate = new Date();
  const formattedDate = `${
    currentDate.getMonth() + 1
  }/${currentDate.getDate()}/${currentDate.getFullYear()}`;

  const userId = userInfo?.uid;

  function saveSearch() {
    saveCompliance(userId, data[0]);
  }

  return (
    <>
      {auth ? (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={toggleDrawer ? 2 : 1}>
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>

            <>
              <Grid
                item
                xs={toggleDrawer ? 10 : 11}
                marginLeft={toggleDrawer ? 40 : 20}
                container
              >
                <TextDefault
                  color={"#4b4b4b"}
                  size={"32px"}
                  style={{ marginTop: 20 }}
                >
                  Saved Sacntion
                </TextDefault>
                <Container>
                  <TabsContainer>
                    <div ref={pdfRef}>
                      <PdfExport action={handlePrint} saveOp={saveSearch} />
                      <HiddenInScreen>
                        Your {trade} compliance survey on the {formattedDate}
                      </HiddenInScreen>
                      <InRow>
                        <Heading>Country of export:</Heading>
                        <p>{item?.countryOfExport}</p>

                        <Ml10>
                          <Heading>Country of import:</Heading>
                        </Ml10>
                        <p>{item?.countryOfImport}</p>
                      </InRow>
                      <hr />
                      <InRow>
                        <Heading>Reference:</Heading>
                        <p>{item?.reference}</p>
                        <Ml10>
                          <Heading>Derived Reference:</Heading>
                        </Ml10>
                        <p>{item?.derivedReference}</p>
                      </InRow>
                      <hr />
                      <InRow>
                        <Heading>Controlling authority:</Heading>
                        <p>{item?.controllingAuthority}</p>
                        <Ml10>
                          <Heading>Scope of control:</Heading>
                        </Ml10>

                        <p>{item?.scopeOfControl}</p>
                      </InRow>

                      <hr />

                      <h2>Notes</h2>
                      <p>{item?.notes}</p>

                      <h2>Exceptions</h2>
                      <p>{item?.exceptions}</p>
                    </div>
                  </TabsContainer>
                </Container>
              </Grid>
            </>
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
