import styled from "styled-components";

export const ContainerPdf = styled.div`
  display: none;

  @media print {
    display: block;

    @page {
      margin: 0;
      padding: 0;
      /* size: A4 portrait; */
      break-before: always;
    }
  }
`;

export const LogoPdf = styled.img`
  width: ${(props) => (props.size ? props.size : "95px")};
  height: ${(props) => (props.size ? props.size : "95px")};
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Rectangle = styled.div`
  background-color: #030637;
  width: 100%;
  padding: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  align-items: center;
`;

export const LeftText = styled.span`
  margin-right: auto;
`;

export const RightText = styled.span`
  margin-left: auto;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 12px;
  > div {
    display: flex;
    align-items: center;
  }
`;

export const Card = styled.div`
  width: 750px;
  height: auto;
  padding: 20px;
  border: 3px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out;

  ${({ active }) =>
    active &&
    `
    border-color: #4b4b4b;
  `}
`;

export const LeftInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const RightInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const InfoTitle = styled.span`
  font-weight: bold;
  color: #4b4b4b;
`;

export const InfoTitleR = styled.span`
  font-weight: normal;
`;

export const InfoValue = styled.span`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 80%;
  margin-left: 5px;
`;

export const InfoLongText = styled.span`
  font-size: 14px;
  margin-left: 5px;
`;

export const InfoRight = styled.span`
  margin-bottom: 15px;
`;

export const CardDetails = styled.div`
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 8px 12px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > div {
    display: flex;
  }
`;
