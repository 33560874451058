import React, { useEffect, useState } from "react";
import { ColumnContainer, ContainerHome } from "../../../assets/styles";
import Drawer from "../../../components/Drawer";
import { Grid } from "@mui/material";
import { Empty, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import country from "../utils/flag";
import { authScreen } from "../../../contexts/auth";
import getUserInfo from "../../../hooks/getUsers";
import LoadingPage from "../../../components/LoadingPage";
import { deleteListByName, getAllLists } from "../../../hooks/leads";
import ModalRenameList from "../components/modalRename";
import { publicIpv4 } from "public-ip";
import { finderLink } from "../../../service/apiHunter";
import LoadingBar from "../../../components/PercentLoading";
import RectanguleCompanyLeads from "../components/boxCompany";
import RectanguleProfileLeads from "../components/boxPerson";
import RectanguleListPerson from "../components/boxListPerson";
import FilterLeads from "../components/filter";

export default function SearchLeads() {
  const [toggleDrawer, useTroggleDawer] = useState(false);
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameCompany = searchParams.get("nameCompany");
  const countryC = searchParams.get("countryC");
  const otherPage = searchParams.get("otherPage");
  const [userInfo, setUserInfo] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countrySel, setCountrySel] = useState("");
  const [lists, setLists] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedListName, setSelectedListName] = useState("");
  const [userIP, setUserIP] = useState("");
  const [selectedInfo, setSelectedInfo] = useState(0);
  const [loadingLinks, setLoadingLinks] = useState(false);
  const [links, setLinks] = useState([]);
  const [listType, setListType] = useState("");
  const [finder, setFinder] = useState(false);

  useEffect(() => {
    if (otherPage === "true") {
      setCompanyName(nameCompany);
      const selectedCountry = country.find((c) => c.value === countryC);
      setCountrySel(selectedCountry.label);
      setListType("company");
    }
  }, [nameCompany, countryC, otherPage]);

  useEffect(() => {
    if (companyName && countrySel && otherPage === "true") {
      finderUrl();
    }
  }, [companyName, countrySel]);

  //aqui
  useEffect(() => {
    if (finder) {
      finderUrl();
      setFinder(false);
    }
  }, [finder]);

  useEffect(() => {
    (async () => {
      setUserIP(await publicIpv4());
    })();
  }, []);

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 500);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    });
  });

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  function SetToggle(state) {
    useTroggleDawer(state);
  }

  async function fetchLists() {
    try {
      const listsData = await getAllLists(userInfo?.uid);
      setLists(listsData);
    } catch (error) {
      console.error("Erro ao buscar listas:", error);
    }
  }

  useEffect(() => {
    fetchLists();
  }, [auth, userInfo]);

  const handleDeleteClick = async (userId, listId) => {
    try {
      console.log(listId);
      const deleteSuccess = await deleteListByName(userId, listId);
      if (deleteSuccess) {
        let successMessage = "List deleted successfully!";
        message.success(successMessage);
        fetchLists();
      } else {
        let errorMessage = "Error when deleting list!";
        message.error(errorMessage);
      }
    } catch (error) {
      let errorMessage = "Error when deleting list!";
      message.error(errorMessage);
    }
  };

  const handleRename = (name) => {
    setSelectedListName(name);
    setIsModalOpen(true);
  };

  const CloseModal = () => {
    setIsModalOpen(false);
  };

  async function finderUrl() {
    try {
      setLoadingLinks(true);
      const response = await finderLink(companyName, countrySel);
      setLinks(response);
    } catch (error) {
      console.error("Erro:", error);
    } finally {
      setTimeout(() => setLoadingLinks(false), 2000);
    }
  }

  return (
    <>
      {auth ? (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            style={{
              height: "100%",
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={toggleDrawer ? 2 : 1}>
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>

            <FilterLeads
              getListType={setListType}
              getCountry={setCountrySel}
              getCompanyName={setCompanyName}
              getFinder={setFinder}
              getFirstName={setFirstName}
              getLastName={setLastName}
            />

            <Grid
              item
              xs={toggleDrawer ? 10 : 11}
              style={{ paddingLeft: 340, paddingTop: 40 }}
            >
              <ColumnContainer style={{ width: "98%" }}>
                <>
                  {loadingLinks && (
                    <div style={{ margin: "20px 0" }}>
                      <LoadingBar isLoading={loadingLinks} />
                    </div>
                  )}

                  {listType == "company" && links.length === 0 ? (
                    <Empty
                      description={
                        <span>
                          Use the filters above to find the website of the
                          company you are looking for
                        </span>
                      }
                    ></Empty>
                  ) : (
                    <> </>
                  )}
                  {listType == "company" && links.length !== 0 ? (
                    <RectanguleCompanyLeads
                      data={links}
                      companyName={companyName}
                      userId={userInfo?.uid}
                      userName={userInfo?.userData?.name}
                      country={countrySel}
                    />
                  ) : null}
                </>

                <>
                  {selectedInfo == "contact" && links.length === 0 ? (
                    <Empty
                      description={
                        <span>
                          Use the filters above to find the website of the
                          conctact you are looking for
                        </span>
                      }
                    ></Empty>
                  ) : (
                    <> </>
                  )}
                  {selectedInfo == "contact" && links?.length != 0 ? (
                    <RectanguleProfileLeads
                      data={links}
                      companyName={companyName}
                      userId={userInfo?.uid}
                      userName={userInfo?.userData?.name}
                      firstName={firstName}
                      lastName={lastName}
                    />
                  ) : (
                    <></>
                  )}
                </>

                {listType === "myList" && (
                  <RectanguleListPerson
                    data={lists}
                    handleDeleteClick={handleDeleteClick}
                    handleRename={handleRename}
                  />
                )}
              </ColumnContainer>
            </Grid>

            <ModalRenameList
              visible={isModalOpen}
              onCancel={CloseModal}
              userId={userInfo?.uid}
              listId={selectedListName}
            />
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}