import styled from "styled-components";

export const Container = styled.div`
  margin-top: 24px;
  height: 950px;
  width: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
`;
