import styled from "styled-components";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import BusinessIcon from "@mui/icons-material/Business";
import { saveAnalytics } from "../../../hooks/analytics";
import { deleteCredit, historyCredits } from "../../../hooks/credits";
import { FullPageRectangle } from "../search/style";
import { sendLinkPeople } from "../../../service/apiHunter";
import { checkIfEnrichmentLeadsExist } from "../../../hooks/filterLeadsDB";
import ProfileCompany from "../profileCompany";
import { useEffect, useState } from "react";
import LanguageIcon from "@mui/icons-material/Language";

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 12px;
  padding: 20px;
  margin: 10px;
  width: calc(50% - 20px);
  cursor: pointer;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #004163;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 20px;
`;

const Content = styled.div`
  flex: 1;
`;

const Title = styled.h3`
  color: #4b4b4b;
  margin: 0;
`;

const Link = styled.a`
  color: #8a97aa;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 14px;

  & > svg {
    margin-right: 5px;
  }
`;

const TextSite = styled.p`
color: #8a97aa;
display: flex;
align-items: center;
text-decoration: none;
font-size: 14px;

& > svg {
  margin-right: 5px;
}
`;

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RectanguleCompanyLeads = ({ data, userId, userName, companyName }) => {
  const formattedDate = new Date().toISOString();
  const [selectedCompanyUrl, setSelectedCompanyUrl] = useState(null);

  useEffect(() => {
    setSelectedCompanyUrl(null);
  }, [data]);

  const handleClick = async (urlCompany) => {
    try {
      const leadsExist = await checkIfEnrichmentLeadsExist(urlCompany);
      console.log("leadsExist", leadsExist);
      if (!leadsExist) {        
        await sendLinkPeople(urlCompany, formattedDate);
      }

      await deleteCredit(userId, 1);
      await historyCredits(
        {
          text: `Credits used with company profile on Leads Enrichment`,
          type: "decrease",
          date: formattedDate,
          credits: 1,
        },
        userId
      );
      const infoA = {
        action: `Search`,
        date: formattedDate,
        page: "Leads Enrichment",
        keywords: `${companyName}`,
        name: userName,
      };
      await saveAnalytics(userId, infoA);
      setSelectedCompanyUrl(urlCompany);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {selectedCompanyUrl ? (
        <ProfileCompany url={selectedCompanyUrl} isChildren={true} />
      ) : (
        <>
          <FullPageRectangle>
            Select the main website that matches the company you are looking for
          </FullPageRectangle>
          <GridContainer>
            {data.map((item, index) => (
              <Container
                key={index}
                onClick={(e) => {
                  if (!e.target.closest("a")) {
                    e.stopPropagation();
                    handleClick(item.link);
                  }
                }}
              >
                <Circle>
                  <BusinessIcon />
                </Circle>
                <Content>
                  <Title>{item.title}</Title>
                  <TextSite>

                  <LanguageIcon style={{ fontSize: 18 }} /> {item.link}
                  </TextSite>
                </Content>
                <Link
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ArrowOutwardIcon style={{ color: "#4B4B4B" }} />
                </Link>
              </Container>
            ))}
          </GridContainer>
        </>
      )}
    </>
  );
};

export default RectanguleCompanyLeads;
