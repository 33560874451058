import React from 'react';
import Chart from 'react-apexcharts';

const DistributionChart = ({ shipments, role }) => {

    const countryData = shipments?.reduce((acc, shipment) => {
        const { consigneeCountry, shipperCountry } = shipment;
        
        if (role === "Supplier") {
            acc[consigneeCountry] = (acc[consigneeCountry] || 0) + 1;
        } else {
            acc[shipperCountry] = (acc[shipperCountry] || 0) + 1;
        }

        return acc;
    }, {});
    
    const data = Object.entries(countryData).map(([country, count]) => ({
        country: country ? country.charAt(0).toUpperCase() + country.slice(1) : "Not Informed",
        count,
    }));
    

    const chartData = {
        series: [{
            name: 'Shipments',
            data: data.map(item => item.count), 
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            xaxis: {
                categories: data.map(item => item.country), 
                title: {
                    text: 'Countries',
                },
            },
            yaxis: {
                title: {
                    text: 'Number of shipments',
                },
            },
            title: {
                text: 'Distribution of shipments by Country',
                align: 'center'
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
        }
    };

    return (
        <div>
            <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={350}
            />
        </div>
    );
};

export default DistributionChart;
