import TradeDataView from "./tradeDataView";
import TradeDataInfo from "./useFNP";

export default function FindNewPartner() {
  return (
    <TradeDataInfo>
      <TradeDataView />
    </TradeDataInfo>
  );
}
