import Box from "@mui/material/Box";
import { Table } from "antd";
import Capitalize from "../../../../../../utils/capitalize";

export default function PortsShipTable({ data }) {
  const uniqueData = data.filter(
    (item, index, self) => index === self.findIndex((t) => t.port === item.port)
  );

  const dataWithKeys = uniqueData.map((item, index) => ({
    ...item,
    key: item.key || index,
  }));

  const columns = [
    {
      dataIndex: "key",
      key: "key",
      title: "Ranking",
      width: 100,
      editable: false,
      render: (_, record, index) => `${index + 1}`,
    },
    {
      dataIndex: "port",
      title: "Ports",
      key: "port",
      flex: 1,
      editable: false,
      render: (_, record) => `${Capitalize(record.port)}`,
    },
  ];

  return (
    <Box sx={{ height: "85%", width: "100%" }}>
      <Table columns={columns} dataSource={dataWithKeys} pagination={false} />
    </Box>
  );
}
