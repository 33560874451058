import React, { useEffect, useState } from "react";
import { ContainerHome, TextDefault } from "../../../assets/styles";
import { Grid } from "@mui/material";
import { authScreen } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import getUserInfo from "../../../hooks/getUsers";
import { Table } from "antd";
import { Container } from "../search/style";
import { getUniqueList } from "../../../hooks/leads";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { TagForType } from "../../../utils/tagForEmail";
import ProfileInfo from "./components/profileInfo";

export default function ListPage({ name }) {
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [lists, setLists] = useState([]);
  const [data, setData] = useState([]);
  const [companyInfo, setCompanyInfo] = useState();

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 500);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    });
  });

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  useEffect(() => {
    async function fetchLists() {
      try {
        const listsData = await getUniqueList(userInfo?.uid, name);
        setCompanyInfo(listsData);
        setLists(Object.values(listsData));
      } catch (error) {
        console.error("Erro ao buscar listas:", error);
      }
    }

    fetchLists();
  }, [auth, userInfo, name]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const formattedDate = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${year}`;
    return formattedDate;
  };

  const columnsList = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Type",
      dataIndex: "status",
      render: (email) => <TagForType type={email} />,
      filters: [
        { text: "Professional", value: "professional" },
        { text: "Generic", value: "generic" },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Job Title",
      dataIndex: "JobTitle",
    },
    {
      title: "Linkedin",
      dataIndex: "LinkedInURL",
      render: (_, record) => {
        return record?.LinkedInURL ? (
          <a href={`${record.LinkedInURL}`} target="_blank" rel="noreferrer">
            <LinkedInIcon />
          </a>
        ) : (
          <em>Not found</em>
        );
      },
    },
  ];

  useEffect(() => {
    if (lists.length > 0) {
      const data = lists[0]?.map((item) => ({
        key: item.id,
        Name: `${item?.full_name}`,
        JobTitle: item?.job_title,
        LinkedInURL: item?.linkedin,
        email: item?.email ? item.email?.email : "Not Informed",
        status: item?.email ? item?.email?.email_type : "unknown",
      }));
      setData(data);
    }
  }, [auth, lists]);

  const created_at = lists[lists.length - 2];
  const formattedDate = companyInfo
    ? formatDate(companyInfo.created_at)
    : formatDate(created_at);

  return (
    <>
      <ContainerHome>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <>
            <div style={{ marginLeft: "35px" }}>
              <br />

              <ProfileInfo companyData={companyInfo} />

              <br />
              <TextDefault color={"#969696"} bold={"400"} size={"16px"}>
                Created on {formattedDate}
              </TextDefault>
              <br />
              <br />

              <Container>
                <Table columns={columnsList} dataSource={data} />
              </Container>
            </div>
          </>
        </Grid>
      </ContainerHome>
    </>
  );
}
