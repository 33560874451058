import React from "react";
import CSV from "../../../../../icons/csv.png";

export default function ExcelExport({ excel }) {
  return (
    <div style={{ position: 'absolute', right: 50, top: -45, margin: 0, padding: 0 }}>
      <ul className="wrapper" style={{ margin: 0, padding: 0 }}>      
        <li className="icon twitter" onClick={excel} style={{ margin: 0, padding: 0 }}>
          <span className="tooltip">Excel</span>
          <span>
            <i className="fab fa-twitter"></i>
          </span>
          <img src={CSV} style={{ width: 25, height: 25 }} alt="Excel Icon" />
        </li>         
      </ul>
    </div>
  );
}