import { Table, Button, Input, Space } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BackgFlag, BtnAddList, BtnDeleteSmall, BtnLoadMore, BtnRmList, CardListAdd, ColumnContainer, ContainerModalHs, GroupInput, ImgClose, ImgDefault, InputDefault, ModalPainel, ModalPainelAddList, RowContainer, TextDefault } from "../../../../assets/styles";
import Capitalize from "../../../../utils/capitalize";
import country from "../../../../components/Flag";
import { TradeDataContext } from "../../useFNP";
import formatToCurrency from "../../../../utils/formatToCurrency";
import { AddCompaniesToList, removeCompaniesToList } from "../../../../hooks/ListCompanies";
import { Modal } from "@mui/material";
import * as XLSX from "xlsx";
import ExtraExport from "../AllCompanies/extra/pdfExport";
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

export default function GridCompanies({...props}) {

  const { requestMore, handlePrint } = useContext(TradeDataContext);

  const [isMapCompanyId, setIsMapComapnyId] = useState([])
  const [isDataTableShort, setIsDataTableShort] = useState([])
  const [loadingMore, setLoadingMore] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [nameNewList, setNameNewList] = useState();
  const [newList, setNewList] = useState(false);
  const [isCeciexModalOpen, setIsCeciexModalOpen] = useState(false);
  const [rowsToExcel, setRowsToExcel] = useState([])
  const [filterCountry, setFilterCountry] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };


  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    
  });
  
  
  useEffect(() => {

    let array = []
    let arrayFilterCountry = []

    if(props.data.companies.length > 0) {
      props.data.companies.forEach(element => {
        array.push(element.companyId)        
      });
    }

    setIsMapComapnyId(array)

    //Cálculo de relevância de empresas
    //(ShipmentValue / Number Shipment) * Trading Partner² = score

    if(props.data.companies.length > 0) {      
      const shortArray = props.data.companies.sort((a, b) => {
        const calcA = calcScore(a.shipmentValue, a.numberOfShipments, a.tradingPartnerCount);
        const calcB = calcScore(b.shipmentValue, b.numberOfShipments, b.tradingPartnerCount);
        return calcB - calcA
      }
      )
      setIsDataTableShort(shortArray)

      const uniqueArrayCountry = props.data.companies.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.country === obj.country
        ))
      );
    
      uniqueArrayCountry.forEach(element => {
        let data1 = {
          text: Capitalize(element.country),
          value: element.country
        }
  
        arrayFilterCountry.push(data1)
      });

      setFilterCountry(arrayFilterCountry)

    }

    console.log('Data')
    console.log(props.data)

  }, [props.data])

  const calcScore = (ship, nShip, partner) => {
    let result = (ship/nShip) * partner**2
    return result
  }

  const getMoreComapnies = () => {
    setLoadingMore(true)
    requestMore(10, isMapCompanyId).then(resp => {
      if(resp) {
        props.setData({companies: [...props.data.companies, ...resp], totalCompanies: props.data.totalCompanies})
        setLoadingMore(false)
      }      
    })
    .catch(e => {
      console.log(e)
      setLoadingMore(false)
    })
  }

  const url = (companyName, countryC) => {

    return window.open(      
      `/leadsenrichment-search?nameCompany=${encodeURIComponent(companyName)}&countryC=${encodeURIComponent(countryC)}&otherPage=true`,
      "_blank"
    );

  }

  const DropMenu = ({param}) => { 
      return(
          <div class="paste-button">
              <button class="buttonDrop">Option &nbsp; ▼</button>
              <div class="dropdown-content">
                  <a id="top" onClick={() => handleRedirect(param)}>Company Profile</a>
                  <a id="middle" onClick={() => url(param.companyName, Capitalize(param.country))}>Leads</a>                  
              </div>
          </div>
      )
  }

  const getFlagCountry = (cty) => {        
      if(cty) {            
          const flag = country.filter(obj => obj.country.toLocaleLowerCase() === cty.toLocaleLowerCase());
          return(flag[0].src)
      }
  
      return(require("../../../../flag/world.png"))
  }

  const navigate = useNavigate()

  const handleRedirect = (params) => {
  
    // return window.open(
    //   `/trade-data?companyName=${params.companyName}&companyId=${params.companyId}&country=${params.country}&role=${params.role}&isFavorited=${props.selectedDataType}`,
    //   "_blank"
    // );
    
    window.open(
      `/trade-data?companyName=${params.companyName}&companyId=${params.companyId}&country=${params.country}&role=${params.role}&isFavorited=${props.selectedDataType}`,
      "_blank"
    )

  };

  const closeModal = () => {
    setIsModal(false)
    setNewList(false)
  }
    
    const columns = [
      {
        title: <TextDefault color="#4b4b4b">Ranking</TextDefault>,
        dataIndex: 'ranking',
        key: 'ranking',        
        render: (_, param, index) => (
          <TextDefault style={{cursor: "pointer"}} color="#8a97aa" size="14px" bold="500">{index+1}</TextDefault>
        )},
      {
        title: <TextDefault color="#4b4b4b">Country</TextDefault>,
        dataIndex: 'country',
        key: 'country',
        filters: filterCountry, 
        onFilter: (value, record) => record.country.indexOf(value) === 0,
        render: (_, param) => (
          <RowContainer style={{justifyContent: "flex-start", alignItems: "center", cursor: "pointer"}}>
              {props.setCountry.toLocaleLowerCase() === param.country.toLocaleLowerCase() || props.dataList ? (
                <>
                  <ImgDefault src={getFlagCountry(param.country)} width="40px" height="40px"/>
                  <TextDefault color="#8a97aa" size="14px" bold="500" style={{marginLeft: 10}}>{Capitalize(param.country)}</TextDefault>
                </>
              ) : (
                <>
                  <ImgDefault src={getFlagCountry(props.setCountry)} width="40px" height="40px" style={{marginTop: -10}}/>
                  <BackgFlag>
                    <ImgDefault src={getFlagCountry(param.country)} width="40px" height="40px"/>
                  </BackgFlag>
                  <TextDefault color="#8a97aa" size="14px" bold="500" style={{marginLeft: 10}}>{Capitalize(param.country)}</TextDefault>
                </>
              )}                      
          </RowContainer>
        ),

      },
      {
        title: <TextDefault color="#4b4b4b">Company Name</TextDefault>,
        dataIndex: 'companyName',
        key: 'companyName',
        render: (_, param) => <TextDefault style={{cursor: "pointer"}} color="#8a97aa" size="14px" bold="500">{Capitalize(param.companyName)}</TextDefault>,
        ...getColumnSearchProps('companyName')
      },
      {
        title: <TextDefault color="#4b4b4b">Shipment Value (USD)</TextDefault>,
        dataIndex: 'shipmentValue',
        key: 'shipmentValue',
        render: (_, param) => <TextDefault style={{cursor: "pointer"}} color="#8a97aa" size="14px" bold="500">{param.shipmentValue > 0 ? formatToCurrency(param.shipmentValue) : '-'}</TextDefault>
      },
      {
        title: <TextDefault color="#8a97aa">Action</TextDefault>,
        dataIndex: 'action',
        key: 'action',
        render: (_, param) => <DropMenu param={param}/>
      },
    ];

    const handleRowClick = (record) => {
      handleRedirect(record) 
    };

    const rowSelection = {
      // função que é chamada quando uma linha é selecionada/desselecionada
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys)
        setSelectedRow(selectedRows)
      },
      // determina qual propriedade usar como chave para a seleção
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        Table.SELECTION_NONE,
        {
          key: 'odd',
          text: 'Select Odd Row',
          onSelect: (changeableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            setSelectedRowKeys(newSelectedRowKeys);
          },
        },
        {
          key: 'even',
          text: 'Select Even Row',
          onSelect: (changeableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            });
            setSelectedRowKeys(newSelectedRowKeys);
          },
        },
      ],
    };
    
    const sendCompaniesList = (step, listName) => {
      if(selectedRow.length > 0) {
        if(step == 1) {
          setIsModal(true)
        } else {
          props.setStateAtt(props.stateAtt + 1)
          AddCompaniesToList(listName, selectedRow)
          .catch(e => {
            console.log(e)
          })
          closeModal()
        }
      }
    }

    const createNewList = () => {
      if(newList) {
        sendCompaniesList(2, nameNewList)
        setNewList(false)
        setIsModal(false)
        setNameNewList("")
        props.setStateAtt(props.stateAtt + 1)

      } else {
        setNewList(true)
      }
    }

    const deleteCompanyList = () => {
      if(selectedRowKeys.length > 0 && props.dataList) {
        removeCompaniesToList(props.infoList.nameList, selectedRowKeys)
        const rmCompanies = isDataTableShort.filter(obj => !selectedRowKeys.includes(obj.companyId));

        props.setData({companies: rmCompanies, totalCompanies: rmCompanies.length})
        setSelectedRow([])
        setSelectedRowKeys([])
        props.setStateAtt(props.stateAtt + 1)
      }
    }

    const formattedData = isDataTableShort.map((company) => ({
      CompanyName:
        company?.companyName.charAt(0).toUpperCase() +
        company?.companyName.slice(1),
      Country:
        company?.country.charAt(0).toUpperCase() + company?.country.slice(1),
    }));
  
    function getHrNow() {
      const now = new Date();

      const year = now.getFullYear();
      const month = ('0' + (now.getMonth() + 1)).slice(-2);
      const day = ('0' + now.getDate()).slice(-2);

      const hours = ('0' + now.getHours()).slice(-2);
      const minutes = ('0' + now.getMinutes()).slice(-2);
      const seconds = ('0' + now.getSeconds()).slice(-2);

      const formattedDateTime = `${year}-${month}-${day} ${hours}.${minutes}`;

      return formattedDateTime;

    }
  
    const sheetName = `Report TD ${getHrNow()}`;
  
    const handleExcel = () => {
  
      const ws = XLSX.utils.json_to_sheet(formattedData);
      isDataTableShort.map(
        (company, i) =>
          (ws[`A${i + 2}`].l = {
            Target: `https://app.tradesee.io/trade-data?companyName=${company.companyName}&companyId=${company.companyId}&country=${company.country}&role=Supplier&isFavorited=New`,
            Tooltip: `Open ${company.companyName} Profile`,
          })
      );
  
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, sheetName + ".xlsx");
      
  
      if (rowsToExcel.length === 0) {
        return;
      }
  
      setIsCeciexModalOpen(true);
    };
      
    return(
      <>
        <ExtraExport pdf={false} action={handlePrint} excel={handleExcel}/>
        <Modal
            open={isModal}
            onClose={() => closeModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <ContainerModalHs>
            <ModalPainelAddList>

            <RowContainer style={{width: "100%", justifyContent: "flex-end", paddingRight: 20}}>
                <ImgClose onClick={closeModal} src={require("../../../../icons/CloseG1.png")} width="14px" height="14px"/>
            </RowContainer>

            <ColumnContainer style={{width: "100%", paddingRight: 20, marginTop: 20, height: "100%"}}>
              <CardListAdd onClick={createNewList} next={newList}>
                  <RowContainer style={{width: 40, height: 40, borderRadius: 25, backgroundColor: "#026AA2", justifyContent: "center", alignItems: "center", marginRight: 10}}>
                    <ImgDefault src={require("../../../../icons/addW.png")} width="17px" height="17px"/>
                  </RowContainer>
                  <ColumnContainer>
                    <TextDefault color={newList ? "#fff" : "#4b4b4b"} size="17px">{newList ? "Save" : "Add new list"}</TextDefault>
                  </ColumnContainer>
              </CardListAdd>

              <RowContainer style={{width: "100%", height: 1, backgroundColor: "#e9edf8", marginBottom: 10}} />

              {newList ? (
                <>
                  <TextDefault size="12px" color="#8a97aa" bold="700" style={{marginTop: 25}}>Name List*</TextDefault>
                  <GroupInput className="groupInputSale" style={{marginTop: 5}}>                    
                      <InputDefault                
                      className={"inputSale selectSale"}
                      placeholder="Company Name"             
                      type="text"
                      style={{ color: "#94a3b8"}}
                      value={nameNewList}
                      onChange={(item) => setNameNewList(item.target.value)}
                      />

                  </GroupInput> 
                </>
              ) : (              
                <ColumnContainer style={{width: "100%", overflowY: "auto", overflowX: "hidden", height: "75%"}}>
                  {props.getLists.length > 0 ? props.getLists.map((list) => (
                    <CardListAdd style={{padding: "20px 20px"}} key={list.nameList} onClick={() => sendCompaniesList(2, list.nameList)}>
                        <RowContainer style={{width: 40, height: 40, borderRadius: 25, backgroundColor: "#026AA2", justifyContent: "center", alignItems: "center", marginRight: 10}}>
                          <ImgDefault src={require("../../../../icons/bookmark 4.png")} width="17px" height="17px"/>
                        </RowContainer>
                        <ColumnContainer>
                          <TextDefault color="#4b4b4b" size="17px">{list.nameList}</TextDefault>
                          <TextDefault color="#8a97aa" size="12px">{list.count} Compan{list.count > 1 ? "ies" : "y"}</TextDefault>
                        </ColumnContainer>
                    </CardListAdd>
                  )) : (
                    <ColumnContainer style={{ width: "100%", height: "90%", justifyContent: "center", alignItems: "center"}}>
                      <ImgDefault src={require("../../../../icons/empty-box.png")} width="90px" height="90px"/>
                      <TextDefault color="#366dfb" size="12px" style={{marginTop: 20}}>You don't have any list, add a new one</TextDefault>                      
                    </ColumnContainer>
                  )}
                </ColumnContainer>
              )}

            </ColumnContainer>
                
            </ModalPainelAddList>
          </ContainerModalHs>

        </Modal>

        <RowContainer style={{ width: "100%", height: 40, justifyContent: "space-between", paddingLeft: 5, paddingBottom: 10}}>
          
          

            

          <RowContainer>
            <BtnRmList onClick={() => sendCompaniesList(1)} style={{marginRight: 15}} active={selectedRow.length > 0} colorHover="#DAF2FF">
                <ImgDefault src={require("../../../../icons/add4b.png")} width="15px" height="15px" style={{marginRight: 5}}/>                 
                <TextDefault size="14px" color="#4b4b4b" bold="600">Add my list</TextDefault>
            </BtnRmList>

            {props.dataList ? (
              <BtnRmList onClick={deleteCompanyList} style={{marginRight: 15}} active={selectedRow.length > 0} colorHover="#FFE4E4">
                  <ImgDefault src={require("../../../../icons/deleteSmall.png")} width="15px" height="15px" style={{marginRight: 5}}/>                 
                  <TextDefault size="14px" color="#4b4b4b" bold="600">Delete</TextDefault>
              </BtnRmList>
            ) : ""}
          </RowContainer>

          {selectedRowKeys.length == 0 ? (
            <TextDefault color="#8a97aa" size="14px" bold="500" style={{marginTop: 5}}>{props.data.companies.length} / {props.data.companies.length > props.data.totalCompanies ? props.data.companies.length : props.data.totalCompanies || props.data.totalCompaniesCount} companies</TextDefault>
          ) : (
            <TextDefault color="#8a97aa" size="14px" bold="500" style={{marginTop: 5}}>{selectedRowKeys.length} {selectedRowKeys.length == 1 ? "company" : "companies"} selected</TextDefault>
          )}



        </RowContainer>

        <Table
        rowSelection={rowSelection}
        rowKey={(record) => record.companyId}
        pagination={false}
        dataSource={isDataTableShort}
        columns={columns}
        onRow={(record) => ({
          onClick: () => {
            handleRowClick(record);
          },
        })}  
        />
        {!props.dataList ? (
          <ColumnContainer style={{marginTop: 20, marginBottom: 30, width: "100%", justifyContent: "center", alignItems: "center"}}>
            <BtnLoadMore onClick={() => getMoreComapnies()}>
              {loadingMore ? (
                <div aria-label="Loading..." role="status" class="loaderGet" style={{width: 75}}>
                  <svg class="iconLoading" viewBox="0 0 256 256">
                      <line x1="128" y1="32" x2="128" y2="64" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                      <line x1="195.9" y1="60.1" x2="173.3" y2="82.7" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                      <line x1="224" y1="128" x2="192" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                      <line x1="195.9" y1="195.9" x2="173.3" y2="173.3" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                      <line x1="128" y1="224" x2="128" y2="192" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                      <line x1="60.1" y1="195.9" x2="82.7" y2="173.3" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                      <line x1="32" y1="128" x2="64" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                      <line x1="60.1" y1="60.1" x2="82.7" y2="82.7" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                  </svg>
                  <TextDefault size="13px" color="#fff" bold="700" >Loading...</TextDefault>
                </div>
              ) : (
                <TextDefault color="#fff" size="16px">Load more</TextDefault>
              )}
            </BtnLoadMore>
            <TextDefault color="#8a97aa" size="14px" bold="500" style={{marginTop: 5}}>Showing {props.data.companies.length} of {props.data.companies.length > props.data.totalCompanies ? props.data.companies.length : props.data.totalCompanies || props.data.totalCompaniesCount} results.</TextDefault>
          </ColumnContainer>
        ) : ""}
      </>
    )
}