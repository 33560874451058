import React, { useEffect, useState } from "react";
import {
  ButtonAction,
  ButtonWrapper,
  InfoItem,
  InfoWrapper,
  InfoWrapperImg,
  StyledRectangle,
} from "./styleCompliance";
import CloseIcon from '@mui/icons-material/Close';
import { TextDefault } from "../../../../assets/styles";
import country from "../flag";
import { saveCompliance } from "../../../../hooks/compliance";

const CardComplianceFavorites = ({ data, userId, itemKey, onRemove }) => {
  const [png, setPng] = useState(null);
  const [pngSecond, setPngSecond] = useState(null);
  const [countryName, setCountryName] = useState(null);
  const [countryNameSecond, setCountryNameSecond] = useState(null);
  
  useEffect(() => {
    country.forEach((item) => {
      if (data?.countryOfImport.toLowerCase() === item.value.toLowerCase()) {
        setCountryName(item.label);
        setPng(item.src);
        return;
      }
    });
  }, [data, country]);

  useEffect(() => {
    country.forEach((item) => {
      if (data?.countryOfExport.toLowerCase() === item.value.toLowerCase()) {
        setCountryNameSecond(item.label);
        setPngSecond(item.src);
        return;
      }
    });
  }, [data, country]);

  function handleRemove() {
    onRemove(userId, itemKey);
  }


  return (
    <StyledRectangle>
      <InfoWrapperImg>
        <img
          style={{ width: "40x", height: "40x" }}
          src={png}
          alt="Company country"
        />
        <InfoItem>
          <TextDefault color={"#8A97AA"} bold={"400"}>
            {countryName}
          </TextDefault>
        </InfoItem>
      </InfoWrapperImg>
      <InfoWrapperImg>
        <img
          style={{ width: "40px", height: "40px" }}
          src={pngSecond}
          alt="Company country"
        />
        <InfoItem>
          <TextDefault color={"#8A97AA"} bold={"400"}>
            {countryNameSecond}
          </TextDefault>
        </InfoItem>
      </InfoWrapperImg>

      <InfoWrapper>
        <InfoItem>
          <TextDefault color={"#8A97AA"} bold={"700"}>
            Derived Reference
          </TextDefault>
        </InfoItem>
        <InfoItem>
          <TextDefault color={"#8A97AA"} bold={"400"}>
            {data?.derivedReference}
          </TextDefault>
        </InfoItem>
      </InfoWrapper>
      <InfoWrapper>
        <InfoItem>
          <TextDefault color={"#8A97AA"} bold={"700"}>
            Scope of Control
          </TextDefault>
        </InfoItem>
        <InfoItem>
          <TextDefault color={"#8A97AA"} bold={"400"}>
            {data?.scopeOfControl}
          </TextDefault>
        </InfoItem>
      </InfoWrapper>
      <ButtonWrapper>
        <ButtonAction onClick={handleRemove}>
          <TextDefault color={"#8A97AA"} bold={"700"}>
            <CloseIcon
              sx={{ fontSize: "1.2rem", verticalAlign: "middle" }}
            />
          </TextDefault>
        </ButtonAction>
      </ButtonWrapper>
    </StyledRectangle>
  );
};

export default CardComplianceFavorites;
