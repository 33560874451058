import React, { useEffect, useRef, useState } from "react";
import { ContainerHome, TextDefault } from "../../../assets/styles";
import Drawer from "../../../components/Drawer";
import { Grid } from "@mui/material";
import {
  InRow,
  TabsContainer,
  Card,
  Container,
  CustomGrid,
  InfoPill,
  InfoRight,
  InfoTitle,
  InfoValue,
  LeftInfo,
  RightInfo,
} from "../search/style";
import MyCollapse from "../utils/collapese";
import data from "../utils/mock";
import { useReactToPrint } from "react-to-print";
import PdfExport from "../utils/pdfExport";
import { authScreen } from "../../../contexts/auth";
import { useLocation, useNavigate } from "react-router-dom";
import getUserInfo from "../../../hooks/getUsers";
import LoadingPage from "../../../components/LoadingPage";
import * as XLSX from "xlsx";
import { PdfPage } from "../utils/pagePdf";

export default function SavedSanction() {
  const [toggleDrawer, useTroggleDawer] = useState(false);
  const [countryState, setCountryState] = useState("");
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  const handleCardClick = (item) => {
    setSelectedItem(item);
  };
  const location = useLocation();
  const item = location.state;

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 500);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    });
  });

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  function SetToggle(state) {
    useTroggleDawer(state);
  }

  const pdfRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });

  function handleItemClick() {
    const item = {
      companyName: companyName,
      countrySelected: countryState,
      page: "sanction",
    };
    navigate("/trade-data", { state: item });
  }

  function back() {
    navigate("/saved-operations");
  }

  const sheetName = "List Sanction";
  const dataSheet = data.map((item) => ({
    matchedEntityName: item.matchedEntityName,
    listName: item.listName,
    sanctioned: "Active",
    matchScore: `${item.matchScore}`,
  }));

  const excel = () => {
    const formattedData = dataSheet.map((item) => ({
      "Sanctioning List": item.matchedEntityName,
      "Sanctioning Source": item.listName,
      "Match Score": `${item.matchScore}%`,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, sheetName + ".xlsx");
  };

  return (
    <>
      {auth ? (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={toggleDrawer ? 2 : 1}>
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>

            <Grid
              item
              xs={toggleDrawer ? 10 : 11}
              marginLeft={toggleDrawer ? 35 : 10}
              container
            >
              <InRow>
                <button
                  className="cursor-pointer duration-200 hover:scale-125 active:scale-100"
                  title="Go Back"
                  style={{
                    backgroundColor: "transparent",
                    borderWidth: 0,
                  }}
                  onClick={() => back()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50px"
                    height="50px"
                    viewBox="0 0 24 24"
                    className="stroke-blue-300"
                  >
                    <path
                      strokeLinejoin="round"
                      strokeLinecap="round"
                      strokeWidth="1.5"
                      d="M11 6L5 12M5 12L11 18M5 12H19"
                    ></path>
                  </svg>
                </button>
                <TextDefault
                  color={"#4b4b4b"}
                  size={"32px"}
                  style={{ marginTop: 20 }}
                >
                  Saved Compliance
                </TextDefault>
              </InRow>

              <TextDefault
                color={"#8a97aa"}
                size={"18px"}
                bold={"400"}
                style={{ width: "100%", marginBottom: 15 }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </TextDefault>
              <Container>
                <TabsContainer>
                  <br />
                  <div>
                    <PdfExport
                      action={handlePrint}
                      fndp={handleItemClick}
                      excel={excel}
                    />
                    <div>
                      {item && <PdfPage ref={pdfRef} item={item} />}
                      <CustomGrid container spacing={1}>
                        <Grid item xs={6}>
                          <Card
                            active={selectedItem === item}
                            onClick={() => handleCardClick(item)}
                          >
                            <LeftInfo>
                              <div className="infoRow">
                                <InfoTitle>Entity Name: </InfoTitle>
                                <InfoValue>{item.matchedEntityName}</InfoValue>
                              </div>
                              <div className="infoRow">
                                <InfoTitle>List Name: </InfoTitle>
                                <InfoValue>{item.listName}</InfoValue>
                              </div>
                            </LeftInfo>
                            <RightInfo>
                              <div>
                                <InfoTitle>Sanctioned:</InfoTitle>
                                <InfoRight>Active</InfoRight>
                              </div>
                              <div>
                                <InfoTitle>Match Score:</InfoTitle>
                                <InfoPill>{item.matchScore}%</InfoPill>
                              </div>
                            </RightInfo>
                          </Card>
                          <MyCollapse data={item} />
                        </Grid>
                      </CustomGrid>
                    </div>
                  </div>
                </TabsContainer>
              </Container>
            </Grid>
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
