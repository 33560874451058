import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BoxCenter,
  BtnNextSolutions,
  Center,
  Column,
  ContainerPay,
  ContainerWithMap,
  FormContainer,
  FormRegister,
  InputWrapper,
  Label,
  MapaMundiR,
  Rectangle,
  StyledInput,
  TableContainer,
  TableItem,
} from "./style";
import { TextDefault } from "../../assets/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { message } from "antd";
import country from "../../utils/flagsIso";
import { CheckCircleOutline } from "@mui/icons-material";
import { registerUserWeb } from "../../contexts/auth";
import {
  addCustomerIDForUser,
  addEndDatePlan,
  addEndDatePlanAnnual,
  addStartDatePlan,
} from "../../hooks/getUsers";
import axios from "axios";
import { historyCredits } from "../../hooks/credits";
import ConfirmPayment from "./confirmPayment";

function Option({ country }) {
  return (
    <div
      className="country-option"
      style={{ display: "flex", alignItems: "center" }}
    >
      <img src={country.src} alt={country.label} width={20} height={20} />
      <strong style={{ marginLeft: 12 }}>{country.label}</strong>
    </div>
  );
}

function PaymentPage() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const theme = useContext(ThemeContext);
  const [countrySel, setCountrySel] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardCVC, setCardCVC] = useState("");
  const [password, setPassword] = useState("");
  const [price, setPrice] = useState("");
  const [credits, setCredits] = useState("");
  const [idInvoice, setIdInvoice] = useState("");
  const [idPayment, setIdPayment] = useState("");
  const formattedDate = new Date().toISOString();
  const [showConfirmPayment, setShowConfirmPayment] = useState(false);
  const [userId, setUserId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const planName = query.get('planName');
  const type = query.get('type');


  function handleCountrySelect(selected) {
    setCountrySel(selected.value);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") setFirstName(value);
    if (name === "lastName") setLastName(value);
    if (name === "email") setEmail(value);
    if (name === "city") setCity(value);
    if (name === "state") setState(value);
    if (name === "postalCode") setPostalCode(value);
    if (name === "streetAddress") setStreetAddress(value);
    if (name === "password") setPassword(value);
  };

  const infoC = {
    text: `You purchased a subscription`,
    type: "increase",
    date: formattedDate,
    credits: credits,
  };

  const customerData = {
    DisplayName: firstName,
    MiddleName: lastName,
    PrimaryEmailAddr: {
      Address: email,
    },
    PrimaryPhone: {
      FreeFormNumber: "(555) 555-5555",
    },
    CompanyName: "TradeSee",
    BillAddr: {
      Line1: streetAddress,
      City: city,
      CountrySubDivisionCode: countrySel,
      PostalCode: postalCode,
    },
  };

  const handleNext = async () => {
    const address = {
      city: city,
      country: countrySel,
      line1: streetAddress,
      postalCode: postalCode,
      state: state,
    };

    const data = {
      name: firstName,
      lastName: lastName,
      email: email,
      password: password,
      phone: "(555) 555-5555",
      role: "management",
      credits: credits,
      planMonthly: credits,
    };

    if (
      currentStep === 0 &&
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      city !== "" &&
      state !== "" &&
      postalCode !== "" &&
      streetAddress !== "" &&
      countrySel !== ""
    ) {
      const uid = await registerUserWeb(address, data);
      setUserId(uid);
      if (uid) {
        try {
          const response = await axios.post(
            "https://api.tsalemetals.com:4578/create-customer",
            customerData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            const newCustomerId = response.data.Customer.Id;
            setCustomerId(newCustomerId);
            await addCustomerIDForUser(uid, response.data.Customer.Id);
          }
        } catch (error) {
          console.error("Error creating customer:", error);
          let errorMessage = "Error";
          message.error(errorMessage);
          return false;
        }
      } else {
        let errorMessage = "Error create account";
        message.error(errorMessage);
      }
      setCurrentStep(1);
    } else if (currentStep === 1) {
      const expMonth = cardExpiry.substring(0, 2);
      const expYear = cardExpiry.substring(2, 6);
      console.log("cc", customerId);
      const cardData = {
        customerId: customerId,
        currency: "USD",
        amount: price,
        userId: userId,
        customerEmail: email,
        context: {
          mobile: "false",
          isEcommerce: "true",
        },
        card: {
          number: cardNumber,
          expMonth,
          expYear,
          name: cardName,
          cvc: cardCVC,
          address: {
            postalCode: postalCode,
            country: countrySel,
            region: state,
            streetAddress: streetAddress,
            city: city,
          },
        },
      };

      try {
        const response = await axios.post(
          "https://api.tsalemetals.com:4578/charge-payment",
          cardData,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          historyCredits(infoC, userId);

          const infoSend = {
            invoiceId: response.data.idInvoice,
            sendTo: email,
          };
          const infoSendPayment = {
            paymentId: response.data.idPayment,
            email: email,
          };

          const response2 = await axios.post(
            "https://api.tsalemetals.com:4578/send-invoice",
            infoSend,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const response3 = await axios.post(
            "https://api.tsalemetals.com:4578/send-payment",
            infoSendPayment,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          console.log("payment", response3.status);

          setIdInvoice(response.data.idInvoice);
          setIdPayment(response.data.idPayment);
          await addStartDatePlan(userId, response.data.charge.created);
          if (type === "annual") {
            await addEndDatePlanAnnual(userId, response.data.charge.created);
          } else {
            await addEndDatePlan(userId, response.data.charge.created);
          }
          if (response2.status === 200) {
            setShowConfirmPayment(true);
          }
        }
      } catch (error) {
        console.error("Erro ao enviar token para o servidor:", error);
        let errorMessage = `Error, ${error}`;
        message.error(errorMessage);
      }
    } else {
      let errorMessage = "Non empaty";
      message.error(errorMessage);
    }
  };
  const handleCardExpiryChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 6) value = value.slice(0, 6);
    setCardExpiry(value);
  };

  const formatCardExpiry = (value) => {
    if (value.length > 2) {
      return `${value.slice(0, 2)} / ${value.slice(2)}`;
    }
    return value;
  };

  useEffect(() => {
    if (planName === "Pro" && type === "annual") {
      setPrice(3600);
      setCredits(350);
    } else if (planName === "Pro" && type === "monthly") {
      setPrice(350);
      setCredits(350);
    } else if (planName === "Enterprise" && type === "annual") {
      setPrice(5000);
      setCredits(600);
    } else if (planName === "Enterprise" && type === "monthly") {
      setPrice(500);
      setCredits(600);
    }
  }, []);


  //avisar isabela para enviar como state params igual exemplo abaixo
  // https://localhost:3000/payment?planName=Pro&type=annual
  // https://localhost:3000/payment?planName=Pro&type=monthly
  // https://localhost:3000/payment?planName=Enterprise&type=annual
  // https://localhost:3000/payment?planName=Enterprise&type=monthly
  
  return (
    <ContainerWithMap>
      <FormContainer>
        <FormRegister>
          {showConfirmPayment ? (
            <ConfirmPayment />
          ) : (
            <>
              {currentStep === 0 ? (
                <>
                  <Center>
                    {" "}
                    <TextDefault
                      color={theme.colors.main[500]}
                      size={"30px"}
                      bold={"600"}
                    >
                      Continue your payment
                    </TextDefault>
                  </Center>
                  <ContainerPay>
                    <InputWrapper>
                      <Label>First Name</Label>
                      <StyledInput
                        name="firstName"
                        placeholder="First Name"
                        value={firstName}
                        onChange={handleInputChange}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Label>Last Name</Label>
                      <StyledInput
                        name="lastName"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={handleInputChange}
                      />
                    </InputWrapper>
                  </ContainerPay>
                  <br />
                  <InputWrapper fullWidth>
                    <Label>Streed Addres</Label>
                    <StyledInput
                      name="streetAddress"
                      placeholder="Street Address"
                      value={streetAddress}
                      onChange={handleInputChange}
                    />
                  </InputWrapper>
                  <ContainerPay>
                    <InputWrapper>
                      <Label>City</Label>
                      <StyledInput
                        name="city"
                        value={city}
                        onChange={handleInputChange}
                        placeholder="City"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Label>State</Label>
                      <StyledInput
                        name="state"
                        value={state}
                        onChange={handleInputChange}
                        placeholder="State"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Label>Postal code</Label>
                      <StyledInput
                        name="postalCode"
                        value={postalCode}
                        onChange={handleInputChange}
                        placeholder="Postal code"
                      />
                    </InputWrapper>
                  </ContainerPay>
                  <br />
                  <InputWrapper fullWidth>
                    <Label>Country</Label>
                    <Select
                      placeholder="Select the country"
                      defaultValue={countrySel}
                      onChange={handleCountrySelect}
                      options={country}
                      formatOptionLabel={(country) => (
                        <Option country={country} />
                      )}
                    />
                    <br />
                  </InputWrapper>
                  <ContainerPay>
                    <InputWrapper>
                      <Label>Email address</Label>
                      <StyledInput
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                        placeholder="Email address"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Label>Password</Label>
                      <StyledInput
                        name="password"
                        value={password}
                        onChange={handleInputChange}
                        placeholder="Password"
                        type={"password"}
                      />
                    </InputWrapper>
                  </ContainerPay>

                  <BtnNextSolutions
                    className={"btnNextBlue"}
                    onClick={() => {
                      handleNext();
                    }}
                  >
                    <>
                      <TextDefault size={"18px"} color={"#fff"}>
                        Next
                      </TextDefault>
                      <ArrowForwardIcon
                        style={{ marginLeft: 20 }}
                        sx={{
                          fontSize: "1.2rem",
                          verticalAlign: "middle",
                        }}
                      />
                    </>
                  </BtnNextSolutions>
                </>
              ) : (
                <>
                  <Center>
                    {" "}
                    <TextDefault
                      color={theme.colors.main[500]}
                      size={"30px"}
                      bold={"600"}
                    >
                      Checkout
                    </TextDefault>
                  </Center>
                  <br />
                  <InputWrapper fullWidth>
                    <Label>Card number</Label>
                    <StyledInput
                      name="cardNumber"
                      placeholder="Card number"
                      value={cardNumber}
                      onChange={(e) => setCardNumber(e.target.value)}
                    />
                  </InputWrapper>
                  <ContainerPay>
                    <InputWrapper>
                      <Label>Card Expiry</Label>
                      <StyledInput
                        name="cardExpiry"
                        placeholder="MM / YYYY"
                        value={formatCardExpiry(cardExpiry)}
                        onChange={handleCardExpiryChange}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Label>CVV</Label>
                      <StyledInput
                        name="cvv"
                        placeholder="CVV"
                        value={cardCVC}
                        onChange={(e) => setCardCVC(e.target.value)}
                      />
                    </InputWrapper>
                  </ContainerPay>
                  <br />
                  <InputWrapper fullWidth>
                    <Label>Full name on card</Label>
                    <StyledInput
                      name="cardName"
                      placeholder="Full name on card"
                      value={cardName}
                      onChange={(e) => setCardName(e.target.value)}
                    />
                  </InputWrapper>

                  <Rectangle>
                    <BoxCenter>
                      <TextDefault
                        color={theme.colors.gray[900]}
                        size={"20px"}
                        bold={"600"}
                      >
                        {planName}
                      </TextDefault>

                      <TextDefault
                        color={theme.colors.gray[500]}
                        size={"14px"}
                        bold={"400"}
                      >
                        {credits} credits | Total: ${price}
                      </TextDefault>
                    </BoxCenter>
                    <TableContainer>
                      <Column>
                        <TableItem> Trade data</TableItem>
                        <TableItem>
                          {" "}
                          <CheckCircleOutline
                            sx={{
                              fontSize: 16,
                              color: `${theme.colors.main[500]}`,
                            }}
                          />
                          Company Profile
                        </TableItem>
                        <TableItem>
                          {" "}
                          <CheckCircleOutline
                            sx={{
                              fontSize: 16,
                              color: `${theme.colors.main[500]}`,
                            }}
                          />
                          Business partners
                        </TableItem>
                        <TableItem>
                          {" "}
                          <CheckCircleOutline
                            sx={{
                              fontSize: 16,
                              color: `${theme.colors.main[500]}`,
                            }}
                          />
                          Import/Export Data
                        </TableItem>
                      </Column>
                      <Column>
                        <TableItem>Enrichment leads</TableItem>
                        <TableItem>
                          {" "}
                          <CheckCircleOutline
                            sx={{
                              fontSize: 16,
                              color: `${theme.colors.main[500]}`,
                            }}
                          />
                          Company Website Finder
                        </TableItem>
                        <TableItem>
                          {" "}
                          <CheckCircleOutline
                            sx={{
                              fontSize: 16,
                              color: `${theme.colors.main[500]}`,
                            }}
                          />
                          Contact Enrichment
                        </TableItem>
                        <TableItem>
                          {" "}
                          <CheckCircleOutline
                            sx={{
                              fontSize: 16,
                              color: `${theme.colors.main[500]}`,
                            }}
                          />
                          Email Enrichment
                        </TableItem>
                      </Column>
                      <Column>
                        <TableItem>Market Intelligence</TableItem>
                        <TableItem>
                          {" "}
                          <CheckCircleOutline
                            sx={{
                              fontSize: 16,
                              color: `${theme.colors.main[500]}`,
                            }}
                          />
                          Metal Prices
                        </TableItem>
                        <TableItem>
                          {" "}
                          <CheckCircleOutline
                            sx={{
                              fontSize: 16,
                              color: `${theme.colors.main[500]}`,
                            }}
                          />
                          LME Prices
                        </TableItem>
                        <TableItem>
                          {" "}
                          <CheckCircleOutline
                            sx={{
                              fontSize: 16,
                              color: `${theme.colors.main[500]}`,
                            }}
                          />
                          Analytic Data
                        </TableItem>
                      </Column>
                    </TableContainer>
                  </Rectangle>

                  <BtnNextSolutions
                    className={"btnNextBlue"}
                    onClick={() => {
                      handleNext();
                    }}
                  >
                    <>
                      <TextDefault size={"18px"} color={"#fff"}>
                        Proceed Payment
                      </TextDefault>
                      <ArrowForwardIcon
                        style={{ marginLeft: 20 }}
                        sx={{
                          fontSize: "1.2rem",
                          verticalAlign: "middle",
                        }}
                      />
                    </>
                  </BtnNextSolutions>
                </>
              )}
            </>
          )}
        </FormRegister>
      </FormContainer>
      <MapaMundiR />
    </ContainerWithMap>
  );
}

export default PaymentPage;
