/* eslint-disable react/prop-types */
import { StyleSheet } from "@react-pdf/renderer";
import { Chart } from "react-google-charts";
import ReactApexChart from "react-apexcharts";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";

import Capitalize from "../../../../utils/capitalize";
import { RowContainer } from "../../../../assets/styles";

const styles = StyleSheet.create({
  Container: {
    paddingBottom: 48,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    flex: 1,
    width: "100%",
    height: "55dvh",
  },
  Logo: {
    width: 100,
    height: 100,
  },
  Title: {
    fontSize: 16,
    alignSelf: "flex-start",
  },
  TitleBold: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "left",
  },
  Section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
  },
  Content: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  ContentNinety: {
    marginTop: 64,
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  TextContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "20%",
  },
  Subtitle: {
    fontSize: 24,
    fontWeight: "bold",
  },
  result: {
    fontSize: 20,
  },
  info: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    fontWeight: "semibold",
    maxWidth: "80%",
    fontSize: 20,
  },
  infoMargin: {
    display: "flex",
    alignItems: "center",
    marginTop: 24,
    textAlign: "center",
    fontWeight: "semibold",
    maxWidth: "80%",
    fontSize: 20,
  },
  DocsInfo: {
    display: "flex",
    flexDirection: "column",
  },
});

export const data = [
  ["Country", "Popularity"],
  ["Germany", 200],
  ["United States", 300],
  ["Brazil", 400],
  ["Canada", 500],
  ["France", 600],
  ["RU", 700],
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

function allyProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function GeneralData({ companies, general, maxCompanies }) {
  const theme = useContext(ThemeContext);
  const [selectedInfo, setSelectedInfo] = useState(0);

  function calculateCompanyRevenue(company) {
    return (
      (company.shipmentValue / company.numberOfShipments) *
      (company.tradingPartnerCount * 2)
    );
  }

  const newCompanies = companies.map((company) => ({
    ...company,
    relevance: calculateCompanyRevenue(company),
  }));

  const SortedCompanies = newCompanies
    .sort((a, b) => b.relevance - a.relevance)
    .filter((_, index) => index <= 4);

  const tradingPartners = SortedCompanies.map(
    (company) => company.tradingPartnerCount
  );

  const sortByShipmentValue = SortedCompanies
    .sort((a, b) => b.shipmentValue - a.shipmentValue)
    .filter((_, index) => index <= 4);

  const sortByShipmentsQty = SortedCompanies
    .sort((a, b) => b.numberOfShipments - a.numberOfShipments)
    .filter((_, index) => index <= 4);

  const sortByPartners = SortedCompanies
    .sort((a, b) => b.tradingPartnerCount - a.tradingPartnerCount)
    .filter((_, index) => index <= 4);

  const companiesNames = SortedCompanies.map((company) => company.companyName);

  const shipmentValueName = sortByShipmentValue.map(
    (company) => company.companyName
  );
  const shipmentsQtyName = sortByShipmentsQty.map(
    (company) => company.companyName
  );
  const PartnersName = sortByPartners.map((company) => company.companyName);

  const ShipmentValueData = sortByShipmentValue.map(
    (company) => company.shipmentValue
  );
  const ShipmentQtyData = sortByShipmentsQty.map(
    (company) => company.numberOfShipments
  );
  const QtyPartnersData = sortByPartners.map(
    (company) => company.tradingPartnerCount
  );

  const formattedCompanyNames = (names) =>
    names.map((company) => {
      const words = company.split(" ");
      return Capitalize(words.slice(0, 2).join(" "));
    });

  const ChartOptionsShipments = {
    options: {
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Top 5 Companies(Shipment Value)",
        align: "center",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: false,
          fomratter: function (name) {
            Capitalize(name);
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (name, val) {
              Capitalize(name);

              const value = val.series[0][val.dataPointIndex];

              if (value >= 1000000000) {
                return value / 1000000000 + " B";
              } else if (value >= 1000000) {
                return value / 1000000 + " M";
              } else if (value >= 1000) {
                return value / 1000 + " K";
              } else {
                return value;
              }
            },
          },
        },
        categories: formattedCompanyNames(shipmentValueName),
      },
    },
  };
  const ChartOptionsQtyShip = {
    options: {
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Top 5 Companies(Shipments Qty.)",
        align: "center",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: false,
          fomratter: function (name) {
            Capitalize(name);
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (name, val) {
              Capitalize(name);

              const value = val.series[0][val.dataPointIndex];

              if (value >= 1000000000) {
                return value / 1000000000 + " B";
              } else if (value >= 1000000) {
                return value / 1000000 + " M";
              } else if (value >= 1000) {
                return value / 1000 + " K";
              } else {
                return value;
              }
            },
          },
        },
        categories: formattedCompanyNames(shipmentsQtyName),
      },
    },
  };
  const ChartOptionsPartners = {
    options: {
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Top 5 Companies(Partners Qty.)",
        align: "center",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: false,
          fomratter: function (name) {
            Capitalize(name);
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (name, val) {
              Capitalize(name);

              const value = val.series[0][val.dataPointIndex];

              if (value >= 1000000000) {
                return value / 1000000000 + " B";
              } else if (value >= 1000000) {
                return value / 1000000 + " M";
              } else if (value >= 1000) {
                return value / 1000 + " K";
              } else {
                return value;
              }
            },
          },
        },
        categories: formattedCompanyNames(PartnersName),
      },
    },
  };

  const ChartOptionsMovings = {
    options: {
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Top 5 Companies",
        align: "center",
      },
      subtitle: {
        text: "Scored by: Shipment value, Volume and Trade parters",
        align: "center",
      },
      grid: {
        row: {
          colors: [`${theme.colors.gray[50]}`, "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#026AA2",
              colorTo: "#026AA2",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: false,
          fomratter: function (name) {
            Capitalize(name);
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (name, val) {
              Capitalize(name);

              const value = val.series[0][val.dataPointIndex];

              if (value >= 1000000000) {
                return value / 1000000000 + " B";
              } else if (value >= 1000000) {
                return value / 1000000 + " M";
              } else if (value >= 1000) {
                return value / 1000 + " K";
              } else {
                return value;
              }
            },
          },
        },
        categories: formattedCompanyNames(companiesNames),
      },
    },
  };

  const qtyCompanies = maxCompanies || 0;

  const handleSelectInfo = (event, newValue) => {
    if (newValue) {
      return setSelectedInfo(newValue);
    }

    setSelectedInfo((prevState) => (prevState === 0 ? 1 : 0));
  };

  return (
    <div style={{width: "100%"}}>

      <Grid container xs={12}>
        <Grid item xs={6}>
          <ReactApexChart
            options={ChartOptionsMovings.options}
            series={[
              {
                name: "Qty. Partners",
                data: tradingPartners,
              },
            ]}
            type="bar"
            height={400}
            width={window.innerWidth >= 1400 ? "100%" : "100%"}
          />
        </Grid>
        <Grid item xs={6}>
          <ReactApexChart
              options={ChartOptionsShipments.options}
              series={[
                {
                  name: "Shipment Value",
                  data: ShipmentValueData,
                },
              ]}
              type="bar"
              height={400}
              width={window.innerWidth >= 1400 ? "100%" : "100%"}
            />
        </Grid>
        <Grid item xs={6}>
          <ReactApexChart
            options={ChartOptionsQtyShip.options}
            series={[
              {
                name: "Number Of Shipments",
                data: ShipmentQtyData,
              },
            ]}
            type="bar"
            height={400}
            width={window.innerWidth >= 1400 ? "100%" : "100%"}
          />
        </Grid>
        <Grid item xs={6}>
          <ReactApexChart
            options={ChartOptionsPartners.options}
            series={[
              {
                name: "Qty. Partners",
                data: QtyPartnersData,
              },
            ]}
            type="bar"
            height={400}
            width={window.innerWidth >= 1400 ? "100%" : "100%"}
          />
        </Grid>
      </Grid>

    </div>
  );
}
