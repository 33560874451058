import React, { useEffect, useState } from "react";

import country from "../../../components/Flag";
import { Container } from "./styles";
import Capitalize from "../../../utils/capitalize";
import {
  ColumnContainer,
  ImgDefault,
  RowContainer,
  TextDefault,
} from "../../../assets/styles";
import NextIcon from "../../../icons/nextBlue.png";

const Partner = ({ data, size }) => {
  const [png, setPng] = useState(null);

  useEffect(() => {
    country.forEach((item) => {
      if (data.country.toLowerCase() === item.country.toLowerCase()) {
        return setPng(item.src);
      }
    });
  }, [data.country]);

  function linkSavedOperation() {
    window.open(
      `/trade-data?companyName=${data.companyName}&companyId=${data.id}&country=${data.country}&role=${"Supplier"}`,
      "_blank"
    );
  }

  return (
    <ColumnContainer style={{ alignItems: 'center', width: '100%', height: '100%', marginTop: 20}} onClick={linkSavedOperation}>
      <ImgDefault src={png} width={size == 7 ? "80px" : "60px"} height={size == 7 ? "80px" : "60px"} />
      <TextDefault className="lineLM2" size={size == 7 ? "16px" : "15px"} color="#4b4b4b" style={{textAlign: "center", marginTop: 10}} >{Capitalize(data.companyName)}</TextDefault>
      <TextDefault size={size == 7 ? "12px" : "10px"} color="#8a97aa" style={{textAlign: "center", marginTop: 10}}>{Capitalize(data.country)}</TextDefault>
    </ColumnContainer>
  );
};

export default Partner;
