import React from 'react';
import Chart from 'react-apexcharts';
import formatNumber from '../../../../../../utils/numberFormat';

const VolumeDistributionChart = ({ shipments, role }) => {

    const volumeData = shipments.reduce((acc, shipment) => {
        const { consigneeCountry, shipperCountry, shipmentWeight } = shipment;

        if (role === "Supplier") {
            acc[consigneeCountry] = (acc[consigneeCountry] || 0) + shipmentWeight;
        } else {
            acc[shipperCountry] = (acc[shipperCountry] || 0) + shipmentWeight;
        }

        return acc;
    }, {});

    const data = Object.entries(volumeData).map(([country, weight]) => ({
        country: country ? country.charAt(0).toUpperCase() + country.slice(1) : "Not Informed",
        weight: formatNumber(weight),
    }));

    
    const chartData = {
        series: [{
            name: 'Shipment volume (ton)',
            data: data.map(item => item.weight),
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350,
            },
            xaxis: {
                categories: data.map(item => item.country),    
                title: {
                    text: 'Countries',
                },        
            },
            yaxis: {
                title: {
                    text: 'Total volume (ton)',
                },
                logarithmic: true, 
            },
            title: {
                text: 'Distribution of shipments by volume',
                align: 'center',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
        },
    };

    return (
        <div>
            <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={350}
            />
        </div>
    );
};
export default VolumeDistributionChart;
