/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Grid } from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import Drawer from "../../components/Drawer";
import LoadingPage from "../../components/LoadingPage";
import AllModal from "../../components/AllModal";
import CompanyProfile from "./components/CompanyProfile";
import { ColumnContainer, ContainerHome, ImgDefault, RowContainer, TextDefault } from "../../assets/styles";
import "./styles.css";
import { TradeDataContext } from "./useFNP";
import HomeScreen from "./components/HomeScreen";
import { useSearchParams } from "react-router-dom";
import Shipments from "./components/Shipments";
import FilterBar from "./components/FIlterBar";
import { ConfigProvider, Tabs } from "antd";
import Empty from "./components/empty";
import GridCompanies from "./components/GridCompanies";
import GridDataRecord from "./components/GridDataRecord";
import GeneralData from "./components/GeneralData";
import { getListsCompanies } from "../../hooks/ListCompanies";
import MyListCompanies from "./components/MyListCompanies";
import GeolocationTable from "./components/GeolocationTable";
import GeolocationAnalytics from "./components/GeolocationAnalytics";
import ScoutTable from "./components/ScoutTable";
import UNcomtradeTradeData, { WorldShare } from "../../service/UNcomtradeTradeData";
import UNComtradeTable from "./components/UNComtradeTable";
import ChartValueCountry from "./components/UNComtradeTable/analytics/chartValueCountry";
//update

export default function TradeDataView() {
  const {
    auth,
    userInfo,
    companySelect,
    switchValue,
    dataCompanyById,
    companies,
    moveStepAnimation,
    fetchFavoritedCompanies,
    toggleDrawer,
    SetToggle,
    step,
    modalVisible,
    handleCloseModal,
    handleConfirmAction,
    modalMoreVisible,
    handleCloseModalMore,
    companyData,
    setCompanyData,
    companiesData,
    shipments,
    listType, 
    scoutData,
  } = useContext(TradeDataContext);

  const [searchParams] = useSearchParams();
  const [selectedDataType, setSelectedDataType] = useState("New");
  const [isLimitModalVisible, setIsLimitModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [redirectCompanyName] = useState(searchParams.get("companyName"));
  const [redirectCompanyId] = useState(searchParams.get("companyId"));
  const [redirectCountry] = useState(searchParams.get("country"));
  const [isFavorited] = useState(searchParams.get("isFavorited"));
  const [dataRecords] = useState(searchParams.get("dataRecords"));
  const [dataCompany, setDataCompany] = useState([])
  const [dataList, setDataList] = useState(false)
  const [infoList, setInfoList] = useState(false)
  const [record, setRecord] = useState([])
  const [countrySelected, setCountrySelected] = useState("")
  const [loading, setLoading] = useState(false)
  const [dataShipment, setDataShipment] = useState(false)
  const [activeKey, setActiveKey] = useState("1")
  const [listsCompanies, setListsCompanies] = useState([])
  const [att, setAtt] = useState(1)
  const [searchFor, setSearchFor] = useState()
  const [UNData, setUNData] = useState()
  const [worldShareValue, setWorldShareValue] = useState()


  
  useEffect(() => {
    if (
      auth &&
      redirectCompanyId &&
      redirectCompanyName &&
      redirectCountry &&
      userInfo
    ) {
      if (!companySelect) {
        dataCompanyById(redirectCompanyId, redirectCompanyName, redirectCountry,  isFavorited === "Favorited");      
      }
    }

    

  }, [
    auth,
    userInfo,
    redirectCompanyId,
    redirectCompanyName,
    redirectCountry,
    companySelect,
    isFavorited,
    switchValue,
    companies,
  ]);


  const requestUNComtrade = (freqCode, period, reporterCode, cmdCode, flowCode, partner) => {

    const formHs = cmdCode[0].hsCode
    const formFlow = flowCode == "Supplier" ? "X" : "M"

    console.log("Valid Request")
    console.log(freqCode)
    console.log(period)
    console.log(reporterCode)
    console.log(formHs)
    console.log(formFlow)
    console.log(partner)


    UNcomtradeTradeData("C", freqCode, "HS", period, reporterCode, formHs, formFlow, partner).then( async resp => {
      if(!resp.error) {
        console.log("API UN Comtrade")
        console.log(resp)
        setUNData(resp)
        setLoading(false)
        try {
          const worldShareResponse = await WorldShare("C", freqCode, period, reporterCode);  
          setWorldShareValue(worldShareResponse);  
        } catch (worldshareError) {
          console.log("Error in Worldshare API", worldshareError);
        }
      } else {
        setLoading(false);
        console.log("Error ", resp.error); 
      }
    })
    .catch(e => {
      console.log("Error ", e);
      setLoading(false);  
    });
};


  const fetchList = async() => {
    await getListsCompanies().then(resp => {

      setTimeout(() => {
        setListsCompanies(resp) 
      }, 1000)
    })
  }

  const moveTabs = (tab) => {
    setActiveKey(tab)
  }

  useEffect(() => {
    if(auth) {
      fetchList()
    }
  }, [auth])

  useEffect(() => {
    
    if (auth && dataRecords && userInfo) {
      moveStepAnimation("next", 4);
      setActiveKey('3')
    }

}, [auth, userInfo, dataRecords, moveStepAnimation, companyData]);

useEffect(() => {
    fetchFavoritedCompanies().then(resp => {
      setRecord(resp)
    })
  }, [att])

  const onChange = (key) => {
    //Trás o numero da tab selecionado
   setActiveKey(key);
  };

  function formatDate(inputDate) {
    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    const date = new Date(inputDate);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    
    return `${month} ${day}, ${year}`;
  }

  const items = [
    {
      key: '1',
      label: <div>
      <ImgDefault src={require("../../icons/rows.png")} width="15px" height="15px" style={{marginRight: 5, marginBottom: -1.5}}/>
      Data
      </div>,
      children: dataCompany.companies?.length > 0 && listType !== "geolocation" ? 
        <GridCompanies
          data={dataCompany}
          setData={setDataCompany}
          setCountry={countrySelected} 
          selectedDataType={selectedDataType}
          getLists={listsCompanies}
          stateAtt={att}
          setStateAtt={setAtt}
          dataList={dataList}
          infoList={infoList}
      
        /> : companyData?.companies?.companies?.length > 0 && listType === "geolocation" ? (
          <GeolocationTable
            data={companyData?.companies?.companies}
          />
        ) : scoutData?.dados && listType === "scout" ? (
          <ScoutTable data={scoutData} />
        ) : UNData && listType === 'un' ? (
          <UNComtradeTable data={UNData}/>
        ) : loading ? (
          <RowContainer style={{ width: "100%", height: "70vh" }}>
            <div className="loaderCentral">
              <div className="item1"></div>
              <div className="item2"></div>
              <div className="item3"></div>
            </div>
          </RowContainer>
         ) : (
      <Empty desc={"No results found. Try adjusting your search terms!"}/>
    ),
    },
    {
      key: '2',
      label: <div>
      <ImgDefault src={require("../../icons/line-chart.png")} width="15px" height="15px" style={{marginRight: 5, marginBottom: -1.5}}/>
      Analytics
      </div>,
      children: 
        dataCompany.companies?.length > 0 && listType !== "geolocation" ? (
          <GeneralData companies={dataCompany.companies} maxCompanies={dataCompany.totalCompanies} /> 
        ) : companyData?.companies?.companies?.length > 0 && listType === "geolocation" ? (
          <GeolocationAnalytics data={companyData?.companies?.companies} />
        ) : UNData && listType === 'un' ? (
          <ChartValueCountry data={UNData?.data} share={worldShareValue?.data} />
        ) :
        <Empty desc={"No results found. Try adjusting your search terms!"}/>,
    },
    {
      key: '4',
      label: <div>
      <ImgDefault src={require("../../icons/bookmark 5.png")} width="12px" height="12px" style={{marginRight: 5, marginBottom: -1}}/>
      My Companies List
      </div>,
      children: listsCompanies.length > 0 ? <MyListCompanies
        getLists={listsCompanies}
        stateAtt={att}
        setStateAtt={setAtt}
        setListsCompanies={setListsCompanies}
        setTabs={moveTabs}
        setDataCompany={setDataCompany}
        setDataList={setDataList}
        setLoading={setLoading}
        setInfoList={setInfoList}
      /> : <Empty desc={"Make a query or select your saved companies"}/>,
    },
    {
      key: '3',
      label: <div>
        <ImgDefault src={require("../../icons/favorite-on.png")} width="12px" height="12px" style={{marginRight: 5, marginBottom: -1}}/>
        Data Record
      </div>,
      children: record.length > 0 ? <GridDataRecord
        getLists={listsCompanies}
        setData={setRecord}
        getData={record}
        stateAtt={att}
        setStateAtt={setAtt}
      /> : <Empty desc={"Make a query or select your saved companies"}/>,
    },
    {
      key: '5',
      label: <div>
        <ImgDefault src={require("../../icons/boxC.png")} width="14px" height="14px" style={{marginRight: 5, marginBottom: -1}}/>
        Shipment
      </div>,
      children:  shipments?.length > 0 ? <Shipments/> : loading ? (
        <RowContainer style={{ width: "100%", height: "70vh" }}>
          <div className="loaderCentral">
            <div className="item1"></div>
            <div className="item2"></div>
            <div className="item3"></div>
          </div>
        </RowContainer>
       ) : <Empty desc={"No results found. Try adjusting your search terms!"}/>,
  },       
  
  ];

  useEffect(() => {
    const isRedirect =
      redirectCompanyId && redirectCompanyName && redirectCountry;

    if (!isRedirect ? auth : auth && companySelect) {
      setIsLoading(false);
    }
  }, [
    auth,
    redirectCompanyId,
    redirectCompanyName,
    redirectCountry,
    companySelect,
  ]);

  return (
    <>
      {!isLoading ? (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            style={{
              height: "100%",
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={toggleDrawer ? 2 : 1}>
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>

            {/* {step === 0 && (
              <HomeScreen setSelectedDataType={setSelectedDataType} />
            )} */}

            {((step === 0 || step === 1 || step === 4 || step === 7)) && (

              <>
                <FilterBar
                  getCompanies={setDataCompany}
                  getCountry={setCountrySelected}
                  getLoading={loading}
                  setLoading={setLoading}
                  setShipment={setDataShipment}
                  setTabs={moveTabs}
                  setDataList={setDataList}
                  setSearchFor={setSearchFor}
                  setCompanyData={setCompanyData}
                  requestUNComtrade={requestUNComtrade}
                />
                
                {/* {dataShipment ? (
                  <Shipments/>
                ) : ( */}

                  <Grid item xs={toggleDrawer ? 10 : 11} style={{paddingLeft: 280, paddingTop: 40}}>                    
                    <ColumnContainer style={{width: "98%"}}>
                      <RowContainer style={{alignItems: "center"}}>
                        <TextDefault size="30px" color="#4b4b4b" bold="800">{dataList ? infoList.nameList : "Trade Data"}</TextDefault>
                        {dataList ? (<ImgDefault src={require("../../icons/bookmark 5.png")} style={{marginLeft: 10}} width="20px" height="20px"/>) : ""}
                      </RowContainer>
                      <TextDefault size="14px" color="#8a97aa" bold="600" style={{marginTop: 10}}>{dataList ? (
                        <RowContainer>
                          <TextDefault color="#8a97aa" size="12px">{formatDate(infoList.lastUpdate)}</TextDefault>
                          <TextDefault color="#8a97aa" size="12px" style={{marginLeft: 20}}>List Type: Company</TextDefault>
                          <TextDefault color="#8a97aa" size="12px" style={{marginLeft: 20}}>Role: {infoList.role}</TextDefault>
                          <TextDefault color="#8a97aa" size="12px" style={{marginLeft: 20}}>HsCodes: {infoList.hsCode}</TextDefault>
                        </RowContainer>
                      ) : "Companies that meets the selected criteria"}</TextDefault>
                    
                      <ConfigProvider theme={{
                          components: {
                            Tabs: {
                              itemHoverColor: "#026AA2",
                              itemSelectedColor: "#026AA2",
                              itemActiveColor: "#026AA2",

                            }
                          }
                        }}>

                        <Tabs
                        activeKey={activeKey}
                        items={items}
                        onChange={onChange}
                        style={{marginTop: 30}}                        
                        />
                      </ConfigProvider>
  
                    </ColumnContainer>
                  </Grid>
                {/* )} */}
              </>
            )}

            {step === 5 && <LoadingPage />}

            {step === 6 && (
              <>
                <CompanyProfile />
              </>
            )}

            <AllModal
              type={"warning"}
              visible={modalVisible}
              onCancel={handleCloseModal}
              message="You need to add more credits to continue the search"
              title="No Balance"
              onConfirm={handleConfirmAction}
            />
            <AllModal
              type={"warning"}
              visible={modalMoreVisible}
              onCancel={handleCloseModalMore}
              message="You need to add more credits to continue the request"
              title="No Balance"
              onConfirm={handleConfirmAction}
            />
            <AllModal
              type={"warning"}
              visible={isLimitModalVisible}
              onCancel={() => setIsLimitModalVisible(false)}
              onConfirm={() => setIsLimitModalVisible(false)}
              message="You have reached your daily limit of 1000 credits"
              title="Limit Reached"
            />
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
