import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ArrowOpt,
  BalanceDrawer,
  CircleLogo,
  CircleLogoClose,
  ColumnContainer,
  CompleteMenu,
  DrawerModern,
  DrawerModernClose,
  IconOpt,
  ImgDefault,
  ImgOpt,
  LogoDrawer,
  MenuDrawer,
  OptionsDrawer,
  RowContainer,
  TextDefault,
  TextSubMenu,
  TxtBalance,
  TxtOpt,
  WalletDrawer,
} from "../assets/styles";

import MenuIcon from "../icons/menu.png";
import HomeIcon from "../icons/homeIcon.png";
import SettingsIcon from "../icons/setting.png";
import { authScreen, logout } from "../contexts/auth";
import getUserInfo, { addCustomerIDForUser } from "../hooks/getUsers";
import {
  addCredit,
  deleteCredit,
  registerAdd,
  registerPlan,
  viewAddMonthly,
  viewCredit,
  viewEndDatePlan,
  viewPlanMonthly,
} from "../hooks/credits";
import iconLogo from "../icons/Main_Icon.svg";
import ResponsiveMapping from "./ResponsiveMapping";
import useAnimatedUnmount from "../hooks/useAnimatedUnmount";
import WebIcon from "../icons/webIcon.png";
import UserIcon from "../icons/userIcon.png";
import nameSee from "../icons/TradeSee_Full_White2.png";
import AllModal from "./AllModal";
import Consumption from "./Consumption";
import { message } from "antd";
import axios from "axios";
import { pushMyNotification } from "../hooks/myNotification";

const { size } = ResponsiveMapping();

export default function Drawer({ handleToggle, initState, isFixed }) {
  const [stateDrawer, setStateDrawer] = useState(initState);
  const [blurValues, setBlurValues] = useState(false);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [isUsage, setIsUsage] = useState(0);
  const [yourPlan, setYourPlan] = useState(0);
  const [yourCredits, setYourCredits] = useState(0);

  //Menu
  const [menuS, setMenuS] = useState(false);

  const navigate = useNavigate();
  function ModIsStateDrawer() {
    setStateDrawer(!stateDrawer);
    handleToggle(!stateDrawer);
  }

  const exit = () => {
    navigate("/");
    logout();
  };

  async function blurAndUpdate() {
    setBlurValues((prevState) => !prevState);
    const userCredits = await viewCredit(userInfo?.uid);
    setCredits(userCredits);
  }

  const [auth, setAuth] = useState(false);

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 2000);
      }
    });
  });

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  const [credits, setCredits] = useState(null);

  function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0; // convertendo p um inteiro de 32 bits
    }
    return hash;
  }

  useEffect(() => {
    if (userInfo) {
      const fetchCredits = async () => {
        try {
          //Quantidade de créditos
          const userCredits = parseInt(await viewCredit(userInfo?.uid));
          //Plano
          const userPlan = parseInt(await viewPlanMonthly(userInfo?.uid));
          //Adicional
          const userAdd = parseInt(await viewAddMonthly(userInfo?.uid));
          //Data de Encerramento
          const dateEndPlan = await viewEndDatePlan(userInfo?.uid)        

          if(userPlan == 0) {            
            await registerAdd(userInfo?.uid, 0);
            await deleteCredit(userInfo?.uid, userCredits);
          } else {

            const dataAtual = new Date();
            const dataInformada = new Date(dateEndPlan);

            if (dataInformada < dataAtual) {
              //A data informada é anterior à data atual
              //Vencida
              await registerPlan(userInfo?.uid, 0);
              await registerAdd(userInfo?.uid, 0);
              await deleteCredit(userInfo?.uid, userCredits);

              setYourPlan(0);
              setYourCredits(0);

            } else if (dataInformada >= dataAtual) {
              //A data informada é superior à data atual
              //Em vigor
              //Validar irregulariedade
              setYourPlan(userPlan + userAdd);
              setYourCredits(userCredits);

              
              if (userPlan + userAdd >= userCredits) {
                 let percent = (userCredits / (userPlan + userAdd)) * 100;
                 let usagePercent = 100 - percent;
                 const intPercent = parseInt(usagePercent);
     
                 setIsUsage(intPercent);
     
                 if (intPercent >= 75) {
                  console.log("Creditos baixo COD-06")
                   const id = hashCode(`${text}${type}${status}${created_at}`)
                     .toString(16)
                     .substr(0, 5);
     
                   const dateNow = new Date();
     
                   var text = "Attention, the limit of your plan is approaching!";
                   var type = "update";
                   var description =
                     "Your plan consumption is already reaching its limit, upgrade your account or purchase additional calls to your plan.";
                   var route = "/credits";
                   var status = "approved";
                   var created_at = dateNow;
     
                   pushMyNotification({
                     id,
                     text,
                     type,
                     description,
                     route,
                     status,
                     created_at,
                   });
                 }
              } else {
                //Alterar os créditos para os planos e adicionais em vigor                
                if(userCredits) {
                  await deleteCredit(userInfo?.uid, userCredits);
                  await addCredit(userInfo?.uid, userPlan+userAdd)
                }

                setYourPlan(userPlan);
                setYourCredits(userPlan+userAdd);
              }
            }
          }
          setCredits(userCredits);
        } catch (error) {
          console.error("Erro ao buscar os créditos do usuário:", error);
        }
      };

      fetchCredits();
    }
  }, [userInfo]);

  const { shouldRender, animatedElementRef } = useAnimatedUnmount(stateDrawer);

  async function createCustomer(rota) {
    const customerData = {
      DisplayName: userInfo?.userData?.name,
      MiddleName: userInfo?.userData?.lastName,
      PrimaryEmailAddr: {
        Address: userInfo?.email,
      },
      PrimaryPhone: {
        FreeFormNumber: userInfo?.userData?.phone,
      },
      CompanyName: "TradeSee",
      BillAddr: {
        Line1: userInfo?.userData?.address?.line1,
        City: userInfo?.userData?.address?.city,
        CountrySubDivisionCode: "US",
        PostalCode: userInfo?.userData?.address?.postalCode,
      },
    };

    if (userInfo?.userData?.customerId) {
      if (rota === "billing") {
        navigate("/billing");
      } else {
        navigate("/credits");
      }
    } else {
      try {
        const response = await axios.post(
          "https://api.tsalemetals.com:4578/create-customer",
          customerData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          await addCustomerIDForUser(userInfo?.uid, response.data.Customer.Id);
          if (rota === "billing") {
            navigate("/billing");
          } else {
            navigate("/credits");
          }
          return true;
        }
      } catch (error) {
        console.error("Error creating customer:", error);
        let errorMessage = "Error";
        message.error(errorMessage);
        return false;
      }
    }
  }

  const roleUser = userInfo?.userData?.role;
  return (
    <>
      <AllModal
        visible={isLogoutModalVisible}
        title={"Already Leaving?"}
        message={"You are logging out, are you sure?"}
        type={"logout"}
        onConfirm={exit}
        onCancel={() => setIsLogoutModalVisible(false)}
      />

      {shouldRender ? (
        <DrawerModern
          style={{ width: size === "sm" ? "220px" : "250px" }}
          isleaving={!stateDrawer}
          ref={animatedElementRef}
        >
          <header>
            <CircleLogo disabled={isFixed} onClick={ModIsStateDrawer}>
              <LogoDrawer src={iconLogo} />
            </CircleLogo>

            <RowContainer>
              <ImgDefault src={nameSee} width="150px" height="27.22px" />
            </RowContainer>
          </header>

          <MenuDrawer>
            <OptionsDrawer
              noBeet={true}
              className="optDrewar"
              onClick={() => navigate("/home")}
            >
              <IconOpt>
                <ImgOpt src={HomeIcon} />
              </IconOpt>
              <TxtOpt>Home</TxtOpt>
            </OptionsDrawer>

            <OptionsDrawer
              className={"optDrewar"}
              onClick={() => navigate("/trade-data")}
            >
              <IconOpt>
                <ImgOpt src={MenuIcon} />
              </IconOpt>
              <TxtOpt>Trade Data</TxtOpt>
            </OptionsDrawer>

            <OptionsDrawer
              className={"optDrewar"}
              onClick={() => navigate("/market-intelligence-dashboard")}
            >
              <IconOpt>
                <ImgOpt src={WebIcon} />
              </IconOpt>
              <TxtOpt>Market Intelligence</TxtOpt>
            </OptionsDrawer>

            <OptionsDrawer
              className={"optDrewar"}
              onClick={() => navigate("/leadsenrichment-search")}
            >
              <IconOpt>
                <ImgOpt src={UserIcon} />
              </IconOpt>
              <TxtOpt>Leads Enrichment</TxtOpt>
            </OptionsDrawer>

            {/*<OptionsDrawer
              className="optDrewar"
              onClick={() => openNewTab("/reports")}
            >
              <IconOpt>
                <ImgOpt src={ReportIcon} />
              </IconOpt>
              <TxtOpt>Reports</TxtOpt>
              <ArrowOpt />
            </OptionsDrawer>*/}

            <OptionsDrawer
              className={menuS ? "optDrewarOpen" : "optDrewar"}
              onClick={() => setMenuS(!menuS)}
            >
              <IconOpt>
                <ImgOpt src={SettingsIcon} />
              </IconOpt>
              <TxtOpt>Settings</TxtOpt>
              <ArrowOpt iconSt={menuS} />
            </OptionsDrawer>
            {menuS ? (
              <CompleteMenu>
                <TextSubMenu onClick={() => navigate("/profile")}>
                  Profile
                </TextSubMenu>
                <TextSubMenu onClick={() => createCustomer("billing")}>
                  Billing
                </TextSubMenu>
                <TextSubMenu onClick={() => createCustomer("credits")}>
                  Subscription & Credits
                </TextSubMenu>
                {roleUser === "admin" ? (
                  <>
                    <TextSubMenu onClick={() => navigate("/marketing")}>
                      Marketing
                    </TextSubMenu>
                    <TextSubMenu onClick={() => navigate("/analytics")}>
                      Analytics
                    </TextSubMenu>
                    <TextSubMenu onClick={() => navigate("/logscontrol")}>
                      Logs Control
                    </TextSubMenu>
                  </>
                ) : (
                  <> </>
                )}
                <TextSubMenu onClick={() => setIsLogoutModalVisible(true)}>
                  Logout
                </TextSubMenu>
              </CompleteMenu>
            ) : (
              ""
            )}
          </MenuDrawer>

          {/*<WalletDrawer>
            <TxtBalance
              size={"12px"}
              bold={"100"}
              style={{
                marginTop: 15,
                textAlign: "center",
                width: "85%",
                lineHeight: 1.3,
                marginBottom: 10,
              }}
            >
              Use credits to access tools and information.
            </TxtBalance>
            <BalanceDrawer className="balanceEffect" onClick={blurAndUpdate}>
              <ColumnContainer
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 13,
                }}
              >
                <RowContainer style={{ width: "100%" }}>
                  <Consumption
                    usage={isUsage}
                    credits={yourCredits}
                    plan={yourPlan}
                  />
                </RowContainer>
              </ColumnContainer>
            </BalanceDrawer>
          </WalletDrawer>*/}
        </DrawerModern>
      ) : (
        <DrawerModernClose>
          <CircleLogoClose onClick={ModIsStateDrawer}>
            <LogoDrawer src={iconLogo} size={"45px"} />
          </CircleLogoClose>
        </DrawerModernClose>
      )}
    </>
  );
}
