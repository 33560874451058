import CountryDemo from "./index.js";
import CountryId from "./UNComtradeMap.json"

export function UnCountryId() {


    // console.log("-----Country-----")
    // console.log(CountryDemo)
    // console.log("-----ID-----")
    // console.log(CountryId.results)

    let array = CountryDemo;


    array.forEach(obj => {
        const countryInfo = CountryId.results.find(country => country.PartnerDesc === obj.country)

        if(countryInfo) {
            obj.id = countryInfo.id
            obj.PartnerCodeIsoAlpha2 = countryInfo.PartnerCodeIsoAlpha2
            obj.PartnerCodeIsoAlpha3 = countryInfo.PartnerCodeIsoAlpha3
        }
    })

    console.log("Result")
    console.log(array)

      

}