import styled from "styled-components";

export const Container = styled.main`
  width: 99dvw;
  max-height: 99dvh;
`;

export const Content = styled.section`
  height: 99dvh;
  max-height: 99dvh;
  width: 100%;
`;
