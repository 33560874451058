import React, { useEffect, useState } from "react";
import { BoldText, CardDetails, Info, InfoTitle } from "../search/style";
import { TextDefault } from "../../../assets/styles";
import { InfoValue } from "./stylePdf";

const MyCardForDetails = ({ data }) => {
  const [isReady, setIsReady] = useState(false);
  const [entityAliases, setEntityAliases] = useState([]);
  const [idNumber, setIdNumber] = useState([]);
  const [idIssueDate, setIdIssueDate] = useState([]);
  const [idIssuingCountry, setIdIssuingCountry] = useState([]);
  const [idNotes, setIdNotes] = useState([]);
  const [idType, setIdType] = useState([]);
  useEffect(() => {
    if (data !== null) {
      const { idDetails, entityAliases } = data?.details;
      const idNumber = idDetails.map((detail) => detail.idNumber);
      const idIssueDate = idDetails.map((detail) => detail.idIssueDate);
      const idIssuingCountry = idDetails.map(
        (detail) => detail.idIssuingCountry
      );
      const idNotes = idDetails.map((detail) => detail.idNotes);
      const idType = idDetails.map((detail) => detail.idType);
      const entity =  idDetails.map((detail) => detail.entityAliases);
      setEntityAliases(entity);
      setIdNumber(idNumber);
      setIdIssueDate(idIssueDate);
      setIdIssuingCountry(idIssuingCountry);
      setIdNotes(idNotes);
      setIdType(idType);
      setIsReady(true);
    }
  }, [data]);

  const formattedNotes = Object.entries(data?.details?.notes).map(
    ([key, value]) => {
      let extractedValue = value;

      if (typeof value === "string") {
        [, extractedValue] = value.split(":");
      }

      if (extractedValue && typeof extractedValue.trim === "function") {
        extractedValue = extractedValue.trim();
      }

      return { label: key, value: extractedValue };
    }
  );
  return (
    <>
      {isReady ? (
        <CardDetails>
          <TextDefault color={"#4b4b4b"} size={"20px"}>
            Primary Details
          </TextDefault>
          <p></p>

          <Info>
            <div>
              <InfoTitle>List Name: </InfoTitle>
              <InfoValue>{data?.listName ? data?.listName : "-"}</InfoValue>
            </div>
            <div>
              <InfoTitle>Entity Type: </InfoTitle>

              {formattedNotes[3]?.value ? formattedNotes[3]?.value : "NA"}
            </div>
            {/* <div className="infoRow">
              <InfoTitle>Entity Name: </InfoTitle>
              <InfoValue>xxxx</InfoValue>
            </div> */}
            <div className="infoRow">
              <InfoTitle>Entity Aliases: </InfoTitle>
              <InfoValue>
                {typeof entityAliases === "object"
                  ? entityAliases?.map((entity, index) =>
                      entityAliases?.length - 1 === index
                        ? `${entity}`
                        : `${entity}, `
                    )
                  : "NA"}
              </InfoValue>
            </div>
            <div className="infoRow">
              <InfoTitle>Expiry Date: </InfoTitle>
              <InfoValue>
                {data?.details?.expiryDate ? data?.details?.expiryDate : "-"}
              </InfoValue>
            </div>

            <div>
              <InfoTitle>Excluding Agency: </InfoTitle>
              <InfoValue>
                {formattedNotes[1]?.value ? formattedNotes[1]?.value : "NA"}
              </InfoValue>
            </div>
            <div>
              <InfoTitle>Exclusion Type: </InfoTitle>
              <InfoValue>
                {formattedNotes[4]?.value ? formattedNotes[4]?.value : "NA"}
              </InfoValue>
            </div>
            <div>
              <InfoTitle>Exclusion Program: </InfoTitle>
              <InfoValue>
                {formattedNotes[6]?.value ? formattedNotes[6]?.value : "NA"}
              </InfoValue>
            </div>
            <div>
              <InfoTitle>Notes: </InfoTitle>
              <InfoValue>
                {formattedNotes[0]?.value ? formattedNotes[0]?.value : "NA"}
              </InfoValue>
            </div>
          </Info>
          <hr />
          <TextDefault color={"#4b4b4b"} size={"20px"}>
            Address Details
          </TextDefault>
          <p></p>

          <Info>
            <div>
              <InfoTitle>City: </InfoTitle>
              <InfoValue>
                {" "}
                {data?.details?.addressDetails.city
                  ? data?.details?.addressDetails.city
                  : "-"}
              </InfoValue>
            </div>
            <div>
              <InfoTitle>State: </InfoTitle>
              <InfoValue>
                {" "}
                {data?.details?.addressDetails.state
                  ? data?.details?.addressDetails.state
                  : "-"}
              </InfoValue>
            </div>
            <div>
              <InfoTitle>Street: </InfoTitle>

              {data?.details?.addressDetails.street
                ? data?.details?.addressDetails.street
                : "-"}
            </div>
            <div>
              <InfoTitle>Country: </InfoTitle>
              <InfoValue>
                {" "}
                {data?.details?.addressDetails.country
                  ? data?.details?.addressDetails.country
                  : "-"}
              </InfoValue>
            </div>
            <div>
              <InfoTitle>Zip: </InfoTitle>
              <InfoValue>
                {" "}
                {data?.details?.addressDetails.zipcode
                  ? data?.details?.addressDetails.zipcode
                  : "-"}
              </InfoValue>
            </div>
          </Info>
          <hr />

          <TextDefault color={"#4b4b4b"} size={"20px"}>
            Id Details
          </TextDefault>
          <p></p>

          <Info>
            <div>
              <InfoTitle>ID Issue Date: </InfoTitle>
              <InfoValue>
                {idIssueDate?.map((_, index) =>
                  idIssueDate?.length - 1 === index
                    ? `${idIssueDate}`
                    : `${idIssueDate} `
                )}
              </InfoValue>
            </div>
            <div>
              <InfoTitle>ID Issuing Country: </InfoTitle>
              <InfoValue>
                {" "}
                {idIssuingCountry?.map((_, index) =>
                  idIssuingCountry?.length - 1 === index
                    ? `${idIssuingCountry}`
                    : `${idIssuingCountry} `
                )}
              </InfoValue>
            </div>
            <div>
              <InfoTitle>Id Notes: </InfoTitle>
              <InfoValue>
                {idNotes?.map((_, index) =>
                  idNotes?.length - 1 === index ? `${idNotes}` : `${idNotes} `
                )}
              </InfoValue>
            </div>
            <div>
              <InfoTitle>Id Number: </InfoTitle>{" "}
              {idNumber?.map((_, index) =>
                idNumber?.length - 1 === index ? `${idNumber}` : `${idNumber} `
              )}
            </div>
            <div>
              <InfoTitle>Id Type: </InfoTitle>{" "}
              {idType?.map((_, index) =>
                idType?.length - 1 === index ? `${idType}` : `${idType} `
              )}
            </div>
          </Info>
        </CardDetails>
      ) : (
        <p>Loading</p>
      )}
    </>
  );
};

export default MyCardForDetails;
