import styled from "styled-components";
import Bg from "../../assets/Bg.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 291mm;
  background: ${({ isFavorite }) =>
    isFavorite ? `url(${Bg}) center no-repeat` : "#fff"};
  position: relative;

  .bar {
    position: absolute;
    width: 100%;
    height: 12px;
    background-color: #0981b1;
    top: 0;

    &::before {
      content: "";
      position: absolute;
      background-color: #089bd5;
      width: 35%;
      height: 12px;
      left: 0;
    }

    &::after {
      content: "";
      position: absolute;
      background-color: #153344;
      width: 35%;
      height: 12px;
      right: 0;
    }
  }

  .TitleContainer {
    display: flex;
    align-items: center;
    margin: 32px 32px 8px 32px;
    gap: 16px;

    img {
      width: 120px;
    }
  }

  h2 {
    margin: 0;
    font-size: 80px;
    font-weight: 900;
    position: relative;
    line-height: 64px;
  }

  span {
    margin: 12px 12px 0 32px;
    font-weight: 500;
  }

  footer {
    display: flex;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
    }
  }
`;
