import React from "react";
import { Modal } from "antd";
import CheckoutPage from "../../checkout";

const PurchaseModal = ({ visible, onCancel, infoPlan, info, address, cards }) => {
  return (
    <Modal visible={visible} onCancel={onCancel} footer={null}>
      <CheckoutPage info={info} address={address} infoPlan={infoPlan} cards={cards} />
    </Modal>
  );
};

export default PurchaseModal;
