/* eslint-disable react/prop-types */
import ReactApexChart from "react-apexcharts";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Col, Row } from "antd";

export default function GeolocationAnalytics({ data }) {

  const theme = useContext(ThemeContext);
  const companiesNames = data?.map((company) => company.name);
  const popularityData = data?.map((company) => company.follower_count);
  const sizeData = data?.map((company) => company.size);
  const industryCount = countPrimaryIndustries(data);
  const industryCategories = Object.keys(industryCount);
  const industryDataSeries = Object.values(industryCount);
  const typeCount = countCompanyTypes(data);
  const typeCategories = Object.keys(typeCount);
  const typeDataSeries = Object.values(typeCount);
  
  function countPrimaryIndustries(data) {
    const industryCount = data.reduce((acc, company) => {
      const industry = company.primary_industry;
      acc[industry] = (acc[industry] || 0) + 1;
      return acc;
    }, {});

    return industryCount;
  }

  function countCompanyTypes(data) {
    const typeCount = data.reduce((acc, company) => {
      const type = company.type;
      if (type) { 
        acc[type] = (acc[type] || 0) + 1;
      }
      return acc;
    }, {});
    return typeCount;
  }
  
  const companySizeChart = {
    options: {
      chart: {
        // height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Company Size",
        align: "center",
      },
      grid: {
        row: {
          colors: [`${theme.colors.gray[50]}`, "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: companiesNames,
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#026AA2",
              colorTo: "#026AA2",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        labels: {
          show: false,
        },
      },
    },
  };

  const followerCountChart = {
    options: {
      chart: {
        // height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Follower Count",
        align: "center",
      },
      grid: {
        row: {
          colors: [`${theme.colors.gray[50]}`, "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: companiesNames,
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#026AA2",
              colorTo: "#026AA2",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        labels: {
          show: false,
        },
      },
    },
  };

  const industriesCountChart = {
    options: {
      chart: {
        // height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Primary Industries",
        align: "center",
      },
      grid: {
        row: {
          colors: ['#f3f4f5', 'transparent'],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: industryCategories,
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#026AA2",
              colorTo: "#026AA2",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    },
  };

  const typesCountChart = {
    options: {
      chart: {
        // height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Company Types",
        align: "center",
      },
      grid: {
        row: {
          colors: ['#f3f4f5', 'transparent'],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: typeCategories,
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#026AA2",
              colorTo: "#026AA2",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    },
  };

  return (
    <Row>
      <Col md={24} lg={12}>
        <ReactApexChart
          options={companySizeChart.options}
          series={[
            {
              name: "Company Size",
              data: sizeData,
            },
          ]}
          type="bar"
        />
      </Col>
      <Col md={24} lg={12}>
        <ReactApexChart
          options={followerCountChart.options}
          series={[
            {
              name: "Followers",
              data: popularityData,
            },
          ]}
          type="bar"
        />
      </Col>
      <Col md={24} lg={12}>
        <ReactApexChart
          options={industriesCountChart.options}
          series={[
            {
              name: "Primary Industries",
              data: industryDataSeries
            }
          ]}
          type="bar"
        />
      </Col>
      <Col md={24} lg={12}>
        <ReactApexChart
          options={typesCountChart.options}
          series={[
            {
              name: "Company Types",
              data: typeDataSeries
            }
          ]}
          type="bar"
        />
      </Col>
    </Row>
  );
}
