import axios from "axios";
import { APITSALE_KEY } from "./key";

export function getAllDataHunter(companyId, domain, limit) {
  return new Promise((resolve, reject) => {
    const body = {};
    if (companyId) {
      body.companyId = companyId;
    }
    body.domain = domain;
    body.limit = limit;
    const payload = JSON.stringify(body);

    const header = {
      headers: {
        Authorization: "Bearer " + APITSALE_KEY.key,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post(
        "https://api.tsalemetals.com/t-api/v1/hunter-all-data",
        payload,
        header
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject("failed");
      });
  });
}

export function finderLink(companyName, country) {
  return new Promise((resolve, reject) => {
    const body = JSON.stringify({
      companyName,
      country,
    });

    const header = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("https://api.tsalemetals.com:3741/finder-link", body, header)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject("failed");
      });
  });
}

export function sendLinkPeople(domain, date) {
  return new Promise((resolve, reject) => {
    const body = JSON.stringify({
      domain,
      date,
    });

    const header = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("https://api.tsalemetals.com/send-link-people", body, header)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject("failed");
      });
  });
}

export function sendUrlCompanies(
  companyIdentiers,
  size,
  country,
  city,
  state,
  industry,
  excludeIndustry,
  keyWordsIncluded,
  keyWordsExcluded,
  location,
  excludeLocation
) {
  return new Promise((resolve, reject) => {
    const body = JSON.stringify({
      companyIdentiers,
      country,
      city,
      state,
      industry,
      excludeIndustry,
      size,
      keyWordsIncluded,
      keyWordsExcluded,
      location,
      excludeLocation,
    });

    const header = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("https://api.tsalemetals.com/search-companies", body, header)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject("failed");
      });
  });
}

export function sendUrlScout(domain) {
  return new Promise((resolve, reject) => {
    const body = JSON.stringify({
      domain,
    });

    const header = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("https://api.tsalemetals.com/scout", body, header)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject("failed");
      });
  });
}

export function sendLinkedinUrls(urls, domain) {
  return new Promise((resolve, reject) => {
    const body = JSON.stringify({
      urls,
      domain,
    });

    const header = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("https://api.tsalemetals.com/send-linkedin", body, header)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject("failed");
      });
  });
}

export function finderLinkSecond(companyName, country) {
  return new Promise((resolve, reject) => {
    const body = JSON.stringify({
      companyName,
      country,
    });

    const header = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("https://api.tsalemetals.com:3741/finder-link-second", body, header)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject("failed");
      });
  });
}

export function finderSocial(companyName, country) {
  return new Promise((resolve, reject) => {
    const body = JSON.stringify({
      companyName,
      country,
    });

    const header = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("https://api.tsalemetals.com:3741/finder-social", body, header)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject("failed");
      });
  });
}

export function finderAddress(companyName, country) {
  return new Promise((resolve, reject) => {
    const body = JSON.stringify({
      companyName,
      country,
    });

    const header = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("https://api.tsalemetals.com:3741/finder-address", body, header)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject("failed");
      });
  });
}

export function getLeadsClay(companyLink) {
  return new Promise((resolve, reject) => {
    const body = JSON.stringify({
      companyLink,
    });

    const header = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("https://api.tsalemetals.com:1470/leads", body, header)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject("failed");
      });
  });
}

export function HunterGetEmail(
  domain,
  first_name,
  last_name,
  user,
  userId,
  userIp
) {
  return new Promise((resolve, reject) => {
    const body = JSON.stringify({
      domain,
      first_name,
      last_name,
      user,
      userId,
      userIp,
    });

    const header = {
      headers: {
        Authorization: "Bearer " + APITSALE_KEY.key,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post(
        "https://api.tsalemetals.com/t-api/v1/hunter-email-data",
        body,
        header
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject("failed");
      });
  });
}
