/* eslint-disable no-undef */
const countriesData = [
    { src: require("../../../flag/globalCountry.png"), label: "Global Search" },
    {
      src: require("../../../flag/afghanistan.png"),
      label: "Afghanistan",
    },
    { src: require("../../../flag/albania.png"), label: "Albania" },
    { src: require("../../../flag/algeria.png"), label: "Algeria" },   
    { src: require("../../../flag/andorra.png"), label: "Andorra" },  
    { src: require("../../../flag/argentina.png"), label: "Argentina" },
    { src: require("../../../flag/armenia.png"), label: "Armenia" },
    { src: require("../../../flag/australia.png"), label: "Australia" },
    { src: require("../../../flag/austria.png"), label: "Austria" },
    { src: require("../../../flag/bahrain.png"), label: "Bahrain" },
    { src: require("../../../flag/belgium-3.png"), label: "Belgium" },
    { src: require("../../../flag/bermuda.png"), label: "Bermuda" },
    { src: require("../../../flag/bhutan.png"), label: "Bhutan" },
    { src: require("../../../flag/bngladesh.png"), label: "Bangladesh" },
    { src: require("../../../flag/bolivia.png"), label: "Bolivia" },
    { src: require("../../../flag/botswana.png"), label: "Botswana" },
    { src: require("../../../flag/brazil.png"), label: "Brazil" }, 
    { src: require("../../../flag/brunei.png"), label: "Brunei" },
    { src: require("../../../flag/bulgaria.png"), label: "Bulgaria" },
   
    { src: require("../../../flag/cambodia.png"), label: "Cambodia" },
    { src: require("../../../flag/canada.png"), label: "Canada" },  
    {
      src: require("../../../flag/chech-republic.png"),
      label: "Czech Republic",
    },
    { src: require("../../../flag/chile.png"), label: "Chile" },
    { src: require("../../../flag/china.png"), label: "China" },
    { src: require("../../../flag/colombia.png"), label: "Colombia" },   
    { src: require("../../../flag/costa-rica.png"), label: "Costa Rica" },
    { src: require("../../../flag/croatia.png"), label: "Croatia" },
    { src: require("../../../flag/cuba.png"), label: "Cuba" },
    { src: require("../../../flag/cyprus.png"), label: "Cyprus" },
    { src: require("../../../flag/denmark.png"), label: "Denmark" },
    { src: require("../../../flag/djibouti.png"), label: "Djibouti" },   
    { src: require("../../../flag/ecuador.png"), label: "Ecuador" },
    { src: require("../../../flag/egypt.png"), label: "Egypt" },   
    { src: require("../../../flag/europe.png"), label: "Europe" },   
    { src: require("../../../flag/england.png"), label: "England" },   
    { src: require("../../../flag/estonia-2.png"), label: "Estonia" },
    { src: require("../../../flag/ethiopia.png"), label: "Ethiopia" },  
    { src: require("../../../flag/finland.png"), label: "Finland" },
    { src: require("../../../flag/france-3.png"), label: "France" },
    { src: require("../../../flag/germany.png"), label: "Germany" },
    { src: require("../../../flag/ghana.png"), label: "Ghana" },
    { src: require("../../../flag/greece.png"), label: "Greece" },
    { src: require("../../../flag/guatemala.png"), label: "Guatemala" },
    { src: require("../../../flag/hong-kong.png"), label: "Hong Kong" },
    { src: require("../../../flag/hungary-3.png"), label: "Hungary" },
    { src: require("../../../flag/india.png"), label: "India" },
    { src: require("../../../flag/indonesia.png"), label: "Indonesia" },
    { src: require("../../../flag/iran.png"), label: "Iran" },
    { src: require("../../../flag/iraq.png"), label: "Iraq" },
    { src: require("../../../flag/ireland.png"), label: "Ireland" },
    { src: require("../../../flag/israel.png"), label: "Israel" },
    { src: require("../../../flag/italy.png"), label: "Italy" },
    { src: require("../../../flag/jamaica.png"), label: "Jamaica" },
    { src: require("../../../flag/japan.png"), label: "Japan" },
    { src: require("../../../flag/jordan-2.png"), label: "Jordan" },
    { src: require("../../../flag/kazakhstan.png"), label: "Kazakhstan" },
    { src: require("../../../flag/kenya.png"), label: "Kenya" },
    { src: require("../../../flag/kuwait.png"), label: "Kuwait" },
    { src: require("../../../flag/kyrgystan.png"), label: "Kyrgyzstan" },
    { src: require("../../../flag/latvia.png"), label: "Latvia" },
    { src: require("../../../flag/lebanon.png"), label: "Lebanon" },   
    { src: require("../../../flag/malaysia.png"), label: "Malaysia" },
    { src: require("../../../flag/maldives.png"), label: "Maldives" },
    { src: require("../../../flag/malta.png"), label: "Malta" },   
    { src: require("../../../flag/mauritius.png"), label: "Mauritius" },
    { src: require("../../../flag/mexico.png"), label: "Mexico" },
    { src: require("../../../flag/moldova.png"), label: "Moldova" },   
    { src: require("../../../flag/myanmar.png"), label: "Myanmar" },
    { src: require("../../../flag/namibia.png"), label: "Namibia" },
    { src: require("../../../flag/nepal.png"), label: "Nepal" },
    {
      src: require("../../../flag/netherlands-3.png"),
      label: "Netherlands",
    },   
    {
      src: require("../../../flag/new-zealand.png"),
      label: "New Zealand",
    },
    { src: require("../../../flag/nicaragua-2.png"), label: "Nicaragua" },
    { src: require("../../../flag/nigeria.png"), label: "Nigeria" },
    {
      src: require("../../../flag/north-korea.png"),
      label: "North Korea",
    },  
    { src: require("../../../flag/norway.png"), label: "Norway" },
    { src: require("../../../flag/oman.png"), label: "Oman" },
    { src: require("../../../flag/pakistan.png"), label: "Pakistan" },
    { src: require("../../../flag/panama.png"), label: "Panama" },   
    { src: require("../../../flag/paraguay.png"), label: "Paraguay" },
    { src: require("../../../flag/peru.png"), label: "Peru" },
    {
      src: require("../../../flag/philippines.png"),
      label: "Philippines",
    },
    { src: require("../../../flag/poland.png"), label: "Poland" },
    { src: require("../../../flag/portugal.png"), label: "Portugal" },   
    { src: require("../../../flag/qatar.png"), label: "Qatar" },
    { src: require("../../../flag/romania.png"), label: "Romania" },
    { src: require("../../../flag/russia.png"), label: "Russia" },   
    {
      src: require("../../../flag/saudi-arabia.png"),
      label: "Saudi Arabia",
    },
    { src: require("../../../flag/scotland.png"), label: "Scotland" },    
    { src: require("../../../flag/singapore.png"), label: "Singapore" },
    { src: require("../../../flag/slovenia.png"), label: "Slovenia" },
    {
      src: require("../../../flag/south-africa.png"),
      label: "South Africa",
    },
    {
      src: require("../../../flag/south-korea.png"),
      label: "South Korea",
    },   
    { src: require("../../../flag/spain.png"), label: "Spain" },
    { src: require("../../../flag/sri-lanka.png"), label: "Sri Lanka" },
    { src: require("../../../flag/sudan.png"), label: "Sudan" },
    { src: require("../../../flag/sweden.png"), label: "Sweden" },
    {
      src: require("../../../flag/switzerland.png"),
      label: "Switzerland",
    },
    { src: require("../../../flag/syria.png"), label: "Syria" },
    { src: require("../../../flag/taiwan.png"), label: "Taiwan" },
    { src: require("../../../flag/tanzania.png"), label: "Tanzania" },
    { src: require("../../../flag/thailand.png"), label: "Thailand" },   
    { src: require("../../../flag/uganda.png"), label: "Uganda" },
    { src: require("../../../flag/ukraine.png"), label: "Ukraine" },
    {
      src: require("../../../flag/united-arab-emirates.png"),
      label: "United Arab Emirates",
    },
    { src: require("../../../flag/uruguay.png"), label: "Uruguay" },
    { src: require("../../../flag/usa.png"), label: "United States" },
    { src: require("../../../flag/uzbekistan.png"), label: "Uzbekistan" },
    { src: require("../../../flag/venezuela.png"), label: "Venezuela" },
    { src: require("../../../flag/vietnam.png"), label: "Vietnam" },
    { src: require("../../../flag/yemen.png"), label: "Yemen" },
    { src: require("../../../flag/zimbabwe.png"), label: "Zimbabwe" },
  ];
  
  const countries = countriesData.map((country) => {
    return {
      ...country,
      value: country.label,
    };
  });
  
  export default countries;
  