import { StyleSheet } from "@react-pdf/renderer";

import { Table } from "./styles";

const styles = StyleSheet.create({
  Container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: 4,
  },
  Logo: {
    width: 100,
    height: 100,
  },
  Title: {
    fontSize: 28,
    alignSelf: "flex-start",
  },
  TitleBold: {
    fontSize: 28,
    fontWeight: "bold",
    textAlign: "center",
  },
  Section: {
    display: "flex",
    width: "100%",
  },
  Content: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  ContentNinety: {
    marginTop: 64,
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  TextContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "20%",
  },
  Subtitle: {
    fontSize: 24,
    fontWeight: "bold",
  },
  info: {
    textAlign: "center",
    fontWeight: "semibold",
    maxWidth: "80%",
    fontSize: 20,
  },
  infoMargin: {
    marginTop: 24,
    textAlign: "center",
    fontWeight: "semibold",
    maxWidth: "80%",
    fontSize: 20,
  },
  DocsInfo: {
    display: "flex",
    flexDirection: "column",
  },
});

export default function Content2({ company }) {
  const TradingPartnersExport = company?.exportTradingPartners.map(
    (partners) => ({ ...partners, role: "Supplier" })
  );
  const TradingPartnersImport = company?.importTradingPartners.map(
    (partners) => ({ ...partners, role: "Buyer" })
  );

  const TradingPartners = [...TradingPartnersExport, ...TradingPartnersImport];

  return (
    <div style={styles.Container}>
      <div
        style={{
          width: "100%",
        }}
      >
        <h3
          style={{
            color: "#4B4B4B",
            fontSize: 16,
          }}
        >
          Top Trade Partners - Suppliers & Buyers
        </h3>
        <span
          style={{
            color: "#A7A7A7",
            fontSize: 12,
          }}
        >
          {company?.companyName?.toUpperCase()}
        </span>
      </div>

      <Table colspan={3}>
        <tr>
          <th></th>
          <th>Company Name</th>
          <th>Country</th>
          <th>Role</th>
          <th>Market Share</th>
        </tr>
        {TradingPartners?.map((company, index) => (
          <>
            {index < 30 && (
              <tr key={company?.companyID}>
                <td>
                  <a
                    href={`https://app.tradesee.io/trade-data?companyName=${company.companyName}&companyId=${company.companyID}&country=${company.country}&role=Supplier`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#000" }}
                  >
                    {index + 1}
                  </a>
                </td>
                <td>
                  <a
                    href={`https://app.tradesee.io/trade-data?companyName=${company.companyName}&companyId=${company.companyID}&country=${company.country}&role=Supplier`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#000" }}
                  >
                    {company?.companyName?.toUpperCase()}{" "}
                  </a>
                </td>
                <td>
                  <a
                    href={`https://app.tradesee.io/trade-data?companyName=${company.companyName}&companyId=${company.companyID}&country=${company.country}&role=Supplier`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#000" }}
                  >
                    {company?.country?.toUpperCase()}{" "}
                  </a>
                </td>
                <td>
                  <a
                    href={`https://app.tradesee.io/trade-data?companyName=${company.companyName}&companyId=${company.companyID}&country=${company.country}&role=Supplier`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#000" }}
                  >
                    {company?.role?.toUpperCase()}{" "}
                  </a>
                </td>
                <td>
                  <a
                    href={`https://app.tradesee.io/trade-data?companyName=${company.companyName}&companyId=${company.companyID}&country=${company.country}&role=Supplier`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#000" }}
                  >
                    {company?.percentageShare?.toFixed(0)}%{" "}
                  </a>
                </td>
              </tr>
            )}
          </>
        ))}
      </Table>

      <p
        style={{
          fontSize: 12,
          maxWidth: "100%",
          background: "#F4F4F4",
          padding: 4,
          borderRadius: 4,
        }}
      >
        <strong>Top Trade Partners: </strong>
        {`Here are listed the main trading partners and their market share.`}
      </p>
    </div>
  );
}
