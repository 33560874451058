/* eslint-disable react/prop-types */
import React from "react";
import { StyleSheet } from "@react-pdf/renderer";

import { Table } from "./styles";

const styles = StyleSheet.create({
  Container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: 4,
  },
  Logo: {
    width: 100,
    height: 100,
  },
  Title: {
    fontSize: 20,
    alignSelf: "flex-start",
  },
  TitleBold: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  Section: {
    display: "flex",
    alignItems: "center",
    width: "90%",
  },
  Content: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  ContentNinety: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  TextContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "20%",
  },
  Subtitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  info: {
    textAlign: "center",
    fontWeight: "semibold",
    maxWidth: "80%",
    fontSize: 20,
  },
  infoMargin: {
    marginTop: 24,
    textAlign: "center",
    fontWeight: "semibold",
    maxWidth: "80%",
    fontSize: 16,
  },
  DocsInfo: {
    display: "flex",
    flexDirection: "column",
  },
});

// Create Document Component
export default function Content({ role, company }) {
  return (
    <div style={styles.Container}>
      <div
        style={{
          width: "100%",
        }}
      >
        <h3
          style={{
            color: "#4B4B4B",
            fontSize: 16,
          }}
        >
          Top Ports - Lading & Unlading
        </h3>
        <span
          style={{
            color: "#A7A7A7",
            fontSize: 12,
          }}
        >
          {company?.companyName?.toUpperCase()}
        </span>
      </div>

      <Table colspan={3}>
        <tr>
          <th style={{ borderLeft: "1px solid #ccc" }}></th>
          <th>Name</th>
          <th style={{ borderRight: "1px solid #ccc" }}>Operation</th>
          <th style={{ borderRight: "1px solid #ccc" }}>Market share</th>
        </tr>

        {company?.portsOfLading?.map((company, index) => (
          <>
            {
              <tr key={company.companyID}>
                <td style={{ borderLeft: "1px solid #ccc" }}>{index + 1}</td>
                <td>{company.port}</td>
                <td style={{ borderRight: "1px solid #ccc" }}>Lading</td>
                <td style={{ borderRight: "1px solid #ccc" }}>
                  {company.percentageShare.toFixed(0)}%
                </td>
              </tr>
            }
          </>
        ))}
        {company?.portsOfUnlading?.map((company, index) => (
          <>
            {
              <tr key={company.companyID}>
                <td style={{ borderLeft: "1px solid #ccc" }}>{index + 1}</td>
                <td>{company.port}</td>
                <td style={{ borderRight: "1px solid #ccc" }}>Unlading</td>
                <td style={{ borderRight: "1px solid #ccc" }}>
                  {company.percentageShare.toFixed(0)}%
                </td>
              </tr>
            }
          </>
        ))}
      </Table>

      <p
        style={{
          fontSize: 12,
          maxWidth: "100%",
          background: "#F4F4F4",
          padding: 4,
          borderRadius: 4,
        }}
      >
        <strong>TOP PORTS: </strong> The ports used by the company. The criteria
        can be based on the company's location or their distribution center,
        federal or state taxes and partnership with warehouse and international
        traders.
      </p>
    </div>
  );
}
