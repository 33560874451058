import { Grid } from "@mui/material";
import React, { useMemo, useState } from "react";
import Drawer from "../../components/Drawer";
import { authScreen } from "../../contexts/auth";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../components/LoadingPage";

export default function MetalPriceDashboard() {

  const [toggleDrawer, setToggleDawer] = useState(false);
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();

  useMemo(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 500);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    });
  },[navigate]);

  const handleToggle = () => {
    setToggleDawer(!toggleDrawer);
  }

  return(
    <>
    {auth ? (
      <>
      <Grid
          item
          xs={toggleDrawer ? 2 : 1}
          sm={toggleDrawer ? 2 : 1}
          md={toggleDrawer ? 3 : 1}
          lg={toggleDrawer ? 2 : 0.5}
          style={{ margin: 0, zIndex: 2}}
        >
          <Drawer handleToggle={handleToggle} initState={toggleDrawer} />
        </Grid>

        <Grid
          item
          xs={toggleDrawer ? 10 : 11}
          sm={toggleDrawer ? 10 : 11}
          md={toggleDrawer ? 9 : 11}
          lg={toggleDrawer ? 10 : 11}
          style={{
            height: "100%",
            padding: "0% 1% 0% 3%",
          }}
        >
        </Grid>
      </>
      ) : 
      (<LoadingPage />)}
    </>
  )
} 