import React, { useState } from "react";
import { TextDefault } from "../../assets/styles";
import { HeaderFilter } from "./styles";
import { message } from "antd";
import { BillingButton } from "../billing/style";
import axios from "axios";
import Select from "react-select";
import { ButtonContainer, CheckoutButton } from "../billing/credits/styles";
import ProgressPayment from "../../components/ProgressPayment";
import { addCredit, historyCredits } from "../../hooks/credits";

export default function CheckoutCredits({
  credits,
  cards,
  info,
  address,
  pay,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardCVC, setCardCVC] = useState("");
  const [idInvoice, setIdInvoice] = useState("");
  const [idPayment, setIdPayment] = useState("");

  const formattedDate = new Date().toISOString();
  const infoC = {
    text: `You purchased a credit package`,
    type: "increase",
    date: formattedDate,
    credits: credits,
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);

    event.preventDefault();

    const expMonth = cardExpiry.substring(0, 2);
    const expYear = cardExpiry.substring(2, 6);

    const cardData = {
      customerId: info.customerId,
      currency: "USD",
      amount: pay,
      customerEmail: info.email,
      context: {
        mobile: "false",
        isEcommerce: "true",
      },
      card: {
        number: cardNumber,
        expMonth,
        expYear,
        name: cardName,
        cvc: cardCVC,
        address: {
          postalCode: address?.postalCode,
          country: "US",
          region: address?.state,
          streetAddress: address?.line1,
          city: address?.city,
        },
      },
    };

    try {
      const response = await axios.post(
        "https://api.tsalemetals.com:4578/charge-payment",
        cardData,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        addCredit(info.userId, credits);
        historyCredits(infoC, info.userId);
        const infoSend = {
          invoiceId: response.data.idInvoice,
          sendTo: info.email,
        };
        const infoSendPayment = {
          paymentId: response.data.idPayment,
          email: info.email,
        };

        const response2 = await axios.post(
          "https://api.tsalemetals.com:4578/send-invoice",
          infoSend,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const response3 = await axios.post(
          "https://api.tsalemetals.com:4578/send-payment",
          infoSendPayment,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setIsOk(true);

        setIdInvoice(response.data.idInvoice);
        setIdPayment(response.data.idPayment);
        if (response2.status === 200) {
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("Erro ao enviar token para o servidor:", error);
      let errorMessage = `Error, ${error}`;
      message.error(errorMessage);
      setIsLoading(false);
    }
  };

  const handleCardExpiryChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 6) value = value.slice(0, 6);
    setCardExpiry(value);
  };

  const formatCardExpiry = (value) => {
    if (value.length > 2) {
      return `${value.slice(0, 2)} / ${value.slice(2)}`;
    }
    return value;
  };

  const payPlan = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    const cardData = {
      customerId: info.customerId,
      customerEmail: info.email,
      name: info.name,
      currency: "USD",
      amount: pay,
      context: {
        mobile: "false",
        isEcommerce: "true",
      },
      card: {
        id: selectedPaymentMethod,
      },
    };

    try {
      const response = await axios.post(
        "https://api.tsalemetals.com:4578/charge-saved-card",
        cardData,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        addCredit(info.userId, credits);
        historyCredits(infoC, info.userId);
        setIsOk(true);
        setIdInvoice(response.data.idInvoice);
        setIdPayment(response.data.idPayment);
        const infoSend = {
          invoiceId: response.data.idInvoice,
          sendTo: info.email,
        };
        const infoSendPayment = {
          paymentId: response.data.idPayment,
          email: info.email,
        };

        const response2 = await axios.post(
          "https://api.tsalemetals.com:4578/send-invoice",
          infoSend,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const response3 = await axios.post(
          "https://api.tsalemetals.com:4578/send-payment",
          infoSendPayment,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );


        if (response2.status === 200) {
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("Erro ao enviar token para o servidor:", error);
      let errorMessage = `Error, ${error}`;
      message.error(errorMessage);
      setIsLoading(false);
    }
  };

  const handleReturn = () => {
    setSelectedPaymentMethod(null);
  };

  return (
    <>
      {isLoading ? (
        <>
          <br />
          <ProgressPayment finish={isOk} />
        </>
      ) : (
        <>
          <br />
          <TextDefault color={"#002D68"}> Select saved card </TextDefault>

          <Select
            placeholder="Select payment method"
            className="basic-select"
            classNamePrefix="Select payment method"
            name="Cards"
            onChange={(selectedOption) =>
              setSelectedPaymentMethod(selectedOption.value)
            }
            options={cards.map((card) => ({
              value: card.id,
              label: `${card.cardType} - ${card.number}`,
            }))}
            isSearchable
          />

          <TextDefault color={"#002D68"}>
            {" "}
            Pay with another Credit card{" "}
          </TextDefault>
          {selectedPaymentMethod ? (
            <>
              <ButtonContainer>
                <CheckoutButton onClick={handleReturn}>Back</CheckoutButton>
                <CheckoutButton onClick={payPlan}>Pay now</CheckoutButton>
              </ButtonContainer>
            </>
          ) : (
            <div className="card-form">
              <HeaderFilter>
                <input
                  placeholder="Card Number"
                  autoComplete="cc-number"
                  name="cardNumber"
                  required
                  autoFocus
                  onChange={(e) => setCardNumber(e.target.value)}
                />
                <input
                  type="tel"
                  placeholder="MM / YYYY"
                  autoComplete="cc-exp"
                  name="cardExpiry"
                  required
                  value={formatCardExpiry(cardExpiry)}
                  onChange={handleCardExpiryChange}
                />
                <br />
                <input
                  type="tel"
                  placeholder="CVC"
                  autoComplete="cc-csc"
                  name="cardCVC"
                  required
                  autoFocus
                  onChange={(e) => setCardCVC(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Full name on card"
                  onChange={(e) => setCardName(e.target.value)}
                />
              </HeaderFilter>
              <BillingButton onClick={handleSubmit}>Pay now</BillingButton>
            </div>
          )}
        </>
      )}
    </>
  );
}
