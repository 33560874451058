import React from "react";
import CSV from "../../../icons/csv.png";


export default function ExcelExport({ excel }) {
  return (
    <div style={{ position: "absolute", right: 200, top: -40 }}>
      <ul className="wrapper">      
        <li className="icon twitter" onClick={excel}>
          <span className="tooltip">Excel</span>
          <span>
            <i className="fab fa-twitter"></i>
          </span>
          <img src={CSV} style={{ width: 25, height: 25 }} alt="Excel Icon" />
        </li>         
      </ul>
    </div>
  );
}
