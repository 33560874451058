import axios from "axios";
import { APITSALE_KEY } from "./key";
import { saveRequestApi } from "../hooks/logFirebase";

// export default function ShipmentsCompanyHS(
//   hsCodes,
//   country,
//   role,
//   countryTrader,
//   fromDate,
//   toDate,
// ) {
//   return new Promise((resolve, reject) => {
//     const limit = 10; 

//     let body = {
//       hsCodes,
//       country,
//       role,
//       limit,     
//       ...(countryTrader && countryTrader.length > 0 ? { countryTrader } : {}),
//       ...(fromDate ? { fromDate } : {}),
//       ...(toDate ? { toDate } : {}),
//     };

//     const header = {
//       headers: {
//         Authorization: "Bearer " + APITSALE_KEY.key,
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//       },
//     };

//     const requestData = {
//       api: "shipmentsByHs",
//       searchQuery: `${hsCodes} - ${country} - ${role}`,
//       creditsUsed: 1,
//       status: null,
//     };

    
//     axios
//       .post("https://api.tsalemetals.com/t-api/v1/shipmentsByHs", body, header)
//       .then((response) => {
//         resolve(response.data);
//       })
//       .catch((error) => {
//         console.log(error);
//         reject("failed");
//       });
//   });
// }

export default async function ShipmentsCompanyHS(
  hsCodes,
  country,
  role,
  countryTrader,
  fromDate,
  toDate,
  productKeywords,
  limit
) {

  let body = {
    ...(hsCodes && hsCodes.length > 0 ? { hsCodes } : {}), 
    country,
    role,
    ...(productKeywords && productKeywords.length > 0 ? { productKeywords } : {}), 
    limit,
    ...(countryTrader && countryTrader.length > 0 ? { countryTrader } : {}),
    ...(fromDate ? { fromDate } : {}),
    ...(toDate ? { toDate } : {}),
  };

  const header = {
    headers: {
      Authorization: "Bearer " + APITSALE_KEY.key,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  const requestData = {
    api: "/shipmentsByHs",
    searchQuery: `${hsCodes} - ${country} - ${role}`,
    creditsUsed: 1,
    status: null,
  };

  try {
    const response = await axios.post("https://api.tsalemetals.com/t-api/v1/shipmentsByHs", body, header);
    requestData.status = response.data.shipment && response.data.shipment.length > 0 ? 200 : "No cust";
    await saveRequestApi(requestData); 
    return response.data; 
  } catch (error) {
    requestData.status = error.response ? error.response.status : "failed"; 
    await saveRequestApi(requestData);
    throw error;
  }
}

export async function GetShipments(
  countrySel,
  supplierOrBuyer,
  hsCode,
  selectedPeriod
) {
  return new Promise((resolve, reject) => {
    const resumType = supplierOrBuyer === "Buyer" ? "import" : "export"; 

    const body = JSON.stringify({
      countryName: countrySel,
      type: resumType,
      hsCode,
      searchType: "hsCode",
      period: selectedPeriod,
    });

    const header = {
      headers: {
        Authorization: "Bearer " + APITSALE_KEY.key,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("https://api.tsalemetals.com:1478/scrape", body, header)
      .then((response) => {
        
        console.log("DADOS: response.data")
        console.log(response.data)
        resolve(response.data);


        
        })
        .catch((error) => {
        console.log("DADOS: ERROR")
        console.log(error);
        reject("failed");
      });
  });
}

export async function fetchCompaniesByName(
  companyName,
  country,
) {
  return new Promise((resolve, reject) => {
    let body = {
      companyName,    
      country,
    };

    const header = {
      headers: {
        Authorization: "Bearer " + APITSALE_KEY.key,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("https://api.tsalemetals.com/t-api/v1/company-matcher", body, header)
      .then((response) => {
        resolve(response.data.companies);
      })
      .catch((error) => {
        console.log(error);
        reject("failed");
      });
  });
}
