import React, { useContext } from "react";
import {
  ImgDefault,
  TextDefault,
} from "../../../assets/styles";
import { ThemeContext } from "styled-components";

export default function CardModulev2({ ...props }) {
  const theme = useContext(ThemeContext);

  return (
    <article className="article-wrapper" onClick={props.click} style={{ margin: "3% 1% 1% 1%" }}>
      <div className="rounded-lg container-project">
        <ImgDefault src={props.img}  style={{margin: "0% 2%"}}/>
          <TextDefault
            style={{ marginTop: 5 }}
            size="80%"
            bold="400"
            color="#fff"
            className="textRespon"
          >
            {props.subTitle}
          </TextDefault>
      </div>
      <div className="project-info">
        <div className="flex-pr">
          <div className="project-title text-nowrap">{props.title}</div>
          <div className="project-hover">
            <svg
              style={{ color: theme.colors.main[500] }}
              xmlns="http://www.w3.org/2000/svg"
              width="2em"
              height="2em"
              color="black"
              strokeLinejoin="round"
              strokeLinecap="round"
              viewBox="0 0 20 20"
              strokeWidth="2"
              fill="none"
              stroke="currentColor"
            >
              <line y2="12" x2="19" y1="12" x1="5"></line>
              <polyline points="12 5 19 12 12 19"></polyline>
            </svg>
          </div>
        </div>
      </div>
    </article>
  );
}
