/* eslint-disable react/prop-types */
import { StyleSheet } from "@react-pdf/renderer";
import { Table } from "./styles";
import useFNP, { TradeDataContext } from "../../../../../../useFNP";
import { useCallback, useContext, useMemo } from "react";

const styles = StyleSheet.create({
  Container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: 4,
  },
  Logo: {
    width: 100,
    height: 100,
  },
  Title: {
    fontSize: 28,
    alignSelf: "flex-start",
  },
  TitleBold: {
    fontSize: 28,
    fontWeight: "bold",
    textAlign: "center",
  },
  Section: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
  },
  Content: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },

  TextContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "20%",
  },
  Subtitle: {
    fontSize: 24,
    fontWeight: "bold",
  },
  info: {
    textAlign: "center",
    fontWeight: "semibold",
    maxWidth: "80%",
    fontSize: 20,
  },
  infoMargin: {
    textAlign: "center",
    fontWeight: "semibold",
    maxWidth: "80%",
    fontSize: 20,
  },
  DocsInfo: {
    display: "flex",
    flexDirection: "column",
  },
});

// Create Document Component
export default function Content({ company }) {
  const { allHsCodes } = useContext(TradeDataContext);

  const handleGetHsName = useCallback(
    (code) => allHsCodes.filter((hs) => hs.hsCode === code)[0]?.hsName,
    [allHsCodes]
  );

  
  const selectedHsCodesExport = company?.exported6DigitHsCodes?.map((hs) => ({
    ...hs,
    role: "Import",
  }));
  const selectedHsCodesImport = company?.imported6DigitHsCodes?.map((hs) => ({
    ...hs,
    role: "Export",
  }));

  const selectedHsCodes = [...selectedHsCodesExport, ...selectedHsCodesImport];

  return (
    <div style={styles.Container}>
      <div
        style={{
          width: "100%",
        }}
      >
        <h3
          style={{
            color: "#4B4B4B",
            fontSize: 16,
          }}
        >
          Top Hs Codes
        </h3>
        <span
          style={{
            color: "#A7A7A7",
            fontSize: 12,
          }}
        >
          {company?.companyName?.toUpperCase()}
        </span>
      </div>

      <Table colspan={3}>
        <tr>
          <th style={{ borderLeft: "1px solid #ccc" }}></th>
          <th>Hs Code</th>
          <th>Hs Name</th>
          <th style={{ borderRight: "1px solid #ccc" }}>Operation</th>
        </tr>
        {selectedHsCodes?.length > 0 &&
          selectedHsCodes?.map((code, index) => (
            <>
              {index < 20 && (
                <tr key={code.key}>
                  <td style={{ borderLeft: "1px solid #ccc" }}>{index + 1}</td>
                  <td>{code?.hscode}</td>
                  <td>{handleGetHsName(code?.hscode?.slice(0, 4))}</td>

                  <td style={{ borderRight: "1px solid #ccc" }}>
                    {code?.role}
                  </td>
                </tr>
              )}
            </>
          ))}
      </Table>

      <p
        style={{
          fontSize: 12,
          maxWidth: "100%",
          background: "#F4F4F4",
          padding: 4,
          borderRadius: 4,
        }}
      >
        <strong>Top Hs Code - Import & Export: </strong>Here are listed the
        company's imports by HS code in the last 12 months, the chart is
        presented to the most recent to the least recent processes.
      </p>
    </div>
  );
}
