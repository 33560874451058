import React, { useEffect, useRef, useState } from "react";
import { RowContainer, CloseAssistant, ImgDefault, ChatAssistant, HeaderAssistant, ProfileAI, TextDefault, ColumnContainer, SubHeaderAssistant, GroupInput, ImgOpt, InputDefault, PanelChat, Balloon, SetOptionChat, BtnDefault, BtnPresets } from "../assets/styles";
import AICON from "../icons/chatAI.png"
import BotAI from "../icons/ai.png"
import Close from "../icons/close.png"
import SendIcon from "../icons/sendChat.png"
import { Flex } from "antd";
import chatGPT from "../service/chatGPT";

export default function Assistent() {

    const [panel, setPanel] = useState(true)
    const [message, setMessage] = useState("")
    const [chat, setChat] = useState([])
    const bottomElementRef = useRef(null);


    const handlerChat = () => {
        setPanel(!panel)
    }
    const validEnter = (event) => {
        if (event.key === 'Enter') {
            sendMessage();
        }      
    }


    useEffect(() => {
        console.log("REF")
        console.log(bottomElementRef)
        if(bottomElementRef.current) {
            const element = bottomElementRef.current;
            element.scrollTop = element.scrollHeight;
        }
    }, [chat]);


    const sendMessage = (nMsg) => {

        const id = chat.length +1;
        const msg = nMsg ? {id, message: nMsg, hrForm: getHrNow(), dateForm: getCurrentDate(), date: new Date(), toFrom: 0} : {id, message, hrForm: getHrNow(), dateForm: getCurrentDate(), date: new Date(), toFrom: 0}
        setChat([...chat, msg])        
        setMessage("")
        console.log("msg")
        console.log(chat)
        
        chatGPT(nMsg ? nMsg : message).then(resp => {
            const msg2 = {id: id+1, message: resp, hrForm: getHrNow(), dateForm: getCurrentDate(), date: new Date(), toFrom: 1}        
            setChat([...chat, msg, msg2])
            console.log("GPT")
            console.log(resp)
        })
        .catch(e => console.log(e))
    }

    function getHrNow(){
        const data = new Date();
        let horas = data.getHours();
        let minutos = data.getMinutes();
        let periodo = horas >= 12 ? 'PM' : 'AM';
    
        horas = horas % 12 || 12; // Converter para formato de 12 horas
        minutos = minutos < 10 ? '0' + minutos : minutos; // Adicionar um zero à esquerda, se necessário
        return `${horas}:${minutos} ${periodo}`;
    }
    
    function getCurrentDate() {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = months[currentDate.getMonth()];
        const year = currentDate.getFullYear();
        
        return `${month} ${day}, ${year}`;
      }
      
    
 
    return(        
        <RowContainer style={{zIndex: 999999}}>
            {panel ? (
                <CloseAssistant onClick={handlerChat}>
                    <ImgDefault src={AICON} width="30px" height="30px"/>
                </CloseAssistant>
            ) : (
                <ChatAssistant>
                    <ColumnContainer>
                        <HeaderAssistant>
                            <ProfileAI>
                                <ImgDefault src={BotAI} width="45px" height="45px"/>
                            </ProfileAI>
                            <ColumnContainer style={{marginLeft: 10}}>
                                <TextDefault size="20px" color="#fff" bold="700">TradeSee</TextDefault>
                                <TextDefault size="15px" color="#fff" bold="500" style={{opacity: 0.7}}>Assistant</TextDefault>
                            </ColumnContainer>
                            <ImgDefault className="CloseAssistantBtn" style={{position: "absolute", right: 10, top: 10}} width="15px" height="15px" src={Close} onClick={handlerChat}/>
                        </HeaderAssistant>
                        <SubHeaderAssistant/>
                        
                        {chat.length == 0 ? (
                            <RowContainer>
                                <SetOptionChat>
                                    <BtnPresets onClick={() => sendMessage("Schedules")}>
                                        <TextDefault size="13px" color="#fff" bold="800">Schedules</TextDefault>
                                    </BtnPresets>
                                    <BtnPresets onClick={() => sendMessage("About the company")}>
                                        <TextDefault size="13px" color="#fff" bold="800">About the company</TextDefault>
                                    </BtnPresets>
                                    <BtnPresets onClick={() => sendMessage("Feedback")}>
                                        <TextDefault size="13px" color="#fff" bold="800">Feedback</TextDefault>
                                    </BtnPresets>
                                    <BtnPresets onClick={() => sendMessage("About my plan")}>
                                        <TextDefault size="13px" color="#fff" bold="800">About my plan</TextDefault>
                                    </BtnPresets>
                                    <BtnPresets onClick={() => sendMessage("Support")}>
                                        <TextDefault size="13px" color="#fff" bold="800">Support</TextDefault>
                                    </BtnPresets>
                                </SetOptionChat>
                            </RowContainer>
                        ) : ""}

                        <ColumnContainer>
                            <PanelChat ref={bottomElementRef} style={{height: chat.length == 0 ? 255 : 355}}>
                                    {chat.map((item) => (
                                        <div style={{display: "flex", justifyContent: item.toFrom == 0 ? "flex-end" : "flex-start", width: "100%"}}>
                                            <Balloon toFrom={item.toFrom}>
                                                <ColumnContainer>
                                                    <TextDefault size="13px" color="#4b4b4b" style={{textAlign: item.toFrom == 0 ? "right": "left", lineHeight: 1.4}}>{item.message}</TextDefault>
                                                    <TextDefault size="9px" color="#8a97aa" bold="300" style={{textAlign: item.toFrom == 0 ? "right" : "left", opacity: 0.7, marginTop: 5}}>{item.hrForm}</TextDefault>
                                                </ColumnContainer>
                                            </Balloon>
                                        </div>                                  
                                    ))}
                            </PanelChat>
                        
                        </ColumnContainer>

                        
                        <RowContainer style={{paddingLeft: 10, paddingRight: 10, bottom: 0}}>                            
                            <GroupInput className="groupInputSale">
                                <InputDefault
                                    className="inputSale"
                                    placeholder="Type your message"
                                    type="message"
                                    id="message"
                                    value={message}
                                    style={{ paddingLeft: 10, paddingRight: 45, overflow: "hidden" }}
                                    onChange={(item) => setMessage(item.target.value)}
                                    onKeyDown={validEnter}
                                />
                                <ImgOpt className="iconInputHide SendChatAssistant" src={SendIcon} onClick={() => sendMessage("")}/>
                            </GroupInput>
                        </RowContainer>
                        
                    </ColumnContainer>                 
                </ChatAssistant>
            )}
        </RowContainer>
    )
}