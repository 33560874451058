import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, Modal } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { authScreen } from "../../contexts/auth";
import CompanyMapperInfo from "./utils/mapper";
import {
  BackgroundClose,
  BarProgress,
  BtnDefault,
  ColumnContainer,
  ContainerHome,
  ImgDefault,
  RowContainer,
  StatesProgress,
  StatesProgressFixed,
  TextDefault,
} from "../../assets/styles";
import LoadingPage from "../../components/LoadingPage";
import Drawer from "../../components/Drawer";
import Partner from "./components/Partner";
import { getPromotions } from "../../hooks/notifications";
import getUserInfo, {
  addCustomerIDForUser,
  getEndPlanUser,
  getHistoryCredits,
} from "../../hooks/getUsers";
import { getCompanieFavorite } from "../../hooks/findNewPartner";
import { useNews } from "../../hooks/getNews";
import { getFavoriteMetals } from "../../hooks/metalPrice";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import {
  BordaLeft,
  ContainerFirstLogin,
  ContainerSeachHome,
  IndFinance,
  NotificationBtn,
  Notifications,
  TagNotification,
} from "./styles";
import "swiper/css";
import SearchInput from "./components/SearchInput";
import ZoomIcon from "../../icons/zoomWhite.png";
import WebIcon from "../../icons/webWhite.png";
import UserIcon from "../../icons/userWhite.png";
import CardModulev2 from "./components/CardModulev2";
import SearchList from "./components/SearchInput/SearchList";
import HeaderSearchModal from "./components/SearchInput/HeaderSearchModal";
import RecentSearch from "./components/SearchInput/RecentSearch";
import { useLme } from "../../hooks/getLME";
import NotificationPainel from "./components/NotificationPainel";
import WelcomeBanner from "../../icons/welcomeBanner.png";
import { AttFirstLogin, getFirstLogin } from "../../hooks/welcomeControl";
import { Alert, Col, message, Row } from "antd";
import axios from "axios";
import Assistant from "../../components/Assistant";
import arrowRight from "../../icons/next-v2.png"
import { pushMyNotification } from "../../hooks/myNotification";
import { addCredit, deleteCredit, registerAdd, registerPlan, viewAddMonthly, viewCredit, viewEndDatePlan, viewPlanMonthly } from "../../hooks/credits";
import EmptyIcon from "../../icons/empty-box.png"

export default function Home() {
  const [isLimitModalVisible, setIsLimitModalVisible] = useState(false);
  const [auth, setAuth] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [loadingg, setLoadingg] = useState(true);
  const [companies, setCompanies] = useState("");
  const [toggleDrawer, setToggleDawer] = useState(true);
  const [favoriteMetals, setFavoriteMetals] = useState([]);
  const [allNews, setAllNews] = useState([]);
  const [search, setSearch] = useState("");
  const [isModalNotification, setIsModalNotification] = useState(false);
  const [isNewNotification, setIsNewNotification] = useState(true);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [isUsage, setIsUsage] = useState(0);
  const [yourPlan, setYourPlan] = useState(0);
  const [yourCredits, setYourCredits] = useState(0);
  const [credits, setCredits] = useState(null);

  const [promotions, setPromotions] = useState([]);

  const [isTradeDataCredit, setIsTradeDataCredit] = useState(0);
  const [isMarketCredit, setIsMarketCredit] = useState(0);
  const [isLeadsCredit, setIsLeadsCredit] = useState(0);

  const [isTradeDataPorcent, setIsTradeDataPorcent] = useState(0);
  const [isMarketCreditPorcent, setIsMarketPorcent] = useState(0);
  const [isLeadsCreditPorcent, setIsLeadsPorcent] = useState(0);

  const [isTradeDataIndex, setIsTradeDataIndex] = useState(0);
  const [isMarketCreditIndex, setIsMarketIndex] = useState(0);
  const [isLeadsCreditIndex, setIsLeadsIndex] = useState(0);
  const [monthOldCredit, setMonthOldCredit] = useState(0);
  const [monthOldPercent, setMonthOldPercent] = useState(0);

  const { newsData, loading } = useNews();
  const windowWidth = useWindowWidth();
  const { lmeData } = useLme();
  const [endPlan, setEndPlan] = useState(false);
  const currentDate = new Date();
  const TitlePage = "Home";
  document.title = TitlePage;
  function indicatorRed(ind) {
    setIsNewNotification(ind);
  }

  function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0; // convertendo p um inteiro de 32 bits
    }
    return hash;
  }

  useEffect(() => {

    const getAsync = async () => {
      if (userInfo) {

        //Quantidade de créditos
        const userCredits = parseInt(await viewCredit(userInfo?.uid));
        //Plano
        const userPlan = parseInt(await viewPlanMonthly(userInfo?.uid));
        //Adicional
        const userAdd = parseInt(await viewAddMonthly(userInfo?.uid));
        //Data de Encerramento
        const dateEndPlan = await viewEndDatePlan(userInfo?.uid) 

        const dateNow = new Date()
        var monthNow = dateNow.getMonth() + 1;

        getHistoryCredits(userInfo?.uid).then(resp => {
          if(resp) {
            //Filtra os registro do mês atual
            const objMonthNow = Object.values(resp).filter(obj => {
              const mesObjeto = new Date(obj.date).getMonth() + 1; // Obtém o mês do objeto
              return mesObjeto === monthNow;
            })
            //Filtra os registro do mês atual
            const objMonthOld = Object.values(resp).filter(obj => {
              const mesObjeto = new Date(obj.date).getMonth() + 1; // Obtém o mês do objeto
              return mesObjeto === monthNow-1;
            })        
  
            let tradeDataCount = 0;
            let marketCount = 0;
            let leadsCount = 0;
            let monthOld = 0;
  
            objMonthOld.forEach(obj => {
              monthOld += parseInt(obj.credits)
            })
  
            if(userPlan+userAdd >= monthOld) {
              setMonthOldPercent(parseInt((monthOld * 100) / (userPlan+userAdd)))
            } else {
              setMonthOldPercent(100)
            }
  
            setMonthOldCredit(monthOld)
            
            objMonthNow.forEach(obj => {
              if(obj.text.toLowerCase().indexOf("trade data") > -1) {
                //Consumo do Trade Data em objetos
                tradeDataCount += parseInt(obj.credits)
                
              } else if(obj.text.toLowerCase().indexOf("market intelligence") > -1) {
                //Consumo do Market Intelligence em objetos
                marketCount += parseInt(obj.credits)
              } else if (obj.text.toLowerCase().indexOf("leads enrichment") > -1) {
                //Consumo do Leads em objetos
                leadsCount += parseInt(obj.credits)
              }
              
            })

            setIsTradeDataCredit(tradeDataCount)
            setIsMarketCredit(marketCount)
            setIsLeadsCredit(leadsCount)
  
            //Maior 100% = index 2
            //Meio = index 3
            //Menor = index 4
            
            let valuesObj = [
              {
                name: "market",
                value: marketCount
              },
              {
                name: "leads",
                value: leadsCount
              },
              {
                name: "tradeData",
                value: tradeDataCount
              },
            ]
  
            valuesObj.sort((a, b) => b.value - a.value);
  
            valuesObj.forEach((obj, index) => {
              
              const porcent = (obj.value * 100) / valuesObj[0].value
              if(obj.name == "tradeData") {
                if(index == 0) {
                  setIsTradeDataPorcent(100)
                  setIsTradeDataIndex(2)          
                } else {
                  setIsTradeDataPorcent(porcent)
                  index == 1 ? setIsTradeDataIndex(3) : setIsTradeDataIndex(4) 
                } 
              } else if (obj.name == "market") {
                if(index == 0) {
                  setIsMarketPorcent(100)              
                  setIsMarketIndex(2)                  
                } else {
                  setIsMarketPorcent(porcent)
                  index == 1 ? setIsMarketIndex(3) : setIsMarketIndex(4)
                }
              } else {
                if(index == 0) {
                  setIsLeadsPorcent(100)    
                  setIsLeadsIndex(2)    
                } else {
                  setIsLeadsPorcent(porcent)
                  index == 1 ? setIsLeadsIndex(3) : setIsLeadsIndex(4)
                }
              }          
            })
          }           

        })

        const fetchCredits = async () => {
          try {           

            if(userPlan == 0) {            
              await registerAdd(userInfo?.uid, 0);
              await deleteCredit(userInfo?.uid, userCredits);
            } else {

              const dataAtual = new Date();
              const dataInformada = new Date(dateEndPlan);

              if (dataInformada < dataAtual) {
                //A data informada é anterior à data atual
                //Vencida
                await registerPlan(userInfo?.uid, 0);
                await registerAdd(userInfo?.uid, 0);
                await deleteCredit(userInfo?.uid, userCredits);

                setYourPlan(0);
                setYourCredits(0);

              } else if (dataInformada >= dataAtual) {
                //A data informada é superior à data atual
                //Em vigor
                //Validar irregulariedade
                setYourPlan(userPlan + userAdd);
                setYourCredits(userCredits);

                
                if (userPlan + userAdd >= userCredits) {
                  let percent = (userCredits / (userPlan + userAdd)) * 100;
                  let usagePercent = 100 - percent;
                  const intPercent = parseInt(usagePercent);
      
                  setIsUsage(intPercent);

                  const text = "Attention, the limit of your plan is approaching!";
                  const type = "update";
                  const description =
                    "Your plan consumption is already reaching its limit, upgrade your account or purchase additional calls to your plan.";
                  const route = "/credits";
                  const status = "approved";
                  const dateNow = new Date();
                  const created_at = dateNow;
      
                  if (intPercent >= 75) {
                    const id = hashCode(`${text}${type}${status}${created_at}`)
                      .toString(16)
                      .substr(0, 5);
                      
                    pushMyNotification({
                      id,
                      text,
                      type,
                      description,
                      route,
                      status,
                      created_at,
                    });
                  }
                } else {
                  //Alterar os créditos para os planos e adicionais em vigor                
                  if(userCredits) {
                    await deleteCredit(userInfo?.uid, userCredits);
                    await addCredit(userInfo?.uid, userPlan+userAdd)
                  }

                  setYourPlan(userPlan);
                  setYourCredits(userPlan+userAdd);
                }
              }
            }
            setCredits(userCredits);
          } catch (error) {
            console.error("Erro ao buscar os créditos do usuário:", error);
          }
        };

        fetchCredits();
      }
    }

    getAsync()
  }, [userInfo]);

  const navigate = useNavigate();
  
  useMemo(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 500);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    });
  },[navigate]);

  useCallback(() => {
    if(userInfo){
      getPromotions()
      .then((dados) => {
        const promotionsArray = Object.values(dados);
        setPromotions(promotionsArray);
      })
      .catch((error) => {
        console.error("Erro ao verificar os dados de promotions:", error);
      });
  
      getFavoriteMetals(userInfo?.uid)
      .then((res) => {
        const favMetals = Object.keys(res).map((key) => res[key].data);
        setFavoriteMetals(favMetals);
      })
      .catch((err) => console.log("Error to fetch metals:", err));
    }
  }, [userInfo]);

  const closeFirstLogin = () => {
    setIsFirstLogin(false);
    AttFirstLogin();
  };

  const closeModal = (modal) => {
    if (modal == "login") {
      setIsFirstLogin(false);
    } else {
      setIsLimitModalVisible(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      const newsArray = newsData ? Object.values(newsData) : [];
      setAllNews(newsArray);
    }
    
    if (search) { 
      setIsLimitModalVisible(true)
    }; 

  }, [loading, newsData, search]);

  async function createCustomer(userInfo) {
    const customerData = {
      DisplayName: userInfo?.userData?.name,
      MiddleName: userInfo?.userData?.lastName,
      PrimaryEmailAddr: {
        Address: userInfo?.email,
      },
      PrimaryPhone: {
        FreeFormNumber: userInfo?.userData?.phone,
      },
      CompanyName: "TradeSee",
      BillAddr: {
        Line1: userInfo?.userData?.address?.line1,
        City: userInfo?.userData?.address?.city,
        CountrySubDivisionCode: "US",
        PostalCode: userInfo?.userData?.address?.postalCode,
      },
    };

    try {
      const response = await axios.post(
        "https://api.tsalemetals.com:4578/create-customer",
        customerData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        addCustomerIDForUser(userInfo?.uid, response.data.Customer.Id);
        return true;
      }
    } catch (error) {
      console.error("Error creating customer:", error);
      let errorMessage = "Error";
      message.error(errorMessage);
      return false;
    }
  }

  const fetchDataC = async () => {
    try {
      const dataUnmapped = await getCompanieFavorite(userInfo.uid);
      const shortObj = dataUnmapped.sort(
        (a, b) => new Date(b.saved_at) - new Date(a.saved_at)
      );
      const mappedData = CompanyMapperInfo(shortObj);
      setCompanies(mappedData);
    } catch (error) {
      console.error("Erro ao buscar informações do usuário:", error);
    } finally {
      setLoadingg(false);
    }
  }

  const fetchData = async () => {
    try {
      const userData = await getUserInfo();
      setUserInfo(userData);

      const resp = await getFirstLogin();
      setIsFirstLogin(resp);
      if (resp) {
        const customerCreated = await createCustomer(userData);
        console.log("Customer created successfully!", customerCreated);
      }
    } catch (error) {
      console.error("Erro ao buscar informações do usuário:", error);
    }
  };

  useEffect(() => {
    if(auth) {
      fetchDataC()
      console.log("GetFavorite")
      if(userInfo == null) {
        console.log("GetInfo User")
        fetchData()
      }
    }
  }, [auth, userInfo])

  useEffect(() => {
    if (auth) {      
      if (userInfo) {
        getEndPlanUser(userInfo?.uid)
        .then((dados) => {
          setEndPlan(dados);
        })
        .catch((error) => {
          console.error("Erro ao verificar os dados:", error);
        });
      }
    }
  }, [auth, userInfo]);

  const handleToggle = () => {
    setToggleDawer(!toggleDrawer);
  }

  const openNotification = () => {
    setIsModalNotification(!isModalNotification);
    setIsNewNotification(false);
  };

  const endPlanDate = new Date(endPlan);

  const differenceInMilliseconds = endPlanDate - currentDate;

  const differenceInDays = Math.ceil(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  const shouldShowAlert = differenceInDays <= 3 && differenceInDays >= 0;

  const renderCompanies = () => {
    if (companies.length > 0 && Array.isArray(companies)) {
      let itemsToShow = 4;
      switch (true) {
        case windowWidth <= 1250:
          itemsToShow = 4;
          break;
        case windowWidth > 1250 && windowWidth < 1670:
          itemsToShow = 5;
          break;
        case windowWidth >= 1670 && windowWidth <= 2100:
          itemsToShow = 7;
          break;
        case windowWidth > 2100:
          itemsToShow = 8;
          break;
        default:
          itemsToShow = 4;
      }

      return companies.slice(0, itemsToShow).map((item, index) => (
        <Col
          className="cardCompany"
          style={{
            backgroundColor: "#fff",
            borderRadius: 8,
            margin: "3% 2%",
            padding: "0.5%",
            width: "150px",
            cursor: "pointer",
          }}
          key={index}
        >
          <Partner data={item} />
        </Col>
      ));
    } else {
      return (
        <Col style={{ width: "100%", justifyContent: "center", alignItems: "center", padding: "3% 5%" }}>
          <Link to="/trade-data" style={{ width: "100%" }}>
            <Row style={{ justifyContent: "center", textAlign: "center" }}>
              <ImgDefault src={EmptyIcon} width="100px" height="100px" />
              <TextDefault
                color="#026AA2"
                style={{ cursor: "pointer", alignContent: "center", margin: "0% 2%" }}
              >
                Search by company to add them to your favorites
              </TextDefault>
            </Row>
          </Link>
        </Col>
      );
    }
  };

  return (
    <>
      {auth ? (
        <ContainerHome>
          {isModalNotification ? (
            <BackgroundClose onClick={() => setIsModalNotification(false)} />
          ) : null}
          <Grid container item rowSpacing={1} xs={12} sm={12} md={12} lg={12} >
            <Assistant />
            <Modal
              open={isFirstLogin}
              onClose={() => closeModal("login")}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <ContainerFirstLogin>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ height: "100%", width: "100%" }}
                >
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <ColumnContainer
                      style={{ paddingLeft: 10, paddingTop: 20 }}
                    >
                      <TextDefault size="35px" color="#4b4b4b" bold="800">
                        Welcome
                      </TextDefault>
                      <TextDefault
                        size="20px"
                        color="#4b4b4b"
                        bold="400"
                        style={{ marginTop: 40 }}
                      >
                        Welcome to our metals and leads trading platform.
                      </TextDefault>
                      <TextDefault
                        size="20px"
                        color="#4b4b4b"
                        bold="400"
                        style={{ marginTop: 10 }}
                      >
                        We are here to help you make your trading experience
                        smooth and successful.
                      </TextDefault>
                    </ColumnContainer>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <div
                      style={{
                        width: "102.8%",
                        height: "100%",
                        backgroundColor: "#026AA2",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ImgDefault
                        src={WelcomeBanner}
                        width={"70%"}
                        height={"60%"}
                      />
                      <RowContainer
                        style={{
                          position: "absolute",
                          bottom: 20,
                          right: 20,
                        }}
                      >
                        <BtnDefault
                          color="#fff"
                          borderR="6px"
                          hoverColor="#e7edfe"
                          onClick={() => closeFirstLogin()}
                        >
                          <TextDefault size="17px" color="#026AA2">
                            Close
                          </TextDefault>
                        </BtnDefault>
                      </RowContainer>
                    </div>
                  </Grid>
                </Grid>
              </ContainerFirstLogin>
            </Modal>
            <Grid
              item
              xs={toggleDrawer ? 2 : 1}
              sm={toggleDrawer ? 2 : 1}
              md={toggleDrawer ? 3 : 1}
              lg={toggleDrawer ? 2 : 0.5}
              style={{ margin: 0, zIndex: 2}}
            >
              <Drawer handleToggle={handleToggle} initState={toggleDrawer} />
            </Grid>

            <Grid
              item
              xs={toggleDrawer ? 10 : 11}
              sm={toggleDrawer ? 10 : 11}
              md={toggleDrawer ? 9 : 11}
              lg={toggleDrawer ? 10 : 11}
              style={{
                height: "100%",
                padding: "0% 1% 0% 3%",
              }}
            >
              <Grid
                container
                item
                rowSpacing={2}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="container-home"

              >
                {/**1ª Row */}
                <Grid item xs={9} sm={9} md={9} lg={9}>
                  {shouldShowAlert && (
                    <>
                      <div style={{ marginTop: "4px" }}>
                        <Alert
                          message="Your plan is about to end, stay tuned to renew!"
                          type="error"
                          showIcon
                        />
                      </div>
                    </>
                  )}
                  <SearchInput
                    value={search}
                    isDrawerOpen={toggleDrawer}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Grid>

                <Modal
                  open={isLimitModalVisible}
                  onClose={() => closeModal("search")}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <>
                    <ContainerSeachHome>
                      <HeaderSearchModal attModal={closeModal} />
                      <ColumnContainer
                        style={{ height: 270, overflow: "auto" }}
                      >
                        <SearchList target={search} attModal={closeModal} />
                      </ColumnContainer>
                      <RecentSearch attModal={closeModal} />
                    </ContainerSeachHome>
                  </>
                </Modal>

                <Grid item xs={2.9} sm={2.9} md={2.9} lg={2.9}>
                  <Notifications>
                    <NotificationPainel
                      visible={isModalNotification}
                      modal={openNotification}
                      readIndicator={indicatorRed}
                    />

                    {isNewNotification ? <TagNotification /> : ""}

                    <NotificationBtn
                      className="btnNotification"
                      onClick={openNotification}
                    >
                      <svg viewBox="0 0 448 512" className="bell">
                        <path d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"></path>
                      </svg>
                    </NotificationBtn>
                  </Notifications>
                </Grid>

                  {/**2ª Row */}
                  <Grid
                    container
                    style={{ marginTop: 15, justifyContent: "space-between", width: "100%" }}
                  >
                    <Col md={24} lg={8} style={{width: "100%"}}>
                      <Link to="/trade-data">
                        <CardModulev2
                          img={ZoomIcon}
                          title="Trade Data"
                          subTitle="Find new business partners worldwide." 
                        />
                      </Link>
                    </Col>
                    <Col md={24} lg={7} style={{width: "100%"}}>
                      <Link to="/market-intelligence-dashboard">
                        <CardModulev2
                          img={WebIcon}
                          title="Market Intelligence"
                          subTitle="Search for any metal and view the prices of the last
                          three days."
                        />
                      </Link>
                    </Col>
                    <Col md={24} lg={7} style={{width: "100%"}}>
                      <Link to="/leadsenrichment-search">
                        <CardModulev2
                          img={UserIcon}
                          title="Leads Enrichment"
                          subTitle="Search for any potential clients you want and access to their contact data."
                        />
                      </Link>
                    </Col>
                  </Grid> 

                <Grid item xs={12} sm={12} md={12} lg={12} >
                  {companies.length > 0 ? (
                    <Row style={{justifyContent: 'flex-end', alignItems: "center", margin: "0",}}>
                      <Link to={`/trade-data?dataRecords=${true}`} style={{ cursor: "pointer" }}>
                        <TextDefault color="#026AA2" style={{marginRight: 5}}>More</TextDefault>
                        <ImgDefault src={arrowRight} width="15px" height="15px" style={{marginTop: 5}}/>
                      </Link>
                    </Row>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Row style={{ borderRadius: 8, backgroundColor: "#e9edf8" }} className="companies-row">
                    {renderCompanies()}
                  </Row>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: 20}}>
                  <Row style={{justifyContent: 'flex-end', alignItems: "center", margin: "0"}}>
                    <Link to="/credits" style={{cursor: "pointer"}}>
                      <TextDefault color="#026AA2" style={{marginRight: 5}}>Details</TextDefault>
                      <ImgDefault src={arrowRight} width="15px" height="15px" style={{marginTop: 5}}/>
                    </Link>
                   </Row>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Row style={{ 
                    backgroundColor: "#e9edf8",
                    padding: "2%",
                    borderRadius: 8,
                    marginBottom: "2%",
                  }}
                  >
                    {/**F1 */}
                    <Col md={24} lg={8} style={{width: "100%"}}>
                      <IndFinance style={{ position: "relative" }}>
                        <BordaLeft color="#026AA2"/>
                        <Col style={{ margin: "20px", width: "100%" }}>
                          <Row style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                            <span style={{ color: "#4b4b4b", fontSize: "20px", fontWeight: "700" }}>
                              {yourPlan - yourCredits} / {yourPlan} Credits
                            </span>
                          </Row>
                          <Row style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                            <span style={{ color: "#8a97aa", fontSize: "11px", fontWeight: "700" }}>
                              Use credits to access tools and information.
                            </span>
                          </Row>

                          <Row style={{marginTop: 15, width: "95%"}}>
                            <BarProgress>
                              <StatesProgress usage={isUsage}/>
                            </BarProgress>
                          </Row>
                          <Row style={{ width: "95%", justifyContent: "center", alignItems: "center", marginTop: 5, display: "flex" }}>
                            <span style={{ color: "#8a97aa", fontSize: "11px", fontWeight: "700" }}>
                              {isUsage}% Usage
                            </span>
                          </Row>
                        </Col>
                      </IndFinance>
                    </Col>
                    
                    {/**F2 */}
                    <Col md={24} lg={8} style={{width: "100%"}}>
                      <IndFinance style={{ position: "relative"}}>
                        <BordaLeft color="#004163"/>
                        <Row style={{marginTop: 20, marginLeft: 20, width: "100%",}}>
                          <Col style={{ width: "100%" }}>
                            <TextDefault size="14px" color="#026AA2" bold="700" style={{}}>● </TextDefault>  
                            <TextDefault size="14px" color="#4b4b4b" bold="700" style={{}}>{isTradeDataCredit} Trade Data</TextDefault>  
                          </Col>
                          <Col style={{ width: "100%" }}>
                            <TextDefault size="14px" color="#393F47" bold="700" style={{}}>● </TextDefault>  
                            <TextDefault size="14px" color="#4b4b4b" bold="700" style={{}}>{isMarketCredit} Market Intelligence</TextDefault>  
                          </Col>
                          <Col style={{ width: "100%" }}>
                            <TextDefault size="14px" color="#74DAE0" bold="700" style={{}}>● </TextDefault>  
                            <TextDefault size="14px" color="#4b4b4b" bold="700" style={{}}>{isLeadsCredit} Leads Enrichment</TextDefault>  
                          </Col>
                          <Col style={{margin: "1 0%", width: "95%"}}>
                            <BarProgress>
                              <StatesProgressFixed usage={isTradeDataPorcent} index={isTradeDataIndex}/>
                              <StatesProgressFixed color="#393F47" usage={isMarketCreditPorcent} index={isMarketCreditIndex}/>
                              <StatesProgressFixed color="#74DAE0" usage={isLeadsCreditPorcent} index={isLeadsCreditIndex}/>
                            </BarProgress>
                          </Col>
                        </Row>
                      </IndFinance>
                    </Col>
                    
                    {/**F3 */}
                    <Col md={24} lg={8} style={{width: "100%"}}>
                      <IndFinance style={{ position: "relative"}}>
                        <BordaLeft color="#026AA2"/>
                        <Col style={{margin: "20px", width: "100%"}}>
                          <Row>
                            <TextDefault size="20px" color="#4b4b4b" bold="700" style={{width: "100%"}}>{monthOldCredit} Credits</TextDefault>
                            <TextDefault size="11px" color="#8a97aa" bold="700">Consumption from the previous month</TextDefault>
                          </Row>
                          <Row style={{marginTop: 15, width: "95%"}}>
                            <BarProgress>
                              <StatesProgressFixed usage={monthOldPercent} color="#026AA2"/>
                            </BarProgress>
                          </Row>
                          <Row style={{width: "95%", justifyContent: "center", alignItems: "center", marginTop: 5}}>
                            <TextDefault size="11px" color="#8a97aa" bold="700">{monthOldPercent}% of the average</TextDefault>
                          </Row>
                        </Col>
                      </IndFinance>
                    </Col>
                  </Row>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
