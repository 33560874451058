import { auth, database, firestore } from "../database/config";
import { logDownload } from "./logFirebase";

export async function AddCompaniesToList(ListName, companies, mod) {
  return new Promise(async (resolve, reject) => {
    const user = auth.currentUser;
    const date = new Date().toISOString();

    if(mod !== "key") {

      companies.forEach(async(company) => {
        await database.ref("Users").child(user.uid).child("MyCompanyList").child(ListName).child(company.companyId).set({
          companyId: company.companyId,
          companyName: company.companyName,
          country: company.country,
          hsCode: company.matchedHsCodes || company.hsCode,
          numberOfShipments: company.numberOfShipments,
          role: company.role || "Supplier",
          shipmentValue: company.shipmentValue,
          tradingPartnerCount: company.tradingPartnerCount,
          lastUpdate: date,
        })
        .catch(e => {
          reject("Error: ", e)
        })
      })
    } else {

      // let array = []

      // companies.forEach(async(id) => {
      //   await firestore
      //     .collection("ListofCompanies")
      //     .where("companyId", "==", id)
      //     .get()
      //     .then((snapshot) => {

      //       console.log("Firestone-snapshot")
      //       console.log(id)
            
      //       snapshot.forEach((doc) => {
      //         array.push(doc.data());
      //       });
      //     })

      // })

      // console.log("Firestone")
      // console.log(array)
    }

  });
}

export function getListsCompanies() {
  return new Promise(async (resolve, reject) => {
    const user = auth.currentUser;  
    let array = []
    await database.ref("Users").child(user.uid).child("MyCompanyList").once("value", (snapshot) => {
      snapshot.forEach((item) => {

        getInfoCompaniesToList(item.key).then(resp => {
          let data = {
            ...resp,
            nameList: item.key
          }
          array.push(data)
        })
      })
    })

    logDownload({
      resp: array,
      title: "getListsCompanies",
      desc: "MyCompanyList"
    })
    resolve(array)
  });
}

export async function getCompaniesToList(ListName) {
  return new Promise(async (resolve, reject) => {
    const user = auth.currentUser;  
    let array = []
    await database.ref("Users").child(user.uid).child("MyCompanyList").child(ListName).once("value", (snapshot) => {
      snapshot.forEach((item) => {

        let data = {
          companyId: item.val().companyId,
          companyName: item.val().companyName,
          country: item.val().country,
          hsCode: item.val().hsCode,
          numberOfShipments: item.val().numberOfShipments,
          role: item.val().role,
          shipmentValue: item.val().shipmentValue,
          tradingPartnerCount: item.val().tradingPartnerCount,
          lastUpdate: item.val().lastUpdate,
        }

        array.push(data)
      })
    })
    resolve(array)  
  });
}

export async function getInfoCompaniesToList(ListName) {
  return new Promise(async (resolve, reject) => {
    const user = auth.currentUser;  
    let array = []
    await database.ref("Users").child(user.uid).child("MyCompanyList").child(ListName).once("value", (snapshot) => {
      snapshot.forEach((item) => {

        let data = {
          companyId: item.val().companyId,
          companyName: item.val().companyName,
          country: item.val().country,
          hsCode: item.val().hsCode,
          numberOfShipments: item.val().numberOfShipments,
          role: item.val().role,
          shipmentValue: item.val().shipmentValue,
          tradingPartnerCount: item.val().tradingPartnerCount,
          lastUpdate: item.val().lastUpdate,
        }

        array.push(data)
      })
    })
    
    let infos = {
      lastUpdate: getLastUpdate(array),
      role: checkRole(array),
      hsCode: getUniqueHSCode(array),
      country: getUniqueCountries(array),
      somaShipmentValue: somaArray(array),
      count: array.length
    }

    logDownload({
      resp: infos,
      title: "getInfoCompaniesToList",
      desc: "MyCompanyList"
    })

    resolve(infos)
    
  });
}

export async function removeCompaniesToList(ListName, companiesKeys) {
  return new Promise(async (resolve, reject) => {
    const user = auth.currentUser;
    companiesKeys.forEach(async(key) => {
      await database.ref("Users").child(user.uid).child("MyCompanyList").child(ListName).child(key).remove()
    })
  });
}

export async function removeList(Lists) {
  return new Promise(async (resolve, reject) => {
    const user = auth.currentUser;
    Lists.forEach(async(listName) => {
      await database.ref("Users").child(user.uid).child("MyCompanyList").child(listName).remove() 
    })
  });
}

function getLastUpdate(arrayDeObjetos) {
  if (arrayDeObjetos.length === 0) {
      return null;
  }

  let dataMaisRecente = arrayDeObjetos[0].lastUpdate;
  let dataMaisRecenteConvertida = new Date(dataMaisRecente);

  for (let i = 1; i < arrayDeObjetos.length; i++) {
      const dataAtual = arrayDeObjetos[i].lastUpdate;
      const dataAtualConvertida = new Date(dataAtual);
      if (dataAtualConvertida > dataMaisRecenteConvertida) {
          dataMaisRecente = dataAtual;
          dataMaisRecenteConvertida = dataAtualConvertida;
      }
  }

  return dataMaisRecente;
}

function somaArray(arr) {
  let total = 0;
  for (let i = 0; i < arr.length; i++) {
      total += arr[i].shipmentValue;
  }
  return total;
}


function checkRole(arr) {
  const roles = arr.map(obj => obj.role);

  if (roles.every(role => role === 'Supplier')) {
      return 'Supplier';
  } else if (roles.every(role => role === 'Buyer')) {
      return 'Buyer';
  } else {
      return 'Supplier | Buyer';
  }
}

function getUniqueHSCode(arr) {
  const uniqueHSCode = new Set(); // Use um Set para armazenar os números sem repetições

  arr.forEach((obj) => {

    obj.hsCode?.forEach((num) => {
      uniqueHSCode.add(num); // Adiciona o número ao Set
    });
  });

  return Array.from(uniqueHSCode); // Converte o Set para Array e retorna
}

function getUniqueCountries(arr) {
  const countries = arr.map(obj => obj.country);
  return [...new Set(countries)];
}
