import { database, firestore } from "../database/config";
import { logDownload } from "./logFirebase";

export default async function filterLeadsDB(domain, companyId) {
  return new Promise(async (resolve, reject) => {
    const processedDomain = domain?.replace(/^www\./i, '');
    let array = [];
    if (domain != null) {
      await firestore
        .collection("ListOfLeads")
        .where("domain", "==", processedDomain)        
        .orderBy("domain")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            array.push(doc.data());
          });
        })
        .catch((e) => {
          reject("Error: " + e);
        });
    } else {
      await firestore
        .collection("ListOfLeads")
        .where("domain", "==", processedDomain)       
        .orderBy("domain")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            array.push(doc.data());
          });
        })
        .catch((e) => {
          reject("Error: " + e);
        });
    }

    const filteredRole = array.filter((leads) => {
      if (leads.domain) {
        return leads.domain == processedDomain;
      } else {
        return domain == "Supplier";
      }
    });
    resolve(filteredRole[0]);
  });
}

export async function filterLeadsCompanyIdDB(companyId) {
  return new Promise(async (resolve, reject) => {
    let array = [];
    if (companyId != null) {
      await firestore
        .collection("ListOfLeads")
        .where("companyId", "==", companyId)        
        .orderBy("companyId")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            array.push(doc.data());
          });
        })
        .catch((e) => {
          reject("Error: " + e);
        });
    } else {
      await firestore
        .collection("ListOfLeads")
        .where("companyId", "==", companyId)       
        .orderBy("companyId")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            array.push(doc.data());
          });
        })
        .catch((e) => {
          reject("Error: " + e);
        });
    }

    const filteredRole = array.filter((leads) => {
      if (leads.companyId) {
        return leads.companyId == companyId;
      } else {
        return companyId == "Supplier";
      }
    });
    resolve(filteredRole[0]);
  });
}

export async function getEnrichmentLeadsByDomain(domain) {
  try {
    const leadsRef = database.ref("EnrichmentLeads");

    const snapshot = await leadsRef.orderByChild('domain').equalTo(domain).once('value');

    if (snapshot.exists()) {
      const data = snapshot.val();
      
      const firstKey = Object.keys(data)[0]; 
      return data[firstKey]; 
    } else {
      return null; 
    }
  } catch (error) {
    console.error("Erro ao buscar dados:", error);
    throw error; 
  }
}

export async function checkIfEnrichmentLeadsExist(domain) {
  try {
    const leadsRef = database.ref("EnrichmentLeads");

    const snapshot = await leadsRef.orderByChild('domain').equalTo(domain).once('value');

    return snapshot.exists();
  } catch (error) {
    console.error("Erro ao verificar a existência de dados:", error);
    throw error;
  }
}


//geolocalização
export async function getGeoCompaniesByDomain(domain, country, industries) {
  try {
    const leadsRef = database.ref("GeoCompanies");
    
    const snapshot = await leadsRef.orderByChild('domain').equalTo(domain).once('value');

    logDownload({
      resp: snapshot,
      title: "getGeoCompaniesByDomain",
      desc: "GeoCompanies"
    });

    if (snapshot.exists()) {
      const data = snapshot.val();

      const industriesString = industries ? industries.join(", ") : "";


      const filteredData = Object.keys(data).find((key) => {
        const item = data[key];
        return (
          item.countrySearch === country &&
          ((industriesString && item.industry === industriesString) || (!industriesString && !item.industry))
        );
      });

      if (filteredData) {
        return data[filteredData]; 
      } else {
        return null; 
      }
    } else {
      return null; 
    }
  } catch (error) {
    console.error("Erro ao buscar dados:", error);
    throw error;
  }
}

//somente o leads completo
export async function getAllLeadsByDomain(domain) {
  try {
    const leadsRef = database.ref("EnrichmentLeads");

    const snapshot = await leadsRef.orderByChild('domain').equalTo(domain).once('value');

    logDownload({
      resp: snapshot,
      title: "getAllLeadsByDomain",
      desc: "EnrichmentLeads"
    })

    if (snapshot.exists()) {
      const data = snapshot.val();
      
      const firstKey = Object.keys(data)[0];
      
      return data[firstKey]?.allLeads || null; 
    } else {
      return null; 
    }
  } catch (error) {
    console.error("Erro ao buscar dados na tabela allLeads:", error);
    throw error;
  }
}

// verifica se ja tem a empresa no banco
export async function checkIfGeoCompaniesExist(domain, country, industries) { 
  try {
    const leadsRef = database.ref("GeoCompanies");

    const snapshot = await leadsRef.orderByChild('domain').equalTo(domain).once('value');

    logDownload({
      resp: snapshot,
      title: "checkIfGeoCompaniesExist",
      desc: "GeoCompanies"
    });

    if (snapshot.exists()) {
      const data = snapshot.val();

      const industriesString = industries ? industries.join(", ") : "";

      for (const key in data) {     
        if (
          data[key].countrySearch === country &&
          ((industriesString && data[key].industry === industriesString) || (!industriesString && !data[key].industry))
        ) {          
          return true;
        }
      }
    }

    return false;
  } catch (error) {
    console.error("Erro ao verificar a existência de dados:", error);
    throw error;
  }
}

// save scout

export async function saveDomainScout(domain) {
  const scoutSupplyRef = database.ref("ScoutSupply");
  try {
   const newEntryRef = scoutSupplyRef.push();

    await newEntryRef.set({
      domain: domain,
    });

    return newEntryRef.key;
  } catch (error) {
    console.error("Erro ao salvar o domínio:", error);
    throw error;
  }
}

export async function getScoutData(domain) {
  try {
    const leadsRef = database.ref("ScoutSupply");

    const snapshot = await leadsRef.orderByChild('domain').equalTo(domain).once('value');

    if (snapshot.exists()) {
      const data = snapshot.val();
     
      const firstKey = Object.keys(data)[0]; 
      return data[firstKey]; 
    } else {
      return null; 
    }
  } catch (error) {
    console.error("Erro ao buscar dados:", error);
    throw error; 
  }
}

export async function checkIfScoutExist(domain) {
  try {
    const leadsRef = database.ref("ScoutSupply");

    const snapshot = await leadsRef.orderByChild('domain').equalTo(domain).once('value');

    logDownload({
      resp: snapshot,
      title: "checkIfScoutExist",
      desc: "ScoutSupply"
    })

    return snapshot.exists();
  } catch (error) {
    console.error("Erro ao verificar a existência de dados:", error);
    throw error;
  }
}