import React, { useEffect, useState } from "react";
import { ContainerHome } from "../../../assets/styles";
import { Grid } from "@mui/material";
import { authScreen } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import getUserInfo from "../../../hooks/getUsers";
import {
  ContainerInfo,
  LeftText,
  Rectangle,
  RightText,
  InfoValue,
  InfoTitle,
  ContainerPdf,
  LogoPdf,
} from "./stylePdf";
import { Descriptions } from "antd";
import iconLogo from "../../../icons/T-SaleMetals-03.svg";
import { Heading, InRow, Ml10 } from "../search/style";

export const PdfPage = React.forwardRef((props, ref) => {
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 500);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    });
  });

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  return (
    <ContainerPdf ref={ref}>
      <ContainerHome>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item container>
            <Rectangle>
              <LogoPdf src={iconLogo} size={"60px"} />

              <LeftText>TradeSee</LeftText>
              <RightText>Compliance Details</RightText>
            </Rectangle>
            <ContainerInfo>
              {/* <Heading>
                            Your {trade} compliance survey on the{" "}
                            {formattedDate}
                          </Heading> */}
              <InRow>
                <Heading>Country of import:</Heading>
                <p>{props?.item[0]?.countryOfImport}</p>

                <Ml10>
                  <Heading>Country of export:</Heading>
                </Ml10>
                <p>{props?.item[0]?.countryOfExport}</p>
              </InRow>
              <hr />
              <InRow>
                <Heading>Reference:</Heading>
                <p>{props?.item[0]?.reference}</p>
                <Ml10>
                  <Heading>Derived Reference:</Heading>
                </Ml10>
                <p>{props?.item[0]?.derivedReference}</p>
              </InRow>
              <hr />
              <InRow>
                <Heading>Controlling authority:</Heading>
                <p>{props?.item[0]?.controllingAuthority}</p>
                <Ml10>
                  <Heading>Scope of control:</Heading>
                </Ml10>

                <p>{props?.item[0]?.scopeOfControl}</p>
              </InRow>

              <hr />

              <h2>Notes</h2>
              <p>{props?.item[0]?.notes}</p>

              <h2>Exceptions</h2>
              <p>{props?.item[0]?.exceptions}</p>
            </ContainerInfo>
          </Grid>
        </Grid>
      </ContainerHome>
    </ContainerPdf>
  );
});
