import React, { useState } from "react";
import { BtnDefault, ColumnContainer, FormTitle, ImgOpt, InputDefault, RowContainer, TextDefault } from "../../assets/styles";
import { Grid } from "@mui/material";
import Drawer from "../../components/Drawer";
import { GroupInput } from "../simulation/styles";
import TableHs from "./components/table";
import LoadingPage from "../../components/LoadingPage";

export default function ManagerHsCode() {

    const [ts, useTs] = useState(false);
    const [toggleDrawer, useTroggleDawer] = useState(true);
    const [hs12, setHs12] = useState("");
    const [hs34, setHs34] = useState("");
    const [hs56, setHs56] = useState("");
    const [description, setDescription] = useState("");
    function SetToggle(state) {
        useTroggleDawer(state);
      }

    return(
    <>
        {ts ? (
            <Grid container rowSpacing={1} xs={12} sm={12} md={12} lg={12}>
                <Grid
                item
                xs={toggleDrawer ? 2 : 1}
                sm={toggleDrawer ? 2 : 1}
                md={toggleDrawer ? 3 : 1}
                lg={toggleDrawer ? 2 : 1}
                style={{ zIndex: 2 }}
                >
                <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
                </Grid>

                <Grid
                item
                xs={toggleDrawer ? 10 : 11}
                sm={toggleDrawer ? 10 : 11}
                md={toggleDrawer ? 8.6 : 11}
                lg={toggleDrawer ? 9.7 : 11}
                style={{
                    height: "100dvh",
                    paddingRight: 16,
                }}
                >
                    <ColumnContainer style={{marginTop: 20, width: "100%"}}>
                        <TextDefault size="30px" color="#4b4b4b" bold="800">Manager HSCodes</TextDefault>
                        <Grid container xs={12} sm={12} md={12} lg={12} style={{marginTop: 40, width: "100%"}}>
                            <Grid item xs={4} sm={4} md={4} lg={4}>                    
                                <TextDefault size="15px" color="#8a97aa" bold="600">HS Code 1-2</TextDefault>
                                <GroupInput className="groupInputSale" style={{marginTop: 8}} noMax={true}>
                                    <InputDefault
                                        className="inputSale"
                                        placeholder="HS Code 1-2"
                                        type="text"
                                        id="hs12"
                                        value={hs12}
                                        style={{ paddingLeft: 10 }}
                                        onChange={(item) => setHs12(item.target.value)}
                                        maxLength={2}
                                    />
                                </GroupInput>
                            </Grid> 
                            <Grid item xs={4} sm={4} md={4} lg={4}>                    
                                <TextDefault size="15px" color="#8a97aa" bold="600">HS Code 3-4</TextDefault>
                                <GroupInput className="groupInputSale" style={{marginTop: 8}} noMax={true}>
                                    <InputDefault
                                        className="inputSale"
                                        placeholder="HS Code 3-4"
                                        type="text"
                                        id="hs34"
                                        value={hs34}
                                        style={{ paddingLeft: 10 }}
                                        onChange={(item) => setHs34(item.target.value)}
                                        maxLength={2}
                                    />
                                </GroupInput>
                            </Grid> 
                            <Grid item xs={4} sm={4} md={4} lg={4}>                    
                                <TextDefault size="15px" color="#8a97aa" bold="600">HS Code 5-6</TextDefault>
                                <GroupInput className="groupInputSale" style={{marginTop: 8}} noMax={true}>
                                    <InputDefault
                                        className="inputSale"
                                        placeholder="HS Code 5-6"
                                        type="text"
                                        id="hs56"
                                        value={hs56}
                                        style={{ paddingLeft: 10 }}
                                        onChange={(item) => setHs56(item.target.value)}
                                        maxLength={2}
                                    />
                                </GroupInput>
                            </Grid> 
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: 20}}>                    
                                <TextDefault size="15px" color="#8a97aa" bold="600">Description HSCode</TextDefault>
                                <GroupInput className="groupInputSale" style={{marginTop: 8, width: "95.5%"}} noMax={true}>
                                    <InputDefault
                                        className="inputSale"
                                        placeholder="Descriptoin"
                                        type="text"
                                        id="descriptionHs"
                                        value={description}
                                        style={{ paddingLeft: 10 }}
                                        onChange={(item) => setDescription(item.target.value)}
                                        maxLength={2}
                                    />
                                </GroupInput>
                            </Grid> 
                            
                            <RowContainer style={{width: "100%", justifyContent: "flex-end", paddingRight: 65, marginTop: 20}}>
                                <BtnDefault>
                                    <TextDefault color="#fff" size="17px" bold="800">Save</TextDefault>
                                </BtnDefault>
                            </RowContainer>

                            <RowContainer style={{width: "95.5%", marginTop: 20}}>
                                <TableHs/>        
                            </RowContainer>

                        </Grid>

                        
                        

                    </ColumnContainer>              

                </Grid>
            </Grid>
        ) : (<LoadingPage/>)}
    </>
    )
}