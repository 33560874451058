export const data = [
    {
      matchScore: 100.0,
      listName: "U.S. General Services Administration (GSA) Parties Excluded",
      matchedEntityName: "PASNA INDUSTRY CO. (A.K.A. Pasna Industry Co.)",
      details: {
          effectiveDate: "2018-01-18",
          expiryDate: "NA",
          citationLink: "https://storage.googleapis.com/trademo-rps-products/US-GSA-PROGRAMS/US-GSA-PROGRAMS.pdf",
          notes: [
              "Notes : PII data has been masked from view",
              "Excluding Agency : TREAS-OFAC",
              "List Name : U.S. General Services Administration (GSA) Parties Excluded",
              "Entity Type : Special Entity Designation",
              "Exclusion Type : Prohibition/Restriction",
              "Authority : U.S. General Services",
              "Exclusion Program : Reciprocal",
              ""
          ],
          addressDetails: {
              country: "United Nations",
              state: "",
              city: "",
              zipcode: "",
              street: ""
          },
          nationality: [],
          citizenship: [],
          idDetails: [
              {
                  idType: "SAM Number",
                  idNumber: "S4MR65N42",
                  idIssueDate: "",
                  idIssuingCountry: "",
                  idNotes: ""
              }
          ],
          vesselInfo: {
              imoNumber: "",
              vesselCall: "",
              vesselOwner: "",
              vesselGrossTonnage: "",
              vesselFlag: "",
              vesselType: "",
              grossRegisteredTonnage: ""
          }
      }
    },
    {
      matchScore: 100.0,
      listName: "Japanese End User List",
      matchedEntityName: "PARDAZAN SYSTEM NAMAD ARMAN (A.K.A. Pasna Industry Co.)",
      details: {
       
          effectiveDate: "2022-10-19",
          expiryDate: "NA",
          citationLink: "https://storage.googleapis.com/trademo-rps-products/JP-METI/JP-METI.pdf",
          notes: [
              "List Name : Japanese End User List",
              "Authority : JP-Japanese End User List",
              ""
          ],
          addressDetails: {
              country: "Islamic Republic of Iran",
              state: "",
              city: "",
              zipcode: "",
              street: ""
          },
          nationality: [],
          citizenship: [],
          idDetails: [],
          vesselInfo: {
              imoNumber: "",
              vesselCall: "",
              vesselOwner: "",
              vesselGrossTonnage: "",
              vesselFlag: "",
              vesselType: "",
              grossRegisteredTonnage: ""
          }
      }
  },
  {
    matchScore: 50.0,
    listName: "Specially Designated Nationals List",
    matchedEntityName: " PARDAZAN SYSTEM NAMAD ARMAN (A.K.A. Pasna Industry Co.)",
    details: {
      
        effectiveDate: "2023-01-16",
        expiryDate: "NA",
        citationLink: "NA",
        notes: [
            "List Name : Specially Designated Nationals List",
            "Program List : IFSR",
            "NPWMD",
            "Entity Type : Entity",
            "Authority : DOT - Department of Treasury",
            ""
        ],
        addressDetails: {
            country: [
                "Iran",
                "Iran",
                "Iran"
            ],
            state: "",
            city: [
                "Tehran",
                "Tehran",
                "Tehran"
            ],
            zipcode: "",
            street: [
                "Number 8, Unit 14, Tavana Building, Khan Babaei Alley Nik Zare Street, Akbari Street, Ashrafti Esfahani Avenue",
                "Ghodarzi Alley, Building No. 11, Alborz, Third Floor No. 9, Monacoheri St., Saadi St.",
                "Sa'di St., Manoucohehri St., Goodarzi Alley Building No. 11, Alborz, Third Floor, No. 9"
            ],
            address: [
                "Number 8, Unit 14, Tavana Building, Khan Babaei Alley Nik Zare Street, Akbari Street, Ashrafti Esfahani Avenue Tehran  Iran",
                "Ghodarzi Alley, Building No. 11, Alborz, Third Floor No. 9, Monacoheri St., Saadi St. Tehran  Iran",
                "Sa'di St., Manoucohehri St., Goodarzi Alley Building No. 11, Alborz, Third Floor, No. 9 Tehran  Iran"
            ]
        },
        nationality: [],
        citizenship: [],
        idDetails: [
            {
                idType: "Additional Sanctions Information -",
                idNumber: "Subject to Secondary Sanctions",
                idIssueDate: "",
                idIssuingCountry: "",
                idNotes: "",
                idCountry: ""
            },
            {
                idType: "Website",
                idNumber: "http://www.pasnaindustry.com",
                idIssueDate: "",
                idIssuingCountry: "",
                idNotes: "",
                idCountry: ""
            }
        ],
        vesselInfo: {
            imoNumber: "",
            vesselCall: "",
            vesselOwner: "",
            vesselGrossTonnage: "",
            vesselFlag: "",
            vesselType: "",
            grossRegisteredTonnage: ""
        }
    }
},
  ];
  
  export default data;
